import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-para-empresa',
  standalone: true,
  imports: [],
  templateUrl: './para-empresa.component.html',
  styleUrl: './para-empresa.component.css'
})
export class ParaEmpresaComponent implements OnInit, AfterViewInit {

  @ViewChild("manual") manual!: ElementRef;
  @ViewChild("manuais") manuais!: ElementRef;
  @ViewChild("certidao") certidao!: ElementRef;
  @ViewChild("hidrometro") hidrometro!: ElementRef;

  constructor(
    private title: Title,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Para Sua Empresa");
  }

  ngAfterViewInit(): void {
      this.scrollToAccorddion();
  }

  accordionToggle(element: any) {
    const wrapper = element.closest('.item');
    wrapper.classList.toggle('-open');

    const collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
  }

  scrollToAccorddion() {
    this.route.fragment.subscribe(frag => {
      if (frag) {
        let element: any;
        switch (frag) {
          case 'manual-do-empreendedor':
            element = this.manual.nativeElement
            break;
  
          case 'manuais-e-normas':
            element = this.manuais.nativeElement
            break;
  
          case 'certidao-de-esgotamento-sanitario':
            element = this.certidao.nativeElement
            break;
  
          case 'hidrometro-individualizado':
            element = this.hidrometro.nativeElement
            break;
        }
        setTimeout(() => {
          window.scrollTo({ top: element.offsetTop - 130, behavior: 'smooth' });
        }, 0);
        this.accordionToggle(element);
      }
    })
  }
}
