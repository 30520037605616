import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-politica-privacidade',
  standalone: true,
  imports: [],
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css']
})
export class PoliticaPrivacidadeComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Politica de Privacidade");
}
}
