import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ErrorService } from '../error.service';
import { environment } from '../../environments/environment';
import { CarouselTemplate } from './template-carrossel';

@Injectable({
    providedIn: 'root'
})
export class TemplateCarrosselService {

    private templateCarrosselUrl = environment.apiUrl + '/api/v1/carouselTemplate';

    constructor(private http: HttpClient) { }

    getCarouselTemplate(): Observable<CarouselTemplate> {
        return this.http.get<CarouselTemplate>(this.templateCarrosselUrl)
            .pipe(
                catchError(ErrorService.handle<CarouselTemplate>('getCarouselTemplate'))
            );
    }
}
