<main class="main">
	<meta name="description" content="Descubra o programa Água Legal da Sabesp e veja como regularizamos o acesso à água potável em comunidades carentes. Saiba mais!">
		<nav class="breadcrumbs">
			<div class="container">
				<ul class="menu">
					<li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="O Que Fazemos">O Que Fazemos</a></li>
					<li><a class="breadcrumb-link" href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="Programa Água Legal">Programa Água Legal</a></li>
				</ul>

				<ul class="buttons">
					<li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
				</ul>
			</div>
		</nav>

		<article class="content">
			<div class="container">
				<h1 class="headline">Programa Água Legal</h1>

				<picture class="picture petals align-right">
					<img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/sustentabilidade/programa-agua-legal.jpg" alt="Funcionário da Sabesp explicando documento a moradora​" title="Programa Água Legal">
				</picture>

				<p>Na Sabesp, transformar vidas por meio do saneamento básico é uma meta diária. Com o Programa Água Legal, isso é uma realidade! Por meio dele, regularizamos o fornecimento de água em regiões de alta vulnerabilidade social, onde os moradores eram abastecidos de modo precário por tubulações improvisadas e sujeitas à contaminação.</p>
				<p>Além de levar água de qualidade diretamente para a casa das pessoas, o Água Legal contribui para a sustentabilidade ambiental, pois ajuda a evitar perdas e preservar os mananciais, e também para a inclusão social, uma vez que a conta de água se torna um comprovante de endereço, essencial para aquisição de diversos serviços.</p>
				<p>Em 2019, o Água Legal foi reconhecido como “Case de Sucesso em Água e Saneamento” pela Rede Brasil do Pacto Global, da Organização das Nações Unidas (<abbr title="Organização das Nações Unidas">ONU</abbr>). A premiação elegeu as melhores iniciativas brasileiras que impactam positivamente no Objetivo do Desenvolvimento Sustentável 6 (Água e Saneamento).</p>
			</div>
		</article>
	</main>
