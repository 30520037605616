<main class="main">
    <meta name="description" content="Saiba como a Sabesp promove a transparência em suas ações e operações, garantindo responsabilidade e confiança. Confira!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Transparência</h1>

            <p>
                Na Sabesp, somos compromissados com a transparência e a conduta ética em todas as nossas ações e nos relacionamentos com os nossos diversos públicos: administradores, colaboradores, clientes, acionistas, investidores, fornecedores, parceiros, terceiros, governo, comunidade e sociedade em geral. Asseguramos uma comunicação clara e eficaz e realizamos a prestação de contas de maneira transparente ao disponibilizarmos informações atualizadas sobre nossos contratos, licitações, balanços financeiros, investimentos, dados operacionais e parcerias.
            </p>

            <h1 class="headline title-transparencia">Informações de Interesse Público</h1>

            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="txt-center" width="200">Ver/Baixar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a target="_blank" rel="noopener noreferrer" class="-link"
                                    href="https://ri.sabesp.com.br/governanca-corporativa/codigo-de-conduta-e-integridade/">
                                    Código de Conduta e Integridade
                                </a>
                            </td>
                            <td class="txt-center">
                                <a target="_blank" class="action-icon -link-external"
                                    href="https://ri.sabesp.com.br/governanca-corporativa/codigo-de-conduta-e-integridade/">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a target="_blank" rel="noopener noreferrer" class="-link"
                                    href="https://www.contatoseguro.com.br/sabesp">
                                    Canal de Denúncias
                                </a>
                            </td>
                            <td class="txt-center">
                                <a target="_blank" class="action-icon -link-external"
                                    href="https://www.contatoseguro.com.br/sabesp">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link" href="/a-sabesp/meu-municipio">
                                    Meu Município
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link" href="/a-sabesp/meu-municipio">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/registros-falta-dagua">
                                    Registros de Falta de Água
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/registros-falta-dagua">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/relatorios-ouvidoria">
                                    Relatórios de Ouvidoria
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/relatorios-ouvidoria">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/o-que-fazemos/fornecimento-agua/qualidade-agua/analises-mensais-qualidade-agua-distribuida">
                                    Relatórios de Qualidade da Água
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/o-que-fazemos/fornecimento-agua/qualidade-agua/analises-mensais-qualidade-agua-distribuida">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h1 class="headline title-transparencia">Requisitos Legais</h1>

            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="txt-center" width="200">Ver/Baixar</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/bacia-rio-itapanhau">
                                    Bacia do Rio Itapanhaú
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/bacia-rio-itapanhau">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/fornecedores-servicos-publicitarios">
                                    Fornecedores de serviços publicitários
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/fornecedores-servicos-publicitarios">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo">
                                    Programa de Saneamento Sustentável e Inclusivo
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/relatorio-igualdade-salarial">
                                    Relatórios de Igualdade Salarial
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/relatorio-igualdade-salarial">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-baleia">
                                    Relatórios de monitoramento da Estação de Tratamento de Esgotos Baleia
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-baleia">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro">
                                    Relatórios de monitoramento da Estação de Tratamento de Esgotos de São Pedro
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a rel="noopener noreferrer" class="-link"
                                    href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-toninhas">
                                    Relatórios de monitoramento da Estação de Tratamento de Esgotos Toninhas
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -link"
                                    href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-toninhas">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>


</main>
