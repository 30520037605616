<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Não alterar Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">Não alterar A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Imprensa">Não alterar Imprensa</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/imprensa/releases" title="Releases">Não alterar Manual</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Manual">Não alterar Manual</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -back" href="/a-sabesp/imprensa/releases" title="Voltar para Releases">Não alterar Voltar para
                        Manual</a>
                </li>
                <li><a class="button -home" href="/" title="Início">Não alterar Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Não alterar Teste </h1>
            <p class="published">Não alterar Publicado em <time>Não alterar Manual</time></p>

            <!-- Alterar e Copiar o interno da div para APIs para o campo "content" em base64 https://www.base64encode.org/ de Comunicados, Releases e Noticias -->
            <div>
<p>A Sabesp inaugura nova agência para atendimento presencial, em Monteiro Lobato, nesta quinta-feira (25/1), às 10 horas, dando continuidade às ações de melhorias na prestação dos serviços ao município e atendendo ao pedido da comunidade. O novo espaço oferece mais conforto e melhor acesso à população, além de contar com horário de atendimento ampliado, das 8h às 16h.</p>
<p>O evento contará com a participação do prefeito de Monteiro Lobato, Edmar Araújo; do superintendente da Sabesp no Vale do Paraíba, Eduardo Camargo Afonso; além de vereadores e da população.</p>
<h2>Serviço:</h2>
<p>Sabesp abre nova agência para atendimento em Monteiro Lobato<br>
Dia 25, quinta-feira, às 10h<br>
Local: rua André Caetano Vitta, 22, Centro – Monteiro Lobato
            </div>
            <!-- Fim da div de conteúdo -->
        </div>
    </article>

    <section class="actions">
        <div class="container">
            <div class="text">
                <h2 class="headline">Não alterar Você também pode:</h2>


                <ul class="circle-buttons">
                    <li>
                        <a class="item print circle-button" onclick="window.print()">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="48" height="47"
                                    src="./assets/images/icon/printer2.svg" alt="Opção de imprimir conteúdo da Sabesp"
                                    title="Opção de imprimir conteúdo da Sabesp">
                            </figure>
                            <p class="caption">Imprimir Matéria</p>
                        </a>
                    </li>
                    <li>
                        <a class="item" href="javascript:void(0);">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="56" height="55"
                                    src="./assets/images/icon/share.svg" alt="Botão de compartilhar conteúdo"
                                    title="Botão de compartilhar conteúdo">
                            </figure>
                            <p class="caption">Compartilhar</p>
                        </a>

                        <nav class="share">
                            <a href="https://wa.me/551133888000" target="_blank"  class="whatsapp" title="Compartilhar no WhatsApp">Compartilhar
                                no WhatsApp</a>
                            <a href="https://facebook.com/SabespOficial"  target="_blank"   class="facebook" title="Compartilhar no Facebook">Compartilhar
                                no Facebook</a>
                            <a href="https://twitter.com/sabesp" target="_blank" class="twitter" title="Compartilhar no Twitter">Compartilhar
                                no Twitter</a>
                            <a href="https://linkedin.com/company/sabespcia" target="_blank"  class="linkedin" title="Compartilhar no LinkedIn">Compartilhar
                                no LinkedIn</a>
                        </nav>
                    </li>
                    <li>
                        <a class="item send-email" href="mailto:?subject=Título Release | Sabesp&amp;body=Lorem%20ipsum%20dolor%20sit%20amet%2C%20consectetur%20adipiscing%20elit.%20%0A%0A%20Sabesp%20inicia%20nesta%20quinta-feira%20(23%2F11)%20o%20feir%C3%A3o%20'Acertando%20Suas%20Contas%20com%20a%20Sabesp'%20%0A%0A%20https%3A%2F%2Fhotsites-foster.azurewebsites.net%2Fsabesp%2Finterna-release.html">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="54" height="37"
                                    src="./assets/images/icon/mail.svg"
                                    alt="Opção de receber conteúdo da Sabesp via e-mail"
                                    title="Opção de receber conteúdo da Sabesp via e-mail">
                            </figure>
                            <p class="caption">Receber por E-mail</p>
                        </a>
                    </li>
                </ul>
            </div>

            <picture class="figure">
                <img loading="lazy" fetchpriority="low" width="592" height="405"
                    src="./assets/images/illustration/sani-mostra-3.png" alt="Sani" title="Sani">
            </picture>
        </div>
    </section>

    <section class="more">
        <div class="container">
            <div class="columns">
                <nav class="nav">
                    <h2 class="headline title-link">Não alterar Relacionados</h2>

                    <ul class="items">
                        <li class="item">
                            <a class="title description-link" href="#">Não alterar Manual</a>
                            <a class="date" href="#"><time>Não alterar </time></a>
                        </li>
                    </ul>
                </nav>

                <nav class="nav">
                    <h2 class="headline title-link">Não alterar Acessados</h2>

                    <ul class="items">
                        <li class="item">
                            <a class="title description-link" href="#">Não alterar Manual</a>
                            <a class="date" href="#"><time>Não alterar</time></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</main>