<main class="main">
    <meta name="description" content="Entenda como a Sabesp realiza o processo de tratamento de água, garantindo pureza e segurança no abastecimento.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Soluções para Esgotos">Soluções para Esgotos</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Tratamento de Esgotos">Tratamento de Esgotos</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O Processo de Tratamento">O Processo de Tratamento</a></li>
            </ul>
            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">O Processo de Tratamento</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="/assets/images/o-que-fazemos/tratamento-esgotos-ete-barueri.jpg" alt="Vista aérea da Estação de Tratamento de Água da SABESP​" title="Tratamento de Esgotos">
            </picture>

            <p>O tratamento de esgoto é uma medida de saneamento básico que consiste na remoção de poluentes, tratando e purificando a água coletada de residências, comércios e indústrias antes de devolvê-la ao meio ambiente ou destiná-la para reúso. O tratamento e os processos utilizados dependem das características físicas, químicas e biológicas do esgoto.</p>
            <p>Na Região Metropolitana de São Paulo, o processo utilizado nas grandes estações de tratamento é por lodos ativados, no qual o esgoto tem suas frações líquida e sólida tratadas separadamente, nas denominadas fase líquida e fase sólida. Esse método foi desenvolvido na Inglaterra, em 1914, e é amplamente utilizado para tratamento de esgotos domésticos e industriais. No interior, além das estações convencionais, a Sabesp também adota um processo de tratamento baseado em lagoas de estabilização. Já no litoral, as instalações adotam o método de lodos ativados e, em algumas cidades, há emissários submarinos para lançar os esgotos tratados no mar.</p>
            <p class="-space-lg">Quer saber mais? Confira todos os <strong><a class="-no-underline -txt-blue" href="/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/tipos-tratamento">Tipos de Tratamento de Esgotos</a></strong> que a Sabesp utiliza!</p>


            <div class="accordion">
                <div class="item -no-overflow -open" id="fase-liquida">
                    <h2 class="head"><a class="accordion-link" (click)="accordionToggle($event)">Fase Líquida</a></h2>
                    <div class="collapse" aria-hidden="false">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Para conhecer os detalhes clique nos números</p>
                            <div class="txt-center">
                                <picture class="imagemap -space-md">
                                    <source media="(max-width: 767px)" width="403" height="1945" srcset="/assets/images/o-que-fazemos/infografico-tratamento-esgoto-fase-liquida-mobile.png">
                                    <img loading="lazy" fetchpriority="low" width="1255" height="825" alt="Tratamento de Esgotos - Fase Líquida" title="Tratamento de Esgotos - Fase Líquida" src="/assets/images/o-que-fazemos/infografico-tratamento-esgoto-fase-liquida.png">

                                    <div class="area" coords="46%,2.5%,8.5%,4.4%" coords-mobile="31.5%,-0.15%,29%,2.2%" style="--map-left-desk: 46%; --map-top-desk: 2.5%; --map-width-desk: 8.5%; --map-height-desk: 4.4%; --map-left-mobile: 31.5%; --map-top-mobile: -0.15%; --map-width-mobile: 29%; --map-height-mobile: 2.2%;">
                                        <button type="button" class="shape">1</button>

                                        <div class="popover" data-width="408%" data-mobile-left="-46.5%" style="--popover-width: 408%; --popover-left: -46.5%;">
                                            <p>Após a distribuição nas residências, a água utilizada para higiene pessoal, alimentação e limpeza vira esgoto. Ao deixar as casas, ele vai para as redes coletoras, passa pelos coletores, troncos e interceptores até chegar às Estações de Tratamento de Esgotos.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="66%,39.2%,8.7%,4.4%" coords-mobile="55.5%,17.9%,29%,2.2%" style="--map-left-desk: 66%; --map-top-desk: 39.2%; --map-width-desk: 8.7%; --map-height-desk: 4.4%; --map-left-mobile: 55.5%; --map-top-mobile: 17.9%; --map-width-mobile: 29%; --map-height-mobile: 2.2%;">
                                        <button type="button" class="shape">2</button>

                                        <div class="popover" data-width="386%" data-mobile-left="-67.9%" style="--popover-width: 386%; --popover-left: -67.9%;">
                                            <p>Antes de ser tratado, o esgoto passa por grades para retirar a sujeira (papel, plástico, tampinha, etc).</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="79%,63.1%,13.9%,4.4%" coords-mobile="55.5%,31.6%,30.2%,3%" style="--map-left-desk: 79%; --map-top-desk: 63.1%; --map-width-desk: 13.9%; --map-height-desk: 4.4%; --map-left-mobile: 55.5%; --map-top-mobile: 31.6%; --map-width-mobile: 30.2%; --map-height-mobile: 3%;">
                                        <button type="button" class="shape">3</button>

                                        <div class="popover -right" data-width="251%" data-mobile-left="-68.5%" style="--popover-width: 251%; --popover-left: -68.5%;">
                                            <p>Depois de passar pelas grades, o esgoto é transportado para uma caixa que vai retirar a areia contida nele.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="60.7%,77.9%,12.2%,6.5%" coords-mobile="55.5%,45.4%,38.4%,2.8%" style="--map-left-desk: 60.7%; --map-top-desk: 77.9%; --map-width-desk: 12.2%; --map-height-desk: 6.5%; --map-left-mobile: 55.5%; --map-top-mobile: 45.4%; --map-width-mobile: 38.4%; --map-height-mobile: 2.8%;">
                                        <button type="button" class="shape">4</button>

                                        <div class="popover" data-width="315%" data-mobile-left="-72.2%" style="--popover-width: 315%; --popover-left: -72.2%;">
                                            <p>Após a caixa de areia, o esgoto é enviado aos decantadores primários onde ocorre a sedimentação de partículas mais pesadas.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="46.3%,67.8%,12%,6.5%" coords-mobile="14.4%,67.4%,38%,3%" style="--map-left-desk: 46.3%; --map-top-desk: 67.8%; --map-width-desk: 12%; --map-height-desk: 6.5%; --map-left-mobile: 14.4%; --map-top-mobile: 67.4%; --map-width-mobile: 38%; --map-height-mobile: 3%;">
                                        <button type="button" class="shape">5</button>

                                        <div class="popover" data-width="349%" data-mobile-left="-35%" style="--popover-width: 349%; --popover-left: -35%;">
                                            <p>O esgoto é composto por matéria orgânica e microrganismos. Nos tanques de aeração, o ar fornecido faz com que os microrganismos ali presentes se multipliquem e se alimentem de material orgânico, formando o lodo e diminuindo assim a carga poluidora do esgoto.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="33.9%,35.5%,12.2%,6%" coords-mobile="46%,84%,40%,2.6%" style="--map-left-desk: 33.9%; --map-top-desk: 35.5%; --map-width-desk: 12.2%; --map-height-desk: 6%; --map-left-mobile: 46%; --map-top-mobile: 84%; --map-width-mobile: 40%; --map-height-mobile: 2.6%;">
                                        <button type="button" class="shape">6</button>

                                        <div class="popover" data-width="263%" data-mobile-left="-64.4%" style="--popover-width: 263%; --popover-left: -64.4%;">
                                            <p>Nos decantadores secundários, o sólido restante vai para o fundo e a parte líquida já está sem 90% das impurezas. Esta água não pode ser bebida. Ela é lançada nos rios ou reaproveitada para limpar ruas, praças e regar jardins.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="19.8%,89.4%,8%,10.5%" coords-mobile="0%,63%,0%,0%" style="--map-left-desk: 19.8%; --map-top-desk: 89.4%; --map-width-desk: 8%; --map-height-desk: 10.5%; --map-left-mobile: 0%; --map-top-mobile: 63%; --map-width-mobile: 0%; --map-height-mobile: 0%;">
                                        <a class="shape" href="#fase-solida">Fase sólida</a>
                                    </div>
                                </picture>
                            </div>

                            <p class="txt-center"><a class="-fw-medium -no-underline -download2" target="_blank" rel="noopener noreferrer" href="/assets/pdf/o-que-fazemos/solucoes-esgotos/tratamento-esgotos/sabesp-tratamento-esgoto-liquido.pdf" download="">Clique aqui para baixar o infográfico</a></p>
                        </div>
                    </div>
                </div>

                <div class="item -no-overflow" id="fase-solida">
                    <h2 class="head"><a class="accordion-link" (click)="accordionToggle($event)">Fase Sólida</a></h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Para conhecer os detalhes clique nos números</p>
                            <div class="txt-center">
                                <picture class="imagemap -space-md">
                                    <source media="(max-width: 767px)" width="397" height="2557" srcset="/assets/images/o-que-fazemos/infografico-tratamento-esgoto-fase-solida-mobile.png">
                                    <img loading="lazy" fetchpriority="low" width="1241" height="1055" alt="Tratamento de Esgotos - Fase Sólida" title="Tratamento de Esgotos - Fase Sólida" src="/assets/images/o-que-fazemos/infografico-tratamento-esgoto-fase-solida.png">

                                    <div class="area" coords="36.3%,17%,13%,3.4%" coords-mobile="1.5%,32%,44%,5.6%" style="--map-left-desk: 36.3%; --map-top-desk: 17%; --map-width-desk: 13%; --map-height-desk: 3.4%; --map-left-mobile: 1.5%; --map-top-mobile: 32%; --map-width-mobile: 44%; --map-height-mobile: 5.6%;">
                                        <button type="button" class="shape">1</button>

                                        <div class="popover" data-width="379%" data-mobile-left="-26.2%" style="--popover-width: 379%; --popover-left: -26.2%;">
                                            <p>Nos adensadores acontece o processo de adensamento do lodo primário (oriundo dos Decantadores Primários) para que o lodo se torne mais concentrado, através da separação de uma parte da água presente e do sólido, semelhante aos decantadores.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="36.3%,53.3%,11.2%,3.4%" coords-mobile="17.5%,59.2%,39%,5.6%" style="--map-left-desk: 36.3%; --map-top-desk: 53.3%; --map-width-desk: 11.2%; --map-height-desk: 3.4%; --map-left-mobile: 17.5%; --map-top-mobile: 59.2%; --map-width-mobile: 39%; --map-height-mobile: 5.6%;">
                                        <button type="button" class="shape">2</button>

                                        <div class="popover" data-width="324%" data-mobile-left="-38.3%" style="--popover-width: 324%; --popover-left: -38.3%;">
                                            <p>O lodo do decantador secundário será tratado pelo processo de adensamento por flotação, que consiste na separação da água do sólido através da introdução de água com microbolhas de ar. Em algumas estações esse processo é realizado por centrifugas.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="76.5%,44.6%,13.4%,3.4%" coords-mobile="54%,35%,44.2%,5.6%" style="--map-left-desk: 76.5%; --map-top-desk: 44.6%; --map-width-desk: 13.4%; --map-height-desk: 3.4%; --map-left-mobile: 54%; --map-top-mobile: 35%; --map-width-mobile: 44.2%; --map-height-mobile: 5.6%;">
                                        <button type="button" class="shape">3</button>

                                        <div class="popover -right" data-width="290%" data-mobile-left="-73.5%" style="--popover-width: 290%; --popover-left: -73.5%;">
                                            <p>Recebem o lodo proveniente dos sistemas de adensamento. Neles, há microorganismos anaeróbicos que degradam a matéria orgânica presente no lodo formando assim gás metano e água, promovendo a estabilização do lodo, ou seja, não haverá odores desagradáveis.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="72.5%,81.5%,14.6%,4.8%" coords-mobile="59%,84.2%,39.2%,11.6%" style="--map-left-desk: 72.5%; --map-top-desk: 81.5%; --map-width-desk: 14.6%; --map-height-desk: 4.8%; --map-left-mobile: 59%; --map-top-mobile: 84.2%; --map-width-mobile: 39.2%; --map-height-mobile: 11.6%;">
                                        <button type="button" class="shape">4</button>

                                        <div class="popover -right" data-width="315%" data-mobile-left="-75.7%" style="--popover-width: 315%; --popover-left: -75.7%;">
                                            <p>Consiste na retirada de uma parcela da água presente no lodo. São realizados por equipamentos como: filtros-prensa, centrífugas, secadores térmicos, etc. O lodo passa a ter um teor de sólidos que, dependendo do processo de desaguamento adotado, pode ser entre 25 a 40%.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="40.7%,83.9%,8.2%,3.3%" coords-mobile="25.5%,78%,29.5%,5.6%" style="--map-left-desk: 40.7%; --map-top-desk: 83.9%; --map-width-desk: 8.2%; --map-height-desk: 3.3%; --map-left-mobile: 25.5%; --map-top-mobile: 78%; --map-width-mobile: 29.5%; --map-height-mobile: 5.6%;">
                                        <button type="button" class="shape">5</button>

                                        <div class="popover" data-width="376%" data-mobile-left="-41.2%" style="--popover-width: 376%; --popover-left: -41.2%;">
                                            <p>Equipamento para transportar as “tortas” de lodo desaguado para seu local de armazenamento.</p>
                                        </div>
                                    </div>

                                    <div class="area" coords="17%,67.5%,16.1%,6.6%" coords-mobile="0%,86.2%,41.2%,14.6%" style="--map-left-desk: 17%; --map-top-desk: 67.5%; --map-width-desk: 16.1%; --map-height-desk: 6.6%; --map-left-mobile: 0%; --map-top-mobile: 86.2%; --map-width-mobile: 41.2%; --map-height-mobile: 14.6%;">
                                        <button type="button" class="shape">6</button>

                                        <div class="popover" data-width="182%" data-mobile-left="-23.6%" style="--popover-width: 182%; --popover-left: -23.6%;">
                                            <p>Neste local são armazenadas as tortas de lodo aguardando seu transporte para destinação final em aterros sanitários.</p>
                                        </div>
                                    </div>
                                </picture>
                            </div>

                            <p class="txt-center"><a class="-fw-medium -no-underline -download2" target="_blank" rel="noopener noreferrer" href="/assets/pdf/o-que-fazemos/solucoes-esgotos/tratamento-esgotos/sabesp-tratamento-esgoto-solido.pdf" download="">Clique aqui para baixar o infográfico</a></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </article>

</main>
