<main class="main">
    <meta name="description" content="Acesse o relatório da Sabesp sobre a qualidade da água dos mananciais, com dados atualizados e transparência no fornecimento.">
    <nav class="breadcrumbs">
        <div class="container">

            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
               <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a href="/o-que-fazemos/fornecimento-agua/qualidade-agua/" title="Qualidade da Água">Qualidade da Água</a></li>
                <li><a href="javascript:void(0);"
                    title="Relatórios de Qualidade da Água dos Mananciais">Relatórios de Qualidade da Água dos Mananciais</a></li>
            </ul>
            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>
    <article class="content">
        <div class="container">

            <h1 class='headline'>Monitoramento dos Mananciais</h1>

            <p>O monitoramento dos mananciais é realizado em pontos estratégicos de cada represa para acompanhar a qualidade da água que será captada para tratamento e posteriormente distribuída à população.</p>

            <p>Este monitoramento é realizado através de sondas de monitoramento e através de coletas de amostras de água por equipes da Sabesp com frequências semanais ou mensais. As amostras de água coletadas são analisadas em laboratório da própria companhia. </p>

            <p>A seguir estão os relatórios: </p>



            <br><br><h2>Monitoramento da Qualidade da Água</h2>





            <br><br><h2>2024</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>



            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_032024 (v_16_04_2024).pdf">  Março 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_032024 (v_16_04_2024).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_022024 (v_16_04_2024).pdf">  Fevereiro 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_022024 (v_16_04_2024).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_012024 (v_16_04_2024).pdf"> Janeiro  2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_012024 (v_16_04_2024).pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2023</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_122023 (v_16_04_2024).pdf"> Dezembro 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_122023 (v_16_04_2024).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_112023 (v_08_01_2024).pdf"> Novembro 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_112023 (v_08_01_2024).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_102023 (v_08_01_2024).pdf"> Outubro 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_102023 (v_08_01_2024).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_092023 (v_28_12_2023).pdf"> Setembro 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_092023 (v_28_12_2023).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_082023 (v_28_12_2023).pdf"> Agosto 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_082023 (v_28_12_2023).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_072023 (v_28_12_2023).pdf"> Julho 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_072023 (v_28_12_2023).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_062022.pdf"> Junho 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_062022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_052023 (v_13_07_2023).pdf"> Maio 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_052023 (v_13_07_2023).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_042023 (v_28_12_2023).pdf"> Abril 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_042023 (v_28_12_2023).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_032023 (v_10_07_2023).pdf"> Março 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_032023 (v_10_07_2023).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_022023 (v_10_07_2023).pdf"> Fevereiro 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_022023 (v_10_07_2023).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/2023/monitoramento_112023 (v_28_12_2023).pdf"> Janeiro 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/2023/monitoramento_112023 (v_28_12_2023).pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2022</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_122022.pdf"> Dezembro 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_122022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_112022.pdf"> Novembro 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_112022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_102022.pdf"> Outubro 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_102022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_092022.pdf"> Setembro 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_092022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_082022.pdf"> Agosto 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_082022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_072022.pdf"> Julho 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_072022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_062022.pdf"> Junho 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_062022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_052022v3.pdf"> Maio 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_052022v3.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_042022_v4.pdf"> Abril 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_042022_v4.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_032022_v4.pdf"> Março 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_032022_v4.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_022022_v03.pdf"> Fevereiro 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_022022_v03.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_012022_v03.pdf"> Janeiro 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_012022_v03.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2021</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_122021_v03.pdf"> Dezembro 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_122021_v03.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_112021_v03.pdf"> Novembro 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_112021_v03.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_102021_v03.pdf"> Outubro 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_102021_v03.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_09_2021.pdf"> Setembro 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_09_2021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_082021.pdf"> Agosto 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_082021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_072021.pdf"> Julho 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_072021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_062021.pdf"> Junho 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_062021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_052021.pdf"> Maio 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_052021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_042021.pdf"> Abril 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_042021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_032021 (v_20_04_2021).pdf"> Março 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_032021 (v_20_04_2021).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_022021_v2.pdf"> Fevereiro 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_022021_v2.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_012021.pdf"> Janeiro 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_012021.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2020</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_122020.pdf"> Dezembro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_122020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_112020_v2.pdf"> Novembro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_112020_v2.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_102020_v2.pdf"> Outubro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_102020_v2.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_092020_v2.pdf"> Setembro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_092020_v2.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_082020 (v_02_10_2020).pdf"> Agosto 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_082020 (v_02_10_2020).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_072020.pdf"> Julho 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_072020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_072020.pdf"> Junho 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_072020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_maio20.pdf"> Maio 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_maio20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_abr20.pdf"> Abril 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_abr20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_mar20.pdf"> Março 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_mar20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_fev20.pdf"> Fevereiro 2020 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_fev20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_jan20.pdf"> Janeiro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_jan20.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2019</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_dez19.pdf"> Dezembro 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_dez19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_112019.pdf"> Novembro 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_112019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_102019.pdf"> Outubro 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_102019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_set19.pdf"> Setembro 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_laboratorial_automatico/monitoramento_set19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_agosto2019.pdf"> Agosto 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_agosto2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_072019.pdf"> Julho 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_072019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_062019.pdf"> Junho 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_062019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_052019.pdf"> Maio 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_052019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_042019.pdf"> Abril 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_042019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_032019.pdf"> Março 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_032019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_022019.pdf"> Fevereiro 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_022019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_012019.pdf"> Janeiro 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_012019.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2> Anos anteriores</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2016-2018.pdf"> Monitoramento 2016-2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2016-2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2013-2015.pdf"> Monitoramento 2013-2015</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2013-2015.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2010-2012.pdf"> Monitoramento 2010-2012</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2010-2012.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2007-2009.pdf"> Monitoramento 2007-2009</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2007-2009.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2004-2006.pdf"> Monitoramento 2004-2006</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_2004-2006.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_1999-2003.pdf"> Monitoramento 1999-2003</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/monitoramento_1999 a 2018/monitoramento_1999-2003.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


        </div>
    </article>


</main>
