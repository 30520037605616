import { Component, ViewEncapsulation } from '@angular/core';
import { City, CityDoc } from '../city';
import { CityService } from '../city.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-minha-cidade',
  templateUrl: './minha-cidade.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MinhaCidadeComponent {
  cities: City[] = [];
  selectedCity = '';
  selectedName = '';
  cityDocs: CityDoc[] = [];

  constructor(private cityService: CityService, private location: Location, private route: ActivatedRoute, private meta: Meta, private title: Title) { }

  ngOnInit() {
    this.getCities();
    this.selectCity();

    this.title.setTitle("Sabesp | Meu Município ");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Consulte e baixe contratos e termos aditivos dos serviços de saneamento fornecidos pela Sabesp para 376 municípios do Estado de São Paulo.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, contratos, saneamento, municípios, São Paulo '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Meu Município '
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Meu Município '
    });
  }

  selectCity() {
    const city = this.route.snapshot.paramMap.get('city');
    if (city) {
      this.getCityDocs(city);
      this.selectedCity = city;
    }
  }

  navigateToCity() {
    this.cityDocs = [];
    if (this.selectedCity) {
      this.getCityDocs(this.selectedCity);
      this.updateCityName();
    }
    const loc = "/a-sabesp/meu-municipio/" + this.selectedCity;
    this.location.go(loc);
  }

 getCities(): void {
  this.cityService.getCitiesWithDocs()
    .subscribe(cities => {
      this.cities = cities;
      this.updateCityName();
    });
}

  getCityDocs(city: string): void {
    this.cityService.getCityDocs(city)
      .subscribe(docs => this.cityDocs = docs);
  }

  updateCityName() {
    if (this.selectedCity) {
      const city = this.cities.find(city => city.id === this.selectedCity);
      if (city) {
        this.selectedName = city.name;
      }
    }
  }
}
