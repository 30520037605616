<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Item">Item</a></li>
                <li><a href="javascript:void(0);" title="Item">Item</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Título</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380"
                    src="./assets/images/template-carrossel.png" alt="Título" title="Título">
            </picture>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi vel,
                tristique blandit purus. Duis et sagittis urna. Pellentesque enim felis, aliquet vel erat at, vehicula
                fringilla ante. Aenean varius risus nec finibus feugiat. Cras commodo dignissim felis, et sodales nulla
                tincidunt non. Fusce lobortis justo in dictum ultrices. Aliquam sodales pharetra dictum. Integer
                eleifend egestas laoreet.</p>
            <p>Ut tincidunt dui quis est pharetra, in commodo nibh hendrerit. Integer imperdiet elit finibus risus
                convallis aliquet. Nullam tempor auctor euismod. Nam arcu nulla, convallis id iaculis quis, facilisis ac
                ipsum. Nam sit amet dui id libero vehicula pretium. Proin volutpat elit id mi pellentesque pretium.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi vel,
                tristique blandit purus. Duis et sagittis urna. Pellentesque enim felis, aliquet vel erat at, vehicula
                fringilla ante. Aenean varius risus nec finibus feugiat. Cras commodo dignissim felis.</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi vel,
                tristique blandit purus. Duis et sagittis urna. Pellentesque enim felis, aliquet vel erat at, vehicula
                fringilla ante. Aenean varius risus nec finibus feugiat. Cras commodo dignissim felis, et sodales nulla
                tincidunt non. Fusce lobortis justo in dictum ultrices. Aliquam sodales pharetra dictum. Integer
                eleifend egestas laoreet.</p>
            <p>Ut tincidunt dui quis est pharetra, in commodo nibh hendrerit. Integer imperdiet elit finibus risus
                convallis aliquet. Nullam tempor auctor euismod. Nam arcu nulla, convallis id iaculis quis, facilisis ac
                ipsum. Nam sit amet dui id libero vehicula pretium. Proin volutpat elit id mi pellentesque pretium. Ut
                imperdiet feugiat urna, non efficitur diam porta at. Vestibulum suscipit rhoncus quam, at consequat sem
                ullamcorper a. Morbi sed ex sit amet leo iaculis efficitur et vel metus. Cras at turpis volutpat enim
                rutrum commodo. Ut eget nisl odio. Nam hendrerit turpis velit, ac blandit eros commodo ac. Aenean
                laoreet egestas mauris et maximus.</p>
            <p>Sed pulvinar, nisi a imperdiet pellentesque, urna urna elementum sapien, in fringilla lectus lorem nec
                lacus. Nulla gravida ullamcorper semper. Sed quis congue urna. Nam condimentum elit et nulla accumsan
                semper. Sed vel eros a eros accumsan aliquam. Donec aliquam efficitur elit, et congue lacus consequat
                consequat. Duis diam neque, vulputate non hendrerit id, bibendum vel dolor. Phasellus neque arcu,
                pellentesque cursus tellus ut, tincidunt mollis metus. In ac sapien ut nunc fermentum condimentum a non
                dolor. Vestibulum turpis quam, aliquet vitae magna eget, pretium molestie enim. Duis sodales sapien
                metus, in aliquam lectus fermentum vitae. Nunc leo urna, pharetra ac vehicula sit amet, posuere ut
                sapien. Vivamus mollis lacinia mollis. Vivamus sollicitudin sed ante sit amet convallis.</p>
        </div>
    </article>

    <app-carrossel></app-carrossel>

    <section class="more -white">
        <div class="container">
            <nav class="nav">
                <h2 class="headline">Lorem ipsum dolor sit amet</h2>

                <ul class="items">
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con tdiperra. Sit justo velit, eu sed.</a>
                    </li>
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con turadip iscingelit.</a>
                    </li>
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con tdiperra. Sit justo velit, eu sed.</a>
                    </li>
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con turadip iscingelit.</a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</main>