<section class="filters">
    <div class="container">
        <form class="form" method="get" action="">
            <fieldset class="categories">
                <div class="select">
                    <select name="categories" id="categories" class="filter" [(ngModel)]="filterCategory" (change)="filterByCategory()">
                        <option [value]="''">Categorias</option>
                        <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
                    </select>
                    <span class="icon"></span>
                </div>
            </fieldset>

            <fieldset class="dates">
                <label for="dateStart">Buscar por data:</label>
                <input type="date" name="dateStart" id="dateStart" class="filter" title="De" placeholder="dd/mm/aaaa"  #starDate [(ngModel)]="starDateValue"  (change)="filterByStartDate(starDate.value)">
                <input type="date" class="filter" name="dateEnd" id="dateEnd" title="Até" placeholder="dd/mm/aaaa"  [(ngModel)]="endDateValue"  [style.pointer-events]="disabled ? 'none' : 'initial'"  #endDate (change)="filterByEndDate(endDate.value)">
            </fieldset>

            <fieldset class="text">
                <input type="search" #search name="q" id="news-search-input" class="input filter" placeholder="Buscar por palavra chave">
                <div class="buttons">
                    <button type="button" class="btn-keyboard"  data-to-focus="news-search-input" title="Ferramenta de inserção de texto">Teclado</button>
                    <button type="submit" class="btn-submit" title="Buscar" (click)="filterTypeSearch(search.value)">Buscar</button>
                </div>
            </fieldset>

            <fieldset class="order">
                <div class="select">
                    <select name="order" id="order" class="filter"  #orderBy (change)="orderByChange(orderBy.value)">
                        <option value="">Ordenar por</option>
                        <option value="DESC">Mais recentes</option>
                        <option value="ASC">Mais antigas</option>
                    </select>
                    <span class="icon"></span>
                </div>
            </fieldset>
        </form>
    </div>
</section>
