import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-registros-falta-dagua',
  standalone: true,
  imports: [],
  templateUrl: './registros-falta-dagua.component.html',
  styleUrl: './registros-falta-dagua.component.css'
})
export class RegistrosFaltaDaguaComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Registros de Falta d'Água");
}
}
