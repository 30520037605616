import {Component} from '@angular/core';

@Component({
    selector: 'app-template-geral-interno',
    standalone: true,
    imports: [],
    templateUrl: './template-geral-interno.component.html',
    styleUrls: ['./template-geral-interno.component.css',
    ]
})
export class TemplateGeralInternoComponent {

}
