<main class="main">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li><a href="/" title="Início">Início</a></li>
        <li>
          <a
            class="breadcrumb-link"
            href="/fale-com-a-sabesp"
            title="Canais de Atendimento"
            >Canais de Atendimento</a
          >
        </li>
        <li>
          <a href="javascript:void(0);" title="Perguntas Frequentes"
            >Perguntas Frequentes</a
          >
        </li>
      </ul>

      <ul class="buttons">
        <li><a class="button -home" href="./" title="Início">Início</a></li>
      </ul>
    </div>
  </nav>

  <section class="generic-filters">
    <div class="container">
      <form class="form" method="get" action="">
        <fieldset class="questions">
          <div class="select">
            <select name="questions" id="questions" [(ngModel)]="filterTerm">
              <option [value]="''">Categorias</option>
              <option *ngFor="let category of categories" [value]="category">
                {{ category }}
              </option>
            </select>
            <span class="icon"></span>
          </div>
        </fieldset>

        <fieldset class="text">
          <input
            type="search"
            name="q"
            id="news-search-input"
            [(ngModel)]="searchTerm"
            class="input"
            placeholder="Buscar por palavra chave"
          />
          <div class="buttons">
            <button
              type="button"
              class="btn-keyboard"
              data-to-focus="news-search-input"
              title="Ferramenta de inserção de texto"
            >
              Teclado
            </button>
            <button
              type="submit"
              class="btn-submit"
              (click)="termImput(searchTerm)"
              title="Buscar"
            >
              Buscar
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </section>

  <section class="content">
    <div class="container">
      <h1 class="headline">Perguntas Frequentes</h1>
      <p>
        Tem dúvida sobre algo ou algum serviço? Confira abaixo as principais
        respostas para cada assunto.
      </p>
      <div class="accordion -accordion">
        <div
          *ngFor="
            let item of questions
              | filterBy
                : ['response', 'categoryName', 'question']
                : getTerms();
            let i = index
          "
          class="item"
        >
          <h2 class="head" (click)="accordionToggle($event)">
            <a href="#lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit">{{
              item.question
            }}</a>
          </h2>
          <div class="collapse" aria-hidden="true">
            <div class="body" [innerHTML]="item.response | safe : 'html'"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="highlight">
    <div class="container">
      <div class="text">
        <div class="boxes -full">
          <div class="item -blue">
            <div class="top">
              <h2 class="title">
                <a
                  rel="noopener noreferrer"
                  class="chat-popup"
                  tabindex="-1"
                >
                  Precisa de ajuda?
                </a>
              </h2>
            </div>
            <p class="description">
              <a
                rel="noopener noreferrer"
                class="chat-popup"
                tabindex="-1"
              >
                <strong>Fale com a Sani, a assistente virtual da Sabesp!</strong
                ><br />
                Você pode conversar com ela pelo chat online ou pelo WhatsApp.
              </a>
            </p>

            <div class="buttons" style="display: inline-flex">
              <a
                class="button chat-popup"
                rel="noopener noreferrer"
                >Ir para o Chat</a
              >
              <a class="button" href="https://wa.me/551133888000">
                <img
                  class="icon"
                  loading="lazy"
                  fetchpriority="low"
                  width="22"
                  height="22"
                  src="assets/images/icon/whatsapp.svg"
                  alt="WhatsApp"
                  title="WhatsApp"
                />
                WhatsApp 11 3388 8000
              </a>
            </div>
          </div>
        </div>
      </div>

      <picture class="figure">
        <img
          loading="lazy"
          fetchpriority="low"
          width="330"
          height="320"
          src="./assets/images/illustration/sani-tablet.png"
          alt="Sani"
          title="Sani"
        />
      </picture>
    </div>
  </section>
</main>
