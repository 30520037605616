import {Component, OnInit} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-carreiras',
  standalone: true,
  imports: [],
  templateUrl: './carreiras.component.html',
  styleUrl: './carreiras.component.css'
})
export class CarreirasComponent implements OnInit{

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {

    this.title.setTitle("Sabesp | Carreiras");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Explore as oportunidades de carreira na Sabesp e saiba como participar dos nossos processos seletivos para vagas de emprego, estágio e aprendizes.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, carreiras, empregos, estágio, aprendizes, concursos públicos'
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Carreiras'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Carreiras'
    });
  }
}
