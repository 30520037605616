import { Component } from '@angular/core';

interface IMenuItem {
  displayName: string;
  open?: boolean;
  route?: string;
  children?: IMenuItem[];
}

@Component({
  selector: 'menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrl: './menu-mobile.component.css'
})
export class MenuMobileComponent {

  menuItems: IMenuItem[] = [
    {
      displayName: 'A Sabesp',
      children: [
        {
          displayName: 'Institucional',
          children: [
            { displayName: 'Perfil', route: '/a-sabesp/institucional/perfil' },
            { displayName: 'Nossa História', route: '/a-sabesp/institucional/nossa-historia' },
          ]
        },
        {
          displayName: 'Inovação e Tecnologia',
          children: [
            { displayName: 'Pesquisa, Desenvolvimento e Inovação', route: 'a-sabesp/inovacao-e-tecnologia/pesquisa-desenvolvimento-inovacao' },
            { displayName: 'Biogás', route: '/a-sabesp/inovacao-e-tecnologia/biogas' },
            { displayName: 'Usinas Fotovoltaicas', route: '/a-sabesp/inovacao-e-tecnologia/usinas-fotovoltaicas' },
            { displayName: 'Sistema de Oxigenação', route: '/a-sabesp/inovacao-e-tecnologia/sistema-de-oxigenacao' },
          ]
        },
        { displayName: 'Meu Município', route: '/a-sabesp/meu-municipio' },
        { displayName: 'Carreiras', route: '/a-sabesp/carreiras' },
        {
          displayName: 'Central de Notícias',
          children: [
            { displayName: 'Notícias', route: '/a-sabesp/central-noticias/noticias' },
            { displayName: 'Utilidade Pública', route: '/a-sabesp/central-noticias/utilidade-publica' },
            { displayName: 'Releases', route: '/a-sabesp/central-noticias/releases' },
            { displayName: 'Contatos da Assessoria de Imprensa', route: '/a-sabesp/central-noticias/contatos-assessoria-imprensa' },
          ]
        },
        {
          displayName: 'Educação',
          children: [
            { displayName: 'Curso de Pesquisa de Vazamentos', route: '/a-sabesp/educacao/curso-pesquisa-vazamento' },
            { displayName: 'Dicas de Economia de Água', route: '/a-sabesp/educacao/dicas-economia-agua' },
            { displayName: 'Visite a Sabesp', route: '/a-sabesp/educacao/visite-sabesp' },
          ]
        },
        { displayName: 'Transparência', route: '/sustentabilidade/transparencia' },
        { displayName: 'Situação dos Mananciais', route: 'https://mananciais.sabesp.com.br/Situacao' },
        { displayName: 'Redução de Pressão', route: 'https://www.sabesp.com.br/reducaopressao/' },
      ]
    },
    {
      displayName: 'O Que Fazemos',
      children: [
        {
          displayName: 'Fornecimento de Água',
          children: [
            { displayName: 'Recursos Hídricos', route: '/o-que-fazemos/fornecimento-agua/recursos-hidricos' },
            { displayName: 'Captação de Água', route: '/o-que-fazemos/fornecimento-agua/captacao-agua' },
            { displayName: 'Tratamento de Água', route: '/o-que-fazemos/fornecimento-agua/tratamento-agua' },
            {
              displayName: 'Distribuição de Água',
              children: [
                { displayName: 'Da Sabesp para sua Casa', route: '/o-que-fazemos/fornecimento-agua/distribuicao-agua/da-sabesp-para-sua-casa' },
                { displayName: 'Controle de Perdas', route: '/o-que-fazemos/obras-programas/reducao-perdas/controle-perdas' },
                { displayName: 'Eliminadores de Ar', route: '/o-que-fazemos/fornecimento-agua/distribuicao-agua/eliminadores-ar' },
                { displayName: 'Gestão de Demanda', route: '/o-que-fazemos/fornecimento-agua/distribuicao-agua/gestao-demanda ' },
              ]
            },
            {
              displayName: 'Qualidade da Água',
              children: [
                { displayName: 'Nosso Cuidado com a Água', route: '/o-que-fazemos/fornecimento-agua/qualidade-agua/nosso-cuidado-com-a-agua' },
                { displayName: 'Relatórios de Qualidade da Água Anuais', route: '/o-que-fazemos/fornecimento-agua/qualidade-agua/analises-anuais-qualidade-agua-distribuida' },
                { displayName: 'Relatórios de Qualidade da Água Mensais', route: '/o-que-fazemos/fornecimento-agua/qualidade-agua/analises-mensais-qualidade-agua-distribuida' },
                { displayName: 'Relatórios de Qualidade da Água dos Mananciais', route: '/o-que-fazemos/fornecimento-agua/qualidade-agua/relatorio-qualidade-agua-mananciais' },
              ]
            },
          ]
        },
        {
          displayName: 'Soluções para Esgotos',
          children: [
            { displayName: 'Sistema de Coleta e Tratamento', route: '/o-que-fazemos/solucoes-para-esgotos/sistema-coleta-tratamento' },
            { displayName: 'Coleta de Esgotos', route: '/o-que-fazemos/solucoes-para-esgotos/coleta-esgotos' },
            {
              displayName: 'Tratamento de Esgotos',
              children: [
                { displayName: 'O Processo de Tratamento', route: '/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/o-processo-tratamento' },
                { displayName: 'Tipos de Tratamento de Esgotos', route: '/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/tipos-tratamento' },
                { displayName: 'Subprodutos de Esgotos', route: '/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos' },
              ]
            },
          ]
        },
        {
          displayName: 'Obras e Programas',
          children: [
            { displayName: 'Saneamento que Transforma', route: '/o-que-fazemos/obras-programas/saneamento-que-transforma' },
            { displayName: 'Programa Água Legal', route: '/o-que-fazemos/obras-programas/agua-legal' },
            { displayName: 'Programa Córrego Limpo', route: '/o-que-fazemos/obras-programas/corrego-limpo' },
            { displayName: 'Programa de Redução de Perdas', route: '/o-que-fazemos/obras-programas/reducao-perdas/controle-perdas' },
            { displayName: 'Programa de Uso Racional da Água', route: '/o-que-fazemos/obras-programas/uso-racional-agua' },
            { displayName: 'Programa Integra Tietê', route: '/o-que-fazemos/obras-programas/integra-tiete' },
            { displayName: 'Programa Onda Limpa', route: '/o-que-fazemos/obras-programas/onda-limpa' },
            { displayName: 'Programa Reciclagem de Óleo', route: '/o-que-fazemos/obras-programas/reciclagem-oleo' },
            { displayName: 'Programa Se Liga na Rede', route: '/o-que-fazemos/obras-programas/se-liga-na-rede' },
          ]
        },
      ]
    },
    {
      displayName: 'Serviços',
      children: [
        { displayName: 'Para Você', route: '/servicos/para-voce' },
        { displayName: 'Para Sua Empresa', route: '/servicos/para-empresa' },
        { displayName: 'Para Investidores', route: 'https://ri.sabesp.com.br/' },
        { displayName: 'Para Fornecedores', route: '/servicos/para-fornecedores' },
      ]
    },
    {
      displayName: 'Sustentabilidade',
      children: [
        { displayName: 'Sabesp Sustentável', route: '/sustentabilidade/sabesp-sustentavel' },
        { displayName: 'Prêmios e Reconhecimentos', route: 'sustentabilidade/premios-reconhecimentos' },
        { displayName: 'Meio Ambiente', route: '/sustentabilidade/meio-ambiente' },
        { displayName: 'Social', route: '/sustentabilidade/cuidando-do-social' },
        { displayName: 'Governança', route: '/sustentabilidade/governanca' },
        { displayName: 'Transparência', route: '/sustentabilidade/transparencia' },
        {
          displayName: 'Documentos e Relatórios',
          children: [
            { displayName: 'Relatórios de Sustentabilidade', route: 'sustentabilidade/documentos-relatorios/relatorios_sustentabilidade' },
            { displayName: 'Relatório Ações Verdes B3', route: '/sustentabilidade/documentos-relatorios/relatorio-acoes-verdes-B3' },
            { displayName: 'Inventário de Emissões de Gases de Efeito Estufa', route: '/sustentabilidade/documentos-relatorios/inventario-emissoes-gases-efeito-estufa' },
            { displayName: 'Framework para Finanças Sustentáveis', route: '/sustentabilidade/documentos-relatorios/framework-financas-sustentaveis' },
          ]
        },
      ]
    },
  ];

  closeMenu() {
    document.getElementById('menuMobile')?.classList.remove('opened')
  }

  clickItem(item: IMenuItem) {
    item.open = !item.open;

    if (item.route) {
      if (item.route.startsWith('http')) {
        window.open(item.route, '_blank', 'noopener,noreferrer');
      } else {
        window.location.href = item.route;
      }
    }
  }
}