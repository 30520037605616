import { Component, ViewEncapsulation } from '@angular/core';
import { ReleaseService } from '../release.service';
import { Release } from '../release';
import { Pagination } from '../pagination';
import { Category } from '../category';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'releases.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ReleasesComponent {
  releases: Pagination<Release> = { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] };
  perPage = 6;
  categoryId = "";
  startDate: Date | null = null;
  endDate: Date | null = null;
  q = "";
  order = "";
  mostViewed: Release[] = [];
  page = Number(1);
  categories: Category[] = [];

  constructor(private releasesService: ReleaseService, private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Releases ");
    this.getReleases(1);
    this.getMostViewedRelease();
    this.getCategories();
  }

  getReleases(page: Number): void {
    this.page = Number(page);
    if (this.q == "" && this.order == "") {
      this.order = "DESC";
    }
    this.releasesService.getReleases(this.categoryId, this.startDate, this.endDate, this.q, this.order, this.page, this.perPage)
      .subscribe(releases => this.releases = releases);
  }

  getMostViewedRelease(): void {
    this.releasesService.getMostViewedReleases()
      .subscribe(releases => this.mostViewed = releases);
  }

  getCategories(): void {
    this.releasesService.getReleaseCategories()
      .subscribe(categories => this.categories = categories);
  }

  filterTypeSearch(searchQuery: any) {
    if (this.q == "" && this.order == "") {
      this.order = "DESC";
    }
    this.releasesService.getReleases(searchQuery.categoryId, searchQuery.startDate, searchQuery.endDate, searchQuery.q, searchQuery.order, this.page, this.perPage)
      .subscribe(releases => this.releases = releases);

  }
}
