import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-onda-limpa',
  standalone: true,
  imports: [],
  templateUrl: './onda-limpa.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
})
export class OndaLimpaComponent {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {

    this.title.setTitle("Sabesp | Programa Onda Limpa");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Conheça o Programa Onda Limpa da Sabesp, que visa ampliar a coleta e tratamento de esgotos, melhorar a balneabilidade das praias e promover a saúde e o desenvolvimento sustentável do litoral paulista. Descubra as etapas e resultados desse importante programa de saneamento. '
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, Onda Limpa, saneamento, coleta de esgotos, tratamento de esgotos, balneabilidade, saúde pública, desenvolvimento sustentável, litoral paulista, Baixada Santista, Litoral Norte, JICA, BNDES. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Onda Limpa'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Onda Limpa'
    });
  }

}
