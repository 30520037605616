<main class="main">
    <meta name="description" content="Explore as oportunidades de carreira na Sabesp e faça parte da equipe que transforma o saneamento básico em São Paulo. Confira as vagas!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Carreiras">Carreiras</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Carreiras</h1>

            <picture class="picture petals-2 -no-shadow">
                <img loading="eager" fetchpriority="low" width="1300" height="244" src="../assets/images/a-sabesp/carreiras.jpg" alt="Operador manobrando escavadeira" title="Operador manobrando escavadeira">
            </picture>

            <p>Em breve divulgaremos informações sobre como fazer parte do nosso time.</p>
            <p>Aguarde!</p>
        </div>
    </article>
</main>
