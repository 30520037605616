<main class="main">
  <meta name="description" content="Sabesp conquista Selo Ouro GHG Protocol, Troféu Transparência e prêmios GRI Awards 2023 por excelência ambiental e comunitária.">
  <nav class="breadcrumbs">
      <div class="container">
          <ul class="menu">
            <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
            <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
            <li><a class="breadcrumb-link" href="javascript:void(0);" title="Prêmios e Reconhecimentos">Prêmios e Reconhecimentos</a></li>
          </ul>

          <ul class="buttons">
              <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
          </ul>
      </div>
  </nav>

  <section class="content -white">
    <div class="container">
      <h1 style="margin: 0px !important;" class="headline">Prêmios e Reconhecimentos</h1>
    </div>
  </section>

  <section class="content -gray">
      <div class="container">
        <div class="g-columns -gap-left -v-center -space-md premios-separator-l">
          <div>
            <h1 class="headline">Selo Ouro do Programa Brasileiro GHG Protocol</h1>
            <p>
              Recebemos, pelo primeiro ano, o Selo Ouro do Programa Brasileiro GHG Protocol, concedido a inventários de emissões de Gases de Efeito Estufa (GEE) completos e verificados, por entidade independente acreditada pelo Inmetro. Trata-se do mais alto nível de qualificação e contribuição para mitigar as emissões de GEE. O Selo Ouro do Programa Brasileiro GHG Protocol conta com o apoio do WRI Brasil (World Resources). O reconhecimento deste trabalho de excelência é uma conquista importante e reflete o compromisso com o aperfeiçoamento técnico dos nossos inventários e a entrega de resultados consistentes.
            </p>
          </div>
          <div class="picture-container">
            <img class="picture-premios" loading="eager" fetchpriority="high" src="../../assets/images/ghg-protocol-2022.png" alt="Selo Ouro do Programa Brasileiro GHG Protocol" title="Selo Ouro do Programa Brasileiro GHG Protocol">
        </div>
        </div>
      </div>
    </section>

  <section class="content -white">
      <div class="container">
          <div class="g-columns -gap-left -v-center -space-md premios-separator-r">
            <div class="picture-container">
              <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/trofeu-transparencia-anefac-2023.png" alt="Troféu Transparência 2023" title="Troféu Transparência 2023">
            </div>  
            <div>
              <h2 class="headline">Troféu Transparência 2023 </h2>
                <p>
                  Na categoria Empresas de Capital Aberto com receita líquida de até R$ 20 bilhões, concedido pela Associação Nacional dos Executivos de Finanças, Administração e Contabilidade (Anefac). A premiação tem análise técnica da Fundação Instituto de Pesquisas Contábeis, Atuariais e Financeiras (Fipecafi). Nos 26 anos do prêmio, acumulamos 23 vitórias.
                </p>
              </div>
          </div>
      </div>
  </section>

  <section class="content -gray">
    <div class="container">
      <div class="g-columns -gap-left -v-center -space-md premios-separator-l">
        <div>
          <h1 class="headline">GRI Infra Awards</h1>
          <p>
            Vencemos em duas categorias da premiação GRI Awards 2023. A Companhia subiu no pódio na categoria “Prêmio ESG - Projeto Destaque em ESG”, reconhecida em primeiro lugar pelo programa Novo Rio Pinheiros e, em segundo lugar, pelo programa Água Legal. Já na categoria Prêmio Comunidade - Desenvolvimento de Comunidades Locais, ficamos com a terceira posição, com o Programa Ensinando a Pescar.
          </p>
        </div>
        <div class="picture-container">
          <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/gri-infra-awards-2023.png" alt="GRI Infra Awards" title="GRI Infra Awards">
        </div>  
      </div>
    </div>
  </section>

  <section class="content -white">
      <div class="container">
          <div class="g-columns -gap-left -v-center -space-md premios-separator-r">
            <div class="picture-container">
              <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/7-premio-casos-de-sucesso-e-esg.png" alt="7º Prêmio Casos de Sucesso & ESG, do Instituto Trata Brasil" title="7º Prêmio Casos de Sucesso & ESG, do Instituto Trata Brasil">
            </div> 
            <div>
              <h2 class="headline">7º Prêmio Casos de Sucesso & ESG, do Instituto Trata Brasil</h2>
                <p>
                  Conquistamos o prêmio de “Melhores Casos ESG”, com o projeto Novo Rio Pinheiros.
                </p>
              </div>
          </div>
      </div>
  </section>

  <section class="content -gray">
    <div class="container">
      <div class="g-columns -gap-left -v-center -space-md premios-separator-l">
        <div>
          <h1 class="headline">Ranking do Saneamento 2023, do Instituto Trata Brasil</h1>
          <p>
            Em parceria com a consultoria GO Associados. De acordo com o levantamento do Instituto, entre os 20 melhores municípios em saneamento do País, quatro são atendidos pela Sabesp, incluindo São Paulo, que é a capital com o melhor saneamento do Brasil.
          </p>
        </div>
        <div class="picture-container">
          <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/trata-brasil-saneamento-e-saude.png" alt="Ranking do Saneamento 2023, do Instituto Trata Brasil" title="Ranking do Saneamento 2023, do Instituto Trata Brasil">
        </div>
      </div>
    </div>
  </section>

  <section class="content -white">
    <div class="container">
        <div class="g-columns -gap-left -v-center -space-md premios-separator-r">
          <div class="picture-container">
            <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/selo-paulista-da-diversidade.png" alt="Selo Paulista de Diversidade" title="Selo Paulista de Diversidade">
          </div>
          <div>
            <h2 class="headline">Selo Paulista de Diversidade</h2>
              <p>
                Por termos como política promover a diversidade em cargos de gestão da Companhia. Esse esforço para ampliar e difundir o tema foi reconhecido pelo Selo Paulista de Diversidade, concedido pelo Governo do Estado de São Paulo.
              </p>
            </div>
        </div>
    </div>
  </section>

  <section class="content -gray">
    <div class="container">
      <div class="g-columns -gap-left -v-center -space-md premios-separator-l">
        <div>
          <h1 class="headline">Certificação ISO 14001</h1>
          <p>
            Renovamos a certificação da ISO 14001 (Sistema de Gestão Ambiental) em 36 estações de tratamento de esgoto, após auditoria realizada pela Bureau Veritas Certification. A renovação da certificação reforça nosso compromisso com os mais altos padrões ambientais.
          </p>
        </div>
        <div class="picture-container">
          <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/iso-14001.png" alt="Certificação ISO 14001" title="Certificação ISO 14001">
        </div>
      </div>
    </div>
  </section>

  <section class="content -white">
    <div class="container">
        <div class="g-columns -gap-left -v-center -space-md premios-separator-r">
          <div class="picture-container">
            <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/fundao-abrinq-empresa-amigo-da-crianca.png" alt="Selo Empresa Amiga da Criança" title="Selo Empresa Amiga da Criança">
          </div>
          <div>
            <h2 class="headline">Selo Empresa Amiga da Criança</h2>
              <p>
                Conquistado novamente em 2023, pelos compromissos assumidos na defesa dos direitos das crianças e dos adolescentes, concedido pela Fundação Abrinq.
              </p>
            </div>
        </div>
    </div>
  </section>

  <section class="content -gray">
    <div class="container">
      <div class="g-columns -gap-left -v-center -space-md premios-separator-l">
        <div>
          <h1 class="headline">Índice de Bem-estar Emocional (Jungle/Great People-GPTW) </h1>
          <p>
            Recebemos, em 2023, o selo de saúde emocional no Estágio Estratégico: Times engajados com o tema da Saúde Mental. O resultado foi extraído da Pesquisa de Clima Organizacional da Sabesp 2023, que adota a metodologia da GPTW 2023. A análise para aferição do resultado é baseada na ciência da Neurolinguagem, em algoritmos e IA (Inteligência Artificial). O índice de bem-estar tem foco nas iniciativas da Companhia para desenvolvimento de uma Saúde Emocional, com uma abordagem integrativa dos benefícios e treinamentos com a rotina das equipes. Nesse estágio estão a maior parte das empresas com melhor clima organizacional.
          </p>
        </div>
        <div class="picture-container">
          <img loading="eager" fetchpriority="high" class="picture-premios" src="../../assets/images/saude-emocional.png" alt="Índice de Bem-estar Emocional (Jungle/Great People-GPTW) " title="Índice de Bem-estar Emocional (Jungle/Great People-GPTW) ">
        </div>
      </div>
    </div>
  </section>
</main>
