import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-institucional',
  templateUrl: './institucional.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None
})
export class InstitucionalComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Perfil");

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Perfil'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Perfil'
    });

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'A Sabesp, maior empresa de saneamento do Brasil, abastece milhões com água e coleta de esgoto. Com mais de 50 anos de história, investimos na ampliação e segurança hídrica. Conheça nossa trajetória e serviços. '
    });
    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, saneamento, água, esgoto, Brasil, segurança hídrica, investimento, serviços de saneamento, história da Sabesp, meio ambiente'
    });
  }
}
