import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorios-atas-pesquisas-anos-anteriores',
  standalone: true,
  imports: [],
  templateUrl: './relatorios-atas-pesquisas-anos-anteriores.component.html',
  styleUrl: './relatorios-atas-pesquisas-anos-anteriores.component.css'
})
export class RelatoriosAtasPesquisasAnosAnterioresComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Anos Anteriores");
}
}
