<main class="main">
    <meta name="description" content="Saiba como os eliminadores de ar da Sabesp melhoram a eficiência da distribuição de água, reduzindo custos e desperdícios. Descubra mais!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua/distribuicao-agua" title="Distribuição de Água">Distribuição de Água</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Eliminadores de Ar">Eliminadores de Ar</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Eliminadores de Ar</h1>

            <picture class="picture align-right">
                <img loading="eager" fetchpriority="high" width="650" height="380" src="./assets/images/o-que-fazemos/eliminadores-ar.jpg" alt="Eliminadores de Ar" title="Eliminadores de Ar">
            </picture>

            <p>Diversas empresas comercializam equipamentos que prometem reduzir a conta de água, supostamente eliminando ou bloqueando o ar existente na rede de abastecimento de água. Porém, estes dispositivos não têm eficácia comprovada e, além de irregulares, os equipamentos podem contaminar a água distribuída ou causar bloqueio no fluxo de entrada do imóvel, trazendo riscos à saúde pública e ao abastecimento.</p>
            <p>De acordo com o Instituto Nacional de Metrologia, Qualidade e Tecnologia – <abbr title="Instituto Nacional de Metrologia, Qualidade e Tecnologia">INMETRO</abbr>, “não existe nenhum tipo de dispositivo eliminador de ar aprovado ou autorizado pelo órgão”. A Associação Brasileira de Normas Técnicas (<abbr title="Associação Brasileira de Normas Técnicas">ABNT</abbr>) também não regulamenta o uso de tais aparelhos e a Agência Reguladora também não autoriza a instalação de eliminadores de ar.</p>
            <p>Testes já demonstraram a ineficácia destes dispositivos. A melhor forma de reduzir a conta é adotar o uso racional e consciente da água.</p>
            <p>A Sabesp possui cursos específicos para pesquisa de vazamentos e dicas sobre economia de água. Além disso, qualquer problema no medidor ou na ligação de água deve ser comunicado à Sabesp pelos canais de atendimento.</p>
        </div>
    </article>

    <section class="more">
        <div class="container petals-2">
            <nav class="nav">
                <h2 class="headline">Veja também</h2>

                <ul class="items">
                    <li class="item">
                        <a class="title acess-link" target="_blank" rel="noopener noreferrer" href="https://www.gov.br/inmetro/pt-br/acesso-a-informacao/perguntas-frequentes/metrologia-legal/medidor-de-consumo-de-agua-hidrometro/eliminador-de-ar-instalado-em-hidrometro-foi-aprovado-pelo-inmetro">INMETRO</a>
                    </li>
                    <li class="item">
                        <a class="title acess-link" href="a-sabesp/educacao/curso-pesquisa-vazamento">Curso de Pesquisa de Vazamento</a>
                    </li>
                    <li class="item">
                        <a class="title acess-link" href="a-sabesp/educacao/dicas-economia-agua">Dicas de Economia</a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</main>
