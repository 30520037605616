import { Component, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-folhetos',
  templateUrl: './folhetos.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', '../../assets/css/flyers.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FolhetosComponent {
  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Folhetos");

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Folhetos'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Folhetos'
    });
  }
}
