<main class="main">
    <meta name="description" content="Conheça as usinas fotovoltaicas da Sabesp e descubra como a energia solar impulsiona a inovação e a sustentabilidade no saneamento básico.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/inovacao-e-tecnologia/pesquisa-desenvolvimento-inovacao" title="Inovação e Tecnologia">Inovação e Tecnologia</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/inovacao-e-tecnologia/usinas-fotovoltaicas" title="Usinas Fotovoltaicas">Usinas Fotovoltaicas</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Usinas Fotovoltaicas</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/a-sabesp/usinas-fotovoltaicas.jpg" alt="Usinas Fotovoltaicas" title="Usinas Fotovoltaicas">
            </picture>

            <p>Em mais uma iniciativa voltada para a inovação e fontes de energia limpas e renováveis, a Sabesp criou o Programa de Geração de Energia Fotovoltaica, em que utilizamos nossas estações de tratamento de água e esgoto para instalar usinas que geram energia elétrica a partir da luz solar.</p>
            <p>Atualmente, temos 16 usinas distribuídas por diversas unidades no estado de São Paulo, que totalizam 20 megawatt-pico de potência instalada. Até 2025, serão 40 usinas em funcionamento, que permitirão atingir uma potência instalada de 60 megawatt-pico, suficiente para suprir 60% de toda energia consumida na Sabesp em baixa tensão.</p>
            <p>Com o volume de energia produzida nas usinas fotovoltaicas, a Sabesp compensa os créditos com consumo de eletricidade usada nas instalações operacionais dos municípios e de outras cidades da região. Há estudos para a ampliação do programa, com foco na autoprodução e destinação da carga remanescente.</p>
        </div>
    </article>
</main>
