import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-corrego-limpo',
  standalone: true,
  imports: [],
  templateUrl: './corrego-limpo.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css']
})
export class CorregoLimpoComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Programa Córrego Limpo");
}
}
