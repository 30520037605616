import { Component, ViewEncapsulation } from '@angular/core';
import { Release } from '../release';
import { ActivatedRoute } from '@angular/router';
import { ReleaseService } from '../release.service';

@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ReleaseComponent {
  release: Release | undefined;
  mostViewed: Release[] = [];
  currentURL: string = '';

  constructor(private route: ActivatedRoute, private releaseService: ReleaseService) { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'instant' })
    this.getRelease();
    this.getMostViewedReleases();
    this.getCurrentURL();
  }

  getCurrentURL(): void {
    this.currentURL = window.location.href;
    console.log(this.currentURL);
  }

  getRelease(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.releaseService.getReleaseById(id ? id : '')
      .subscribe(release => this.release = release);
  }

  getMostViewedReleases(): void {
    this.releaseService.getMostViewedReleases()
      .subscribe(releases => this.mostViewed = releases);
  }
}
