import { Injectable } from '@angular/core';
import { City, CityDoc } from './city';
import { catchError, tap, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './error.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private citiesUrl = environment.apiUrl + '/api/v1/cities';
  private cityDocsUrl = environment.apiUrl + '/api/v1/cityDocs';
  private citiesWithDocsUrl = environment.apiUrl + '/api/v1/citiesWithDocs';
  private citiesWithWaterQualityUrl = environment.apiUrl + '/api/v1/citiesWithWaterQuality';
  private cityWaterQualityUrl = environment.apiUrl + '/api/v1/cityWaterQuality';
  private cachedCities: City[] = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getCities().subscribe(cities => this.cachedCities = cities);
  }

  getCities(): Observable<City[]> {
    if (this.cachedCities.length) {
      return of(this.cachedCities);
    }
    return this.http.get<City[]>(this.citiesUrl)
      .pipe(
        tap(cities => this.cachedCities = cities),
        catchError(ErrorService.handle<City[]>('getCities', []))
      );
  }

  getCitiesWithDocs(): Observable<City[]> {
    return this.http.get<City[]>(this.citiesWithDocsUrl).pipe(
      catchError(ErrorService.handle<City[]>('getCitiesWithDocs', []))
    );
  }

  getCitiesWithWaterQuality(): Observable<City[]> {
    return this.http.get<City[]>(this.citiesWithWaterQualityUrl).pipe(
      catchError(ErrorService.handle<City[]>('getCitiesWithWaterQuality', []))
    );
  }

  getCityDocs(city: string): Observable<CityDoc[]> {
    const url = `${this.cityDocsUrl}/${city}?order=asc`;
    return this.http.get<CityDoc[]>(url).pipe(
      catchError(ErrorService.handle<CityDoc[]>(`getCityDocs city=${city}`))
    );
  }

  getCityWaterQuality(city: string): Observable<CityDoc[]> {
    const url = `${this.cityWaterQualityUrl}/${city}`;
    return this.http.get<CityDoc[]>(url).pipe(
      catchError(ErrorService.handle<CityDoc[]>(`getCityWaterQuality city=${city}`))
    );
  }
}