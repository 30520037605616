<main class="main">
    <meta name="description" content="Obras de Esgotos: veja como a Sabesp atua na expansão e modernização da rede de esgoto, promovendo saneamento sustentável e inclusão.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo"
                        title="Programa de Saneamento Sustentável Inclusivo">Programa de Saneamento Sustentável Inclusivo</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/obras-esgotos"
                        title="Obras de Esgotos">Obras de Esgotos</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">



            <h1 class='headline'>Obras de esgotos</h1>

            <p>As obras de esgoto previstas no Programa Saneamento Sustentável têm por finalidade a redução do aporte de cargas poluidoras à represa do Guarapiranga e a seus afluentes. As intervenções incluem:</p>

            <p>Expansão dos sistemas de esgotamento sanitário (redes e ligações) nos municípios de Embu das Artes e Itapecerica da Serra, para reduzir a descarga direta de esgotos em corpos d'água.</p>

            <p>Implantação de unidade de tratamento das águas do rio Embu-Mirim, um dos principais formadores da represa.</p>

            <p>Modernização tecnológica de estações elevatórias de esgoto atualmente em operação.</p>

            <p>Substituição de coletor tronco de esgotos da sub-bacia do córrego Guavirutuba e da linha de recalque Talamanca, ambos importantes para a transferência de vazões de esgoto para a estação de tratamento de Barueri.</p>



            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/ras_guarapiranga_esgoto.pdf"> Relatório Socioambiental Obras de Esgotos na Bacia Hidrográfica do Guarapiranga</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/ras_guarapiranga_esgoto.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/consulta_publica_embu_artes.pdf"> Sistema de coleta e afastamento dos esgotos sanitários de Embu das Artes</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/consulta_publica_embu_artes.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/embu_artes_etapa1.pdf"> Relatório Socioambiental das obras de esgoto de Embu das Artes - Etapa 1</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/embu_artes_etapa1.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_itapecerica_serra.pdf"> Plano de Reassentamento e Aquisição do município de Itapecerica da Serra</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_itapecerica_serra.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_socioambiental_itapecerica_serra.pdf"> Relatório socioambiental das obras de esgoto de Itapecerica da Serra</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_socioambiental_itapecerica_serra.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/ras_guavirutuba_talamanca_final.pdf"> Relatório Sociambiental Guavirutuba Talamanca</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/ras_guavirutuba_talamanca_final.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/consulta_publica_socioambiental_guavirituba_talamanca.pdf">  Aspectos sociambiental Guavirutuba Talamanca</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/consulta_publica_socioambiental_guavirituba_talamanca.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/consulta_publica__obras_guavirituba_talamanca.pdf"> Obras Guavirutuba Talamanca</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/consulta_publica__obras_guavirituba_talamanca.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>PLANOS DE GESTÃO AMBIENTAL E SOCIAL DE OBRAS</h2>

            <p>Para consulta, estão disponíveis os Planos de Gestão Ambiental e Social de Obras elaborados para as áreas de intervenção:</p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_ses_embu.pdf">Embu das Artes - Etapa 1</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_ses_embu.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_guavirituba_talamanca.pdf">Guavirutuba Talamanca</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_guavirituba_talamanca.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_itapecerica.pdf">Itapecerica da Serra</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_itapecerica.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>



        </div>
    </article>


</main>
