import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sistema-oxigenacao',
  standalone: true,
  imports: [],
  templateUrl: './sistema-oxigenacao.component.html',
  styleUrl: './sistema-oxigenacao.component.css'
})
export class SistemaOxigenacaoComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Sistema de Oxigenação");
}
}
