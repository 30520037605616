import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';

@Component({
  selector: 'app-usinas-fotovoltaicas',
  standalone: true,
  imports: [],
  templateUrl: './usinas-fotovoltaicas.component.html',
  styleUrl: './usinas-fotovoltaicas.component.css'
})
export class UsinasFotovoltaicasComponent {
  constructor(private title: Title){}

  ngOnInit() {

    this.title.setTitle("Sabesp | Usinas Fotovoltaicas");

}
}
