<main class="main">
    <meta name="description" content="Confira os folhetos informativos da Sabesp e obtenha orientações e dicas sobre serviços de água e saneamento. Saiba mais!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0)" title="Serviços">Serviços</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Folhetos">Folhetos</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <section class="content pb-0">
        <div class="container">
            <h1 class="headline">Folhetos</h1>
        </div>
    </section>

    <section class="highlight">
        <div class="container">
            <div class="text">
                <div class="boxes -full">
                    <div class="item -gray">
                        <div class="top -space-md">
                            <h2 class="title"><a href="javascript:void(0);" tabindex="-1">Nova Conta Sabesp</a></h2>
                        </div>
                        <a class="button" href="servicos/para-voce#entenda-sua-conta" title="Conheça a nova conta">Conheça a nova conta</a>
                    </div>
                </div>
            </div>

            <picture class="figure">
                <img loading="eager" fetchpriority="high" width="506" height="308" src="/assets/images/illustration/sani-mobile.png" alt="Personagem Sabesp mostrando conta de água no celular" title="Nova Conta Sabesp">
            </picture>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <h2 class="headline">Água</h2>

            <div class="boards">
                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-agua.pdf" tabindex="-1">
                            <img loading="eager" fetchpriority="low" width="192" height="257" src="/assets/images/folhetos/pedido-ligacao-agua.jpg" alt="Mulher bebendo água limpa sorrindo" title="Pedido de Ligação de Água">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-agua.pdf" tabindex="-1">Pedido De Ligação De Água</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-agua.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-3-a-8-hidrometros.pdf" tabindex="-1">
                            <img loading="eager" fetchpriority="low" width="192" height="257" src="/assets/images/folhetos/orientacoes-instalacoes-3-8-hidrometros.jpg" alt="Mulher bebendo água limpa sorrindo" title="Orientações Para Instalações de 3 a 8 Hidrômetros">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-3-a-8-hidrometros.pdf" tabindex="-1">Orientações Para Instalações De 3 a 8 Hidrômetros</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-3-a-8-hidrometros.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-instalacao-caixa-agua.pdf" tabindex="-1">
                            <img loading="eager" fetchpriority="low" width="192" height="257" src="/assets/images/folhetos/instalacao-caixa-d-agua.jpg" alt="Instalação da Caixa D’ água" title="Instalação da Caixa D’ água">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-instalacao-caixa-agua.pdf" tabindex="-1">Instalação Da Caixa D’ Água</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-instalacao-caixa-agua.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-limpeza-caixa-agua.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/limpeza-caixa-d-agua.jpg" alt="Caixa d'água com tampa azul" title="Limpeza da caixa d'água">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-limpeza-caixa-agua.pdf" tabindex="-1">Limpeza Da Caixa D'Água</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-limpeza-caixa-agua.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-instalacao-hidraulica.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/instalacoes-hidraulicas.jpg" alt="Chave de grifo e válvula metálica" title="Instalações hidráulicas">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-instalacao-hidraulica.pdf" tabindex="-1">Instalações Hidráulicas</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-instalacao-hidraulica.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-fraude-ligacao-agua.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/fraude-ligacao-agua.jpg" alt="Mascote Sabesp com lupa" title="Fraude na ligação de água">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-fraude-ligacao-agua.pdf" tabindex="-1">Fraude Na Ligação De Água</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-fraude-ligacao-agua.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-dicas-economia-agua.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/dicas-economia.jpg" alt="Personagem tanque de água Sabesp" title="Dicas de economia">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-dicas-economia-agua.pdf" tabindex="-1">Dicas De Economia</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-dicas-economia-agua.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-agua-potavel-torneira.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/agua-potavel-direto-torneira.jpg" alt="Enchendo copo com água da torneira" title="Água potável direto da torneira">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-agua-potavel-torneira.pdf" tabindex="-1">Água Potável Direto Da Torneira</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-agua-potavel-torneira.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-beba-agua-torneira.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/beba-agua-torneira.jpg" alt="Cartaz Sabesp incentivo ao consumo de água da torneira" title="Beba água da torneira">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-beba-agua-torneira.pdf" tabindex="-1">Beba Água Da Torneira</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-beba-agua-torneira.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-agua-oleo-nao-se-misturam.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/agua-oleo-nao-se-misturam.jpg" alt="Gota de líquido amarelo" title="Água e óleo não se misturam">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-agua-oleo-nao-se-misturam.pdf" tabindex="-1">Água e Óleo Não Se Misturam</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-agua-oleo-nao-se-misturam.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-programa-uso-racional.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/programa-uso-racional-agua-pura.jpg" alt="Planeta Terra com onda de água" title="Programa de Uso Racional da Água - Pura">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-programa-uso-racional.pdf" tabindex="-1">Programa De Uso Racional Da Água - Pura</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-programa-uso-racional.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-curso-pesquisa-vazamento.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/curso-sobre-pesquisa-vazamento.jpg" alt="Curso sobre pesquisa de vazamento" title="Curso sobre pesquisa de vazamento">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-curso-pesquisa-vazamento.pdf" tabindex="-1">Curso Sobre Pesquisa De Vazamento</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-curso-pesquisa-vazamento.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content -gray">
        <div class="container">
            <h2 class="headline">Esgoto</h2>

            <div class="boards">
                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-esgoto.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/pedido-ligacao-esgotos.jpg" alt="Operários Sabesp em obra de encanamento" title="Pedido de ligação de esgotos">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-esgoto.pdf" tabindex="-1">Pedido De Ligação De Esgotos</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-pedido-ligacao-esgoto.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-rede-coletora.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/rede-coletora-esgotos.jpg" alt="Operário utilizando escavadeira" title="Rede coletora de esgotos">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-rede-coletora.pdf" tabindex="-1">Rede Coletora De Esgotos</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-rede-coletora.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-esgoto-lixo.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/esgoto-lixo.jpg" alt="Mulher descartando resíduos na lixeira" title="Esgoto e lixo">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-esgoto-lixo.pdf" tabindex="-1">Esgoto e Lixo</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-esgoto-lixo.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-caixa-gordura.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/caixa-gordura.jpg" alt=" Ilustração de caixa de gordura" title="Caixa de Gordura">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-caixa-gordura.pdf" tabindex="-1">Caixa De Gordura</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-caixa-gordura.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-agua-reuso.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/agua-reuso.jpg" alt="Símbolo de reciclagem de água" title="Água de reúso">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-agua-reuso.pdf" tabindex="-1">Água De Reúso</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-agua-reuso.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-ligacao-esgoto.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/ligacao-esgoto.jpg" alt="Logotipo Sabesp em tampa de bueiro" title="Ligação de Esgoto">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-ligacao-esgoto.pdf" tabindex="-1">Ligação De Esgoto</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-ligacao-esgoto.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-valor-esgoto.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/valor-esgoto.jpg" alt="Água escorrendo pelo ralo" title="Valor do esgoto">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-valor-esgoto.pdf" tabindex="-1">Valor Do Esgoto</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-valor-esgoto.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-fertil.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/sabesfertil.jpg" alt="Homem rural com ferramentas e chapéu" title="Sabesfértil">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-fertil.pdf" tabindex="-1">Sabesfértil</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-fertil.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <h2 class="headline">Serviços</h2>

            <div class="boards">
                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-atende-empreendimentos-imobiliarios.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/atendimento-empreendimentos-imobiliarios.jpg" alt="Projeto arquitetônico de construção" title="Atendimento empreendimentos imobiliários">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-atende-empreendimentos-imobiliarios.pdf" tabindex="-1">Atendimento Empreendimentos Imobiliários</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-atende-empreendimentos-imobiliarios.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-aguas-pluviais.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/aguas-pluviais.jpg" alt="Chuva caindo em jardim" title="Águas pluviais">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-aguas-pluviais.pdf" tabindex="-1">Águas Pluviais</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-aguas-pluviais.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-canais-atendimento.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/canais-atendimento.jpg" alt="Aplicativo Sabesp em smartphone" title="Canais de Atendimento">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-canais-atendimento.pdf" tabindex="-1">Canais De Atendimento</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-canais-atendimento.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-debito-automatico.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/debito-automatico.jpg" alt="Família satisfeita com serviços Sabesp" title="Débito Automático">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-debito-automatico.pdf" tabindex="-1">Débito Automático</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-debito-automatico.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-falsos-funcionarios.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/falsos-funcionarios.jpg" alt="Símbolo de alerta Sabesp" title="Falsos Funcionários">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-falsos-funcionarios.pdf" tabindex="-1">Falsos Funcionários</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-falsos-funcionarios.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-construir-reformar.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/vai-construir-reformar.jpg" alt="Trabalhador com kit de ferramentas Sabesp" title="Vai construir ou reformar?">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-construir-reformar.pdf" tabindex="-1">Vai Construir Ou Reformar?</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-construir-reformar.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-aprenda-controlar-consumo-agua.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/aprenda-controlar-consumo.jpg" alt="Mão fechando torneira " title="Aprenda a controlar seu consumo">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-aprenda-controlar-consumo-agua.pdf" tabindex="-1">Aprenda a Controlar Seu Consumo</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-aprenda-controlar-consumo-agua.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-fale-com-sabesp.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/fale-com-sabesp.jpg" alt="Fale com a Sabesp" title="Fale com a Sabesp">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-fale-com-sabesp.pdf" tabindex="-1">Fale Com a Sabesp</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-fale-com-sabesp.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-fontes-alternativas.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/fonte-alternativas.jpg" alt="Mulher oferecendo copo de água" title="Fonte Alternativas">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-fontes-alternativas.pdf" tabindex="-1">Fonte Alternativas</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-fontes-alternativas.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-atualize-cadastro.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/atualizacao-cadastral.jpg" alt=" Casal feliz em novo lar" title="Atualização Cadastral">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-atualize-cadastro.pdf" tabindex="-1">Atualização Cadastral</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-atualize-cadastro.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-contrato-adesao.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/contrato-adesao.jpg" alt="Contrato de adesão Sabesp" title="Contrato de Adesão">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-contrato-adesao.pdf" tabindex="-1">Contrato De Adesão</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-contrato-adesao.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item -gray">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-manual-cliente-sabesp.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/manual-cliente.jpg" alt="Vista Aérea paisagem fluvial" title="Manual do Cliente">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-manual-cliente-sabesp.pdf" tabindex="-1">Manual Do Cliente</a></h3>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-manual-cliente-sabesp.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content -gray">
        <div class="container">
            <h2 class="headline">Campanha Guardião Das Águas</h2>

            <div class="boards">
                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-cartaz-dicas-guardiao-agua.pdf" tabindex="-1">
                            <img loading="lazy"  fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/cartaz-dicas-economia.jpg" alt=" Campanha Selo Guardião das Águas" title="Cartaz Com Dicas De Economia">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-cartaz-dicas-guardiao-agua.pdf" tabindex="-1">Cartaz Com Dicas De Economia (Versão Detalhada)</a></h3>
                        <p class="description"><a target="_blank" href="/assets/images/folhetos/sabesp-cartaz-dicas-guardiao-agua.pdf" tabindex="-1">Orientações Para Reduzir o Consumo e Colaborar Com a Campanha Guardião Das Águas</a></p>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-cartaz-dicas-guardiao-agua.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>

                <div class="item">
                    <figure class="figure">
                        <a target="_blank" href="/assets/images/folhetos/sabesp-cartaz-lista-dicas-guardiao.pdf" tabindex="-1">
                            <img loading="lazy" fetchpriority="low" width="192" height="257" data-src="/assets/images/folhetos/cartaz-dicas-economia-2.jpg" alt="Campanha Selo Guardião das Águas" title="Cartaz com dicas de economia">
                        </a>
                    </figure>
                    <div class="info">
                        <h3 class="title"><a target="_blank" href="/assets/images/folhetos/sabesp-cartaz-lista-dicas-guardiao.pdf" tabindex="-1">Cartaz Com Dicas De Economia (Versão Resumida)</a></h3>
                        <p class="description"><a target="_blank" href="/assets/images/folhetos/sabesp-cartaz-lista-dicas-guardiao.pdf" tabindex="-1">Orientações Para Reduzir o Consumo e Colaborar Com a Campanha Guardião Das Águas</a></p>
                        <a class="link" target="_blank" href="/assets/images/folhetos/sabesp-cartaz-lista-dicas-guardiao.pdf" title="Ver folheto">Ver folheto</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
