import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-qualidade-agua-distribuida',
  standalone: true,
  imports: [],
  templateUrl: './qualidade-agua-distribuida.component.html',
  styleUrl: './qualidade-agua-distribuida.component.css'
})
export class QualidadeAguaDistribuidaComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Relatórios de Qualidade da Água Mensais");
}
}
