import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Notice } from './notice';
import { Pagination } from './pagination';
import { environment } from '../environments/environment';
import { Category } from './category';
import { formatDate } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  private noticesUrl = environment.apiUrl + '/api/v1/notices';
  private mostViewedNoticiesUrl = environment.apiUrl + '/api/v1/mostViewedNotices';
  private noticeCategoriesUrl = environment.apiUrl + '/api/v1/noticeCategories';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getNotice(categoryId: string, startDate: Date | null, endDate: Date | null, q: string, order: string, page: Number, perPage: Number): Observable<Pagination<Notice>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('perPage', perPage.toString());
    if (categoryId) {
      params = params.set('categoryId', categoryId);
    }
    if (startDate && endDate) {
      params = params.set('startDate', formatDate(startDate, 'dd-MM-yyyy', 'en-US'));
      params = params.set('endDate', formatDate(endDate, 'dd-MM-yyyy', 'en-US'));
    }
    if (q) {
      params = params.set('q', q);
    }
    if (order) {
      params = params.set('order', order);
    }
    return this.http.get<Pagination<Notice>>(this.noticesUrl, { params })
      .pipe(
        catchError(ErrorService.handle<Pagination<Notice>>('getNotice', { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] }))
      );
  }

  getNoticeById(id: string): Observable<Notice> {
    const url = `${this.noticesUrl}/${id}`;
    return this.http.get<Notice>(url).pipe(
      catchError(ErrorService.handle<Notice>(`getNoticeById id=${id}`))
    );
  }

  getMostViewedNoticies(): Observable<Notice[]> {
    const url = `${this.mostViewedNoticiesUrl}`;
    return this.http.get<Notice[]>(url).pipe(
      catchError(ErrorService.handle<Notice[]>('getMostViewedNoticies', []))
    );
  }

  getNoticeCategories(): Observable<Category[]> {
    const url = `${this.noticeCategoriesUrl}`;
    return this.http.get<Category[]>(url).pipe(
      catchError(ErrorService.handle<Category[]>('getNoticeCategories', []))
    );
  }
}