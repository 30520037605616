<main class="main">
  <meta name="description" content="Acesse a Agência Virtual da Sabesp e conheça todos os serviços disponíveis para você. Consulte informações sobre tarifas, categorias de consumo e o regulamento do sistema tarifário.">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
        <li><a class="breadcrumb-link" href="javascript:void(0);" title="Serviços">Serviços</a></li>
        <li><a class="breadcrumb-link" href="javascript:void(0);" title="Para Você">Para Você</a></li>
      </ul>

      <ul class="buttons">
        <li><a class="button -home" href="/" title="Início">Início</a></li>
      </ul>
    </div>
  </nav>

  <section id="agencia-virtual" class="content">
    <div id="guia-servicos" class="container">
      <h1 class="headline">Para Você</h1>

      <div class="boxes">
        <div class="item -gray">
          <div class="top">
            <figure class="icon">
              <a class="icon-link" href="https://agenciavirtual.sabesp.com.br/" target="_blank" rel="noopener noreferrer" tabindex="-1">
                <img loading="lazy" fetchpriority="low" width="34" height="30" src="../assets/images/icon/monitor.svg" alt="Ícone Agência Virtual" title="Agência Virtual">
              </a>
            </figure>
            <h2 class="title">
              <a class="title-link" href="https://agenciavirtual.sabesp.com.br/" target="_blank" rel="noopener noreferrer" tabindex="-1">
                Agência Virtual
              </a>
            </h2>
          </div>
          <p class="description">
            <a class="description-link" href="https://agenciavirtual.sabesp.com.br/" target="_blank" rel="noopener noreferrer" tabindex="-1">
              Acesse a Agência Virtual da Sabesp para solicitar um serviço para sua
              residência. <strong>Agência Virtual</strong>.
            </a>
          </p>
          <a class="button" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual.sabesp.com.br/" title="Acesse">Acesse</a>
        </div>

        <div class="item -gray">
          <div class="top">
            <figure class="icon">
              <a class="icon-link" href="https://agenciavirtual.sabesp.com.br" target="_blank" tabindex="-1">
                <img loading="lazy" fetchpriority="low" width="28" height="28" src="../assets/images/icon/services-grid.svg" alt="Ícone Guia de Serviços" title="Guia de Serviços">
              </a>
            </figure>
            <h2 class="title">
              <a class="title-link" href="https://agenciavirtual.sabesp.com.br" target="_blank" tabindex="-1">
                Guia de Serviços
              </a>
            </h2>
          </div>
          <p class="description">
            <a class="description-link" href="https://agenciavirtual.sabesp.com.br" target="_blank" tabindex="-1">
              Aqui você encontra todos os serviços da Sabesp disponíveis para você.
            </a>
          </p>
          <a class="button" href="https://agenciavirtual.sabesp.com.br" target="_blank" title="Confira">Confira</a>
        </div>
      </div>
    </div>
  </section>

  <section #tarifas class="content -gray">
    <div class="container">
      <h2 class="headline">Tarifas</h2>
      <p>A estrutura tarifária da Sabesp é formada por um conjunto de tarifas e regras aplicadas ao faturamento da Companhia, em que os usuários são classificados nas categorias divididas em residencial, comercial, industrial e pública. Para essas categorias, existem tabelas com os valores estabelecidos para o consumo de até 10 m<sup>3</sup>, de 11 a 20 m<sup>3</sup>, de 21 a 50 m<sup>3</sup> e acima de 50 m<sup>3</sup>, com exceção das tarifas residencial social e residencial favelas, que possuem 5 faixas de consumo: até 10 m<sup>3</sup>, de 11 a 20 m<sup>3</sup>, de 21 a 30 m<sup>3</sup>, 31 a 50 m<sup>3</sup> e acima de 50 m<sup>3</sup>.</p>
      <p>Adicionalmente, a Sabesp possui tarifas diferenciadas para a população com menor poder aquisitivo e para as entidades assistenciais sem fins lucrativos, desde que observadas as condições de elegibilidade publicadas e acatadas pelo órgão regulador. Tanto a cobrança por meio de tarifas como a possibilidade de diferenciação estão previstas na legislação que autorizou a constituição da Sabesp.</p>
      <p>De acordo com o marco legal e regulatório vigente para o setor, a estrutura de remuneração e cobrança dos serviços públicos de saneamento básico poderá levar em consideração quantidade mínima de consumo ou de utilização do serviço.</p>
      <p>O consumo mínimo consiste em um instrumento de tarifação que permite a cobrança de um preço fixo para o primeiro bloco de consumo até determinada faixa de volume em metros cúbicos. Nesse instrumento, o volume mínimo faturável é cobrado mensalmente do consumidor, independente se há consumo ou não.</p>
      <p>Para os grandes consumidores e municípios em que a Sabesp fornece água ao atacado e disponibiliza tratamento dos esgotos por eles coletados, também há tabelas tarifárias próprias.</p>
      <p>Para os grandes consumidores e para os municípios em que a Sabesp fornece água ao atacado e disponibiliza tratamento dos esgotos por eles coletados, também há tabelas tarifárias próprias.</p>

      <div class="accordion">
        <div class="item">
          <h3 class="head">
            <a class="accordion-link" href="#tarifas-vigentes" (click)="accordionToggle($event)">Tarifas Vigentes</a>
          </h3>
          <div class="collapse" aria-hidden="true">
            <div class="body">
              <button type="button" class="close">Fechar</button>

              <div class="columns">
                <div class="column">
                  <p class="-txt-blue"><strong>Nossas Tarifas</strong></p>
                  <p class="-balance -space-lg"><a class="-fw-medium -no-underline -internal" href="https://agenciavirtual.sabesp.com.br/canais-atendimento" target="_blank" title="Consulte as tarifas de água e esgotos">Consulte as tarifas de água e esgotos praticadas pela Sabesp em seu município</a></p>

                  <p class="-txt-blue"><strong>Tabelas de Tarifas</strong></p>
                  <ul class="list -gap-lg">
                    <li>
                      URAE 1 – Sudeste<br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/deliberacao-arsesp-1539.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Deliberação ARSESP 1.539</a>; com vigência a partir de 23/07/2024<br>
                      <small class="-txt-gray">Contrato regulado pela ARSESP</small>
                    </li>
                    <li>
                      Mogi das Cruzes (Bairro da Divisa e Aruã/Distrito Industrial do Taboão)<br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/deliberacao-arsesp-1514.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Deliberação ARSESP 1.514</a><br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/comunicado-sabesp-1-24.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Comunicado Tarifário Sabesp 1/24</a>; com vigência a partir de 10/05/2024<br>
                      <small class="-txt-gray">Município regulado pela ARSESP</small>
                    </li>
                    <li>
                      Miguelópolis<br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/deliberacao-arsesp-1514.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Deliberação ARSESP 1.514</a><br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/comunicado-sabesp-1-24.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Comunicado Tarifário Sabesp 1/24</a>; com vigência a partir de 10/05/2024<br>
                      <small class="-txt-gray">Município regulado pela ARSESP</small>
                    </li>
                  </ul>
                </div>
                <div class="column">
                  <ul class="list -gap-lg">

                    <li>
                      Nova Guataporanga<br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/deliberacao-arsesp-1514.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Deliberação ARSESP 1.514</a><br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/comunicado-sabesp-1-24.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Comunicado Tarifário Sabesp 1/24</a>; com vigência a partir de 10/05/2024<br>
                      <small class="-txt-gray">Município regulado pela ARSESP</small>
                    </li>
                    <li>
                      Quintana<br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/deliberacao-arsesp-1514.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Deliberação ARSESP 1.514</a><br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/comunicado-sabesp-1-24.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Comunicado Tarifário Sabesp 1/24</a>; com vigência a partir de 10/05/2024<br>
                      <small class="-txt-gray">
                        Município regulado pela ARSESP
                      </small>
                    </li>
                    <li>
                      Lins<br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/deliberacao-arsesp-1483-Lins.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Deliberação ARSESP 1.483</a><br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/comunicado_5_23.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Comunicado Tarifário Sabesp 5/2023</a>; com vigência a partir de 26/01/2024<br>
                      <small class="-txt-gray">Município regulado pela ARSESP</small>
                    </li>
                    <li>
                      Olímpia<br>
                      <a class="-fw-medium -no-underline" href="../assets/pdf/servicos/para-voce/comunicado_tarifario_olimpia.pdf" target="_blank" rel="noopener noreferrer" title="Comunicado Sabesp">Comunicado 1/2023</a>; com vigência a partir de 11/12/2023<br>
                      <small class="-txt-gray">Município regulado pela ARES-PCJ</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section #tarifaSocial class="content">
    <div class="container">
      <h2 class="headline">Tarifa Social</h2>
      <p class="-space-lg">A Tarifa Social Residencial é destinada a residências unifamiliares (imóvel residencial para uma única família, como uma casa), desempregados, habitações coletivas ou remoção de área de risco, que atendam os critérios definidos pelo comunicado tarifário. </p>
      <div class="accordion -space-lg">
        <div class="item">
          <h3 class="head">
            <a class="accordion-link" href="#confira-condicoes" (click)="accordionToggle($event)">Confira as Condições</a>
          </h3>
          <div class="collapse" aria-hidden="true">
            <div class="body">
              <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

              <div class="columns">
                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Residência Unifamiliar:</strong></p>
                  <ul class="list">
                    <li>Renda familiar de até 3 (três) salários mínimos;</li>
                    <li>Ser morador de habitação com área útil construída até 60m2;</li>
                    <li>Ser consumidor de energia elétrica com consumo de até 170 kwh/mês;</li>
                    <li>Não haver débitos para o imóvel;</li>
                    <li>A cada 24 meses, os clientes deverão comprovar o enquadramento na tarifa social. Os que não comprovarem ou atingirem as condições estabelecidas para a renovação do cadastramento, correm o risco de sofrer o descadastramento automático.</li>
                  </ul>
                </div>

                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Pessoas Desempregadas:</strong></p>
                  <ul class="list">
                    <li>Consumo máximo de até 15m<sup>3</sup>;</li>
                    <li>Ser o titular da conta há mais de 90 (noventa) dias;</li>
                    <li>Último salário tenha sido de até 3 (três) salários mínimos;</li>
                    <li>Demissão não tenha ocorrido por justa causa;</li>
                    <li>Não tenha débitos ou débitos negociados.</li>
                    <li>O tempo máximo do enquadramento será de 12 (doze) meses, não podendo ser renovado.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item -open">
          <h3 class="head">
            <a class="accordion-link" (click)="accordionToggle($event)" href="#documentos-necessarios">Documentos Necessários</a>
          </h3>
          <div class="collapse" aria-hidden="false">
            <div class="body">
              <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

              <div class="columns">
                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Residência Unifamiliar:</strong></p>
                  <ul class="list">
                    <li>RG ou documento equivalente com foto e CPF;</li>
                    <li>Comprovante de renda. No caso de trabalhador informal, preencher declaração juntamente com parecer do técnico comunitário da área;</li>
                    <li>Cópia da última conta de luz comprovando consumo de até 170 kWh/mês (podendo ser conta dos últimos 12 meses);</li>
                    <li>Cópia do comprovante da área útil construída do imóvel (por exemplo, o IPTU);</li>
                    <li>RG ou documento equivalente com foto e CPF.</li>
                  </ul>
                </div>

                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Pessoas Desempregadas:</strong></p>
                  <ul class="list">
                    <li>Carteira de trabalho com anotação da baixa, ou termo de rescisão do contrato de trabalho homologado pelo sindicato ou pela Delegacia Regional do Trabalho;</li>
                    <li>Comprovante do Seguro Desemprego;</li>
                    <li>Conta de Água atual com média de consumo de até 15 (quinze) metros cúbicos;</li>
                    <li>Comprovante de renda - último salário de até 3 (três) salários mínimos.</li>
                  </ul>
                </div>

                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Habitação coletiva:</strong></p>
                  <ul class="list">
                    <li>Ofício do órgão responsável caracterizando o imóvel na situação de caráter social, exemplo do Ofício CDHU.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 class="subheadline -space-md">Onde solicitar?</h3>

      <div class="boxes -space-lg">
        <div class="item -gray">
          <div class="top">
            <figure class="icon">
              <a class="icon-link" href="https://sabespfacil.sabesp.com.br/" tabindex="-1">
                <img loading="lazy" fetchpriority="low" width="32" height="27" alt="Ícone Internet:&nbsp;Sabesp Fácil" title="Internet:&nbsp;Sabesp Fácil" src="../assets/images/icon/smartphone-monitor.svg">
              </a>
            </figure>
            <h4 class="title">
              <a class="title-link" href="https://sabespfacil.sabesp.com.br/" tabindex="-1">
                Internet
              </a>
            </h4>
          </div>
          <p class="description">
            <a class="description-link" href="https://sabespfacil.sabesp.com.br/" target="_blank" rel="noopener noreferrer" tabindex="-1">
              Clique aqui e acesse o Sabesp Fácil para solicitar a Tarifa Social Residencial.
            </a>
          </p>
          <a class="button" href="https://sabespfacil.sabesp.com.br/" title="Clique Aqui e Acesse">Clique Aqui e Acesse</a>
        </div>

        <div class="item -gray">
          <div class="top">
            <figure class="icon">
              <a class="icon-link" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" tabindex="-1">
                <img loading="lazy" fetchpriority="low" width="36" height="38" alt="Ícone Agências da Sabesp" title="Agências da Sabesp" src="../assets/images/icon/predio.svg">
              </a>
            </figure>
            <h4 class="title">
              <a class="title-link" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" tabindex="-1">
                Agências da Sabesp
              </a>
            </h4>
          </div>
          <p class="description">
            <a class="description-link" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" tabindex="-1">
              Localize a agência mais próxima de você.
            </a>
          </p>
          <a class="button" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" title="Localize a Mais Próxima&nbsp;">Localize a Mais Próxima&nbsp;</a>
        </div>
      </div>

      <h3 class="subheadline">Prazo de atendimento</h3>
      <p class="-space-md">O prazo para obtenção da Tarifa Social Residencial depende de análise da documentação apresentada e/ou vistoria, e será informado pela Unidade de Atendimento. O benefício será aplicado após o cadastramento e não terá efeito retroativo.</p>

      <h3 class="subheadline">Quanto custa</h3>
      <p>A solicitação da Tarifa Social é um serviço gratuito.</p>
    </div>
  </section>

  <section #tarifaSocial class="content">
    <div class="container">
      <h2 class="headline">Tarifa Vulnerável</h2>
      <p class="-space-lg">A Tarifa Residencial Vulnerável é destinada aos clientes registrados no Cadastro Único do Governo Federal (CadÚnico). </p>
      <div class="accordion -space-lg">
        <div class="item">
          <h3 class="head">
            <a class="accordion-link" href="#confira-condicoes" (click)="accordionToggle($event)">Confira o que é necessário para solicitar</a>
          </h3>
          <div class="collapse" aria-hidden="true">
            <div class="body">
              <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

              <div >
                <div class="column">
                  <ul class="list">
                    <li>Caracterização para beneficiário do Cadastro Único;</li>
                    <li>CPF do titular do Fornecimento na Sabesp deve constar como beneficiário do CadÚnico;</li>
                    <li>Fornecimento constando na tarifa Residencial Normal e Residencial Social;</li>
                    <li>Cliente registrado no CadÚnico com renda mensal per capita na primeira faixa do cadastro;</li>
                  </ul>
                </div>


              </div>
            </div>
          </div>
        </div>

        <!--div class="item -open">
          <h3 class="head">
            <a class="accordion-link" (click)="accordionToggle($event)" href="#documentos-necessarios">Documentos Necessários</a>
          </h3>
          <div class="collapse" aria-hidden="false">
            <div class="body">
              <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

              <div class="columns">
                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Residência Unifamiliar:</strong></p>
                  <ul class="list">
                    <li>RG ou documento equivalente com foto e CPF;</li>
                    <li>Comprovante de renda. No caso de trabalhador informal, preencher declaração juntamente com parecer do técnico comunitário da área;</li>
                    <li>Cópia da última conta de luz comprovando consumo de até 170 kWh/mês (podendo ser conta dos últimos 12 meses);</li>
                    <li>Cópia do comprovante da área útil construída do imóvel (por exemplo, o IPTU);</li>
                    <li>RG ou documento equivalente com foto e CPF.</li>
                  </ul>
                </div>

                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Pessoas Desempregadas:</strong></p>
                  <ul class="list">
                    <li>Carteira de trabalho com anotação da baixa, ou termo de rescisão do contrato de trabalho homologado pelo sindicato ou pela Delegacia Regional do Trabalho;</li>
                    <li>Comprovante do Seguro Desemprego;</li>
                    <li>Conta de Água atual com média de consumo de até 15 (quinze) metros cúbicos;</li>
                    <li>Comprovante de renda - último salário de até 3 (três) salários mínimos.</li>
                  </ul>
                </div>

                <div class="column">
                  <p class="-txt-blue"><strong>Tarifa Social Residencial para Habitação coletiva:</strong></p>
                  <ul class="list">
                    <li>Ofício do órgão responsável caracterizando o imóvel na situação de caráter social, exemplo do Ofício CDHU.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div-->
      </div>

      <h3 class="subheadline -space-md">Onde solicitar?</h3>

      <div class="boxes -space-lg">
        <div class="item -gray">
          <div class="top">
            <figure class="icon">
              <a class="icon-link" href="https://sabespfacil.sabesp.com.br/" tabindex="-1">
                <img loading="lazy" fetchpriority="low" width="32" height="27" alt="Ícone Internet:&nbsp;Sabesp Fácil" title="Internet:&nbsp;Sabesp Fácil" src="../assets/images/icon/smartphone-monitor.svg">
              </a>
            </figure>
            <h4 class="title">
              <a class="title-link" href="https://sabespfacil.sabesp.com.br/" tabindex="-1">
                Telefones
              </a>
            </h4>
          </div>
          <p class="description">
            Informações e orientações podem ser obtidas pela Central de Atendimento Telefônico.
          </p>
          <ul class="columns">
            <li>
              <a href="tel:08000550195">
                Central de Atendimento
                <div class="-lg"><strong>0800 055 0195</strong></div>
              </a>
            </li>
            <li>
              <a href="tel:+08000160195">
                Deficientes Auditivos e de Fala
                <div class="-lg"><strong>0800 016 0195</strong></div>
              </a>
            </li>
          </ul>
        </div>

        <div class="item -gray">
          <div class="top">
            <figure class="icon">
              <a class="icon-link" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" tabindex="-1">
                <img loading="lazy" fetchpriority="low" width="36" height="38" alt="Ícone Agências da Sabesp" title="Agências da Sabesp" src="../assets/images/icon/predio.svg">
              </a>
            </figure>
            <h4 class="title">
              <a class="title-link" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" tabindex="-1">
                Agências da Sabesp
              </a>
            </h4>
          </div>
          <p class="description">
            <a class="description-link" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" tabindex="-1">
              Localize a agência mais próxima de você.
            </a>
          </p>
          <a class="button" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" title="Localize a Mais Próxima&nbsp;">Localize a Mais Próxima&nbsp;</a>
        </div>
      </div>

      <h3 class="subheadline">Qual o prazo para atendimento?</h3>
      <p class="-space-md">O prazo depende de análise da documentação apresentada. O prazo do benefício será aplicado após o cadastramento e não terá efeito retroativo.</p>

      <h3 class="subheadline">Quanto custa e qual a forma de pagamento?</h3>
      <p>O serviço é gratuito.</p>
    </div>
  </section>

  <section #entenda class="content -gray">
    <div class="container">
      <h2 class="headline">Entenda sua conta</h2>
      <p>Todo cliente Sabesp possui uma identificação, que é o número do fornecimento, localizado no canto superior esquerdo da sua conta. Esse número é necessário para fazer a solicitação de serviços ou comunicar alguma ocorrência relacionada ao fornecimento de água.</p>
      <p>A solicitação de serviços também pode ser feita pelo CPF ou CNPJ, o que facilita a mudança de titularidade e também o acesso à nova agência virtual: <a class="-no-underline" href="http://agenciavirtual.sabesp.com.br" target="_blank" rel="noopener noreferrer">agenciavirtual.sabesp.com.br</a>.</p>
      <p class="-space-md">Para garantir mais agilidade no atendimento, mantenha sempre seu cadastro atualizado!</p>

      <div class="accordion">
        <div class="item -open" id="confira-campos-detalhes-fatura">
          <h3 class="head"><a (click)="accordionToggle($event)" class="accordion-link" href="#confira-campos-detalhes-fatura">Confira os Campos e Detalhes da sua Fatura</a></h3>
          <div class="collapse" aria-hidden="false">
            <div class="body">
              <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

              <div class="columns -auto">
                <picture class="align-left mr-0">
                  <img loading="lazy" fetchpriority="low" width="437" height="1254" alt="Conta Sabesp" title="Conta" src="../assets/images/conta-sabesp_att.jpg">
                </picture>

                <ol class="numeric">
                  <li><strong>Fornecimento:</strong> Número de identificação do seu imóvel na Sabesp</li>
                  <li><strong>Data de emissão:</strong> Indica dia, mês e ano em que a fatura foi emitida</li>
                  <li>Identifica o <strong>medidor instalado</strong> em seu imóvel. O número está gravado no equipamento</li>
                  <li><strong>Economias:</strong> Identifica o número de unidades consumidoras que utilizam a mesma ligação e qual a categoria de utilização da água para cada uma destas unidades – residencial, comercial, industrial ou pública</li>
                  <li>Descreve se o <strong>tipo da ligação</strong> é de água, esgoto ou ambos</li>
                  <li><strong>Consumo:</strong> É a diferença da leitura atual e a anterior, ou seja, quantidade de água consumida no período em metros cúbicos (1 metro cúbico = 1.000 litros)</li>
                  <li><strong>Período de consumo:</strong> Quantidade de dias considerados no período de leitura; contados do dia seguinte à <strong>Leitura Anterior</strong> até o dia da <strong>Leitura Atual</strong></li>
                  <li><strong>Média de consumo</strong> dos 6 últimos meses</li>
                  <li><strong>Tabela tarifária:</strong> A tarifa da Sabesp é progressiva e varia de acordo com o volume utilizado</li>
                  <li>Código para solicitar o cadastramento em <strong>débito automático</strong></li>
                  <li><strong>Valor Total:</strong> Soma dos valores dos consumos&nbsp;de água, esgoto e demais acréscimos</li>
                  <li><strong>Vencimento:</strong> Data limite para o pagamento de sua conta. Pagamentos efetuados fora deste prazo terão acréscimo de multa, atualização monetária e juros que serão cobrados na próxima conta</li>
                  <li>QR Code para <strong>pagamento via PIX</strong></li>
                  <li>Avisos aos clientes com <strong>informações importantes</strong></li>
                  <li>Endereço da <strong>agência da Sabesp</strong> responsável pelo atendimento a seu imóvel</li>
                  <li>Informações sobre a <strong>qualidade da água</strong> fornecida</li>
                  <li>Indica o <strong>sistema de abastecimento</strong> que abastece sua ligação</li>
                  <li>Valores de <strong>tributos calculados</strong> para esta fatura</li>
                  <li><strong>Código de barras</strong> para pagamento</li>
                  <li><strong>Data de Emissão:</strong> Indica dia, mês e ano em que a fatura foi emitida</li>
                  <li><strong>Vencimento:</strong> Data limite para o pagamento de sua conta</li>
                  <li><strong>Valor Total:</strong> Soma dos valores dos consumos&nbsp;de água, esgoto e demais acréscimos</li>
                </ol>
              </div>
              <div class="clear -space-md"></div>
              <div class="txt-center">
                <a class="button" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual2.sabesp.com.br/agencia-mais-proxima" title="Localize a agência mais próxima">Localize a agência mais próxima</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="mb-0">
    </div>
  </section>

  <section class="highlight">
    <div class="container">
      <div class="text">
        <div class="boxes -full">
          <div class="item">
            <div class="top">
              <figure class="icon">
                <a class="icon-link" href="../servicos/folhetos" tabindex="-1">
                  <img loading="lazy" fetchpriority="low" width="27" height="27" alt="Ícone Folhetos" title="Folhetos" src="../assets/images/icon/jornal.svg">
                </a>
              </figure>
              <h2 class="title">
                <a class="title-link" href="../servicos/folhetos" tabindex="-1">
                  Folhetos
                </a>
              </h2>
            </div>
            <p class="description">
              <a class="description-link" target="_blank" rel="noopener noreferrer" href="../servicos/folhetos" tabindex="-1">
                Aqui estão disponíveis os folhetos com dicas, <br> orientações e instruções da Sabesp para você!
              </a>
            </p>
            <a class="button" href="../servicos/folhetos" title="Confira">Confira</a>
          </div>
        </div>
      </div>

      <picture class="figure">
        <img loading="lazy" fetchpriority="low" width="322" height="380" alt="Mascote da Sabesp sentada e pensativa" title="Mascote da Sabesp sentada e pensativa" src="../assets/images/illustration/sani-pensando.png">
      </picture>
    </div>
  </section>
</main>
