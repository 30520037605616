<main class="main">
    <meta name="description" content="Descubra como a Sabesp cuida do social com projetos e ações que promovem o bem-estar e a sustentabilidade nas comunidades. Saiba mais!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Social">Social</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Cuidando do Social</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/sustentabilidade/cuidando-social.jpg" alt="Cuidando do Social" title="Cuidando do Social">
            </picture>

            <p>Atuar no saneamento básico é estar diretamente inserido na vida de milhões de pessoas, promovendo a saúde e transformando vidas. Na Sabesp, cuidar do social é o que nos move a cumprir nosso objetivo estratégico de promover a universalização do saneamento e oferecer serviços de alta qualidade para gerar impactos positivos na sociedade e meio ambiente.</p>
            <p>Com programas e iniciativas de grande alcance e que fomentam o desenvolvimento social nas comunidades onde operamos, como Água Legal, Se Liga na Rede e a tarifa social, reforçamos nosso papel como agentes de mudança positiva e promovemos a inclusão por meio do saneamento.</p>
            <p>Internamente, incentivamos um ambiente de trabalho com foco no bem-estar e desenvolvimento contínuo de cada colaborador. Por intermédio da valorização e da qualificação, avançamos para alcançar nossa visão de ser referência mundial em inovação e sustentabilidade no saneamento.</p>
            <p>Repudiamos todo e qualquer tipo de discriminação e, aqui na Sabesp, temos iniciativas de incentivo à equidade e combate ao assédio, como a Jornada da Diversidade e Inclusão e o programa Ao Lado Delas. Em caso de descumprimento do nosso Código de Conduta e Integridade, disponibilizamos o Canal de Denúncias, que está acessível de forma ágil por diversos meios e de maneira confidencial.</p>
        </div>

        <section class="more">
            <div class="container">
                <nav class="nav">
                    <h2 class="headline">Conheça Nossos Programas</h2>
                    <ul class="items grid-programas">
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/agua-legal" title="Programa Água Legal">Programa Água Legal</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/uso-racional-agua" title="Programa de Uso Racional da Água">Programa de Uso Racional da Água</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/corrego-limpo" title="Programa Córrego Limpo">Programa Córrego Limpo</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/integra-tiete" title="Programa Integra Tietê">Programa Integra Tietê</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="/o-que-fazemos/obras-programas/reciclagem-oleo" title="Programa de Reciclagem de Óleo de Frituras">Programa de Reciclagem de Óleo</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/onda-limpa" title="Programa Onda Limpa">Programa Onda Limpa</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/reducao-perdas/controle-perdas" title="Programa de Redução de Perdas">Programa de Redução de Perdas</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/se-liga-na-rede" title="Programa Se Liga na Rede">Programa Se Liga na Rede</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    </article>
</main>
