import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-para-voce',
    standalone: true,
    imports: [],
    templateUrl: './para-voce.component.html',
    styleUrls: ['./para-voce.component.css',
        "../../assets/css/internal.css",
        "../../assets/css/components.css",
        "../../assets/css/for-you.css"]
})
export class ParaVoceComponent implements OnInit, AfterViewInit {

    @ViewChild("tarifas") tarifas!: ElementRef;
    @ViewChild("tarifaSocial") tarifaSocial!: ElementRef;
    @ViewChild("entenda") entenda!: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private title: Title
    ) { }

    ngOnInit() {
        this.title.setTitle("Sabesp | Para Você ");
    }

    ngAfterViewInit(): void {
        this.scrollToSection()
    }

    accordionToggle(e: any) {
        e.preventDefault();
        let wrapper = e.target.closest('.item');
        wrapper.classList.toggle('-open');

        let collapse = wrapper.querySelector('.collapse');
        collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
        console.log('it works')
    }

    scrollToSection() {
        this.route.fragment.subscribe(frag => {
            if (frag) {
                let element: any;
                switch (frag) {
                    case 'tarifas':
                        element = this.tarifas.nativeElement
                        break;

                    case 'tarifa-social':
                        element = this.tarifaSocial.nativeElement
                        break;

                    case 'entenda-sua-conta':
                        element = this.entenda.nativeElement
                        break;
                }
                setTimeout(() => {
                    window.scrollTo({ top: element.offsetTop - 100, behavior: 'smooth' });
                }, 0);
            }
        })
    }
}
