import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro',
  standalone: true,
  imports: [],
  templateUrl: './relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro.component.html',
  styleUrl: './relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro.component.css'
})
export class RelatoriosMonitoramentoEstacaoTratamentoEsgotosSaoPedroComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp |  ETE São Pedro");
}
}
