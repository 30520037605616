<main class="main">
    <meta name="description" content="Descubra os serviços da Sabesp no seu município. Informações sobre abastecimento, atendimento e infraestrutura local">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Meu Município">Meu Município</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <section class="generic-filters">
        <div class="container">
            <form class="form" method="get" action="">
                <fieldset class="cities">
                    <div class="select">
                        <select name="cidade" id="cidade" class="filtro-link" [(ngModel)]="selectedCity" (change)="navigateToCity()">
                            <option [value]="''">Cidade</option>
                            <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
                        </select>
                        <span class="icon"></span>
                    </div>
                </fieldset>
            </form>
        </div>
    </section>

    <article class="content">
        <div class="container">
            <h1 class="headline">Meu Município</h1>

            <p>A Sabesp fornece água, coleta e tratamento de esgotos para mais de 375 municípios do Estado de São Paulo
                e aqui você pode conferir e baixar os arquivos de contrato e termos aditivos da sua cidade.</p>
            <p *ngIf="selectCity" class="lead"><strong>Exibindo resultados para: <span class="-txt-blue"
                        id="selectedCity">{{ selectedName }}</span></strong></p>

            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                        <tr>
                            <th width="300">Cidade</th>
                            <th>Nome</th>
                            <th class="txt-center" width="200">Ver/Baixar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doc of cityDocs">
                            <td>
                                <a href="{{ doc.url }}" target="_blank" rel="noopener noreferrer">
                                    {{ selectedName }}
                                </a>
                            </td>
                            <td>
                                <a href="{{ doc.url }}" target="_blank" rel="noopener noreferrer" class="-link">
                                    {{ doc.title }}
                                </a>
                            </td>
                            <td class="txt-center">
                                <a *ngIf="doc.type == 'download'" class="action-icon -download download-link" href="{{ doc.url }}" download>
                                    <span>Baixar arquivo</span>
                                </a>
                                <a *ngIf="doc.type == 'external'" class="action-icon -link-external external-link" href="{{ doc.url }}" target="_blank"
                                    rel="noopener noreferrer">
                                    <span>Ver página</span>
                                </a>
                                <a *ngIf="doc.type == 'page'" class="action-icon -link acess-link" href="{{ doc.url }}">
                                    <span>Acessar</span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>
</main>
