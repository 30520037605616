<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/participacoes-societarias"
                        title="Participações Societárias">Participações Societárias</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <h1 class='headline'>Participações Societárias</h1>

            <p>Em participações societárias com empresas privadas, a Sabesp presta serviços de água e esgotos em outros municípios, além de produzir água de reúso como sócia para abastecer o Polo Petroquímico de Capuava e atuar em projetos de esgotos não domésticos.</p>

            <p>Nesta seção, estão os contratos de acionistas e demais documentos relacionados às Empresas.</p>


            <br><br><h2><a target='_blank' rel='noopener noreferrer' class='-link' href='https://www.iguasa.com.br/andradina'>ÁGUAS DE ANDRADINA S/A</a></h2>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/estatuto_andradina.pdf">Estatuto Social</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/estatuto_andradina.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2><a target='_blank' rel='noopener noreferrer' class='-link' href='http://www.aquapolo.com.br/'>AQUAPOLO AMBIENTAL</a></h2>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/aquapolo_estatuto_social_ata2014.pdf">Estatuto Social</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/aquapolo_estatuto_social_ata2014.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2><a target='_blank' rel='noopener noreferrer' class='-link' href='https://www.iguasa.com.br/castilho'>ÁGUAS DE CASTILHO S.A</a></h2>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/castilho_estatuto_ata_constituicao.pdf">Estatuto social e ata de constituição</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/castilho_estatuto_ata_constituicao.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2><a target='_blank' rel='noopener noreferrer' class='-link' href='https://attendambiental.com.br/'>ATTEND AMBIENTAL</a></h2>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/attend_estatuto_ata_constituicao.pdf">Estatuto social e ata de constituição</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/attend_estatuto_ata_constituicao.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

            <br><br><h2><a target='_blank' rel='noopener noreferrer' class='-link' href='http://www.sesamm.com.br/'>SERVIÇOS DE SANEAMENTO DE MOGI MIRIM S.A</a></h2>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/sesamm_estatuto_social.pdf">Estatuto Social</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/sesamm_estatuto_social.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2><a target='_blank' rel='noopener noreferrer' class='-link' href='https://www.emis.com/php/company-profile/BR/Paulista_Geradora_de_Energia_SA_en_4054607.html'>PAULISTA GERADORA DE ENERGIAS S/A</a></h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/paulista_geradora_estatuto_social.pdf">Estatuto Social</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/paulista_geradora_estatuto_social.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

            <br><br><h2><a target='_blank' rel='noopener noreferrer' class='-link' href='https://www.saneaqua.com.br/'>SANEAQUA MAIRINQUE</a></h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/estatuto_social_saneaqua.pdf">Estatuto Social</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/estatuto_social_saneaqua.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

            <br><br><h2>Cantareira SP Energia S/A</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contratos_transparencia/estatuto_social_cantareira_energia.pdf">Estatuto Social</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contratos_transparencia/estatuto_social_cantareira_energia.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>



        </div>
    </article>


</main>
