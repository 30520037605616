import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-substituicao-redes-setorizacao',
  standalone: true,
  imports: [],
  templateUrl: './substituicao-redes-setorizacao.component.html',
  styleUrl: './substituicao-redes-setorizacao.component.css'
})
export class SubstituicaoRedesSetorizacaoComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Substituição de Redes e Setorização");
}

}
