import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-atas-comite-auditoria',
  standalone: true,
  imports: [],
  templateUrl: './atas-comite-auditoria.component.html',
  styleUrl: './atas-comite-auditoria.component.css'
})
export class AtasComiteAuditoriaComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Atas do Comitê de Auditoria");
}
}
