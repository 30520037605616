import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-framework-financas-sustentaveis',
  templateUrl: './framework-financas-sustentaveis.component.html',
  styleUrl: './framework-financas-sustentaveis.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class FrameworkFinancasSustentaveisComponent {

  constructor(
    private title: Title
  ) {
    this.title.setTitle('Sabesp | Framework para Finanças Sustentáveis');
  }

}
