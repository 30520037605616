<main class="main">
	<meta name="description" content="Conheça o programa Se Liga na Rede da Sabesp, que facilita o acesso à rede de esgoto e promove a saúde pública. Saiba mais!">
		<nav class="breadcrumbs">
			<div class="container">
				<ul class="menu">
					<li><a href="/" title="Início">Início</a></li>
					<li><a href="javascript:void(0);" title="O Que Fazemos">O Que Fazemos</a></li>
					<li><a href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
					<li><a href="javascript:void(0);" title="Programa Se Liga na Rede">Programa Se Liga na Rede</a></li>
				</ul>

				<ul class="buttons">
					<li><a class="button -home" href="/" title="Início">Início</a></li>
				</ul>
			</div>
		</nav>

		<article class="content">
			<div class="container">
				<h1 class="headline">Programa Se Liga na Rede</h1>

				<picture class="picture petals align-right">
					<img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/sustentabilidade/programa-se-liga-na-rede.jpg" alt="Profissional da Sabesp explicando documento a cliente​" title="Programa Se Liga na Rede">
				</picture>

				<p>Além de levar água de qualidade às populações que vivem em regiões de vulnerabilidade social, a Sabesp também proporciona a coleta e o encaminhamento do esgoto para tratamento nessas áreas por meio do Programa Se Liga na Rede.</p>
				<p>Em parceria com o Governo do Estado, realizamos gratuitamente as instalações sanitárias para famílias de baixa renda, que recebem até três salários-mínimos mensais, às redes de coleta, evitando o descarte irregular de esgoto no meio ambiente.</p>
				<p>O Programa Se Liga na Rede conta ainda com a participação de moradores das próprias comunidades, que são contratados para promover a conscientização da população sobre os benefícios ambientais da ligação de esgoto.</p>
			</div>
		</article>
	</main>
