<main class="main">
    <meta name="description" content="Sabesp lança Framework para Finanças Sustentáveis, alinhado a princípios globais e ODS, para projetos verdes e sociais.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a title="Sustentabilidade ">Sustentabilidade</a></li>
                <li><a title="Documentos e Relatórios">Documentos e Relatórios</a></li>
                <li><a title="Integra Tietê">Framework para Finanças Sustentáveis</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Framework para Finanças Sustentáveis</h1>

            <p>
                A Sabesp desenvolveu o Framework para Finanças Sustentáveis com o objetivo de financiar e refinanciar ativos e operações que possuam impactos ambientais e sociais positivos, alinhados à sua estratégia corporativa. Este Framework compromete a empresa a investir em projetos que direcionam recursos para categorias verdes, azuis ou sociais, com prazos estabelecidos para alcançar a alocação almejada. Ele foi desenvolvido em conformidade com os Green Bond Principles (GBP), Social Bond Principles (SBP), Sustainability Bond Guidelines (SBG), Green Loan Principles (GLP), e Social Loan Principles (SLP). Também está alinhado às diretrizes do Guidelines for Blue Finance do IFC, contribuindo significativamente para os ODS 6 (Água potável e saneamento) e ODS 14 (Vida na Água), e segue processos voluntários internacionalmente aceitos que promovem transparência e integridade nas melhores práticas.
            </p>

            <br><br>
            <h2>2023</h2>
            <div class='table-responsive'>
                <table class='table col-striped' style='min-width: 700px;'>
                    <thead>
                        <tr>
                            <th></th>
                            <th class='txt-center' width='200'>Ver/Baixar</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                                <a target="_blank" rel="noopener noreferrer" class="-link"
                                    href="/site/uploads/file/relatorios/framework_financas_sustentaveis/Framework%20para%20Finan%C3%A7as%20Sustent%C3%A1veis.pdf">
                                    Framework para Finanças Sustentáveis
                                </a>
                            </td>
                            <td class="txt-center">
                                <a target="_blank" class="action-icon -download"
                                    href="/site/uploads/file/relatorios/framework_financas_sustentaveis/Framework%20para%20Finan%C3%A7as%20Sustent%C3%A1veis.pdf"><span>Acessar</span></a>
                            </td>
                        </tr>

                        <tr>
                            <td><a target="_blank" rel="noopener noreferrer" class="-link"
                                    href="/site/uploads/file/relatorios/framework_financas_sustentaveis/Parecer%20de%20Segunda%20Opini%C3%A3o%20sobre%20o%20Framework.pdf">
                                    Parecer de Segunda Opinião sobre o Framework
                                </a></td>
                            <td class="txt-center"><a target="_blank" class="action-icon -download"
                                    href="/site/uploads/file/relatorios/framework_financas_sustentaveis/Parecer%20de%20Segunda%20Opini%C3%A3o%20sobre%20o%20Framework.pdf"><span>Acessar</span></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>
</main>