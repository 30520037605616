<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Item">Item</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Item">Item</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Template Geral Interno</h1>

            <p>A Sabesp é uma sociedade anônima de economia mista fundada em 1973 e atualmente é responsável pelo fornecimento de água, coleta e tratamento de esgotos de 375 municípios do Estado de São Paulo.</p>
            <p>É considerada uma das maiores empresas de saneamento do mundo em população atendida. São 28,1 milhões de pessoas abastecidas com água e 24,9 milhões de pessoas com coleta de esgotos.</p>

            <picture class="picture align-right">
                <img loading="eager" fetchpriority="low" width="650" height="390" src="./assets/images/template-geral-interno.png" alt="Alternativo" title="Título">
            </picture>

            <p>A Sabesp é responsável por cerca de 30% do investimento em saneamento básico feito no Brasil. Para o período 2024-2028, planeja investir aproximadamente R$ 47,4 bilhões, com foco na ampliação da disponibilidade e segurança hídrica, sem prejuízo dos avanços conquistados nos índices de coleta e tratamento de esgotos.</p>
            <p>Em parceria com empresas privadas, a Companhia também presta serviços de água e esgoto em outros quatro municípios, Mogi-Mirim, Castilho, Andradina e Mairinque.</p>
            <p>No segmento de água de reúso obtida a partir do tratamento de esgotos, a Sabesp produz, fornece e comercializa diretamente o produto por meio de suas próprias estações e, como sócia na Aquapolo Ambiental, que abastece o Polo Petroquímico de Capuava.</p>
            <p>Além disso, no segmento de esgotos não domésticos, a Companhia é sócia da Attend Ambiental. No segmento de energia elétrica, criou em 2015 a Paulista Geradora de Energia S.A.</p>

            <p>Em 2023, a Sabesp venceu a licitação para gestão do saneamento de Olímpia. Foi a primeira concessão que a Companhia ganhou na sua história em um processo competitivo.</p>


            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                    <tr>
                        <th colspan="2">Água</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Ligações Cadastradas de Água</td>
                        <td width="339" class="-txt-blue -fw-medium">10,3 Milhões</td>
                    </tr>

                    <tr>
                        <td>Estações de Tratamento de Água</td>
                        <td class="-txt-blue -fw-medium">235</td>
                    </tr>
                    <tr>
                        <td>Reservatórios</td>
                        <td class="-txt-blue -fw-medium">235</td>
                    </tr>
                    <tr>
                        <td>Capacidade do Armazenamento de Água (Reservatórios)</td>
                        <td class="-txt-blue -fw-medium">3,8 Bilhões de Litros</td>
                    </tr>
                    <tr>
                        <td>Poços</td>
                        <td class="-txt-blue -fw-medium">1.218</td>
                    </tr>
                    <tr>
                        <td>Adutoras</td>
                        <td class="-txt-blue -fw-medium">6,2 Mil Quilômetros</td>
                    </tr>
                    <tr>
                        <td>Redes de Distribuição de Água</td>
                        <td class="-txt-blue -fw-medium">86,9 Mil Quilômetros</td>
                    </tr>
                    <tr>
                        <td>Centrais de Controle Sanitário</td>
                        <td class="-txt-blue -fw-medium">16</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                    <tr>
                        <th colspan="2">Esgoto</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Ligações Cadastradas de Esgotos</td>
                        <td class="-txt-blue -fw-medium" width="339">8,8 Milhões</td>
                    </tr>
                    <tr>
                        <td>Estações de Tratamento de Esgotos</td>
                        <td class="-txt-blue -fw-medium">596</td>
                    </tr>
                    <tr>
                        <td>Redes Coletoras de Esgotos</td>
                        <td class="-txt-blue -fw-medium">60,7 Mil Quilômetros</td>
                    </tr>
                    <tr>
                        <td>Coletores, Emissários e Interceptores</td>
                        <td class="-txt-blue -fw-medium">2,9 Mil Quilômetros</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <p>&nbsp;</p>

            <h2 class="-txt-blue -fw-medium">Lorem ipsum dolor sit amet, consectetur</h2>

            <ul class="list -fw-medium">
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi vel, tristique blandit purus.</li>
                <li>Cras commodo dignissim felis, et sodales. Duis et sagittis urna.</li>
                <li>Ut tincidunt dui quis est pharetra</li>
                <li>Quisque eros tortor, euismod vel nisi vel, tristique blandit purus. Duis et sagittis urna.</li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi vel, tristique blandit purus. Duis et sagittis urna.</li>
            </ul>
        </div>
    </article>

    <section class="more">
        <div class="container petals-2">
            <nav class="nav">
                <h2 class="headline">Lorem ipsum dolor sit amet</h2>

                <ul class="items">
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con tdiperra. Sit justo velit, eu sed.</a>
                    </li>
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con turadip iscingelit.</a>
                    </li>
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con tdiperra. Sit justo velit, eu sed.</a>
                    </li>
                    <li class="item">
                        <a class="title" href="javascript:void(0);">Lorem ipsum dolor sit amet, con turadip iscingelit.</a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</main>
