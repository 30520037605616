<main class="main">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
        <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
        <li><a class="breadcrumb-link" href="javascript:void(0);" title="Documentos de Governança">Documentos de Governança</a></li>
      </ul>

      <ul class="buttons">
        <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
      </ul>
    </div>
  </nav>

  <article class="content">
    <div class="container">
      <h1 class="headline">Documentos de Governança</h1>

      <div class="table-responsive">
        <table class="table col-striped" style="min-width: 475px;">
          <thead>
            <tr>
              <th>Nome</th>
              <th class="txt-center" width="180">Acesso</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/governanca-corporativa/conselhos-comites-do-conselho-e-diretoria/">
                  Estrutura da Governança
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/governanca-corporativa/conselhos-comites-do-conselho-e-diretoria/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/governanca-corporativa/conselhos-comites-do-conselho-e-diretoria/">
                  Perfil dos Administradores
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/governanca-corporativa/conselhos-comites-do-conselho-e-diretoria/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/governanca-corporativa/assembleia-e-atas/">
                  Assembleias e Atas
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/governanca-corporativa/assembleia-e-atas/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/informacoes-financeiras/central-de-resultados/">
                  Parecer do Comitê de Auditoria
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/informacoes-financeiras/central-de-resultados/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/informacoes-financeiras/central-de-resultados/">
                  Parecer do Comitê Fiscal
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/informacoes-financeiras/central-de-resultados/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/governanca-corporativa/estatuto-e-politicas/">
                  Estatuto e Políticas
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/governanca-corporativa/estatuto-e-politicas/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/governanca-corporativa/codigo-de-conduta-e-integridade/">
                  Código de Conduta e Integridade
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/governanca-corporativa/codigo-de-conduta-e-integridade/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://ri.sabesp.com.br/governanca-corporativa/informe-de-governanca/">
                  Informe de Governança
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://ri.sabesp.com.br/governanca-corporativa/informe-de-governanca/">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a class="-link" target="_blank" rel="noopener noreferrer" tabindex="-1" href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm">
                  Legislação Aplicável
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" target="_blank" rel="noopener noreferrer" href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</main>
