<main class="main">
    <meta name="description" content="Relatórios de Monitoramento ETE Toninhas: veja os dados sobre o tratamento de esgotos e a gestão ambiental na Estação Toninhas.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-toninhas"
                        title="ETE Toninhas">Relatórios de Monitoramento da Estação de Tratamento de Esgotos Toninhas</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            <h1 class='headline'>Relatórios de Monitoramento da Estação de Tratamento de Esgotos Toninhas</h1>



            <p>A Estação de Tratamento de Esgoto Toninhas foi inaugurada em 18 de julho de 1998 e atende o bairro de mesmo nome, no município de Ubatuba. </p>

            <p>O sistema beneficia uma população de mais de 6,6 mil habitantes. </p>

            <p>A Sabesp realiza o monitoramento mensal do curso d'água no ponto após o lançamento do efluente tratado. Confira o relatório com os parâmetros analisados:</p>


            <br><br><h2>2024</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_24.pdf">Relatório de junho de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_24.pdf"><span>Acessar</span></a></td></tr>

            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mai_24.pdf">Relatório de maio de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mai_24.pdf"><span>Acessar</span></a></td></tr>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abr_24.pdf">Relatório de abril de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abr_24.pdf"><span>Acessar</span></a></td></tr>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar_24.pdf">Relatório de março de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar_24.pdf"><span>Acessar</span></a></td></tr>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_fev_24.pdf">Relatório de fevereiro de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_fev_24.pdf"><span>Acessar</span></a></td></tr>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan_24.pdf">Relatório de janeiro de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan_24.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>



            <br><br><h2>2023</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez23.pdf">Relatório de dezembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_nov_23.pdf">Relatório de novembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_nov_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_out_23.pdf">Relatório de outubro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_out_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_set_23.pdf">Relatório de setembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_set_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago_23.pdf">Relatório de agosto de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jul_23.pdf">Relatório de julho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jul_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_23.pdf">Relatório de junho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mai_23.pdf">Relatório de maio de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mai_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abr23.pdf">Relatório de abril de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abr23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar_23.pdf">Relatório de março de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_fev_23.pdf">Relatório de fevereiro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_fev_23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan23.pdf">Relatório de janeiro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan23.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>



            <br><br><h2>2022</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez22.pdf">Relatório de dezembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_nov_22.pdf">Relatório de novembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_nov_22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_out22.pdf">Relatório de outubro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_out22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_set22.pdf">Relatório de setembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_set22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago22.pdf">Relatório de agosto de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_julho22.pdf">Relatório de julho de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_julho22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_2022.pdf">Relatório de junho de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_2022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_maio22.pdf">Relatório de maio de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_maio22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abril22.pdf">Relatório de abril de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abril22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar_22.pdf">Relatório de março de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar_22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/ete_toninhas_fev2022.pdf">Relatório de fevereiro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/ete_toninhas_fev2022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan_22.pdf">Relatório de janeiro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan_22.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            <br><br><h2>2021</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez21.pdf">Relatório de dezembro de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_nov_21.pdf">Relatório de novembro de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_nov_21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_out_21.pdf">Relatório de outubro de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_out_21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/ete_toninhas_set21.pdf">Relatório de setembro de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/ete_toninhas_set21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago_21.pdf">Relatório de agosto de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago_21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jul_21.pdf">Relatório de julho de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jul_21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_21.pdf">Relatório de junho de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun_21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/ete_toninhas_maio_2021.pdf">Relatório de maio de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/ete_toninhas_maio_2021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abr21.pdf">Relatório de abril de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abr21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar21.pdf">Relatório de março de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_mar21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_mensal_ete_toninhas_fev_2021.pdf">Relatório de fevereiro de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_mensal_ete_toninhas_fev_2021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_etetoninhas_jan21.pdf">Relatório de janeiro de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_etetoninhas_jan21.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2020</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_etetoninhas_dez20.pdf">Relatório de dezembro de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_etetoninhas_dez20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_novembro20.pdf">Relatório de novembro de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_novembro20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_outubro20.pdf">Relatório de outubro de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_outubro20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_setembro20.pdf">Relatório de setembro de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_setembro20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago2020.pdf">Relatório de agosto de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_ago2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jul2020.pdf">Relatório de julho de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jul2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun20.pdf">Relatório de junho de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jun20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_maio_2020.pdf">Relatório de maio de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_maio_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abril2020.pdf">Relatório de abril de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_abril2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_16mar20.pdf">Relatório de março de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_16mar20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_fev2020.pdf">Relatório fevereiro de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_fev2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan2020.pdf">Relatório janeiro de 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_jan2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez2019.jpg">Relatório dezembro de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_toninhas/monitoramento_ete_toninhas_dez2019.jpg"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

            <p>Clique na imagem e veja o mapa ampliado com os pontos de monitoramento:</p>
            <p><a href="/site/uploads/file/ete_toninhas/mapa_monitoramento_amp.jpg" target="_blank" class="link"><img src="/site/uploads/image/ete_toninhas/mapa_ete_toninhas.jpg" width="680" height="383" alt="" /></a></p>

        </div>
    </article>


</main>
