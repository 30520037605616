<main class="main">
	<meta name="description" content="Conheça as práticas de governança corporativa da Sabesp, pautadas pela transparência, responsabilidade e valores éticos. Saiba como adotamos políticas e procedimentos avançados para mitigar riscos, prevenir fraudes e garantir a conformidade regulatória.">
		<nav class="breadcrumbs breadcrumb-link">
			<div class="container">
				<ul class="menu">
					<li><a href="/" title="Início">Início</a></li>
					<li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
					<li><a href="/sustentabilidade/governanca" title="Governança">Governança</a></li>
				</ul>

				<ul class="buttons">
					<li><a class="button -home" href="/" title="Início">Início</a></li>
				</ul>
			</div>
		</nav>

		<article class="content">
			<div class="container">
				<h1 class="headline">Fortalecendo a Governança</h1>

				<picture class="picture petals align-right">
					<img loading="eager" fetchpriority="low" width="650" height="380" src="/assets/images/sustentabilidade/fortalecendo-governanca.jpg" alt="Tecnologia ESG sendo digitada em laptop" title="Fortalecendo a Governança">
				</picture>
				<p>Para cumprir nossa missão de prestar serviços de saneamento com excelência, nós, da Sabesp, adotamos práticas de governança corporativa avançadas, que incluem a integridade e conformidade contínuas para mitigar riscos e prevenir fraudes e corrupção.</p>
        <p>Somos pautados pela transparência, responsabilidade e valores éticos em nossas relações, o que nos traz o fortalecimento da imagem da empresa, gera valor e aumenta o desenvolvimento sustentável de nossa gestão.</p>
        <p>Nossas políticas, regras e procedimentos estão alinhados aos mais altos padrões de governança corporativa, constantemente atualizados para atender às diretrizes da Comissão de Valores Mobiliários (<abbr title="Comissão de Valores Mobiliários">CVM</abbr>) do Brasil e da <em lang="en-US">Securities and Exchange Commission</em> (<abbr title="Securities and Exchange Commission">SEC</abbr>) dos <abbr title="Estados Unidos América">EUA</abbr>, o que evidencia nosso compromisso com a conformidade regulatória e legal em todas as jurisdições onde operamos.</p>
			</div>
		</article>

		<section class="more -white">
			<div class="container">
				<nav class="nav">
					<h2 class="headline">Veja também</h2>

					<ul class="items">
						<li class="item">
							<a class="title acess-link" target="_blank" href="https://ri.sabesp.com.br/governanca-corporativa/conselhos-comites-do-conselho-e-diretoria/">Documentos de Governança</a>
						</li>
					</ul>
				</nav>
			</div>
		</section>
	</main>
