<main class="main">
    <meta name="description" content="Descubra a história da Sabesp, uma trajetória de dedicação ao saneamento básico em São Paulo. Conheça mais sobre nossa jornada!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/institucional/perfil" title="Institucional">Institucional</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Nossa História">Nossa História</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container petals petals-2">
            <h1 class="headline">Linha do Tempo</h1>

            <p class="-balance">O tempo é um rio que percorre as nossas vidas. Então vamos juntos fazer essa viagem
                navegando pelos fatos mais marcantes destes 50 anos de história da Sabesp, do saneamento e de todos nós.
            </p>


            <div class="timeline">

                    <div *ngFor="let timeline of timelines , let i = index" class="block" [ngClass]="i % 2 ? 'to-left' : 'to-right'">

                        <div class="item">
                            <div class="top">
                                <h2 class="title">{{timeline.year}}</h2>
                                <picture class="figure">
                                    <img loading="lazy" fetchpriority="low" width="420" height="225"
                                        src="{{timeline.image}}" alt="{{timeline.alt}}" title="{{timeline.alt}}">
                                </picture>
                            </div>
                            <div class="description">
                                <div [innerHTML]="timeline.description"></div>
                                <div class="hidden-line"></div>
                            </div>
                        </div>
                    </div>
            </div>

        </div>
    </article>
</main>
