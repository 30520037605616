import { Component, ViewEncapsulation, Renderer2, OnInit, ElementRef } from '@angular/core';
import { CommonQuestions } from '../common-questions';
import { CommonQuestionsService } from '../commonQuestions.service';
import { empty } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-perguntas-frequentes',
  templateUrl: './perguntas-frequentes.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'perguntas-frequentes.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PerguntasFrequentesComponent implements OnInit {

  questions: CommonQuestions[] = [];
  searchTerm = "";
  filterTerm = "";
  categories :any = [];

  unlistener: () => void;

  constructor(private commonQuestionsService: CommonQuestionsService, private renderer: Renderer2, private renderer2: Renderer2, private elementRef: ElementRef, private title: Title) { this.unlistener = () => { }; }

  ngOnInit() {
    this.title.setTitle("Sabesp | Perguntas Frequentes");
    this.getCommonQuestions();

    const floatButtons = document.querySelectorAll('.chat-popup');
    floatButtons.forEach(button => {
      this.renderer.listen(button, 'click', (e) => {
        e.preventDefault();
        this.windowOpen('https://sabesp-chat.sabesp.com.br/chat-externo', 340, 752);
      });
    });
  }

  windowOpen(url: string, w: number, h: number) {
    let newW = w + 100,
        newH = h + 100,
        top = window.screen.height - h - 250,
        left = window.screen.width - w - 15;

    let newWindow = window.open(url, 'name', 'width=' + newW + ',height=' + newH + ',left=' + left + ',top=' + top);

    if (newWindow) {
      newWindow.resizeTo(newW, newH);
      newWindow.moveTo(left, top);
      newWindow.focus();
    } else {
      return
    }

    return false;
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.querySelector('.accordion .head a')
      .addEventListener('click', this.onClick.bind(this));
    // this.elementRef.nativeElement.querySelector('.accordion .close')
    //.addEventListener('click', this.onClick.bind(this));


  }

  ngAfterContentChecked() {
    this.getCategories();
  }

  onClick(e: any) {
    e.preventDefault();
    let wrapper = e.target.closest('.item');
    wrapper.classList.toggle('-open');
    let collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
  }

  accordionToggle(e: any) {
    e.preventDefault();
    let wrapper = e.target.closest('.item');
    wrapper.classList.toggle('-open');
    let collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');

  }

  getCommonQuestions(): void {
    this.commonQuestionsService.getCommonQuestions()
      .subscribe(questions => this.questions = questions);
  }

  termImput(e: any) {
    this.filterTerm = e;
  }

  getTerms() {
    return this.filterTerm
  }

  getCategories() {
    this.categories = [...new Set(this.questions.map((item :any)=> item.categoryName))];
  }

}
