import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorios-sustentabilidade',
  standalone: true,
  imports: [],
  templateUrl: './relatorios-sustentabilidade.component.html',
  styleUrl: './relatorios-sustentabilidade.component.css'
})
export class RelatoriosSustentabilidadeComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Relatórios de Sustentabilidade");
}
}
