import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cuidando-do-social',
  standalone: true,
  imports: [],
  templateUrl: './cuidando-do-social.component.html',
  styleUrl: './cuidando-do-social.component.css'
})
export class CuidandoDoSocialComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Social");
}
}
