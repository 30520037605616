<main class="main">
    <meta name="description" content="Substituição de Redes e Setorização: descubra como a Sabesp moderniza a infraestrutura para melhorar o abastecimento de água e reduzir perdas.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo"
                        title="Programa de Saneamento Sustentável Inclusivo">Programa de Saneamento Sustentável Inclusivo</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/substituicao-redes-setorizacao"
                        title="Substituição de redes e setorização">Substituição de redes e setorização</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

<h1 class='headline'>Substituição de redes e setorização</h1>

<p>A reabilitação e renovação de redes de distribuição de água têm por finalidade reduzir as perdas no sistema de abastecimento público, sobretudo aquelas mais antigas e que se encontram em condições críticas de manutenção e operação.</p>

<p>A meta é substituir 850 km de tubulações na Região Metropolitana de São Paulo e  suas respectivas conexões prediais, permitindo à Sabesp ampliar o nível de confiabilidade e segurança do abastecimento, aumentar a resiliência do sistema distribuidor e melhorar a qualidade e a quantidade da água fornecida.</p>



<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa%20morumbi%20ct%201596_23-01-%20publicac%CC%A7a%CC%83o.pdf"> Plano de Gestão Socioambiental - Butantã</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa%20morumbi%20ct%201596_23-01-%20publicac%CC%A7a%CC%83o.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa 8_2 morumbi.pdf"> Plano de Gestão Socioambiental — Morumbi</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa 8_2 morumbi.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_carapicuiba.pdf"> Plano de Gestão Socioambiental - Carapicuíba</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_carapicuiba.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/psga_artur_alvim.pdf"> Plano de Gestão Socioambiental - Artur Alvim</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/psga_artur_alvim.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_jabaquara.pdf"> Plano de Gestão Socioambiental – Jabaquara</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_jabaquara.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_socioambiental_der_santana.pdf"> Plano de Gestão Socioambiental - Derivação Santana</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_socioambiental_der_santana.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_socioambiental_lapa.pdf"> Plano de Gestão Socioambiental - Lapa</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_socioambiental_lapa.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_socioambiental_campo_belo.pdf"> Plano de Gestão Socioambiental - Campo Belo</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_socioambiental_campo_belo.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_troca_redes_der_santana.pdf"> Plano de Gestão Ambiental Piloto - Derivação Santana</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_troca_redes_der_santana.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_salvaguarda_grajau.pdf"> Relatório de Cumprimento de Salvaguardas Grajaú</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_salvaguarda_grajau.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_salvaguarda_angela.pdf"> Relatório de Cumprimento de Salvaguardas Jardim Ângela</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_salvaguarda_angela.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_comunicacao_performance_angela.pdf"> Plano de Gestão de Comunicação das obras do Jardim Ângela</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_comunicacao_performance_angela.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_ambiental_performance_angela.pdf"> Plano de Gestão Ambiental das obras do Jardim Ângela</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_ambiental_performance_angela.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_comunicacao_performance_grajau.pdf"> Plano de Gestão de Comunicação das obras do Grajaú</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_comunicacao_performance_grajau.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_ambiental_performance_grajau.pdf"> Plano de Gestão Ambiental das obras do Grajaú</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/plano_gestao_ambiental_performance_grajau.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>



        </div>
    </article>


</main>
