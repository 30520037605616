import { Injectable } from '@angular/core';
import { Banner } from './banner';
import { catchError, map, tap, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './error.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private bannersUrl = environment.apiUrl + '/api/v1/banners';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getBanners(): Observable<Banner[]> {
    return this.http.get<Banner[]>(this.bannersUrl)
      .pipe(
        catchError(ErrorService.handle<Banner[]>('getBanners', []))
      );
  }
}