import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TinySliderInstance, tns } from 'tiny-slider';

@Component({
  selector: 'app-distribuicao-agua',
  templateUrl: './distribuicao-agua.component.html',
  styleUrls: [
    '../../assets/css/components.css',
    '../../assets/css/internal.css',
    '../../assets/css/water-distribution.css',
    './distribuicao-agua.component.css'
  ],
})
export class DistribuicaoAguaComponent implements AfterViewChecked, OnInit {

  peopleNumberHouse = [
    { number: 3, img: { width: 206, height: 165 }, averageConsumption: 450, waterTankSize: 500 },
    { number: 4, img: { width: 203, height: 166 }, averageConsumption: 600, waterTankSize: 1000 },
    { number: 5, img: { width: 250, height: 166 }, averageConsumption: 750, waterTankSize: 1000 },
    { number: 6, img: { width: 298, height: 166 }, averageConsumption: 900, waterTankSize: 1000 }
  ];

  carousel!: TinySliderInstance;
  carouselStarted: boolean = false;

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Da Sabesp para sua Casa");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Saiba como a Sabesp distribui água tratada para milhões de consumidores. Entenda o processo de distribuição, desde os reservatórios até as residências, e conheça nossos programas de redução de perdas e uso consciente.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, distribuição de água, água tratada, reservatórios, redes de distribuição, caixa d água, redução de pressão, controle de perdas, hidrômetros individuais, eliminadores de ar. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Distribuição de Água'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Distribuição de Água'
    });
  }

  ngAfterViewChecked() {
    if (!this.carouselStarted) {
      this.initCarousel();
      this.carouselStarted = true;
    }
  }

  private initCarousel(): void {
    this.carousel = tns({
      container: '.carousel-slider',
      items: 1,
      autoplay: false,
      loop: true,
      controls: true,
      controlsText: ['Anterior', 'Próximo'],
      navPosition: 'bottom',
      autoplayText: ['', ''],
      autoplayButtonOutput: false,
      autoHeight: true,
      lazyload: true,
      speed: 200
    });

    this.carouselInit();

    this.carousel?.events.on('indexChanged', (info: any) => {
      const outer = document.querySelector('.tns-outer');
      outer?.setAttribute('data-navColor', info.slideItems[info.index]?.dataset.navColor);
    });

    this.carousel?.events.on('transitionEnd', () => {
      this.carousel.updateSliderHeight();
    });
  }

  carouselInit(): void {
    const info: any = this.carousel.getInfo();
    const outer = document.querySelector('.tns-outer');
    outer?.setAttribute('data-navColor', info.slideItems[info.index]?.dataset.navColor);

    const dots = document.querySelectorAll('.tns-nav [data-nav]');
    dots.forEach((dot, index) => {
      const span = document.createElement('span');
      span.textContent = (index + 1).toString();
      span.classList.add('classlist');
      dot.innerHTML = '';
      dot.appendChild(span);
    });

    const nav = document.querySelector(`#${info.container.id}-ow .tns-nav`) as HTMLElement;

    const controls = document.querySelector(`#${info.container.id}-ow .tns-controls`) as HTMLElement;
    controls?.style.setProperty('--nav-width', `${nav?.offsetWidth}px`);

    // Adiciona classes para os botões de navegação anterior e próximo
    const prev = controls.querySelector('[data-controls="prev"]');
    prev?.classList.add('slider-prev');

    const next = controls.querySelector('[data-controls="next"]');
    next?.classList.add('slider-next');
  }


  getAltText(item: any): string {
    if (item.number === 3) {
      return 'ícone de uma criança e dois adultos';
    } else if (item.number === 4) {
      return 'Ilustração de família de quatro pessoas';
    } else if (item.number === 5) {
      return ' imagem de três adultos e duas crianças';
    } else if (item.number === 6) {
      return ' imagem de três adultos e duas crianças';
    } else {
      return `${item.number} pessoas na casa com consumo médio de ${item.averageConsumption} litros por dia`;
    }
  }

  trackByFn(index: number, item: any): number {
    return item.number;
  }
}
