<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Fale com a Sabesp">Fale com a Sabesp</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <section class="content">
        <div class="container">
            <h1 class="headline">Fale com a Sabesp</h1>

            <div class="boxes">
                <div class="item -gray">
                    <div class="top">
                        <figure class="icon">
                            <a rel="noopener noreferrer"
                                class="chat-popup" tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="33" height="33"
                                    src="./assets/images/icon/chat.svg" alt="Ícone Chat" title="Chat">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a rel="noopener noreferrer"
                                class="chat-popup" tabindex="-1">
                                Chat
                            </a>
                        </h2>
                    </div>
                    <p class="description"><a rel="noopener noreferrer"
                            class="chat-popup" tabindex="-1">Converse com um de
                            nossos atendentes via Chat para solicitar serviços e atendimento.</a></p>
                    <a id="chat" class="button chat-popup" rel="noopener noreferrer"
                        title="Inicie">Inicie</a>
                </div>
                <div class="item -gray">
                    <div class="top">
                        <figure class="icon">
                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/551133888000"
                                tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="40" height="40"
                                    src="./assets/images/icon/whatsapp2.svg" alt="Símbolo de chamada telefônica em balão de fala" title="WhatsApp">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/551133888000"
                                tabindex="-1">
                                WhatsApp
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a target="_blank" rel="noopener noreferrer" href="https://wa.me/551133888000" tabindex="-1">
                            É possível solicitar serviços pelo WhatsApp agora. Basta salvar o número <strong>(11
                                3388-8000)</strong> nos seus contatos para agilizar as suas solicitações.
                        </a>
                    </p>
                    <a class="button" href="https://wa.me/551133888000" target="_blank" title="Confira">Confira</a>
                </div>
            </div>

        </div>
    </section>

    <section class="highlight">
        <div class="container">
            <div class="text">
                <div class="boxes -full">
                    <div class="item -blue">
                        <div class="top">
                            <figure class="icon">
                                <a href="/fale-com-a-sabesp/perguntas-frequentes" tabindex="-1">
                                    <img loading="lazy" fetchpriority="low" width="40" height="40"
                                        src="./assets/images/icon/duvida.svg" alt=" Ícone de suporte ao cliente com interrogação"
                                        title="Perguntas Frequentes">
                                </a>
                            </figure>
                            <h2 class="title">
                                <a href="/fale-com-a-sabesp/perguntas-frequentes" tabindex="-1">
                                    Perguntas Frequentes
                                </a>
                            </h2>
                        </div>
                        <p class="description">
                            <a href="/fale-com-a-sabesp/perguntas-frequentes" tabindex="-1">
                                Tem dúvida sobre algo ou algum serviço? Consulte as perguntas frequentes.
                            </a>
                        </p>
                        <a class="button" href="/fale-com-a-sabesp/perguntas-frequentes" title="Confira">Confira</a>
                    </div>
                </div>
            </div>

            <picture class="figure">
                <img loading="lazy" fetchpriority="low" width="385" height="325"
                    src="./assets/images/illustration/sani-mostra-4.png" alt="Sani" title="Sani">
            </picture>
        </div>
    </section>

    <section class="content -gray">
        <div class="container">
            <div class="boxes">
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <a href="https://agenciavirtual.sabesp.com.br" target="_blank" rel="noopener noreferrer"
                                tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="34" height="30"
                                    src="./assets/images/icon/monitor.svg" alt="Ícone de monitor com seta de clique"
                                    title="Ícone de monitor com seta de clique">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="https://agenciavirtual.sabesp.com.br" target="_blank" rel="noopener noreferrer"
                                tabindex="-1">
                                Agência Virtual
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="https://agenciavirtual.sabesp.com.br" target="_blank" rel="noopener noreferrer"
                            tabindex="-1">
                            Quer solicitar um serviço? Temos várias opções a sua disposição em nossa <strong>Agência
                                Virtual</strong>.
                        </a>
                    </p>
                    <a class="button" target="_blank" rel="noopener noreferrer"
                        href="https://agenciavirtual.sabesp.com.br" title="Acesse">Acesse</a>
                </div>
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <img loading="lazy" fetchpriority="low" width="33" height="33"
                                src="./assets/images/icon/telefone.svg" alt="Ícone de telefone para suporte ao cliente" title="Ícone de telefone para suporte ao cliente">
                        </figure>
                        <h2 class="title">Telefones</h2>
                    </div>
                    <div class="description">
                        <p>Atendimento 24 horas</p>

                        <ul class="columns">
                            <li>
                                <a href="tel:08000550195">
                                    Central de Atendimento
                                    <div class="-lg"><strong>0800 055 0195</strong></div>
                                </a>
                            </li>
                            <li>
                                <a href="tel:+08000160195">
                                    Deficientes Auditivos e de Fala
                                    <div class="-lg"><strong>0800 016 0195</strong></div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <a href="https://icom.app/sabesp-site" target="_blank" tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="41" height="32"
                                    src="./assets/images/icon/libras.svg" alt="Símbolo de serviço de atendimento em Libras"
                                    title="Central de Atendimento em Libras">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="https://icom.app/sabesp-site" target="_blank" tabindex="-1">
                                Central de Atendimento em Libras
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="https://icom.app/sabesp-site" target="_blank" tabindex="-1">
                            Atendimento por videochamada com um intérprete de Libras.
                        </a>
                    </p>
                    <a class="button" href="https://icom.app/sabesp-site"  target="_blank" title="Acesse">Acesse</a>
                </div>
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <a href="https://agenciavirtual.sabesp.com.br/canais-atendimento/agencias-proximas" target="_blank" tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="40" height="40"
                                    src="./assets/images/icon/predio.svg" alt="Ícone azul de um prédio"
                                    title="Agências de Atendimento">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="https://agenciavirtual.sabesp.com.br/canais-atendimento/agencias-proximas" target="_blank" tabindex="-1">
                                Agências de Atendimento
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" tabindex="-1">
                            Consulte a agência mais próxima.
                        </a>
                    </p>
                    <a class="button" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" title="Entre">Entre</a>
                </div>
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <a href="http://faleconosco.sabesp.com.br" target="_blank" tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="31" height="33"
                                    src="./assets/images/icon/checklist.svg" alt="Ícone de checklist em azul"
                                    title="Fale Conosco">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="http://faleconosco.sabesp.com.br" target="_blank" tabindex="-1">
                                Fale Conosco
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="http://faleconosco.sabesp.com.br" target="_blank" tabindex="-1">
                            Encaminhe sua mensagem de elogio ou dúvida pelo nosso formulário.
                        </a>
                    </p>
                    <a class="button" href="http://faleconosco.sabesp.com.br" target="_blank" title="Envie">Envie</a>
                </div>
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <a href="/fale-com-a-sabesp/ouvidoria" tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="35" height="30"
                                    src="./assets/images/icon/audio.svg" alt="Ícone de comunicação com megafone" title="Ouvidoria">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="/fale-com-a-sabesp/ouvidoria" tabindex="-1">
                                Ouvidoria
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="/fale-com-a-sabesp/ouvidoria" tabindex="-1">
                            Você registrou o pedido em um dos canais de atendimento da Sabesp e não obteve uma solução?
                        </a>
                    </p>
                    <a class="button" href="/fale-com-a-sabesp/ouvidoria" title="Informe">Informe</a>
                </div>
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <a href="https://www.contatoseguro.com.br/sabesp" target="_blank" tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="34" height="30"
                                    src="./assets/images/icon/atencao.svg" alt="Ícone azul de triângulo com ponto de exclamação"
                                    title="Canal de Denúncia">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="https://www.contatoseguro.com.br/sabesp" target="_blank" tabindex="-1">
                                Canal de Denúncia
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="https://www.contatoseguro.com.br/sabesp" target="_blank" tabindex="-1">
                            Faça denúncias sobre desvios de conduta de empregados (próprios e terceiros), suspeitas de
                            fraudes, corrupção, atos ilícitos ou quaisquer transgressões ao Código de Conduta e
                            Integridade.
                        </a>
                    </p>
                    <a class="button" href="https://www.contatoseguro.com.br/sabesp" target="_blank" title="Acesse">Acesse</a>
                </div>
                <div class="item">
                    <div class="top">
                        <figure class="icon">
                            <a href="/sustentabilidade/transparencia/informacoes-ao-cidadao" tabindex="-1">
                                <img loading="lazy" fetchpriority="low" width="35" height="35"
                                    src="./assets/images/icon/duvidas.svg" alt="Ícone de suporte ao cliente com balões de fala" title="SIC">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="/sustentabilidade/transparencia/informacoes-ao-cidadao" tabindex="-1">
                                Informações ao Cidadão
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="/sustentabilidade/transparencia/informacoes-ao-cidadao" tabindex="-1">
                            Aqui você encontra o acesso para o Fala.SP, o canal de comunicação para pedido de acesso à informação.
                        </a>
                    </p>
                    <a class="button" href="/sustentabilidade/transparencia/informacoes-ao-cidadao" title="Solicite">Solicite</a>
                </div>
            </div>
        </div>
    </section>
</main>
