import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-eliminadores-ar',
  standalone: true,
  imports: [],
  templateUrl: './eliminadores-ar.component.html',
  styleUrl: './eliminadores-ar.component.css'
})
export class EliminadoresArComponent {
  constructor( private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Eliminadores de Ar");
}
}
