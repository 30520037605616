<main class="main">
  <meta name="description" content="Acesse o portal Sabesp para fornecedores: cadastro, qualificação técnica, atestados e informações de pagamento.">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li><a href="/" title="Início">Início</a></li>
        <li><a href="javascript:void(0);" title="Serviços">Serviços</a></li>
        <li><a href="servicos/para-fornecedores" title="Para Fornecedores">Para Fornecedores</a></li>
      </ul>

      <ul class="buttons">
        <li><a class="button -home" href="/" title="Início">Início</a></li>
      </ul>
    </div>
  </nav>

  <article class="content">
    <div class="container">
      <h1 class="headline">Fornecedores</h1>

      <picture class="banner -no-shadow">
        <img loading="eager" fetchpriority="high" width="1300" height="244" src="../assets/images/sabesp-fornecedores.jpg" alt="Fornecedores" title="Fornecedores">
      </picture>

      <p>Nesta seção você encontra todas as informações necessárias para Fornecedores.</p>

      <ul class="list -fw-medium">
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="http://licitacoes.sabesp.com.br/wps/portal/licitacoes"><strong>Portal de Contratação</strong>: Acesso para os fornecedores já cadastrados na Sabesp.</a></li>
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="http://licitacoes.sabesp.com.br/wps/portal/licitacoes"><strong>Cadastro de Fornecedores</strong>: Aqui começa o processo de Cadastro dos nossos Fornecedores.</a></li>
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="http://licitacoes.sabesp.com.br/wps/portal/licitacoes"><strong>Qualificação Técnica</strong>: Dicas e Orientações aos Fornecedores Qualificados.</a></li>
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="http://licitacoes.sabesp.com.br/wps/portal/licitacoes"><strong>Atestado Técnico</strong>: Espaço dedicado para o Fornecedor pedir seu Atestado Técnico.</a></li>
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="http://informes.sabesp.com.br/sgl/fornecedor/informes/pesquisar"><strong>Informe de Pagamentos</strong>: Pesquisar Pagamentos efetuados e a efetuar.</a></li>
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="http://informes.sabesp.com.br/sgl/fornecedor/informes/pesquisar"><strong>Informe de Retenções</strong>: Pesquisar Retenções.</a></li>
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="https://normastecnicas.sabesp.com.br/"><strong>Normas Técnicas</strong>: Consultar Normas Técnicas Sabesp.</a></li>
        <li><a class="-no-underline -external -external-2" target="_blank" rel="noopener noreferrer" href="https://desenhopadrao.sabesp.com.br/"><strong>Desenho Padrão</strong>: Consultar Desenho Padrão Sabesp.</a></li>
      </ul>
    </div>
  </article>
</main>