import { Component, ViewEncapsulation } from '@angular/core';
import { NoticeService } from '../notice.service';
import { Notice } from '../notice';
import { Category } from '../category';
import { Pagination } from '../pagination';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'comunicados.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ComunicadosComponent {
  notices: Pagination<Notice> = { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] };
  perPage = 6;
  categoryId = "";
  startDate: Date | null = null;
  endDate: Date | null = null;
  q = "";
  order = "";
  mostViewed: Notice[] = [];
  noticies: Notice[] = [];
  categories: Category[] = [];
  page = Number(1);

  constructor(private noticeService: NoticeService, private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Utilidade Pública");
    this.getNotices(1);
    this.getMostViewedNoticies();
    this.getCategories();
  }

  getNotices(page: Number): void {
    this.page = Number(page);
    if (this.q == "" && this.order == "") {
      this.order = "DESC";
    }
    this.noticeService.getNotice(this.categoryId, this.startDate, this.endDate, this.q, this.order, page, this.perPage)
      .subscribe(notices => this.notices = notices);
  }

  getMostViewedNoticies(): void {
    this.noticeService.getMostViewedNoticies()
      .subscribe(notice => this.noticies = notice);
  }

  getCategories(): void {
    this.noticeService.getNoticeCategories()
      .subscribe(categories => this.categories = categories);
  }

  filterTypeSearch(searchQuery: any) {
    if (this.q == "" && this.order == "") {
      this.order = "DESC";
    }
    this.noticeService.getNotice(searchQuery.categoryId, searchQuery.startDate, searchQuery.endDate, searchQuery.q, searchQuery.order, this.page, this.perPage)
    .subscribe(notices => this.notices = notices);
  }
}
