import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-curso-pesquisa-vazamentos',
  templateUrl: './curso-pesquisa-vazamentos.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CursoPesquisaVazamentosComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Curso de Pesquisa de Vazamentos");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Participe do curso gratuito de pesquisa de vazamento oferecido pela Sabesp. Aprenda técnicas práticas para identificar vazamentos em instalações hidráulicas. Inscreva-se agora!'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, curso de pesquisa de vazamento, identificar vazamentos, Educação Sabesp, vazamentos, instalações hidráulicas, inscrições curso Sabesp '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Curso de Pesquisa de Vazamentos'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Curso de Pesquisa de Vazamentos'
    });
  }
}
