<main class="main">
	<meta name="description" content="Conheça o programa Córrego Limpo da Sabesp e descubra como contribuímos para a despoluição e preservação dos córregos. Saiba mais!">
		<nav class="breadcrumbs">
			<div class="container">
				<ul class="menu">
					<li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="O Que Fazemos">O Que Fazemos</a></li>
					<li><a class="breadcrumb-link" href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="Córrego Limpo">Programa Córrego Limpo</a></li>
				</ul>

				<ul class="buttons">
					<li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
				</ul>
			</div>
		</nav>

		<article class="content">
			<div class="container">
				<h1 class="headline">Programa Córrego Limpo</h1>

				<picture class="picture align-right petals">
					<img loading="eager" fetchpriority="high" width="650" height="380" src="/assets/images/sustentabilidade/corrego-limpo-ipiranga.jpg" alt="Canal de drenagem urbana e via com carros" title="Córrego Limpo">
				</picture>

				<p>Muito além do saneamento básico, a Sabesp atua em busca da melhoria na qualidade do meio ambiente e da integração da sociedade com a natureza. Com o programa Córrego Limpo, em parceria com a prefeitura de São Paulo, realizamos a despoluição de córregos na cidade, trazendo mais saúde e qualidade de vida para a população que reside em seu entorno.</p>
				<p>Monitoramento dos córregos e adequações no sistema de esgotamento sanitário para que o esgoto seja encaminhado para tratamento. A prefeitura é responsável por remover entulhos e sedimentos das margens e leitos, fazer a poda da vegetação e ações de reurbanização na área.</p>
				<p>Realizado segundo o modelo de governança colaborativa, o trabalho de despoluição é feito em parceria com os moradores das margens dos córregos, que passam por um processo de conscientização, ajudando a prevenir o lançamento de esgoto e o descarte irregular de lixo nesses corpos hídricos.</p>
			</div>
		</article>

		<section class="more -white">
			<div class="container">
				<nav class="nav">
					<h2 class="headline">Veja também</h2>

					<ul class="items">
						<li class="item">
							<a class="title acess-link" target="_blank" rel="noopener noreferrer" href="../../assets/pdf/monitoramento_corrego_limpo_mar24.pdf">Monitoramento da qualidade dos córregos</a>
						</li>
						<li class="item">
							<a class="title acess-link" target="_blank" rel="noopener noreferrer" href="../../assets/pdf/corregos_relacao.pdf">Relação dos córregos do programa</a>
						</li>
					</ul>
				</nav>
			</div>
		</section>
	</main>
