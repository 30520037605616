import { Component, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dicas-economia-agua',
  templateUrl: './dicas-economia-agua.component.html',
  styleUrls: ['./dicas-economia-agua.component.css','../../assets/css/internal.css', '../../assets/css/components.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DicasEconomiaAguaComponent {
  constructor(private title: Title, private meta: Meta){}
  ngOnInit() {
    this.title.setTitle("Sabesp | Dicas de Economia de água ");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Aprenda como economizar água com as dicas da Sabesp. Reduza seu consumo diário e contribua para a preservação dos recursos naturais e a economia financeira. Confira nossas sugestões para o banheiro, cozinha, área de serviço e jardim.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, economia de água, dicas de economia de água, uso consciente da água, preservar água, reduzir consumo de água, sustentabilidade, meio ambiente, banho curto, lavar louça, máquina de lavar, jardim, área externa, vazamentos. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Dicas para economizar água'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Dicas para economizar água'
    });
  }

  accordionToggle(e: any) {
    e.preventDefault();
    let wrapper = e.target.closest('.item');
    wrapper.classList.toggle('-open');

    let collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
  }
}
