import { Injectable } from '@angular/core';
import { Timelines } from './timelines';
import { catchError, map, tap, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './error.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimelinesService {
  private timelinesUrl = environment.apiUrl + '/api/v1/timelines';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getTimelines(): Observable<Timelines[]> {
    return this.http.get<Timelines[]>(this.timelinesUrl)
      .pipe(
        catchError(ErrorService.handle<Timelines[]>('getTimelines', []))
      );
  }
}