import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-premios-reconhecimentos',
  standalone: true,
  imports: [],
  templateUrl: './premios-reconhecimentos.component.html',
  styleUrl: './premios-reconhecimentos.component.css'
})
export class PremiosReconhecimentosComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Prêmios e Reconhecimentos");
  }
}
