<main class="main">
    <meta name="description" content="Programa de Saneamento Sustentável Inclusivo: descubra as iniciativas da Sabesp para promover o acesso universal e sustentável ao saneamento básico.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo"
                        title="Programa de Saneamento Sustentável e Inclusivo">Programa de Saneamento Sustentável e Inclusivo</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


<h1 class='headline'>Programa de Saneamento Sustentável e Inclusivo</h1>

<p>O Programa Saneamento Sustentável e Inclusivo tem como objetivo expandir o acesso a serviços públicos regulares de água e esgoto, reduzir  perdas de água nos sistemas de distribuição,  ampliar a resiliência dos sistemas produtores de água na Região Metropolitana de São Paulo e, também, diminuir o aporte de cargas poluidoras na bacia hidrográfica do Guarapiranga. </p>

<p>Financiado pelo Banco Internacional para Reconstrução e Desenvolvimento - BIRD,  o programa está relacionado a temas como segurança hídrica, inclusão social, preservação ambiental e inovações técnicas e contratuais. </p>

<p>O Manual de Operação do Programa Sustentabilidade e Inclusão Social (MOP), composto por 3 volumes, foi elaborado para atender a cláusulas contratuais previstas no Acordo de Empréstimo e constituir um marco de referência para a implementação do Programa. Do seu Volume 1, consta uma apresentação detalhada do Programa Saneamento Sustentável e Inclusivo.</p>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/consulta_publica/manual de operacao vol 1 - rev junho23.pdf">Volume I: contém o texto básico do Manual de Operação do Programa Sustentabilidade e Inclusão Social.</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/consulta_publica/manual de operacao vol 1 - rev junho23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/manual_operacao_volume2_jun22.pdf">Volume II: documentos-padrão que deverão ser utilizados ao longo da implementação do Projeto.</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/manual_operacao_volume2_jun22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/manual_operacao_volume3.pdf">Volume III: constituído pelo Marco de Desapropriação e Reassentamento Involuntário e pelo Marco de Gestão Socioambiental do Programa (MGSA) e seus anexos: Manual da Comunicação Social (MCS); Manual de Orientação Social (MOS); Manual Ambiental da Construção (MAC); Manual Físico-Cultural e o Plano de Ação para a Segurança de Barragens.</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/manual_operacao_volume3.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>

<h2 class='headline'>VEJA TAMBÉM</h2>

<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/programa-agua-legal">Programa Água Legal</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/programa-agua-legal"><span>Acessar</span></a></td></tr>

<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/substituicao-redes-setorizacao">Substituição de redes</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/substituicao-redes-setorizacao"><span>Acessar</span></a></td></tr>

<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/obras-esgotos">Obras de esgotos</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/obras-esgotos"><span>Acessar</span></a></td></tr>

<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/seguranca-hidrica">Obras em barragens</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/seguranca-hidrica"><span>Acessar</span></a></td></tr>

<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/consulta-publica-virtual">Consulta Pública Virtual</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/consulta-publica-virtual"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2019.pdf"> Relatório de Auditoria 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2019.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2020.pdf"> Relatório de Auditoria 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2020.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2021.pdf"> Relatório de Auditoria 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2021.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2022.pdf"> Relatório de Auditoria 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_auditoria_2022.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


        </div>
    </article>


</main>
