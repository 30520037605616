<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/atas-do-comite-de-auditoria"
                        title="Atas do Comitê de Auditoria">Atas do Comitê de Auditoria</a></li>
                        <li><a href="/sustentabilidade/transparencia/relatorios-atas-pesquisas-anos-anteriores"
                            title="Relatórios, atas e pesquisas - Anos anteriores">Anos
                            anteriores</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <br><br><h2>ATAS DAS REUNIÕES DO COMITÊ DE AUDITORIA - Anos anteriores 2022 a 2018</h2>
            <br><br><h2>Atas ano 2022</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20221212_extratoata_2522.pdf"> 425ª Reunião - 12/12/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20221212_extratoata_2522.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20221206_extratoata_2422.pdf"> 424ª Reunião - 06/12/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20221206_extratoata_2422.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20221125_extratoata_2322.pdf"> 423ª Reunião -  25/11/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20221125_extratoata_2322.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20221108_extratoata_2222.pdf"> 422ª Reunião - 08/11/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20221108_extratoata_2222.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20221019_extratoata_2122.pdf"> 421ª Reunião - 19/10/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20221019_extratoata_2122.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20221011_extratoata_2022.pdf"> 420ª Reunião -  11/10/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20221011_extratoata_2022.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220919_extratoata_1922.pdf"> 419ª Reunião - 19/09/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220919_extratoata_1922.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220913_extratoata_1822.pdf"> 418ª Reunião - 13/09/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220913_extratoata_1822.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/20220825_extratoata_1722.pdf"> 417ª Reunião - 25/08/2022  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/20220825_extratoata_1722.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220809_extratoata_1622.pdf"> 416ª Reunião - 09/08/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220809_extratoata_1622.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220719_extratoata_1522.pdf"> 415ª Reunião - 19/07/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220719_extratoata_1522.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220712_extratoata_1422.pdf"> 414ª Reunião - 12/07/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220712_extratoata_1422.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220628_extratoata_1322.pdf"> 413ª Reunião - 28/06/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220628_extratoata_1322.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220621_extratoata_1222.pdf"> 412ª Reunião - 21/06/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220621_extratoata_1222.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220530_extratoata_1122.pdf"> 411ª Reunião - 30/05/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220530_extratoata_1122.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220505_extratoata_1022.pdf"> 410ª Reunião - 05/05/2022  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220505_extratoata_1022.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220419_extratoata_0922.pdf"> 409ª Reunião - 19/04/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220419_extratoata_0922.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220412_extratoata_0822.pdf"> 408ª Reunião - 12/04/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220412_extratoata_0822.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220322_extratoata_0722.pdf"> 407ª Reunião - 22/03/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220322_extratoata_0722.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220308_extratoata_0622.pdf"> 406ª Reunião - 08/03/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220308_extratoata_0622.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220222_extratoata_0522.pdf"> 405ª Reunião - 22/02/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220222_extratoata_0522.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220221_extratoata_0422.pdf"> 404ª Reunião - 21/02/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220221_extratoata_0422.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220208_extratoata_0322.pdf"> 403ª Reunião - 08/02/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220208_extratoata_0322.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220127_extratoata_0222.pdf"> 402ª Reunião - 27/01/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220127_extratoata_0222.pdf"><span>Acessar</span></a></td></tr>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20220118_extratoata_0122.pdf"> 401ª Reunião - 18/01/2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20220118_extratoata_0122.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


<br><br><h2>Atas ano 2021</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20212912_extratoata_3021.pdf"> 400ª Reunião - 29/12/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20212912_extratoata_3021.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20211612_extratoata_2921.pdf"> 399ª Reunião - 16/12/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20211612_extratoata_2921.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20211412_extratoata_2821.pdf"> 398ª Reunião - 14/12/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20211412_extratoata_2821.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210712_extratoata_2721.pdf"> 397ª Reunião - 07/12/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210712_extratoata_2721.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/396_20212311_extratoata_2621.pdf"> 396ª Reunião - 23/11/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/396_20212311_extratoata_2621.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/395_20210911_extratoata_2521.pdf"> 395ª Reunião - 09/11/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/395_20210911_extratoata_2521.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/394_20210311_extratoata_2421.pdf"> 394ª Reunião - 03/11/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/394_20210311_extratoata_2421.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/393_20211910_extratoata_2321.pdf"> 393ª Reunião - 19/10/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/393_20211910_extratoata_2321.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/392_20210410_extratoata_2221.pdf"> 392ª Reunião - 04/10/2021 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/392_20210410_extratoata_2221.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210928_extratoata_2121.pdf"> 391ª Reunião - 28/09/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210928_extratoata_2121.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210914_extratoata_2021.pdf"> 390ª Reunião - 14/09/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210914_extratoata_2021.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210824_extratoata_1921.pdf"> 389ª Reunião - 24/08/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210824_extratoata_1921.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/388_20210810_extratoata_1821.pdf"> 388ª Reunião - 10/08/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/388_20210810_extratoata_1821.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/387_20210805_extratoata_1721.pdf"> 387ª Reunião - 05/08/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/387_20210805_extratoata_1721.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/386_20210720_extratoata_1621.pdf"> 386ª Reunião - 20/07/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/386_20210720_extratoata_1621.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/385_20210713_extratoata_1521.pdf"> 385ª Reunião - 13/07/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/385_20210713_extratoata_1521.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/384_extratoata_290621.pdf"> 384ª Reunião - 29/06/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/384_extratoata_290621.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/383_extratoata_150621.pdf"> 383ª Reunião - 15/06/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/383_extratoata_150621.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210525_extratoata_1221.pdf"> 382ª Reunião - 25/05/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210525_extratoata_1221.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210511_extratoata_1121.pdf"> 381ª Reunião - 11/05/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210511_extratoata_1121.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210426_extratoata_1021.pdf"> 380ª Reunião - 26/04/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210426_extratoata_1021.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210420_extratoata_0921.pdf"> 379ª Reunião - 20/04/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210420_extratoata_0921.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210413_extratoata_0821.pdf"> 378ª Reunião - 13/04/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210413_extratoata_0821.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210406_extratoata_0721.pdf"> 377ª Reunião - 06/04/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210406_extratoata_0721.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210322_extratoata_0621.pdf"> 376ª Reunião - 22/03/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210322_extratoata_0621.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210308_extratoata_0521.pdf"> 375ª Reunião - 08/03/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210308_extratoata_0521.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210223_extratoata_0421.pdf"> 374ª Reunião - 23/02/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210223_extratoata_0421.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210209_extratoata_0321.pdf"> 373ª Reunião - 09/02/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210209_extratoata_0321.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210126_extratoata_0221.pdf"> 372ª Reunião - 19/01/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210126_extratoata_0221.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20210119_extratoata_0121.pdf"> 371ª Reunião - 19/01/2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20210119_extratoata_0121.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>



<br><br><h2>Atas ano 2020</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20201215_extratoata_2520.pdf"> 370ª Reunião - 15/12/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20201215_extratoata_2520.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20201201_extratoata_2420.pdf"> 369ª Reunião - 01/12/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20201201_extratoata_2420.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20201123_extratoata_2320.pdf"> 368ª Reunião - 23/11/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20201123_extratoata_2320.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20201111_extratoata_2220.pdf"> 367ª Reunião - 22/11/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20201111_extratoata_2220.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20201020_extratoata_2120.pdf"> 366ª Reunião - 20/10/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20201020_extratoata_2120.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20201015_extratoata_2020.pdf"> 365ª Reunião - 15/10/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20201015_extratoata_2020.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200922_extratoata_1920.pdf"> 364ª Reunião - 22/09/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200922_extratoata_1920.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200908_extratoata_1820.pdf"> 363ª Reunião - 08/09/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200908_extratoata_1820.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200818_extratoata_1720.pdf"> 362ª Reunião - 18/08/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200818_extratoata_1720.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200812_extratoata_1620.pdf"> 361ª Reunião - 12/08/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200812_extratoata_1620.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/extratoata_360.pdf"> 360ª Reunião - 21/07/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/extratoata_360.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/extratoata_359.pdf"> 359ª Reunião - 14/07/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/extratoata_359.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/extratoata_358.pdf"> 358ª Reunião - 30/06/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/extratoata_358.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200624_extratoata_1220.pdf"> 357ª Reunião – 24/06/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200624_extratoata_1220.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200616_extratoata_1120.pdf"> 356ª Reunião – 16/06/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200616_extratoata_1120.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200526_extratoata_1020.pdf"> 355ª Reunião – 26/05/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200526_extratoata_1020.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200512_extratoata_0920.pdf"> 354ª Reunião – 12/05/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200512_extratoata_0920.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200414_extratoata_0820.pdf"> 353ª Reunião –  14/04/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200414_extratoata_0820.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200407_extratoata_0720.pdf"> 352ª Reunião – 07/04/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200407_extratoata_0720.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200324_extratoata_0620.pdf"> 351ª Reunião – 24/03/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200324_extratoata_0620.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200317_extratoata_0520.pdf"> 350ª Reunião – 17/03/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200317_extratoata_0520.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200218_extratoata_0420.pdf"> 349ª Reunião – 18/02/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200218_extratoata_0420.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200211_extratoata_0320.pdf"> 348ª Reunião – 11/02/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200211_extratoata_0320.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200128_extratoata_0220.pdf"> 347ª Reunião – 28/01/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200128_extratoata_0220.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20200121_extratoata_0120.pdf"> 346ª Reunião – 21/01/2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20200121_extratoata_0120.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>




<br><br><h2>Atas ano 2019</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20191210_extratoata_2419.pdf"> 345ª Reunião – 10/12/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20191210_extratoata_2419.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20191203_extratoata_2319.pdf"> 344ª Reunião – 03/12/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20191203_extratoata_2319.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20191126_extratoata_2219.pdf"> 343ª Reunião – 26/11/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20191126_extratoata_2219.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20191113_extratoata_2119.pdf"> 342ª Reunião – 13/11/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20191113_extratoata_2119.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20191022_extratoata_2019.pdf"> 341ª Reunião - 22/10/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20191022_extratoata_2019.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20191008_extratoata_1919.pdf"> 340ª Reunião – 08/10/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20191008_extratoata_1919.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/extrato_ata_1819.pdf"> 339ª Reunião – 17/09/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/extrato_ata_1819.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/extrato_ata_1719.pdf"> 338ª Reunião – 03/09/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/extrato_ata_1719.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190625_extratoata_1219.pdf"> 333ª Reunião – 25/06/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190625_extratoata_1219.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190611_extratoata_1119.pdf"> 332ª Reunião – 11/06/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190611_extratoata_1119.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/331_extratoata_1019.pdf"> 331ª Reunião - 28/05/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/331_extratoata_1019.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/330_extratoata_0919.pdf"> 330ª Reunião - 08/05/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/330_extratoata_0919.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/329_extratoata_0819.pdf"> 329ª Reunião - 23/04/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/329_extratoata_0819.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/328_extratoata_0719.pdf"> 328ª Reunião - 09/04/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/328_extratoata_0719.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190327_extratoata_0619.pdf"> 327ª Reunião - 27/03/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190327_extratoata_0619.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190312_extratoata_0519.pdf"> 326ª Reunião - 12/03/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190312_extratoata_0519.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190219_extratoata_0419.pdf"> 325ª Reunião - 19/02/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190219_extratoata_0419.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190212_extratoata_0319.pdf"> 324ª Reunião - 12/02/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190212_extratoata_0319.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190131_extratoata_0219.pdf"> 323ª Reunião - 31/01/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190131_extratoata_0219.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20190122_extratoata_0119.pdf"> 322ª Reunião - 22/01/2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20190122_extratoata_0119.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>Atas ano 2018</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20181218_ata_auditoria.pdf"> 321ª Reunião - 18/12/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20181218_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20181204_ata_auditoria.pdf"> 320ª Reunião - 04/12/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20181204_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20181122_ata_auditoria.pdf"> 319ª Reunião - 22/11/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20181122_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20181107_ata_auditoria.pdf"> 318ª Reunião - 07/11/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20181107_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20181030_ata_auditoria.pdf"> 317ª Reunião - 30/10/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20181030_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20181016_ata_auditoria.pdf"> 316ª Reunião - 16/10/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20181016_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180925_ata_auditoria.pdf"> 315ª Reunião - 25/09/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180925_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180911_ata_auditoria.pdf"> 314ª Reunião - 11/09/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180911_ata_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180821_ata_comite_auditoria.pdf"> 313ª Reunião - 21/08/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180821_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180808_ata_comite_auditoria.pdf"> 312ª Reunião - 08/08/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180808_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180731_ata_comite_auditoria.pdf"> 311ª Reunião - 31/07/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180731_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180717_ata_comite_auditoria.pdf"> 310ª Reunião - 17/07/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180717_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180619_ata_comite_auditoria.pdf"> 309ª Reunião - 19/06/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180619_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180605_ata_comite_auditoria.pdf"> 308ª Reunião - 05/06/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180605_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180522_ata_comite_auditoria.pdf"> 307ª Reunião - 22/05/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180522_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20180508_ata_comite_auditoria.pdf"> 306ª Reunião - 08/05/2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20180508_ata_comite_auditoria.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>

        </div>
    </article>


</main>
