import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-integra-tiete',
  standalone: true,
  imports: [],
  templateUrl: './integra-tiete.component.html',
  styleUrls: ['/src/assets/css/internal.css', '/src/assets/css/components.css']
})
export class IntegraTieteComponent {
  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Programa Integra Tietê");

    this.meta.updateTag({
      property: 'twitter:description',
      content: ' Conheça o Programa Integra Tietê, uma iniciativa da Sabesp em parceria com o Governo do Estado para revitalizar o Rio Tietê. Descubra nossas ações em saneamento, controle de cheias, recuperação ambiental e gestão de resíduos, promovendo o desenvolvimento sustentável.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, Integra Tietê, revitalização do Rio Tietê, saneamento, controle de cheias, recuperação ambiental, gestão de resíduos, sustentabilidade, recursos hídricos, São Paulo.'
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Integra Tietê'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Integra Tietê'
    });
  }
}
