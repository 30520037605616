import { Component, ViewEncapsulation } from '@angular/core';
import { CityService } from '../city.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { City, CityDoc } from '../city';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-qualidade-agua',
  templateUrl: './qualidade-agua.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None,
})
export class QualidadeAguaComponent {
  cities: City[] = [];
  selectedCity = '';
  selectedName = '';
  waterQuality: CityDoc[] = [];

  constructor(private cityService: CityService, private location: Location, private route: ActivatedRoute, private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.getCities();
    this.selectCity();

    this.title.setTitle("Sabesp | Qualidade da Água");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'A Sabesp controla rigorosamente a qualidade da água desde os mananciais até sua distribuição. Conheça nossos processos de monitoramento, análise laboratorial e degustação para garantir água potável e segura para todos. '
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, qualidade da água, controle de qualidade, tratamento de água, monitoramento de mananciais, água potável, parâmetros de qualidade da água, ensaios laboratoriais, degustação de água.'
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Qualidade da Água '
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Qualidade da Água '
    });
  }

  selectCity() {
    const city = this.route.snapshot.paramMap.get('city');
    if (city) {
      this.getCityWaterQuality(city);
      this.selectedCity = city;
    }
  }

  navigateToCity() {
    this.waterQuality = [];
    if (this.selectedCity) {
      this.getCityWaterQuality(this.selectedCity);
      this.updateCityName();
    }
    const loc = "/o-que-fazemos/fornecimento-agua/qualidade-agua/analises-anuais-qualidade-agua-distribuida/" + this.selectedCity;
    this.location.go(loc);
  }

  getCities(): void {
    this.cityService.getCitiesWithWaterQuality()
      .subscribe(cities => {
        this.cities = cities;
        this.updateCityName();
      });
  }

  getCityWaterQuality(city: string): void {
    this.cityService.getCityWaterQuality(city)
      .subscribe(docs => this.waterQuality = docs);
  }

  updateCityName() {
    if (this.selectedCity) {
      const city = this.cities.find(city => city.id === this.selectedCity);
      if (city) {
        this.selectedName = city.name;
      }
    }
  }
}
