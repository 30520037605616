import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormularioOuvidoriaService {
  private formUrl = environment.apiUrl + '/api/v1/ouvidoria';

  constructor(private http: HttpClient) {}

  enviarFormulario(formData: FormData): Observable<any> {
    return this.http.post<any>(this.formUrl, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Error:', error);
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
