import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

const REDIR: { [key: string]: string } = {
  "/site/interna/subhome.aspx?secaoid=3": "/a-sabesp/institucional/perfil",
  "/site/interna/default.aspx?secaoid=802": "/a-sabesp/institucional/perfil",
  "/site/interna/default.aspx?secaoid=445": "https://www.contatoseguro.com.br/sabesp",
  "/site/interna/default.aspx?secaoid=5": "/a-sabesp/institucional/perfil",
  "/site/interna/default.aspx?secaoid=6": "/a-sabesp/institucional/perfil",
  "/site/interna/default.aspx?secaoid=169": "/a-sabesp/institucional/perfil",
  "/site/interna/default.aspx?secaoid=710": "/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo",
  "/site/interna/default.aspx?secaoid=779": "/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/programa-agua-legal",
  "/site/interna/default.aspx?secaoid=780": "/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/substituicao-redes-setorizacao",
  "/site/interna/default.aspx?secaoid=781": "/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/obras-esgotos",
  "/site/interna/default.aspx?secaoid=799": "/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/substituicao-redes-setorizacao",
  "/site/interna/default.aspx?secaoid=782": "/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/seguranca-hidrica",
  "/site/interna/default.aspx?secaoid=783": "/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/consulta-publica-virtual",
  "/site/interna/default.aspx?secaoid=22": "/a-sabesp/carreiras",
  "/site/imprensa/noticias-detalhe.aspx?secaoid=65&id=8808": "/a-sabesp/carreiras",
  "/site/interna/subhome.aspx?secaoid=30": "/o-que-fazemos/fornecimento-agua",
  "/site/interna/default.aspx?secaoid=31": "/o-que-fazemos/fornecimento-agua/captacao-agua",
  "/site/interna/default.aspx?secaoid=47": "/o-que-fazemos/fornecimento-agua/tratamento-agua",
  "/site/interna/default.aspx?secaoid=42": "/o-que-fazemos/fornecimento-agua/qualidade-agua",
  "/site/interna/default.aspx?secaoid=622": "/o-que-fazemos/fornecimento-agua/distribuicao-agua",
  //"/site/interna/default.aspx?secaoid=31": "/o-que-fazemos/fornecimento-agua/captacao-agua",
  "/site/interna/default.aspx?secaoid=553": "/o-que-fazemos/fornecimento-agua/mananciais ",
  "/site/interna/default.aspx?secaoid=43": "/o-que-fazemos/fornecimento-agua/qualidade-agua/relatorio-qualidade-agua-mananciais",
  //"/site/interna/default.aspx?secaoid=47": "/o-que-fazemos/tratamento-agua",
  "/site/interna/default.aspx?secaoid=34": "/o-que-fazemos/fornecimento-agua/captacao-agua/mananciais",
  "/site/interna/default.aspx?secaoid=35": "/assets/images/folhetos/sabesp-instalacao-caixa-agua.pdf",
  "/reducaopressao/": "/o-que-fazemos/fornecimento-agua/distribuicao-agua/gestao-demanda",
  //"/site/interna/default.aspx?secaoid=622": "/assets/images/folhetos/sabesp-instalacao-caixa-agua.pdf",
  "/site/uploads/file/folhetos/2019/limpeza_caixa_agua.pdf": "/assets/images/folhetos/sabesp-limpeza-caixa-agua.pdf",
  "/site/interna/default.aspx?secaoid=38": "/o-que-fazemos/distribuicao-agua/eliminadores-ar",
  "/site/interna/default.aspx?secaoid=144": "/a-sabesp/educacao/curso-pesquisa-vazamento",
  "/site/interna/default.aspx?secaoid=39": "/servicos/para-empresa#hidrometros-individuais",
  "/site/interna/default.aspx?secaoid=40": "/sustentabilidade/relatorios/qualidade-agua ",
  //"/site/interna/default.aspx?secaoid=42": "/o-que-fazemos/qualidade-agua/analises-mensais-qualidade-agua-distribuida ",
  "/site/interna/default.aspx?secaoid=253": "/'o-que-fazemos/qualidade-agua/analises-anuais-qualidade-agua-distribuida ",
  "/site/interna/default.aspx?secaoid=41": "/o-que-fazemos/fornecimento-agua/qualidade-agua",
  "/site/interna/default.aspx?secaoid=37": "/o-que-fazemos/obras-programas/reducao-perdas/controle-perdas ",
  "/site/interna/default.aspx?secaoid=613": "/sustentabilidade/transparencia/bacia-rio-itapanhau",
  "/site/interna/subhome.aspx?secaoid=48": "/o-que-fazemos/solucoes-para-esgotos",
  "/site/interna/default.aspx?secaoid=50": "/o-que-fazemos/solucoes-para-esgotos/coleta-esgotos",
  "/site/interna/default.aspx?secaoid=49": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos",
  "/site/interna/default.aspx?secaoid=61": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/tipos-tratamento",
  "/site/interna/default.aspx?secaoid=570": "/servicos/para-empresa#certidao-de-esgotamento-sanitario",
  "/site/saneamento/esgoto.aspx?secaoid=48": "/o-que-fazemos/solucoes-para-esgotos",
  "/site/interna/default.aspx?secaoid=569": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos#aguareuso",
  //"/site/interna/default.aspx?secaoid=50": "/o-que-fazemos/solucoes-para-esgotos",
  "/site/interna/default.aspx?secaoid=283": "/o-que-fazemos/solucoes-para-esgotos/coleta-esgotos",
  //"/site/interna/default.aspx?secaoid=49": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos ",
  "/site/interna/default.aspx?secaoid=627": "/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro",
  //"/site/interna/default.aspx?secaoid=61": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/tipos-tratamento",
  "/site/interna/default.aspx?secaoid=741": "/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-toninhas",
  "/site/uploads/file/ete_toninhas/": "/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-toninhas",
  "/site/interna/default.aspx?secaoid=792": "/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-baleia",
  "/site/uploads/file/ete_baleia/": "/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-baleia",
  //"/site/interna/default.aspx?secaoid=569": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos#aguareuso",
  "/site/interna/default.aspx?secaoid=51": "/o-que-fazemos/solucoes-para-esgotos/coleta-esgotos#esgotos-nao-domesticos",
  //"/site/interna/default.aspx?secaoid=570": "/servicos/para-empresa#certidao-de-esgotamento-sanitario",
  "/site/interna/default.aspx?secaoid=800": "/o-que-fazemos/obras-programas/onda-limpa ",
  "/site/interna/default.aspx?secaoid=181": "/servicos/para-voce ",
  "/site/imprensa/noticias-detalhe.aspx?secaoid=65&id=8788": "https://api.whatsapp.com/send?phone=551133888000",
  "/site/interna/default.aspx?secaoid=777": "https://agenciavirtual.sabesp.com.br/",
  "/site/interna/default.aspx?secaoid=744": "/fale-com-a-sabesp",
  "/site/interna/default.aspx?secaoid=183": "/servicos/para-voce#tarifas",
  //"/site/interna/default.aspx?secaoid=181": "https://agenciavirtual2.sabesp.com.br/guia-de-servicos",
  "/site/interna/default.aspx?secaoid=184": "/a-sabesp/educacao/dicas-economia-agua",
  //"/site/interna/default.aspx?secaoid=181": "/servicos/para-voce#guia-servicos",
  //"/site/interna/default.aspx?secaoid=183": "/servicos/para-voce#tarifas-precos",
  "/site/interna/default.aspx?secaoid=772": "/servicos/para-voce#tarifa-social",
  "/agvirtual2/asp/lista_munic.asp": "https://agenciavirtual2.sabesp.com.br/agencia-mais-proxima",
  //"/site/interna/default.aspx?secaoid=777": "/servicos/para-voce#agencia-virtual-guia-servicos",
  "/site/interna/default.aspx?secaoid=776": "/servicos/para-voce#entenda-sua-conta",
  //"/site/interna/default.aspx?secaoid=184": "/assets/images/folhetos/sabesp-dicas-economia-agua.pdf",
  "/site/interna/default.aspx?secaoid=324": "/a-sabesp/educacao/dicas-economia-agua",
  "/uploads/file/clientes_servicos/troca_hidrometro.pdf": "/servicos/para-voce#guia-servicos",
  "/site/interna/default.aspx?secaoid=141": "/a-sabesp/educacao/dicas-economia-agua",
  //"/site/interna/default.aspx?secaoid=144": "/a-sabesp/educacao/curso-pesquisa-vazamento",
  "/site/interna/default.aspx?secaoid=282": "/assets/images/folhetos/sabesp-pedido-ligacao-agua.pdf",
  //"/site/interna/default.aspx?secaoid=283": "/assets/images/folhetos/sabesp-pedido-ligacao-esgoto.pdf",
  "/site/interna/default.aspx?secaoid=240": "/assets/images/folhetos/sabesp-limpeza-caixa-agua.pdf",
  "/site/interna/default.aspx?secaoid=244": "/a-sabesp/educacao/curso-pesquisa-vazamento",
  "/site/interna/default.aspx?secaoid=482": "/servicos/folhetos",
  //"/site/interna/default.aspx?secaoid=776": "/servicos/para-voce#entenda-sua-conta ",
  "/site/interna/default.aspx?secaoid=323": "/servicos/para-empresa#empreendimentos",
  "/site/interna/subhome.aspx?secaoid=62": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=137": "/o-que-fazemos/obras-programas/uso-racional-agua",
  "/site/interna/default.aspx?secaoid=93": "/sustentabilidade/sabesp-sustentavel",
  "/site/interna/default.aspx?secaoid=595": "/a-sabesp/educacao/dicas-economia-agua",
  "/site/interna/default.aspx?secaoid=148": "/sustentabilidade/meio-ambiente",
  //"/site/interna/default.aspx?secaoid=144": "/a-sabesp/educacao/curso-pesquisa-vazamento",
  "/site/interna/subhome.aspx?secaoid=73": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=762": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=758": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=760": "/sustentabilidade/meio-ambiente",
  //"/site/interna/default.aspx?secaoid=758": "/sustentabilidade/meio-ambiente",
  //"/site/interna/default.aspx?secaoid=760": "/sustentabilidade/meio-ambiente",
  //"/site/interna/default.aspx?secaoid=762": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=118": "/a-sabesp/educacao/visite-sabesp",
  "/site/interna/default.aspx?secaoid=767": "/o-que-fazemos/fornecimento-agua/captacao-agua/mananciais",
  "/muitoalemdaagua/": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=768": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=770": "/sustentabilidade/meio-ambiente",
  //"/site/interna/default.aspx?secaoid=148": "/sustentabilidade/meio-ambiente",
  //"/site/interna/default.aspx?secaoid=93": "/sustentabilidade/relatorios/relatorio-sustentabilidade ",
  //"/site/interna/default.aspx?secaoid=137": "/assets/images/folhetos/sabesp-programa-uso-racional.pdf",
  "/site/interna/default.aspx?secaoid=138": "/assets/images/folhetos/sabesp-programa-uso-racional.pdf",
  "/site/interna/default.aspx?secaoid=609": "/a-sabesp/educacao/dicas-economia-agua",
  //"/site/interna/default.aspx?secaoid=595": "/a-sabesp/educacao/dicas-economia-agua",
  "/site/interna/default.aspx?secaoid=600": "/a-sabesp/educacao/dicas-economia-agua",
  "/site/sociedade-meioambiente/dicas.aspx?secaoid=450": "/a-sabesp/educacao/dicas-economia-agua",
  //"/site/interna/default.aspx?secaoid=141": "/a-sabesp/educacao/curso-pesquisa-vazamento",
  //"/site/interna/default.aspx?secaoid=144": "/a-sabesp/educacao/curso-pesquisa-vazamento",
  "/site/interna/formulario_inscricao.aspx?secaoid=426": "/a-sabesp/educacao/curso-pesquisa-vazamento",
  "/site/interna/default.aspx?secaoid=145": "/o-que-fazemos/obras-programas/uso-racional-agua",
  "/site/interna/default.aspx?secaoid=611": "/a-sabesp/educacao/dicas-economia-agua",
  "/site/interna/default.aspx?secaoid=808": "/sustentabilidade/meio-ambiente",
  "/site/interna/default.aspx?secaoid=82": "/a-sabesp/educacao/dicas-economia-agua",
  "/site/uploads/file/asabesp_doctos/guia_pratico_meio_ambiente.pdf": "/site/uploads/file/asabesp_doctos/guia_pratico_meio_ambiente.pdf",
  "/site/interna/subhome.aspx?secaoid=63": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos",
  "/site/interna/default.aspx?secaoid=583": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos",
  "/site/interna/default.aspx?secaoid=586": "/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos",
  "/site/interna/default.aspx?secaoid=587": "/o-que-fazemos/obras-programas/uso-racional-agua",
  "/site/interna/default.aspx?secaoid=588": "/servicos/para-empresa#hidrometros-individuais",
  "/site/interna/default.aspx?secaoid=722": "/servicos/para-empresa",
  "/site/uploads/file/asabesp_doctos/manual_empreendedor.pdf": "/site/uploads/file/asabesp_doctos/manual_empreendedor.pdf",
  // "/site/interna/Default.aspx?secaoId=570": "Sabesp | Para Sua Empresa",
  "/site/uploads/file/asabesp_doctos/contratos_vigentes_grandes_consumidoresv2.pdf": "/site/uploads/file/asabesp_doctos/contratos_vigentes_grandes_consumidoresv2.pdf",
  "/site/interna/subhome.aspx?secaoid=95": "/sustentabilidade/transparencia",
  "/site/interna/default.aspx?secaoid=822": "/sustentabilidade/transparencia/informacoes-ao-cidadao",
  "/site/interna/default.aspx?secaoid=96": "/sustentabilidade/sabesp-sustentavel",
  "/site/interna/default.aspx?secaoid=112": "/sustentabilidade/transparencia",
  "/site/interna/default.aspx?secaoid=676": "/sustentabilidade/transparencia/participacoes-societarias",
  "/site/interna/default.aspx?secaoid=675": "http://licitacoes.sabesp.com.br/wps/portal/licitacoes",
  "/site/interna/default.aspx?secaoid=674": "/sustentabilidade/transparencia",
  "/site/fale-conosco/default.aspx?secaoid=71": "http://faleconosco.sabesp.com.br/",
  "/site/interna/default.aspx?secaoid=135": "/fale-com-a-sabesp",
  "/site/fale-conosco/faq.aspx?secaoid=134": "/fale-com-a-sabesp/perguntas-frequentes",
  "/uploads/file/folhetos/2014/manual_do_cliente.pdf": "/uploads/file/Folhetos/2014/manual_do_cliente.pdf",
  //"/site/interna/default.aspx?secaoid=183": "https://agenciavirtual.sabesp.com.br/",
  //"/site/interna/default.aspx?secaoid=183": "https://agenciavirtual.sabesp.com.br/",
  //"/site/interna/default.aspx?secaoid=42": "https://agenciavirtual.sabesp.com.br/",
  "/site/interna/ouvidoria.aspx": "/servicos/para-empresa#empreendimentos",
  "/site/interna/formulario_ouvidoria.aspx": "/fale-com-a-sabesp/ouvidoria",
  //"/site/fale-conosco/faq.aspx?secaoid=134": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=23": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=29": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=2": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=3": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=4": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=6": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=7": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=8": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=9": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=10": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=11": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=12": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=13": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=14": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=15": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=22": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=21": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=24": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=25": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=26": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=27": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/fale-conosco/faq.aspx?secaoid=134&cid=28": "/fale-com-a-sabesp/perguntas-frequentes",
  "/site/interna/default.aspx?secaoid=766": "/politica-privacidade",
  "/site/": "/sustentabilidade/transparencia/informacoes-ao-cidadao",
}

@Component({
  selector: 'app-redirect-map',
  template: '',
})
export class RedirectMapComponent {
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const url = (window.location.pathname + window.location.search).toLowerCase();
    if (REDIR[url]) {
      const r = REDIR[url];
      if (r.startsWith('http')) {
        return window.location.href = r;
      }
      return this.router.navigate([r]);
    }
    return this.router.navigate(['/']);
  };
}


