<main class="main">
    <meta name="description" content="Descubra como a Sabesp gerencia recursos hídricos, garantindo o abastecimento de água com eficiência e sustentabilidade.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua/recursos-hidricos" title="Recursos Hídrico">Recursos Hídricos</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Recursos Hídricos</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="./assets/images/o-que-fazemos/fornecimento-agua.jpg" alt=" Lago de reservatório da Sabesp com colinas ao fundo" title="Fornecimento de Água">
            </picture>

            <p>A nossa grande matéria-prima é a água. Precisamos dela para realizar o nosso trabalho diário de abastecer a casa de milhões de pessoas.</p>
            <p>Para fazer isso, buscamos a água na natureza em rios, poços e represas criadas artificialmente. No entanto, se a água não estiver em boas condições e estiver muito poluída, fica mais difícil fazer o tratamento para fazer com que ela chegue pura à casa das pessoas.</p>
            <p>Da mesma forma, se as fontes de água que usamos estiverem muito secas, temos dificuldade para retirar a quantidade necessária para abastecer as pessoas. Precisamos ter a água em ótimas condições e em boa quantidade na natureza para fazermos o nosso trabalho. Por isso, para ajudar a cuidar da água, temos muitas ações de plantio e conservação de áreas verdes ao redor de rios, lagos e represas. Estar em harmonia com o meio ambiente à nossa volta é fundamental para nós, da Sabesp.</p>
        </div>
    </article>

    <section class="more">
        <div class="container">
            <nav class="nav">
                <h2 class="headline">Veja também</h2>

                <ul class="items">
                    <li class="item">
                        <a class="title acess-link" href="o-que-fazemos/fornecimento-agua/captacao-agua">Captação de água</a>
                    </li>
                    <li class="item">
                        <a class="title acess-link" href="o-que-fazemos/fornecimento-agua/tratamento-agua">Tratamento de água</a>
                    </li>
                    <li class="item">
                        <a class="title acess-link" href="o-que-fazemos/fornecimento-agua/distribuicao-agua/da-sabesp-para-sua-casa">Da Sabesp para sua Casa</a>
                    </li>
                    <li class="item">
                        <a class="title acess-link" href="o-que-fazemos/fornecimento-agua/qualidade-agua/nosso-cuidado-com-a-agua">Nosso Cuidado com a Água</a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</main>
