import { Injectable } from '@angular/core';
import { CommonQuestions } from './common-questions';
import { catchError, map, tap, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './error.service';
import { environment } from '../environments/environment';
import { Category } from './category';
import { ContentPipe } from './content-pipe/content.pipe';

@Injectable({
  providedIn: 'root'
})
export class CommonQuestionsService {
  private commonQuestionsUrl = environment.apiUrl + '/api/v1/questions';
  private questionCategoriesUrl = environment.apiUrl + '/api/v1/questionCategories';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getCommonQuestions(): Observable<CommonQuestions[]> {
    return this.http.get<CommonQuestions[]>(this.commonQuestionsUrl)
      .pipe(
        map(q => {
          return q.map(c => {
            return {
              categoryName: c.category?.name || '',
              question: c.title,
              response: ContentPipe.base64ToUtf8(c.content),
              title: c.title,
              content: c.content,
              category: c.category
            }
          });
        }),
        catchError(ErrorService.handle<CommonQuestions[]>('getCommonQuestions', []))
      );
  }

  getQuestionCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(this.questionCategoriesUrl)
      .pipe(
        catchError(ErrorService.handle<Category[]>('getQuestionCategories', []))
      );
  }
}