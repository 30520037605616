<main class="main">
    <meta name="description" content="Ouvidoria Sabesp: registre suas reclamações, sugestões ou elogios e acompanhe o atendimento de suas demandas de forma ágil e transparente.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="/fale-com-a-sabesp" title="Fale com a Sabesp">Fale com a Sabesp</a></li>
                <li><a href="javascript:void(0);" title="Ouvidoria">Ouvidoria</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>

            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Ouvidoria</h1>

            <h2 class="subheadline">Bem-vindo(a) à Ouvidoria Sabesp</h2>

            <p>
                A Ouvidoria é o canal especial da Sabesp para resolver problemas quando você já entrou em contato pelos outros atendimentos.<br>
                Aqui, você pode fazer reclamações, críticas, elogios e denúncias sobre os serviços da Sabesp.
            </p>
            <h2 class="subheadline">O que é a Ouvidoria?</h2>
            <p>
                A Ouvidoria é o último recurso para resolver problemas com a Sabesp. Se você já tentou outros atendimentos e ainda não conseguiu uma solução, a Ouvidoria está aqui para te ajudar.
            </p>
            <p>
                <b>Antes de usar a Ouvidoria, tenha o número do protocolo da sua solicitação</b> ou reclamação feita nos outros canais de atendimentos primários da Sabesp.
            </p>
            <p>
                Se você ainda não tem um protocolo, registre sua solicitação pelos canais de atendimento da Sabesp listados abaixo.
            </p>
        </div>
    </article>

    <section class="content -gray">
        <div class="container">

            <div class="g-columns -reverse -v-center -auto-max">
                <div class="align-center">
                    <picture class="picture">
                        <source media="(max-width: 767px)" srcset="./assets/images/illustration/sani-ouvidoria-mobile.png">
                        <img loading="eager" fetchpriority="high" width="706" height="836" src="./assets/images/illustration/sani-ouvidoria.png" alt="Sani" title="Sani">
                    </picture>
                </div>
                <div>
                    <div class="s-channels">
                        <div class="item">
                            <picture class="figure">
                                <a href="tel:0800 055 0195">
                                    <img loading="eager" fetchpriority="high" width="129" height="123" src="./assets/images/icon/colorful-phone.svg" alt="Telefone" title="Telefone">
                                </a>
                            </picture>
                            <div class="info">
                                <h2 class="title">Telefone</h2>
                                <strong><a class="drop-link -no-underline -txt-blue" href="tel:0800 055 0195">0800 055 0195</a></strong>
                                <p>(24 horas, incluindo emergências)</p>
                            </div>
                        </div>

                        <div class="item">
                            <picture class="figure">
                                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/551133888000">
                                    <img loading="eager" fetchpriority="high" width="124" height="129" src="./assets/images/icon/colorful-whatsapp.svg" alt="WhatsApp" title="WhatsApp">
                                </a>
                            </picture>
                            <div class="info">
                                <h2 class="title">WhatsApp</h2>
                                <strong><a class="drop-link -no-underline -txt-blue" target="_blank" rel="noopener noreferrer" href="https://wa.me/551133888000">11 3388 8000</a></strong>
                                <p>(somente texto)</p>
                            </div>
                        </div>

                        <div class="item">
                            <picture class="figure">
                                <a class="chat-popup" rel="noopener noreferrer">
                                    <img loading="lazy" fetchpriority="low" width="138" height="129" data-src="./assets/images/icon/colorful-chat.svg" alt="Chat" title="Chat">
                                </a>
                            </picture>
                            <div class="info">
                                <h2 class="title">Chat</h2>
                                <a class="link -black -underline chat-popup" rel="noopener noreferrer">Acessar</a>
                            </div>
                        </div>

                        <div class="item">
                            <picture class="figure">
                                <a target="_blank" rel="noopener noreferrer" href="javascript:void(0);">
                                    <img loading="lazy" fetchpriority="low" width="103" height="132" data-src="./assets/images/icon/colorful-app.svg" alt="Aplicativo" title="Aplicativo">
                                </a>
                            </picture>
                            <div class="info">
                                <h2 class="title">App Sabesp Mobile</h2>
                                <a class="link -black -underline" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/br/app/sabesp-mobile/id1184961285">Acesso IoS</a>
                                <a class="link -black -underline" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=br.com.sabesp.android.sabespmobile&hl=pt_BR&gl=US">Acesso Android</a>
                            </div>
                        </div>

                        <div class="item">
                            <picture class="figure">
                                <a target="_blank" rel="noopener noreferrer" href="https://agenciavirtual2.sabesp.com.br/agencia-mais-proxima">
                                    <img loading="lazy" fetchpriority="low" width="124" height="129" data-src="./assets/images/icon/colorful-agencias.svg" alt="Atendimento Presencial" title="Atendimento Presencial">
                                </a>
                            </picture>
                            <div class="info">
                                <h2 class="title">Atendimento Presencial com Agendamento</h2>
                                <a class="link -black -underline" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual.sabesp.com.br/">Agendar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <h2 class="subheadline">Outros assuntos</h2>
            <p class="-balance -space-md">Se o assunto é do âmbito institucional, ou seja, não está relacionado com os serviços ou os atendimentos prestados pela Sabesp aos clientes, <strong class="-txt-blue">verifique na lista abaixo o contato da área especializada</strong> que se enquadra no caso que deseja informar ou do qual precisa solicitar esclarecimentos.</p>

            <div class="accordion -columns" style="--half-items: 4">
                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#assuntos-juridicos">Assuntos Jurídicos</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p class="-balance">Poder Judiciário, Ministério Público Estadual e Federal, JEC, CEJUSC, Polícia Civil, Militar, Ambiental, Notificação Extrajudicial.</p>
                            <ul class="list">
                                <li><b><a class="-mail -no-underline" href="mailto:juridico@sabesp.com.br">juridico&#64;sabesp.com.br</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#assuntos-recursos-humanos">Assuntos de Recursos Humanos</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Concurso Público, contratação de pessoal, documentações diversas de funcionários e ex-funcionários.</p>
                            <ul class="list">
                                <li><b><a class="-mail -no-underline" href="mailto:canalabertorh@sabesp.com.br">canalabertorh&#64;sabesp.com.br</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#lei-acesso-informacao-lai"> Informações ao Cidadão</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Pela Plataforma Fala.SP você pode registrar seu pedido de acesso à informação. Basta acessar <a style="text-decoration: none;" href="https://fala.sp.gov.br/">(https://fala.sp.gov.br/) </a>, escolher o quadro "Pedido de Acesso à Informação", fazer seu login com sua conta do <a style="text-decoration: none;" href="https://www.gov.br/pt-br">gov.br</a> e será direcionado para o formulário de registro de seu pedido.</p>
                            <ul class="list">
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#relacoes-investidores">Relações com Investidores</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Este é um canal de relacionamento entre a Sabesp, seus investidores e acionistas. Envie suas sugestões e comentários, tire suas dúvidas.</p>
                            <ul class="list">
                                <li><b><a class="-phone -no-underline" href="tel:+55 11 3388 9267">+55 (11) 3388 9267</a></b></li>
                                <li><b><a class="-external -external-2 -no-underline" target="_blank" href="https://ri.sabesp.com.br/servicos-aos-investidores/fale-com-ri/">Canal de Relacionamento entre a Sabesp e Investidores</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#assuntos-comunicacao">Assuntos de Comunicação</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Comunicados de imprensa, solicitação de visitas nas estações de tratamento da Sabesp, hidratação de eventos, apoios e patrocínios.</p>
                            <ul class="list">
                                <li><b><a class="-mail -no-underline" href="mailto:comunicacao@sabesp.com.br">comunicacao&#64;sabesp.com.br</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#assuntos-sobre-fornecedores-licitacoes">Assuntos sobre Fornecedores e Licitações</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Consultar e acompanhar os pagamentos decorrentes de contratos realizados junto aos fornecedores da Sabesp, recursos, reclamações de pagamento ou de contratos em geral, solicitações de prazo, renovação de cadastro.</p>
                            <ul class="list">
                                <li><b><a class="-mail -no-underline" href="mailto:csq@sabesp.com.br">csq&#64;sabesp.com.br</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#lei-geral-protecao-dados-lgpd">Lei Geral de Proteção de Dados (LGPD)</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>Informações, esclarecimentos, reclamações ou demais providências relacionadas aos seus dados pessoais devem ser encaminhadas para a Encarregada da Proteção de Dados Pessoais da Sabesp.</p>
                            <ul class="list">
                                <li><b><a class="-mail -no-underline" href="mailto:lgpd@sabesp.com.br">lgpd&#64;sabesp.com.br</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#canal-denuncia">Canal de Denúncia</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <p>O Canal de Denúncias é uma forma de ampliar nosso comprometimento com a transparência e a ética. Essa ferramenta independente, sigilosa e imparcial está disponível para os públicos externo e interno da Sabesp.</p>
                            <ul class="list">
                                <li><b><a class="-external -external-2 -no-underline" target="_blank" href="https://www.contatoseguro.com.br/sabesp">Canal de Denúncias Sabesp</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content -gray">
        <div class="container">
            <h2 class="subheadline">Se você chegou até aqui, seu caso será analisado pela Ouvidoria.</h2>
            <p class="-space-md -balance">Agora, sua manifestação poderá ser encaminhada para a equipe da Ouvidoria Sabesp. Ressaltamos que, se não for encontrado registro inicial de sua solicitação nos canais de atendimento já informados, infelizmente não será possível prosseguir com a análise de seu caso nesta instância de atendimento.</p>
            <p><strong class="-txt-blue">Relate seu caso por meio dos canais de comunicação abaixo:</strong></p>

            <div class="basiccards">
                <div class="item">
                    <p><strong class="-txt-blue">Será necessário informar:</strong></p>
                    <ul class="list">
                        <li>Número do Fornecimento que está no canto esquerdo superior da conta de água;</li>
                        <li>Endereço completo do local relacionado à manifestação;</li>
                        <li>E-mail e telefones de contato;</li>
                    </ul>

                    <p>As informações fornecidas serão tratadas com segurança, privacidade e com a finalidade exclusiva de tratar sua manifestação.</p>
                    <p>Se houver necessidade de anexar arquivos de evidências, utilize o e-mail <b><a class="-mail -no-underline" href="mailto:ouvidoria@sabesp.com.br">ouvidoria&#64;sabesp.com.br</a></b></p>
                </div>
            </div>

        </div>
    </section>

    <section class="content">
        <div class="highlight">
            <div class="container">
                <div class="text">
                    <div class="boxes -full">
                        <div class="item -blue">
                            <div class="top">
                                <h2 class="title">
                                    <a target="_blank" rel="noopener noreferrer" href="tel:0800 055 0565" tabindex="-1">Atendimento Telefônico</a>
                                </h2>
                            </div>
                            <div class="description">
                                <p><a class="drop-link" target="_blank" rel="noopener noreferrer" href="tel:0800 055 0565" tabindex="-1">
                                    <strong>0800 055 0565</strong>
                                </a></p>
                                <p>Funcionamento: Segunda a sexta-feira, das 8 às 18h (exceto feriados).</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text2">
                    <div class="boxes -full">
                        <div class="item -blue">
                            <div class="top">
                                <h2 class="title">
                                    <a href="/fale-com-a-sabesp/ouvidoria/formulario" tabindex="-1">E-mail</a>
                                </h2>
                            </div>
                            <p class="description">
                                <a href="/fale-com-a-sabesp/ouvidoria/formulario" tabindex="-1"><strong>ouvidoria&#64;sabesp.com.br</strong></a>
                            </p>
                            <a class="button" rel="noopener noreferrer" title="Formulário" href="/fale-com-a-sabesp/ouvidoria/formulario">Formulário</a>
                        </div>
                    </div>
                </div>

                <picture class="figure">
                    <img loading="lazy" fetchpriority="low" width="372" height="322" data-src="./assets/images/illustration/sani-cumprimenta.png" alt="Sani" title="Sani">
                </picture>
            </div>
        </div>
    </section>
</main>
