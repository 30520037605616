import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-biogas',
  templateUrl: './biogas.component.html',
  styleUrl: './biogas.component.css'
})
export class BiogasComponent {
  constructor(private title: Title){}

  ngOnInit() {

    this.title.setTitle("Sabesp | Biogás");

}
}
