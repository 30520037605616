<main class="main">
    <meta name="description" content="Conheça o processo de tratamento de água realizado pela Sabesp. Nossas estações de tratamento produzem água potável com rigorosos controles de qualidade, garantindo água segura e limpa para milhões de pessoas.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Tratamento de Água">Tratamento de Água</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Tratamento de Água</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="./assets/images/o-que-fazemos/tratamento-agua.jpg" alt="Tratamento de Água" title="Tratamento de Água">
            </picture>

            <p>As estações de tratamento de água (ETAs) da Sabesp funcionam como verdadeiras fábricas para produzir água potável. Atualmente, são tratados mais de 119 mil litros de água por segundo.</p>
            <p>O processo convencional de tratamento de água é dividido em fases. Em cada uma delas existe um rígido controle de dosagem de produtos químicos e acompanhamento dos padrões de qualidade.</p>
            <h2 class="subheadline">Fases do tratamento</h2>

            <ul class="list -fw-medium">
                <li><strong class="-txt-blue">Pré-cloração:</strong> o cloro é adicionado assim que a água chega à estação. Isso facilita a retirada de matéria orgânica e metais;</li>
                <li><strong class="-txt-blue">Pré-alcalinização:</strong> depois do cloro, a água recebe cal ou soda, se necessário, que servem para ajustar o pH* aos valores adequados às fases seguintes do tratamento;</li>
                <li><strong class="-txt-blue">Mistura rápida/coagulação:</strong> nesta fase, é adicionado sulfato de alumínio, cloreto de polialumínio (PAC), cloreto férrico ou outro coagulante, seguido de uma agitação violenta da água. Assim, as partículas de sujeira ficam eletricamente desestabilizadas e mais fáceis de agregar;</li>
                <li><strong class="-txt-blue">Floculação:</strong> após a coagulação, há uma mistura lenta da água, que serve para provocar a formação e crescimento de flocos;</li>
                <li><strong class="-txt-blue">Decantação:</strong> neste processo, a água passa por grandes tanques para separar os flocos de sujeira formados na etapa anterior;</li>
                <li><strong class="-txt-blue">Filtração:</strong> logo depois, a água atravessa tanques formados por pedras, areia e carvão antracito, responsáveis por reter os flocos que restaram da fase de decantação;</li>
                <li><strong class="-txt-blue">Pós-alcalinização:</strong> em seguida, é feita a correção final do pH da água, para evitar a corrosão ou incrustação das tubulações;</li>
                <li><strong class="-txt-blue">Desinfecção:</strong> é feita uma última adição de cloro na água tratada antes de sua saída da Estação de Tratamento. Ela garante que a água fornecida chegue isenta de bactérias e vírus até a casa do consumidor;</li>
                <li><strong class="-txt-blue">Fluoretação:</strong> o flúor também é adicionado à água. A substância ajuda a prevenir cáries.</li>
            </ul>

            <p class="-txt-gray -space-lg"><small><em>*Fator pH –O índice pH indica se o meio é ácido, básico ou neutro. Uma solução com pH 7 é neutra; com pH abaixo de 7 é ácida e com pH acima de 7 é básica ou alcalina.<br>Para o consumo humano, recomenda-se que a água potável esteja em um pH entre 6,0 e 9,5.</em></small></p>
            <p>Para conhecer os detalhes clique nos números</p>
            <div class="basiccards">
                <div class="item -center">
                    <picture class="imagemap -space-md">
                        <source media="(max-width: 767px)" width="397" height="2557" srcset="/assets/images/o-que-fazemos/infografico-tratamento-agua-mobile.png">
                        <img loading="lazy" fetchpriority="low" width="1206" height="685" src="/assets/images/o-que-fazemos/infografico-tratamento-agua.png" alt="Tratamento de Água" title="Tratamento de Água">

                        <div class="area" coords="28.3%,7%,12%,7.5%" coords-mobile="31%,0,31%,1.5%" style="--map-left-desk: 28.3%; --map-top-desk: 7%; --map-width-desk: 12%; --map-height-desk: 7.5%; --map-left-mobile: 31%; --map-top-mobile: 0; --map-width-mobile: 31%; --map-height-mobile: 1.5%;">
                            <button type="button" class="shape">1</button>

                            <div class="popover" data-width="360%" data-mobile-left="-46.7%" style="--popover-width: 360%; --popover-left: -46.7%;">
                                <p>As represas armazenam água, permitindo a captação para abastecimento público ao longo de todo o ano. Também são fundamentais no controle de cheias.</p>
                            </div>
                        </div>

                        <div class="area" coords="0,51.9%,3.6%,7%" coords-mobile="8.3%,19.3%,11%,1.65%" style="--map-left-desk: 0; --map-top-desk: 51.9%; --map-width-desk: 3.6%; --map-height-desk: 7%; --map-left-mobile: 8.3%; --map-top-mobile: 19.3%; --map-width-mobile: 11%; --map-height-mobile: 1.65%;">
                            <button type="button" class="shape">2</button>

                            <div class="popover" data-width="1450%" data-mobile-left="-17.7%" style="--popover-width: 1450%; --popover-left: -17.7%;">
                                <p>Os produtos químicos como cloro e cal podem ser adicionados para adequar a água captada para as próximas etapas do tratamento. É nesta fase que coagulantes são adicionados e a água passa por agitação, para agregar as partículas de sujeira. Os coagulantes comumente utilizados são Sulfato de alumínio, Cloreto de Polialumínio (PAC) e cloreto férrico.</p>
                            </div>
                        </div>

                        <div class="area" coords="6%,79%,9.5%,12%" coords-mobile="0.3%,27.7%,26%,2.6%" style="--map-left-desk: 6%; --map-top-desk: 79%; --map-width-desk: 9.5%; --map-height-desk: 12%; --map-left-mobile: 0.3%; --map-top-mobile: 27.7%; --map-width-mobile: 26%; --map-height-mobile: 2.6%;">
                            <button type="button" class="shape">3</button>

                            <div class="popover" data-width="470%" data-mobile-left="-17.2%" style="--popover-width: 470%; --popover-left: -17.2%;">
                                <p>Nesta fase, há uma mistura lenta da água para formação e crescimento de flocos que contém as partículas de sujeira.</p>
                            </div>
                        </div>

                        <div class="area" coords="16%,79%,10.5%,12%" coords-mobile="37.5%,27.7%,30%,2.6%" style="--map-left-desk: 16%; --map-top-desk: 79%; --map-width-desk: 10.5%; --map-height-desk: 12%; --map-left-mobile: 37.5%; --map-top-mobile: 27.7%; --map-width-mobile: 30%; --map-height-mobile: 2.6%;">
                            <button type="button" class="shape">4</button>

                            <div class="popover" data-width="430%" data-mobile-left="-52.2%" style="--popover-width: 430%; --popover-left: -52.2%;">
                                <p>Nesta etapa, a água passa por grandes tanques e os flocos de sujeira decantam, formando o lodo que vai para o fundo dos decantadores.</p>
                            </div>
                        </div>

                        <div class="area" coords="27%,79%,7.3%,12%" coords-mobile="76%,27.7%,22%,2.6%" style="--map-left-desk: 27%; --map-top-desk: 79%; --map-width-desk: 7.3%; --map-height-desk: 12%; --map-left-mobile: 76%; --map-top-mobile: 27.7%; --map-width-mobile: 22%; --map-height-mobile: 2.6%;">
                            <button type="button" class="shape">5</button>

                            <div class="popover" data-width="490%" data-mobile-left="-83%" style="--popover-width: 490%; --popover-left: -83%;">
                                <p>Aqui a água passa por tanques formados por pedras, areia e carvão antracitoso que irão reter os flocos que restaram da decantação.</p>
                            </div>
                        </div>

                        <div class="area" coords="37.6%,55%,9%,10.5%" coords-mobile="31%,36.5%,28%,2.8%" style="--map-left-desk: 37.6%; --map-top-desk: 55%; --map-width-desk: 9%; --map-height-desk: 10.5%; --map-left-mobile: 31%; --map-top-mobile: 36.5%; --map-width-mobile: 28%; --map-height-mobile: 2.8%;">
                            <button type="button" class="shape">6</button>

                            <div class="popover" data-width="500%" data-mobile-left="-45.5%" style="--popover-width: 500%; --popover-left: -45.5%;">
                                <p>Nesta etapa é feita a correção do pH da água tratada com cal, que evita corrosão nas adutoras, a seguir é adicionado cloro para garantir que a água chegue com garantia sanitária até os clientes e o flúor é adicionado auxiliando no combate às cáries.</p>
                            </div>
                        </div>

                        <div class="area" coords="49.2%,45.8%,18%,7.5%" coords-mobile="15%,45%,68%,2.2%" style="--map-left-desk: 49.2%; --map-top-desk: 45.8%; --map-width-desk: 18%; --map-height-desk: 7.5%; --map-left-mobile: 15%; --map-top-mobile: 45%; --map-width-mobile: 68%; --map-height-mobile: 2.2%;">
                            <button type="button" class="shape">7</button>

                            <div class="popover" data-width="215%" data-mobile-left="-49%" style="--popover-width: 215%; --popover-left: -49%;">
                                <p>A medida que a água é tratada, armazenamos em grandes reservatórios dentro das Estações de Tratamento de Água para depois ser distribuída nos reservatórios dos bairros.</p>
                            </div>
                        </div>

                        <div class="area" coords="85.2%,47.1%,15%,7.5%" coords-mobile="15%,65.3%,69.7%,1.7%" style="--map-left-desk: 85.2%; --map-top-desk: 47.1%; --map-width-desk: 15%; --map-height-desk: 7.5%; --map-left-mobile: 15%; --map-top-mobile: 65.3%; --map-width-mobile: 69.7%; --map-height-mobile: 1.7%;">
                            <button type="button" class="shape">8</button>

                            <div class="popover -right" data-width="230%" style="--popover-width: 230%;">
                                <p>A água tratada fica armazenada nestes reservatórios para serem distribuídas para os consumidores.</p>
                            </div>
                        </div>

                        <div class="area" coords="60.8%,1.5%,14%,7.5%" coords-mobile="30.6%,90.65%,40.7%,1.7%" style="--map-left-desk: 60.8%; --map-top-desk: 1.5%; --map-width-desk: 14%; --map-height-desk: 7.5%; --map-left-mobile: 30.6%; --map-top-mobile: 90.65%; --map-width-mobile: 40.7%; --map-height-mobile: 1.7%;">
                            <button type="button" class="shape">9</button>

                            <div class="popover" data-width="270%" data-mobile-left="-50.9%" style="--popover-width: 270%; --popover-left: -50.9%;">
                                <p>A água dos reservatórios chega até os clientes através de uma extensa rede de distribuição subterrânea.</p>
                            </div>
                        </div>
                    </picture>

                    <p class="txt-center"><a class="-fw-medium -no-underline -download" target="_blank" rel="noopener noreferrer" href="/assets/pdf/sabesp-tratamento-agua.pdf" download="">Clique aqui para baixar o infográfico</a></p>
                </div>
            </div>
        </div>
    </article>
</main>
