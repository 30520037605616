<main class="main">
    <meta name="description" content="Relatórios de Monitoramento ETE Baleia: acesse dados sobre o tratamento de esgotos e acompanhe a gestão ambiental na Estação Baleia.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-baleia"
                        title="Relatórios de Monitoramento da Estação de Tratamento de Esgotos Baleia">Relatórios de Monitoramento da Estação de Tratamento de Esgotos Baleia</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            
<h1 class='headline'>Relatórios de Monitoramento da Estação de Tratamento de Esgotos Baleia</h1>

<p>O Sistema de Esgotos Sanitários Baleia/Sahy é composto por mais de 8.900 metros de rede coletora, 4 Estações Elevatórias de Esgotos que operam de forma sequencial no bairro Sahy, e, no bairro Baleia, outras 3 estações elevatórias que também operam de forma sequencial.</p>

<p>Vale destacar que a Estação Elevatória Final  é a responsável pelo encaminhamento dos efluentes domésticos para Estação de Tratamento de Esgotos.</p>

<p>A Estação de Tratamento de Esgotos Baleia/Sahy está localizada na Rua Joaquim Manoel de Macedo e foi inaugurada em 18 de dezembro de 2013, com vazão nominal de 40 l/s, licenciada na CETESB desde 03 de junho de 2013, sob o nº 68000049, e mais recentemente em 30 de julho de 2020, sob nº 68000425, licença válida até 30 de julho de 2025.</p>

<p>A Sabesp realiza o monitoramento mensal em pontos do curso d'água. Confira os relatórios com os parâmetros analisados:</p>



<br><br><h2>2024</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jun_24.pdf">Relatório de junho de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jun_24.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mai_24.pdf">Relatório de maio de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mai_24.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_abr_24.pdf">Relatório de abril de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_abr_24.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mar_24.pdf">Relatório de março de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mar_24.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_fev_24.pdf">Relatório de fevereiro de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_fev_24.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jan_24.pdf">Relatório de janeiro de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jan_24.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>




<br><br><h2>2023</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_dez23.pdf">Relatório de dezembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_dez23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_nov_23.pdf">Relatório de novembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_nov_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_out_23.pdf">Relatório de outubro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_out_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_set_23.pdf">Relatório de setembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_set_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_ago_23.pdf">Relatório de agosto de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_ago_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jul_23.pdf">Relatório de julho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jul_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jun_23.pdf">Relatório de junho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jun_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mai_23.pdf">Relatório de maio de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mai_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_abr23.pdf">Relatório de abril de 2023 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_abr23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mar_23.pdf">Relatório de março de 2023 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_mar_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_fev_23.pdf">Relatório de fevereiro de 2023 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_fev_23.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jan23.pdf">Relatório de janeiro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jan23.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>2022</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_dez_22.pdf"> Relatório de dezembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_dez_22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_nov_22.pdf">Relatório de novembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_nov_22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_out22.pdf">Relatório de outubro de 2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_out22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_set22.pdf">Relatório de setembro de 2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_set22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_ago22.pdf">Relatório de agosto de 2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_ago22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_julho22.pdf">Relatório de julho de 2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_julho22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jun_2022.pdf">Relatório de junho de 2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_jun_2022.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_maio22.pdf">Relatório de maio de 2022 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_maio22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_abril22.pdf">Relatório de abril de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ete_baleia_abril22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_mar_22.pdf">Relatório de março de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_mar_22.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_fev_22.pdf">Relatório de fevereiro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/ete_baleia/monitoramento_ses_baleia_fev_22.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


            <p>Clique na imagem e veja o mapa ampliado com os pontos de monitoramento:</p>
            <p><a href="/site/uploads/image/ete_baleia/monitoramento_ete_baleia.jpg" target="_blank" class="link"><img src="/site/uploads/image/ete_baleia/monitoramento_ete_baleia_peq.jpg" width="680" height="383" alt="" /></a></p>


        </div>
    </article>


</main>