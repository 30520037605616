<main class="main">
    <meta name="description" content="Bacia do Rio Itapanhaú: explore informações detalhadas sobre a gestão, monitoramento e preservação dos recursos hídricos na região.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a class="breadcrumb-link" href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a class="breadcrumb-link" href="/sustentabilidade/transparencia/bacia-rio-itapanhau" title="Bacia do Rio Itapanhaú">Bacia do Rio Itapanhaú</a></li>
            </ul>
            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">



            <h1 class='headline'>Bacia do Rio Itapanhaú</h1>

            <p> A Sabesp protocolou em 19 de novembro de 2015, na Companhia Ambiental do Estado de São Paulo – CETESB, o Estudo de Impacto Ambiental e respectivo Relatório de Impacto Ambiental – EIA/RIMA para a obra de Aproveitamento da Bacia do Rio Itapanhaú para o Abastecimento da Região Metropolitana de São Paulo (RMSP). </p>
            
            <p>Este documento foi elaborado com o objetivo de instruir a obtenção do Licenciamento Ambiental Prévio do empreendimento junto à CETESB.</p>
            
            <p>O aproveitamento da Bacia do Rio Itapanhaú será realizado mediante sistema de reversão de águas do Ribeirão Sertãozinho (formador do Rio Itapanhaú) para o reservatório de Biritiba (Bacia Alto Tietê), visando o reforço da capacidade de produção de água do Sistema Produtor Alto Tietê (SPAT). </p>
            
            <p>O sistema está concebido para reverter à vazão média de 2,0 m3/s, autorizada na Outorga de Implantação emitida pelo DAEE e está dimensionado para a vazão máxima instantânea de 2,5 m3/s.</p>
            
            <p>Este documento apresenta as razões da proposição do projeto, as características principais, as áreas de influência do empreendimento, assim como os impactos socioambientais e as medidas para evitá-los, mitigá-los ou compensá-los.</p>
            
            <p>Abaixo os arquivos do EIA/RIMA das Obras de Aproveitamento da Bacia do Rio Itapanhaú para Abastecimento da RMSP para download (arquivos em zip):</p>
            
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/eia%20tomo%201%2C%20ate%CC%81%20cap_%205.zip"> EIA Volume I – textos, Tomo 1: Estudo de Impacto Ambiental</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/site/uploads/file/eiarimaitapanhau/eia%20tomo%201%2C%20ate%CC%81%20cap_%205.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/eia tomo 2, itens 6_1, 6_2, 6_3.zip"> EIA Volume I – Textos, Tomo 2: Diagnóstico Ambiental. Contexto Regional e Meio Físico</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/site/uploads/file/eiarimaitapanhau/eia tomo 2, itens 6_1, 6_2, 6_3.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/eia tomo 3, item 6_4.zip"> EIA Volume I – Textos, Tomo 3: Diagnóstico Ambiental do Meio Biótico</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/site/uploads/file/eiarimaitapanhau/eia tomo 3, item 6_4.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/eia tomo 4, item 6_5, cap_ 7 a 12.zip"> EIA Volume I – Textos, Tomo 4: Diagnóstico Ambiental do Meio Socioeconômico e Estudo de Impactos e Programas</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/site/uploads/file/eiarimaitapanhau/eia tomo 4, item 6_5, cap_ 7 a 12.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/2 vol ii anexos tomo 1.zip"> EIA Volume II – Anexos, Tomo 1: Documentos de Campanhas de Campo</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/site/uploads/file/eiarimaitapanhau/2 vol ii anexos tomo 1.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/3 vol ii anexos tomo 2.zip"> EIA Volume II – Anexos, Tomo 2: Documentos protocolados no IPHAN</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/site/uploads/file/eiarimaitapanhau/3 vol ii anexos tomo 2.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a rel="noopener noreferrer" class="-link" href="site/uploads/file/eiarimaitapanhau/4%20vol%20iii.zip"> EIA Volume III – Desenhos</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="site/uploads/file/eiarimaitapanhau/4%20vol%20iii.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a rel="noopener noreferrer" class="-link" href="site/uploads/file/eiarimaitapanhau/5%20rima.zip"> Relatório de Impacto Ambiental – RIMA</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="site/uploads/file/eiarimaitapanhau/5%20rima.zip"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/relatorio_informacao_compl_ric.pdf"> Relatório de Informação Complementar - RIC</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/eiarimaitapanhau/relatorio_informacao_compl_ric.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/estudo_vazao_area_restinga_itapanhau.pdf"> Relatório de Informação Complementar - RIC - Estudo da vazão na área da Restinga do rio Itapanhaú</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/eiarimaitapanhau/estudo_vazao_area_restinga_itapanhau.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/eiarimaitapanhau/revisao_impactos_ictiofauna.pdf"> Relatório de Informação Complementar - RIC - Revisão dos Impactos na Ictiofauna</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/eiarimaitapanhau/revisao_impactos_ictiofauna.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>Plano de Monitoramento do Médio e Baixo Itapanhaú</h2>
            <p>Em cumprimento à condicionante ambiental 1.33 da Licença Prévia (LP) nº2507/2016, constante no processo de licenciamento em progresso na CETESB, a Sabesp está desenvolvendo o Plano de Monitoramento do Médio e Baixo Itapanhaú.</p>
            
            <p>De acordo com o Parecer Técnico da CETESB nº234-16-IE, a Sabesp fornece os dados de chuva, nível d’água e salinidade do estuário, resultantes da execução deste monitoramento.</p>
            
            <p>O monitoramento hidrológico e de salinidade desenvolvido no Plano de Monitoramento do Médio e Baixo Itapanhaú, visa levantar informações sobre o comportamento de variáveis hidrológicas da bacia e da salinidade no estuário por meio de estações localizadas em pontos notáveis do sistema hídrico associado ao Aproveitamento das Águas da Bacia do Rio Itapanhaú para Abastecimento da Região Metropolitana de São Paulo. </p>
            
            <p>O monitoramento hidrológico é composto por 2 (duas) estações exclusivamente pluviométricas (PLU) instaladas na Cabeceira do Rio Gracianópolis e na Cabeceira do Rio Itatinga, e por 4 (quatro) estações pluviométricas e fluviométricas (PLU/FLU) instaladas na Captação SABESP, na Soleira Light, na Captação Riviera e no Rio Itatinga.</p>
            
            <p>Já o monitoramento de salinidade consta de 4 (quatro) estações instaladas ao longo do rio Itapanhaú, localizadas nos Km 16.3, Km 17.6, Km 20.8 e Km 26.3, sendo essa distância considerada a partir de sua foz. Essas quatro estações também realizam o monitoramento de chuva na bacia (PLU).</p>
            
            <p> Informações sobre as estações de monitoramento</p>
            <p><strong class="fckInline"><img src="/site/uploads/image/asabesp/estacao_itapanhau.jpg" alt="Estação de Monitoramento Itapanhaú" width="650" height="572" /></strong></p>
            <p><strong class="fckInline"><img src="/site/uploads/image/asabesp/estacao_monitoramento_itapanhau.jpg" width="350" height="205" alt="" /></strong></p>
            
            
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://www.saisp.br/online/monitoramento-sabesp/">Acesse a página: https://www.saisp.br/online/monitoramento-sabesp/</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://www.saisp.br/online/monitoramento-sabesp/"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            

        </div>
    </article>


</main>