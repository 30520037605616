import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tipos-de-tratamento',
  standalone: true,
  imports: [],
  templateUrl: './tipos-de-tratamento.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css']
})
export class TiposDeTratamentoComponent implements OnInit, AfterViewInit {

  @ViewChild('lagoaFacultativa') lagoaFacultativa!: ElementRef

  constructor(
    private title: Title,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Tipos de Tratamento de Esgotos");
  }
  
  ngAfterViewInit(): void {
    this.route.fragment.subscribe(frag => {
      if (frag && frag === 'lagoa-facultativa') {
        const lagoaEl = this.lagoaFacultativa.nativeElement;
        lagoaEl.classList.add('-open');

        const collapse = lagoaEl.querySelector('.collapse');
        collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');

        setTimeout(() => {
          window.scrollTo({ top: lagoaEl.offsetTop - 130, behavior: 'smooth' });
        }, 0);
      }
    })
  }


  accordionToggle(e: any) {
    e.preventDefault();
    let wrapper = e.target.closest('.item');
    wrapper.classList.toggle('-open');

    let collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
  }
}
