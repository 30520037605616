import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-coleta-esgotos',
  standalone: true,
  imports: [],
  templateUrl: './coleta-esgotos.component.html',
  styleUrl: './coleta-esgotos.component.css'
})
export class ColetaEsgotosComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Coleta de Esgotos ");
}
}
