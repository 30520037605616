<main class="main">
    <meta name="description" content="Venha conhecer a Sabesp! Oferecemos visitas guiadas para estudantes e professores, mostrando o processo de tratamento de água e esgoto na Região Metropolitana de São Paulo e na Baixada Santista. Agende sua visita e descubra como cuidamos da água.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Educação">Educação</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Visite a Sabesp">Visite a Sabesp</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Visite a Sabesp</h1>

            <p>Venha conhecer a Sabesp! Estamos sempre abertos para atender estudantes e professores interessados em
                conhecer o processo de tratamento de água na Região Metropolitana de São Paulo e na Baixada Santista.
            </p>
            <p>Por questões de segurança, a visita é permitida somente para estudantes com idade superior a 11 anos.
                Vale ressaltar que esta atividade é realizada de segunda a sexta-feira e que o transporte fica a cargo
                do solicitante.</p>
            <p><strong>Para agendar e obter todas as informações necessárias, entre em contato pelo e-mail <a
                        class="-txt-blue -no-underline mailto"
                        href="mailto:visita@sabesp.com.br">visita&#64;sabesp.com.br</a>.</strong></p>
            <p>Abaixo você confere quais estações podem ser visitadas, informações e requisitos de cada uma.</p>
        </div>
    </article>

    <section class="content -gray">
        <div class="container">
            <div class="g-columns -gap-left -v-center -space-md">
                <div>
                    <h2 class="headline">Sistema Cantareira</h2>
                    <p>No Sistema Cantareira, a visita compreende a Represa Paulo de Paiva Castro, formada pelo rio
                        Juqueri, a estação de bombeamento chamada Elevatória Santa Inês (ESI) e a estação de tratamento
                        de água do Guaraú.</p>
                    <p>O ponto de encontro é no <strong class="-txt-blue">km 2 da Estrada de Santa Inês</strong>, bairro
                        Pedra Branca, próximo ao Horto Florestal. Caso a visita seja prolongada, <strong
                            class="-fw-medium">sugerimos trazer lanche.</strong></p>
                </div>
                <div>
                    <picture class="picture">
                        <img loading="eager" fetchpriority="high" width="650" height="260"
                            src="/assets/images/cantareira.jpg" alt="Reservatório de água com vegetação e montanhas ao redor" title="Sistema Cantareira">
                    </picture>
                </div>
            </div>

            <div class="accordion">
                <div class="item">
                    <h3 class="head"><a (click)="accordionToggle($event)" href="#requisitos" class="accordion-link">Requisitos</a></h3>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <div class="columns">
                                <div class="column">
                                    <ul class="list">
                                        <li>Visita para estudantes do <strong class="-txt-blue">Ensino Médio</strong>;
                                        </li>
                                        <li>Grupos de no <strong class="-fw-medium">mínimo 20 pessoas</strong> e <strong
                                                class="-txt-blue">máximo de 40 pessoas</strong>;</li>
                                        <li>Acompanhamento de <strong class="-txt-blue">2 ou mais professores</strong>;
                                        </li>
                                        <li>Tempo estimado: <strong class="-txt-blue">6 horas</strong> (em média);</li>
                                    </ul>
                                </div>

                                <div class="column">
                                    <ul class="list">
                                        <li>Monitoramento por funcionários da Sabesp;</li>
                                        <li>Horário de início da visita: <strong class="-txt-blue">10h</strong>;</li>
                                        <li><strong class="-fw-medium">O transporte é por conta do visitante</strong>,
                                            sendo que o veículo deve permanecer no local durante toda a visita.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <div class="g-columns -gap-left -v-center -space-md">
                <div>
                    <h2 class="headline">Estação de Tratamento do Guaraú</h2>
                    <p>Para a visita à Estação de Tratamento do Guaraú, o <strong class="-fw-medium">ponto de
                            encontro</strong> é no <strong class="-txt-blue">km 2 da Estrada de Santa Inês</strong>,
                        bairro Pedra Branca, próximo ao Horto Florestal.</p>
                </div>
                <div>
                    <picture class="picture">
                        <img loading="eager" fetchpriority="high" width="650" height="260"
                            src="/assets/images/guarau.jpg" alt="Vista aérea de estação de tratamento de água da Sabesp"
                            title="Estação de Tratamento do Guaraú">
                    </picture>
                </div>
            </div>

            <div class="accordion">
                <div class="item">
                    <h3 class="head"><a (click)="accordionToggle($event)" href="#requisitos-2" class="accordion-link">Requisitos</a></h3>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <div class="columns">
                                <div class="column">
                                    <ul class="list">
                                        <li>Visita para estudantes a <strong>partir do 7º ano</strong> (a partir de 11
                                            anos);</li>
                                        <li>Grupos de no <strong class="-fw-medium">mínimo 20 pessoas</strong> e
                                            <strong>máximo de 40 pessoas</strong>;</li>
                                        <li>Acompanhamento de <strong class="-txt-blue">2 ou mais professores</strong>;
                                        </li>
                                        <li>Tempo estimado: entre <strong class="-txt-blue">1h30 e 2h</strong>;</li>
                                    </ul>
                                </div>

                                <div class="column">
                                    <ul class="list">
                                        <li>Monitoramento por funcionários da Sabesp;</li>
                                        <li>A visita pode ser realizada em dois horários: <strong class="-txt-blue">10h
                                                e 14h</strong>;</li>
                                        <li><strong class="-fw-medium">O transporte é por conta do visitante</strong>,
                                            sendo que o veículo deve permanecer no local durante toda a visita.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content -gray">
        <div class="container">

            <div class="g-columns supercards">
                <div class="item">
                    <picture class="picture">
                        <img loading="lazy" fetchpriority="low" width="620" height="260"
                            data-src="/assets/images/visitas-etes.jpg" alt="Estação de Tratamento de Esgotos"
                            title="Estação de Tratamento de Esgotos">
                    </picture>
                    <div class="info">
                        <h2 class="title">Estação de Tratamento de Esgotos</h2>
                        <p>As informações sobre agendamento de visitas monitoradas e estudo de campo nas Estações de
                            Tratamento de Esgotos da Região Metropolitana de São Paulo podem ser obtidas pelo e-mail <a
                                class="-no-underline -txt-blue"
                                href="mailto:agendarvisitas.etes@sabesp.com.br"><strong>agendarvisitas.etes&#64;sabesp.com.br</strong></a>.
                        </p>
                        <p class="-fw-medium -txt-blue">Observações importantes:</p>
                        <ul class="list">
                            <li>O agendamento é feito <strong>diretamente com a Sabesp</strong>, sem intermediários, e
                                <strong>não há cobrança</strong> para a realização das visitas;</li>
                            <li>Vale destacar que transporte, <strong>lanche e/ou materiais adicionais</strong> são
                                <strong>por conta do solicitante</strong>.</li>
                        </ul>
                    </div>
                </div>

                <div class="item">
                    <picture class="picture">
                        <img loading="lazy" fetchpriority="low" width="620" height="260"
                            data-src="/assets/images/eta-cubatao.jpg" alt="Estação de Tratamento de Água Cubatão"
                            title="Estação de Tratamento de Água Cubatão">
                    </picture>
                    <div class="info">
                        <h2 class="title">Estação de Tratamento de Água Cubatão</h2>
                        <p>A Estação de Tratamento de Água Cubatão <strong class="-fw-medium">é a maior do litoral
                                Paulista</strong> e é o coração do Sistema Produtor de Água que abastece os municípios
                            de Cubatão, Santos, São Vicente e parte de Guarujá e Praia Grande.</p>
                        <p>As visitas acontecem às <strong class="-fw-medium">quartas e sextas-feiras</strong>, às
                            <strong class="-txt-blue">9 ou às 14 horas</strong>.</p>
                        <p>O agendamento deve ser feito diretamente com o Polo de Comunicação da Sabesp na Baixada
                            Santista, pelo telefone <a class="-no-underline -txt-blue phone-link"
                                href="tel:+(13) 3201-2657"><strong>(13) 3201-2657</strong></a> ou pelo e-mail <a
                                class="-no-underline -txt-blue"
                                href="mailto:comunicacao_rs@sabesp.com.br"><strong>comunicacao_rs&#64;sabesp.com.br</strong></a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
