<main class="main">
    <meta name="description" content="Confira dados e análises das manifestações dos clientes, garantindo transparência e melhoria contínua dos serviços da Sabesp.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="javascript:void(0);" title="Informações Operacionais">Relatórios de Ouvidoria</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <p class='lead'><strong>RELATÓRIOS DA OUVIDORIA SABESP</strong></p>
            <p>O Relatório da Ouvidoria Sabesp contém informações relacionadas ao número de s realizados pela Ouvidoria. O documento é uma das ferramentas de gestão da companhia.</p>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th>Descrição</th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_mai_2024.pdf"> Relatório de maio de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_mai_2024.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_abr_2024.pdf"> Relatório de abril de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_abr_2024.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_marco_2024.pdf"> Relatório de março de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_marco_2024.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_fevereiro2024.pdf"> Relatório de fevereiro de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_fevereiro2024.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_janeiro2024.pdf"> Relatório de janeiro de 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/rel_ouv_janeiro2024.pdf"><span>Acessar</span></a></td></tr>

            </tbody></table></div>

            <p class='lead'><strong>Anos anteriores</strong></p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th>Descrição</th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/relato%CC%81rio_ouvidoria_2023.pdf"> Relatório anual de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/relato%CC%81rio_ouvidoria_2023.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/relatorio_anual_ouvidoria_2022.pdf"> Relatório anual de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/relatorio_anual_ouvidoria_2022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/relatorio_ouvidoria_2021.pdf"> Relatório anual 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/relatorio_ouvidoria_2021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_ouvidoria/relatorio_anual_2020.pdf"> Relatório anual 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_ouvidoria/relatorio_anual_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_ouvidoria_2019.pdf"> Relatório anual 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_ouvidoria_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_ouvidoria_anual_2018.pdf"> Relatório anual 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_ouvidoria_anual_2018.pdf"><span>Acessar</span></a></td></tr>

            </tbody></table></div>


        </div>
    </article>


</main>
