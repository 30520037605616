<main class="main">
  <meta name="description" content="Conheça os subprodutos do tratamento de esgotos gerenciados pela Sabesp, incluindo a água de reúso para indústrias e urbanas, produção de biogás e lodo para geração de energia. Descubra como transformamos resíduos em recursos valiosos e sustentáveis.">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li><a href="/" title="Início">Início</a></li>
        <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
        <li><a href="/o-que-fazemos/solucoes-para-esgotos" title="Soluções para Esgotos">Soluções para Esgotos</a></li>
        <li><a href="/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos" title="Tratamento de Esgotos">Tratamento de Esgotos</a></li>
        <li><a href="javascript:void(0);" title="Subprodutos de Esgotos">Subprodutos de Esgotos</a></li>
      </ul>

      <ul class="buttons">
        <li><a class="button -home" href="/" title="Início">Início</a></li>
      </ul>
    </div>
  </nav>

  <article class="content">
    <div class="container">
      
      <h1 class="headline">Subprodutos de Esgotos</h1>
      
      <picture class="banner -no-shadow">
        <img loading="eager" fetchpriority="high" width="1300" height="244" src="../../../assets/images/subprodutos-agua-reuso.jpg" alt="Funcionário da Sabesp segurando amostras de esgoto e reuso​" title="Subprodutos de Esgotos">
      </picture>
      
      <span id="aguareuso" class="anchor-offset"></span>
      <h2 class="subheadline">Água de Reúso</h2>
      <p>A tecnologia de reúso está entre as mais eficientes iniciativas para o consumo sustentável da água. Resulta em grande economia de água bruta que deixa de ser retirada dos mananciais e também em maior disponibilidade de água tratada para o abastecimento da população.</p>
      <p>A Sabesp iniciou de forma pioneira a prática de produção de água de reúso a partir de 1998, com a transformação da Estação de Tratamento de Esgotos convencional, ETE Jesus Netto, em uma estação para produção de água de reúso.</p>
      <p>Atualmente, são produzidos 502 litros de água por segundo em média para fornecimento externo ou uso interno da Sabesp nas estações Jesus Netto, Parque Novo Mundo, São Miguel e ABC, este último por meio do Aquapolo Ambiental. O empreendimento, parceria da Sabesp com o setor privado, utiliza os esgotos tratados da estação de tratamento ABC e, após processo adicional, produz água de reúso customizada para utilização no Polo Petroquímico da Região do Grande ABC, transportada por uma rede de aproximadamente 17 km.</p>
    </div>
  </article>

  <section class="content -gray">
    <div class="container">
      <h2 class="subheadline">Água de Reúso para Indústrias</h2>
      <p>A Sabesp possui 7,8 km de redes exclusivas para fornecimento de água de reúso. Aproximadamente, 1 bilhão de litros de água por mês são destinados ao Polo Petroquímico de Capuava, na região do ABC Paulista. Trata-se do maior empreendimento para a produção de água de reúso industrial na América do Sul e o 5º maior do mundo.</p>
    </div>
  </section>

  <section class="content">
    <div class="container">
      <div class="g-columns -gap-right -v-center">
        <div>
          <picture class="picture">
            <img loading="lazy" fetchpriority="low" width="650" height="294" data-src="../../../assets/images/subprodutos-esgotos-biogas.jpg" alt="Estrutura de biometano da Sabesp com veículo no posto​" title="Biogás">
          </picture>
        </div>
        <div>
          <h2 class="subheadline">Biogás</h2>
          <p>Biogás é um tipo de biocombustível, produzido a partir da decomposição de materiais orgânicos, e que pode ser aproveitado para geração de energia térmica, elétrica e como combustível veicular. A Sabesp tem um projeto inovador na Unidade de Beneficiamento de Biogás da Estação de Tratamento de Esgoto (ETE) Franca, que utiliza o gás gerado pelo esgoto para abastecer sua frota de veículos.</p>
          <a class="button" href="a-sabesp/inovacao-e-tecnologia/biogas">Saiba mais</a>
        </div>
      </div>
    </div>
  </section>
</main>
