<main class="main">
    <meta name="description" content="Fique por dentro das notícias de utilidade pública da Sabesp. Informações atualizadas sobre serviços e alertas importantes para sua região.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Central de Notícias">Central de Notícias</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Utilidade Pública">Utilidade Pública</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <app-filter
    [classList]="'filter'"
    [totalItems]="1"
    [categories]="categories"
    (filterChange)="filterTypeSearch($event)">
    </app-filter>


    <section class="content">
    <div class="container petals">
        <div class="content">
            <h1 class="headline title-link">Utilidade Pública</h1>
            <div class="listing">
                <div class="item" *ngFor="let n of notices.items">
                    <h2 class="title"><a routerLink="/a-sabesp/central-noticias/utilidade-publica/{{n.id}}">{{ n.title }}</a></h2>
                    <p class="published"><a routerLink="/a-sabesp/central-noticias/utilidade-publica/{{n.id}}" tabindex="-1">Publicado em <time attr.datetime="{{ n.date }}">{{ n.date | date:'dd/MM/yyyy':'UTC' }}</time></a></p>
                </div>
            </div>

            <app-pagination
                [totalItems]="notices.totalItems"
                [itemsPerPage]="notices.itemsPerPage"
                [totalPages]="notices.totalPages"
                (pageChange)="getNotices($event)"></app-pagination>
        </div>
    </div>
    </section>
</main>
