<main class="main">
    <meta name="description" content="Acesse comunicados de imprensa e informações sobre eventos da Sabesp. Encontre recursos e contatos para jornalistas e profissionais de mídia.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Central de Notícias">Central de Notícias</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Contatos da Assessoria de Imprensa">Contatos da Assessoria de Imprensa</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Contatos da Assessoria de Imprensa</h1>

            <picture class="banner petals-2 -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="/assets/images/imprensa.jpg"
                    alt="Pessoa fazendo anotações em um caderno enquanto usa um laptop" title="Contatos da Assessoria de Imprensa">
            </picture>

            <p class="mt-0">A Assessoria de Imprensa da Sabesp é um canal exclusivo para jornalistas e veículos de
                comunicação. Se você é jornalista e precisa de alguma informação, envie e-mail para
                <a class="-no-underline -txt-blue" href="mailto:comunicacao_sabesp@sabesp.com.br">
                    <strong class="mailto">comunicacao_sabesp&#64;sabesp.com.br</strong>
                </a>.
                Ou entre em contato pelos telefones:
            </p>

            <ul class="list">
                <li><b><a class="-phone -no-underline phone-link" href="tel:(11) 3388-8594">(11) 3388-8594</a></b></li>
                <li><b><a class="-phone -no-underline phone-link" href="tel:(11) 3388-8555">(11) 3388-8555</a></b></li>
            </ul>

            <p>Nossa redação funciona de segunda a sexta-feira, das 8h30 às 20h30. Após esse horário e durante sábados, domingos e feriados, o contato deve ser feito por e-mail ou pelo telefone de plantão
                <b><a class="-phone -no-underline" href="tel:(11) 98685-3175">(11) 98685-3175</a></b>.
            </p>
        </div>
    </article>
</main>
