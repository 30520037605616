import {
  Component,
  ViewEncapsulation,
  OnInit,
  AfterViewChecked,
  Renderer2,
} from '@angular/core';
import { Banner } from '../banner';
import { BannerService } from '../banner.service';
import { tns } from 'tiny-slider/src/tiny-slider';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, AfterViewChecked {
  banners: Banner[] = [];
  bannerInit = false;

  constructor(
    private bannerService: BannerService,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.getBanners();

    this.title.setTitle('Sabesp | Site Oficial');

    this.meta.updateTag({
      property: 'twitter:description',
      content:
        'Bem-vindo ao site oficial da Sabesp, a maior empresa de saneamento do Brasil, fornecendo água e tratamento de esgoto para milhões de pessoas em São Paulo.',
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, saneamento, água, esgoto, São Paulo, serviços públicos',
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Controle de Perdas',
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Controle de Perdas',
    });
  }

  getBanners(): void {
    this.bannerService
      .getBanners()
      .subscribe((banners) => (this.banners = banners));
  }

  sanitizeHtml(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  ngAfterViewChecked() {
    if (!this.bannerInit && this.banners.length > 0) {
      const controlsText = [
        this.sanitizeHtml('Anterior') as string,
        this.sanitizeHtml('Próximo') as string,
      ];

      tns({
        container: '.hero-slider',
        items: 1,
        autoplay: false,
        loop: true,
        controls: true,
        controlsText: controlsText,
        navPosition: 'bottom',
        autoplayText: ['', ''],
        autoplayButtonOutput: false,
        lazyload: true,
        onInit: () => {
          this.removeClassById('agencia-virtual', 'agencia-virtual-btn');
          this.addClassToControlButtons();
          this.addClassToNavButtons();
        },
      });
      this.bannerInit = true;
    }
  }

  addClassToControlButtons(): void {
    const prevButton = document.querySelector('.tns-controls [data-controls="prev"]') as HTMLElement;
    const nextButton = document.querySelector('.tns-controls [data-controls="next"]') as HTMLElement;

    if (prevButton) {
      this.renderer.addClass(prevButton, 'slider-page');
    }
    if (nextButton) {
      this.renderer.addClass(nextButton, 'slider-page');
    }
  }

  addClassToNavButtons(): void {
    const navButtons = document.querySelectorAll('.tns-nav button') as NodeListOf<HTMLElement>;
    navButtons.forEach(button => {
      this.renderer.addClass(button, 'slider-page');
    });
  }

  removeClassById(elementId: string, className: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      this.renderer.removeClass(element, className);
    }
  }
}
