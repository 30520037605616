import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MockDbService } from './mock-db.service';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { NoticiaComponent } from './noticia/noticia.component';
import { ContentPipe } from './content-pipe/content.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { MinhaCidadeComponent } from './minha-cidade/minha-cidade.component';
import { CanaisAtendimentoComponent } from './canais-atendimento/canais-atendimento.component';
import { ReleaseComponent } from './release/release.component';
import { ReleasesComponent } from './releases/releases.component';
import { QualidadeAguaComponent } from './qualidade-agua/qualidade-agua.component';
import { TemplateCarrosselComponent } from './template-carrossel/template-carrossel.component';
import { CarrosselComponent } from './carrossel/carrossel.component';
import { LinhaTempoComponent } from './linha-tempo/linha-tempo.component';
import { PerguntasFrequentesComponent } from './perguntas-frequentes/perguntas-frequentes.component';
import { NgArrayPipesModule } from 'ngx-pipes';
import { FolhetosComponent } from './folhetos/folhetos.component';
import { CursoPesquisaVazamentosComponent } from './curso-pesquisa-vazamentos/curso-pesquisa-vazamentos.component';
// import { TemplateGeralInternoComponent } from './template-geral-interno/template-geral-interno.component';
import { VisiteSabespComponent } from './visite-sabesp/visite-sabesp.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { ContatoImprensaComponent } from './contato-imprensa/contato-imprensa.component';
import { CaptacaoAguaComponent } from './captacao-agua/captacao-agua.component';
import { QualidadeAgua2Component } from './qualidade-agua-2/qualidade-agua-2.component';
import { DistribuicaoAguaComponent } from './distribuicao-agua/distribuicao-agua.component';
import { BuscaGeralComponent } from './busca-geral/busca-geral.component';
import { ComunicadoComponent } from './comunicado/comunicado.component';
import { ComunicadosComponent } from './comunicados/comunicados.component';
import { FilterComponent } from './filter/filter.component';
import { InventarioEmissoesGasesEfeitoEstufaComponent } from './inventario-emissoes-gases-efeito-estufa/inventario-emissoes-gases-efeito-estufa.component';
import { RelatorioAcoesVerdesB3Component } from './relatorio-acoes-verdes-b3/relatorio-acoes-verdes-b3.component';
import { FrameworkFinancasSustentaveisComponent } from './framework-financas-sustentaveis/framework-financas-sustentaveis.component';
import { RedirectMapComponent } from './redirect-map/redirect-map.component';
import { SafePipe } from './shared/pipes/Safe.pipe';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FormularioOuvidoriaComponent } from './formulario-ouvidoria/formulario-ouvidoria.component';
import { MenuMobileComponent } from './menu-mobile/menu-mobile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BiogasComponent } from './biogas/biogas.component';
import { environment } from '../environments/environment';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ContentPipe,
    NgArrayPipesModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorkerModuleEnabled,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    PaginationComponent,
    NoticiasComponent,
    NoticiaComponent,
    ReleasesComponent,
    ReleaseComponent,
    MinhaCidadeComponent,
    QualidadeAguaComponent,
    CanaisAtendimentoComponent,
    LinhaTempoComponent,
    PerguntasFrequentesComponent,
    TemplateCarrosselComponent,
    CarrosselComponent,
    LinhaTempoComponent,
    FolhetosComponent,
    CursoPesquisaVazamentosComponent,
    // TemplateGeralInternoComponent,
    VisiteSabespComponent,
    InstitucionalComponent,
    ContatoImprensaComponent,
    CaptacaoAguaComponent,
    QualidadeAgua2Component,
    DistribuicaoAguaComponent,
    BuscaGeralComponent,
    ComunicadoComponent,
    ComunicadosComponent,
    FilterComponent,
    InventarioEmissoesGasesEfeitoEstufaComponent,
    RelatorioAcoesVerdesB3Component,
    FrameworkFinancasSustentaveisComponent,
    RedirectMapComponent,
    SafePipe,
    FormularioOuvidoriaComponent,
    MenuMobileComponent,
    BiogasComponent,
  ],
  exports: [
    SafePipe
  ],
  bootstrap: [AppComponent],
  providers: [provideNgxMask()]
})
export class AppModule { }
