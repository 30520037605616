<main class="main">
    <meta name="description" content="Saiba como a Sabesp leva água de qualidade até sua casa, com serviços confiáveis e atendimento eficiente para sua comodidade.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Distribuição de Água">Distribuição de Água</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Da Sabesp para sua Casa">Da Sabesp para sua Casa</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Da Sabesp para sua Casa</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244"
                    src="/assets/images/distribuicao-agua.jpg" alt="Engenheiro da Sabesp em frente a tanque" title="Distribuição de Água">
            </picture>

            <p>Após ser tratada, a água é armazenada em reservatórios de distribuição, depois, é levada até os
                reservatórios de bairros, estrategicamente localizados. De lá, a água segue por tubulações maiores
                (adutoras) e entra nas redes de distribuição até chegar ao consumidor final.</p>
            <p>Nas casas das pessoas, o armazenamento é feito geralmente em caixas d’água. A responsabilidade da Sabesp
                é levar a água até a entrada da residência, onde estão o cavalete e o hidrômetro (o relógio que registra
                o consumo de água). A partir daí, o cliente é quem deve cuidar das instalações internas e da limpeza e
                        conservação do seu reservatório.</p>
        </div>
    </article>

    <section class="content -gray">
        <div class="container">
            <h2 class="subheadline">Mantenha uma Reserva de Água para Você e sua Família</h2>
            <p>Instalar uma caixa de água na sua casa é um dos melhores investimentos que você pode fazer para a saúde e
                conforto da sua família. Ela garante o abastecimento em sua casa mesmo quando a Sabesp é obrigada a
                interromper o fornecimento de água para consertar um vazamento ou resolver qualquer outro problema.</p>

            <div class="basiccards">
                <div class="item">
                    <h2 class="headline txt-center">Veja qual o tamanho da caixa de água ideal para sua casa:</h2>
                    <p><b>Número de pessoas na casa:</b></p>

                    <div class="ideal-tank">
                      <div *ngFor="let item of peopleNumberHouse; trackBy: trackByFn" class="item b-none p-0">
                          <button type="button" class="number">{{ item.number }}</button>
                          <picture class="figure">
                              <img loading="lazy" [width]="item.img.width" [height]="item.img.height"
                                  [src]="'/assets/images/sabesp-familia-' + item.number + '-pessoas.png'"
                                  [alt]="getAltText(item)"
                                  [title]="item.number + ' pessoas na casa'" />
                          </picture>
                          <div class="info txt-center">
                              <p>
                                  Média de Consumo por Dia:<br>
                                  <span class="data"><strong>{{ item.averageConsumption }}</strong> litros/dia</span>
                              </p>
                              <p>
                                  Tamanho de Caixa de Água Ideal:<br>
                                  <span class="data"><strong>{{ item.waterTankSize }}</strong> litros</span>
                              </p>
                          </div>
                      </div>
                  </div>


                    <hr class="-gap-md">

                    <h2 class="headline txt-center">Veja como instalar uma caixa de água</h2>
                    <div class="g-columns -max-auto -gap-sm">
                        <picture>
                            <img loading="lazy" fetchpriority="low" width="702" height="440"
                                data-src="/assets/images/como-instalar-caixa-agua.png"
                                alt="Diagrama de instalação de tanque " title="Como instalar uma caixa de água">
                        </picture>

                        <ol class="numeric -blue -fw-medium">
                            <li>Tubo roscado ou soldável DN 20 (3/4).</li>
                            <li>Curva 90º roscada ou soldável DN 20 (3/4).</li>
                            <li>Registro de gaveta de latão para saneamento DNR 20 (3/4).</li>
                            <li>Flange para caixa d’água com tubo roscado e extremidade roscada ou soldável para ligação
                                DN 20 (3/4).</li>
                            <li>Torneira boia para caixa d’água DNR 20 (3/4) Tubulação de Saída para Limpeza e do
                                Extravasor (Ladrão).</li>
                            <li>Flange para caixa d’água com tubo roscado de extremidade; roscada ou soldável para
                                ligação DN 25 (1).</li>
                            <li>Tubo roscado ou soldável DN 25 (1).</li>
                            <li>Curva 90º roscada ou soldável DN 25 (1).</li>
                            <li>Registro de gaveta de latão para saneamento DNR 25 (1).</li>
                            <li>Te 90º roscado ou soldável DN 25 (1).</li>
                        </ol>
                    </div>

                    <hr class="-gap-md">

                    <h2 class="headline txt-center">Dicas importantes</h2>
                    <ul class="list">
                        <li>Mantenha a caixa d'água sempre bem tampada, para evitar que insetos e animais sujem a água;
                        </li>
                        <li>Solicite que o lojista indique e forneça somente produtos de qualidade;</li>
                        <li>Limpe a caixa d'água a cada seis meses, para garantir a qualidade da água que sua família
                            consome.</li>
                    </ul>
                </div>
            </div>

        </div>
    </section>

    <section class="carousel">
        <div class="carousel-slider">
            <div class="item -green-teal">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="eager" fetchpriority="high" width="644" height="298"
                                src="/assets/images/reducao-pressao-agua.jpg" alt="Funcionário da Sabesp operando equipamento"
                                title="Redução de Pressão da Água">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Redução de Pressão da Água</h2>

                        <div class="description">
                            <p>A redução de pressão nas tubulações das redes de água é uma tecnologia praticada pela
                                Sabesp há décadas na Grande São Paulo. <strong>Ela serve para evitar desperdício,
                                    principalmente durante a noite/madrugada</strong>, período em que a grande maioria
                                da população dorme e as atividades econômicas e comerciais diminuem drasticamente.</p>
                            <p>Se o seu imóvel possui caixa de água adequada à quantidade de moradores, a redução de
                                pressão na rede não deve ser percebida e não interfere na sua rotina, mas faz uma grande
                                diferença para garantir um consumo mais consciente e fornecimento de água para todos.
                            </p>
                            <p>Você pode conferir mais informações sobre a redução e os horários em que ela acontece na
                                sua região <strong><a class="white" target="_blank" rel="noopener noreferrer"
                                        href="https://www.sabesp.com.br/reducaopressao" title="clicando aqui">clicando
                                        aqui</a></strong>.</p>
                        </div>
                        <a class="link -white" href="/o-que-fazemos/fornecimento-agua/distribuicao-agua/gestao-demanda"
                            title="Saiba mais sobre a Gestão de Demanda">Saiba mais sobre a Gestão de Demanda</a>
                    </div>
                </div>
            </div>

            <div class="item -dark-blue">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="lazy" fetchpriority="low" width="644" height="298"
                                src="/assets/images/programa-reducao-perdas.jpg"
                                alt="Funcionário ajustando equipamento" title="Programa de Redução de Perdas">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Programa de Redução de Perdas</h2>
                        <div class="description">
                            <p>As perdas de água nos sistemas de abastecimento correspondem à diferença entre o volume
                                total de água produzido nas estações de tratamento e a soma dos volumes medidos nos
                                hidrômetros instalados nos imóveis dos clientes.</p>
                            <p><strong>O combate às perdas demanda um esforço permanente</strong>, pois as perdas de
                                água têm uma tendência natural de aumento, pois com o passar do tempo, a infraestrutura
                                envelhece, surgem novos vazamentos, os hidrômetros perdem precisão e as irregularidades
                                aumentam.</p>
                        </div>
                        <a class="link -white" href="/o-que-fazemos/obras-programas/reducao-perdas/controle-perdas"
                            title="Saiba mais sobre o Controle de Perdas">Saiba mais sobre o Controle de Perdas</a>
                    </div>
                </div>
            </div>

            <div class="item -green-teal">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="lazy" fetchpriority="low" width="644" height="298"
                                src="/assets/images/eliminadores-ar-reduzem-conta.jpg"
                                alt="Técnico da Sabesp realizando leitura de medidor" title="Eliminadores de ar Reduzem a Conta?">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Eliminadores de ar Reduzem a Conta?</h2>

                        <div class="description">
                            <p>Diversas empresas comercializam equipamentos que prometem reduzir a conta de água,
                                eliminando ou bloqueando o ar existente na rede de abastecimento. Porém, esses
                                dispositivos não têm eficácia comprovada e podem trazer riscos à saúde pública e ao
                                abastecimento, pois além de irregulares, os equipamentos podem contaminar a água
                                distribuída ou causar bloqueio no fluxo de entrada do imóvel.</p>
                            <p>
                                A melhor forma de reduzir a conta é adotar o uso racional da água.<br>
                                Quer saber mais sobre esse assunto?
                            </p>
                        </div>
                        <a class="link -white" href="/o-que-fazemos/fornecimento-agua/distribuicao-agua/eliminadores-ar" title="Clique Aqui">Clique
                            Aqui</a>
                    </div>
                </div>
            </div>

            <div class="item -dark-blue">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="lazy" fetchpriority="low" width="644" height="298"
                                src="/assets/images/hidrometros-individuais.jpg" alt=" Prédio residencial com palmeiras na frente"
                                title="Hidrômetros Individuais">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Hidrômetros Individuais</h2>
                        <div class="description">
                            <p>A Sabesp tem um programa que capacita e certifica profissionais para a elaboração de
                                projetos e a homologação de tecnologias, equipamentos e hidrômetros individuais. É o
                                Proacqua – Programa de Qualidade e Produtividade dos Sistemas de Medição Individualizada
                                de Água.</p>
                            <p>A instalação desses equipamentos propicia a medição individualizada de água em
                                condomínios horizontais e verticais. Dessa forma, cada unidade passa a ter sua conta
                                individual da Sabesp, mais a conta da área comum.</p>
                            <p>Saiba mais sobre o Programa da Sabesp para instalação de hidrômetros individualizados.
                            </p>
                        </div>
                        <a class="link -white" href="/servicos/para-empresa#hidrometros-individuais"
                            title="Clicando Aqui">Clicando Aqui</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
