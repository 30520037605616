<main class="main">
    <meta name="description" content="Segurança Hídrica: conheça as iniciativas da Sabesp para garantir o abastecimento de água seguro e sustentável em diversas regiões.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo"
                        title="Programa de Saneamento Sustentável Inclusivo">Programa de Saneamento Sustentável Inclusivo</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/seguranca-hidrica"
                        title="Segurança Hídrica">Segurança Hídrica</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">




<h1 class='headline'>Segurança Hídrica</h1>

<p>As ações desse componente têm a finalidade de ampliar a resiliência e o nível de segurança das barragens utilizadas para o represamento de água destinada ao abastecimento público da Região Metropolitana de São Paulo.</p>

<p>Desse escopo, fazem parte o diagnóstico da situação de cada barragem, as intervenções que forem julgadas necessárias, a modernização tecnológica do sistema de monitoramento, a elaboração/ajuste de Planos de Ação de Emergência e a elevação da capacidade institucional e técnica de supervisão e controle.</p>

<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/ras_eta abv_ apos_consulta_publica.pdf">Relatório Socioambiental de Adequação e Modernização da Estação de Tratamento de Água Engenheiro Rodolfo José da Costa e Silva</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/ras_eta abv_ apos_consulta_publica.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>



        </div>
    </article>


</main>
