<main class="main">
    <meta name="description" content="Conheça as iniciativas da Sabesp para proteger e revitalizar o meio ambiente. Descubra nossos programas de despoluição, recuperação de florestas, gestão de emissões e soluções sustentáveis em saneamento.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Meio Ambiente">Meio Ambiente</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Cuidando do Meio Ambiente</h1>

            <picture class="banner petals -no-shadow">
                <img loading="eager" fetchpriority="low" width="1300" height="244" src="../../assets/images/sustentabilidade/cuidando-meio-ambiente.jpg" alt="Cuidando do Meio Ambiente" title="Cuidando do Meio Ambiente">
            </picture>

            <p>Nosso trabalho aqui na Sabesp sempre esteve ligado ao meio ambiente. É dele que coletamos a água que tratamos e distribuímos com qualidade aos nossos clientes e é para ele que devolvemos o esgoto tratado e transformado novamente em água. Neste ciclo do saneamento, estamos interligados à natureza e temos respeito pelo que ela nos fornece.</p>
            <p>Por meio de iniciativas sustentáveis difundidas em toda a empresa, nós, da Sabesp, atuamos para proteger e revitalizar o meio ambiente. Despoluição de rios, com os programas Integra Tietê e Novo Rio Pinheiros, recuperação de florestas e plantio de árvores por meio do Cinturão Verde, gestão de emissões de gases de efeito estufa, gestão ambiental das estações de tratamento de água e esgoto, promoção da segurança hídrica, combate a perdas de água e 3S - Soluções Sustentáveis em Saneamento - são algumas das inúmeras iniciativas que promovemos em busca de um futuro sustentável e inclusivo.</p>
            <p>Guiados por nossa Política Institucional de Meio Ambiente, estabelecemos claras diretrizes para uma gestão ambiental transparente, responsável e com foco no desenvolvimento ambiental e social.</p>
        </div>

        <section class="more">
            <div class="container">
                <nav class="nav">
                    <h2 class="headline">Conheça Nossos Programas</h2>
                    <ul class="items grid-programas">
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/agua-legal" title="Programa Água Legal">Programa Água Legal</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/uso-racional-agua" title="Programa de Uso Racional da Água">Programa de Uso Racional da Água</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/corrego-limpo" title="Programa Córrego Limpo">Programa Córrego Limpo</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/integra-tiete" title="Programa Integra Tietê">Programa Integra Tietê</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="/o-que-fazemos/obras-programas/reciclagem-oleo" title="Programa de Reciclagem de Óleo de Frituras">Programa de Reciclagem de Óleo</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/onda-limpa" title="Programa Onda Limpa">Programa Onda Limpa</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/reducao-perdas/controle-perdas" title="Programa de Redução de Perdas">Programa de Redução de Perdas</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/se-liga-na-rede" title="Programa Se Liga na Rede">Programa Se Liga na Rede</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    </article>

</main>
