import {AfterViewChecked, Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TinySliderInstance, tns} from "tiny-slider";

@Component({
  selector: 'app-obras-programas',
  standalone: true,
  imports: [],
  templateUrl: './obras-programas.component.html',
  styleUrl: './obras-programas.component.css'
})

export class ObrasProgramasComponent implements AfterViewChecked, OnInit{

  carousel!: TinySliderInstance;
  carouselStarted: boolean = false;
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Saneamento que Transforma");
}

  ngAfterViewChecked(): void {
    if (!this.carouselStarted) {
      this.initCarousel();
      this.carouselStarted = true;
    }
  }

  private initCarousel(): void {
    this.carousel = tns({
      container: '.carousel-slider',
      items: 1,
      autoplay: false,
      loop: true,
      controls: true,
      controlsText: ['Anterior', 'Próximo'],
      navPosition: 'bottom',
      autoplayText: ['', ''],
      autoplayButtonOutput: false,
      autoHeight: true,
      lazyload: true,
      speed: 200
    });

    this.carouselInit();

    this.carousel?.events.on('indexChanged', (info: any) => {
      const outer = document.querySelector('.tns-outer');
      outer?.setAttribute('data-navColor', info.slideItems[info.index]?.dataset.navColor);
    });

    this.carousel?.events.on('transitionEnd', () => {
      this.carousel.updateSliderHeight();
    });
  }

  carouselInit(): void {
    const info: any = this.carousel.getInfo();
    const outer = document.querySelector('.tns-outer');
    outer?.setAttribute('data-navColor', info.slideItems[info.index]?.dataset.navColor);

    const dots = document.querySelectorAll('.tns-nav [data-nav]');
    dots.forEach((dot, index) => {
      const span = document.createElement('span');
      span.textContent = (index + 1).toString();
      span.classList.add('classlist');
      dot.innerHTML = '';
      dot.appendChild(span);
    });

    const nav = document.querySelector(`#${info.container.id}-ow .tns-nav`) as HTMLElement;

    const controls = document.querySelector(`#${info.container.id}-ow .tns-controls`) as HTMLElement;
    controls?.style.setProperty('--nav-width', `${nav?.offsetWidth}px`);

    // Adiciona classes para os botões de navegação anterior e próximo
    const prev = controls.querySelector('[data-controls="prev"]');
    prev?.classList.add('slider-prev');

    const next = controls.querySelector('[data-controls="next"]');
    next?.classList.add('slider-next');
  }
}
