import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fornecedores-servicos-publicitarios',
  standalone: true,
  imports: [],
  templateUrl: './fornecedores-servicos-publicitarios.component.html',
  styleUrl: './fornecedores-servicos-publicitarios.component.css'
})
export class FornecedoresServicosPublicitariosComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Fornecedores de Serviços Publicitários");
}
}
