<main class="main">
  <meta name="description" content="Conheça o Programa Integra Tietê, uma iniciativa da Sabesp em parceria com o Governo do Estado para revitalizar o Rio Tietê. Descubra nossas ações em saneamento, controle de cheias, recuperação ambiental e gestão de resíduos, promovendo o desenvolvimento sustentável.">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li><a href="/" title="Início">Início</a></li>
       <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
        <li><a href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
        <li><a href="javascript:void(0);" title="Integra Tietê">Programa Integra Tietê</a></li>
      </ul>

      <ul class="buttons">
        <li><a class="button -home" href="/" title="Início">Início</a></li>
      </ul>
    </div>
  </nav>

  <article class="content">
    <div class="container">
      <h1 class="headline">Programa Integra Tietê</h1>

      <picture class="picture align-right">
        <img loading="eager" fetchpriority="low" width="650" height="380" src="../../assets/images/integra-tiete.jpg" alt="Trânsito na Marginal Pinheiros ao lado do Rio Tietê​" title="Integra Tietê">
      </picture>

      <p>Com seus 1.136 quilômetros de extensão, o Rio Tietê atravessa o estado de São Paulo, conectando dezenas de cidades paulistas por seu caminho. Suas águas contam a história do desenvolvimento de São Paulo e nós, da Sabesp, atuamos junto ao Governo do Estado, por meio do Programa Integra Tietê, para promover a revitalização do maior rio paulista e de seus afluentes.</p>
      <p>No eixo do saneamento, trabalhamos para realizar, até 2026, melhorias no sistema de esgotamento sanitário, com a implantação de 590 km de interceptores e coletores-tronco e 6 mil km de redes coletoras na Região Metropolitana de São Paulo. As Estações de Tratamento de Esgoto (ETE) Barueri, ABC, Parque Novo Mundo, São Miguel e Suzano serão ampliadas e modernizadas, tornando-se unidades de economia circular.</p>
      <p>O programa engloba iniciativas de curto, médio e longo prazos e, além de atuar no saneamento básico, também investe na gestão de resíduos, controle de cheias, melhorias no monitoramento da qualidade da água, recuperação de fauna e flora, implementação de parques, entre outras medidas.</p>
      <p>Como o próprio nome já diz, o Integra Tietê é fruto de uma integração entre governo, iniciativa privada e sociedade civil. Juntos, direcionamos nossos esforços em busca do desenvolvimento sustentável e da preservação dos recursos hídricos.</p>
    </div>
  </article>
</main>
