import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Sabesp';

  constructor(private metaTagService: Meta, private router: Router, private viewportScroller: ViewportScroller) { }
  ngOnInit() {
    if (!environment.production) {
      this.metaTagService.addTags([
        { name: 'robots', content: 'noindex, nofollow' },
        { name: 'googlebot', content: 'noindex' }
      ]);
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.viewportScroller.scrollToPosition([0,0]);
    });
  }
}