<head>
    <meta property="og:title" content="{{ notice?.title }}"/>
    <meta property="og:url" content="{{ currentURL }}"/>
    <meta property="og:description" content="{{ notice?.content }}"/>
</head>
<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Central de Notícias">Central de Notícias</a></li>
                <li><a class="breadcrumb-link" href="a-sabesp/central-noticias/utilidade-publica" title="Utilidade Pública">Utilidade Pública</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="{{ notice?.title }}">{{ notice?.title }}</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -back" href="a-sabesp/central-noticias/utilidade-publica" title="Voltar para Utilidade Pública">Voltar para Utilidade Pública</a>
                </li>
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">{{ notice?.title }} </h1>
            <p class="published">Publicado em <time attr.datetime="{{ notice?.date }}">{{ notice?.date | date:'dd/MM/yyyy':'UTC' }}</time></p>

            <div [innerHTML]="notice?.content | contentPipe"></div>
        </div>
    </article>

    <section class="actions">
        <div class="container">
            <div class="text">
                <h2 class="headline">Você também pode:</h2>

                <ul class="circle-buttons">
                    <li>
                        <a class="item print" href="javascript:void(0);">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="48" height="47"
                                    src="/assets/images/icon/printer2.svg" alt="Opção de imprimir conteúdo da Sabesp"
                                    title="Opção de imprimir conteúdo da Sabesp">
                            </figure>
                            <p class="caption">Imprimir Matéria</p>
                        </a>
                    </li>
                    <li>
                        <a class="item" href="javascript:void(0);">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="56" height="55"
                                    src="/assets/images/icon/share.svg" alt="Botão de compartilhar conteúdo" title="Botão de compartilhar conteúdo">
                            </figure>
                            <p class="caption">Compartilhar</p>
                        </a>

                        <nav class="share">
                            <a href="https://wa.me/?text={{ currentURL }}" target="_blank" class="whatsapp" title="Compartilhar no WhatsApp">Compartilhar
                                no WhatsApp</a>
                            <a href="https://www.facebook.com/sharer/sharer.php?u={{ currentURL }}" target="_blank" class="facebook" title="Compartilhar no Facebook">Compartilhar
                                no Facebook</a>
                            <a href="http://twitter.com/share?text={{ notice?.title }}&url={{ currentURL }}&hashtags=Sabesp,Comunicados" target="_blank" class="twitter" title="Compartilhar no Twitter">Compartilhar
                                no Twitter</a>
                            <a href="https://www.linkedin.com/sharing/share-offsite/?url={{ currentURL }}" target="_blank" class="linkedin" title="Compartilhar no LinkedIn">Compartilhar
                                no LinkedIn</a>
                        </nav>
                    </li>
                    <li>
                        <a class="item send-email" href="javascript:void(0);">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="54" height="37"
                                    src="/assets/images/icon/mail.svg" alt="Opção de receber conteúdo da Sabesp via e-mail" title="Opção de receber conteúdo da Sabesp via e-mail">
                            </figure>
                            <p class="caption">Enviar por E-mail</p>
                        </a>
                    </li>
                </ul>
            </div>

            <picture class="figure">
                <img loading="lazy" fetchpriority="low" width="592" height="405"
                    src="/assets/images/illustration/sani-mostra-3.png" alt="Sani" title="Sani">
            </picture>
        </div>
    </section>

    <section class="more">
        <div class="container">
            <div class="columns">
                <nav class="nav">
                    <h2 class="headline">Comunicados Relacionados</h2>

                    <ul class="items">
                        <li class="item" *ngFor="let n of notice?.related">
                            <a class="title" href="a-sabesp/central-noticias/utilidade-publica/{{n.id}}">{{ n.title }}</a>
                            <a class="date" href="a-sabesp/central-noticias/utilidade-publica/{{n.id}}"><time attr.datetime="{{ notice?.date }}">{{ notice?.date | date:'dd/MM/yyyy':'UTC' }}</time></a>
                        </li>
                    </ul>
                </nav>

                <nav class="nav">
                    <h2 class="headline">Comunicados mais Acessados</h2>

                    <ul class="items">
                        <li class="item" *ngFor="let r of mostViewed">
                            <a class="title" href="a-sabesp/central-noticias/utilidade-publica/{{r.id}}">{{ r.title }}</a>
                            <a class="date" href="a-sabesp/central-noticias/utilidade-publica/{{r.id}}"><time attr.datetime="{{ r.date }}">{{ r.date | date:'dd/MM/yyyy':'UTC' }}</time></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</main>
