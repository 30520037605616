import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-para-fornecedores',
  standalone: true,
  imports: [],
  templateUrl: './para-fornecedores.component.html',
  styleUrl: './para-fornecedores.component.css'
})
export class ParaFornecedoresComponent {
  constructor(
    private title: Title
  ) {
    this.title.setTitle('Sabesp | Para Fornecedores');
  }
}
