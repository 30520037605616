<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/sabesp-e-esg" title="Sabesp e ESG">Sabesp e ESG</a></li>
                <li><a href="/sustentabilidade/transparencia/sabesp-e-esg/estatuto-social" title="Estatuto Social">Estatuto Social</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <h1 class='headline'>Estatuto Social</h1>

<p>O estatuto social é o documento que define o objeto, funcionamento e estrutura da Empresa.</p>

<p>A sociedade por ações denominada Companhia de Saneamento Básico do Estado de São Paulo - Sabesp é parte integrante da administração indireta do Estado de São Paulo, regendo-se pelo presente estatuto, pela Lei Federal nº 6.404/76 e demais disposições legais. </p>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/2d21bfc4-5095-4c97-ab29-2b10432c03fc?origin=1"> Consulte o documento completo (PDF)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/2d21bfc4-5095-4c97-ab29-2b10432c03fc?origin=1"><span>Acessar</span></a></td></tr>
</tbody></table></div>


        </div>
    </article>


</main>