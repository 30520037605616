import { Component } from '@angular/core';

@Component({
  selector: 'app-codigo-conduta-integridade',
  standalone: true,
  imports: [],
  templateUrl: './codigo-conduta-integridade.component.html',
  styleUrl: './codigo-conduta-integridade.component.css'
})
export class CodigoCondutaIntegridadeComponent {

}
