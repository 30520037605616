<main class="main">
  <meta name="description" content="Relatórios de Monitoramento ETE São Pedro: confira dados sobre o tratamento de esgotos e a gestão ambiental na Estação São Pedro.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro"
                        title="Monitoramento Águas de São Pedro">Relatórios de monitoramento da Estação de Tratamento de Esgotos de São Pedro
                      </a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


<h1 class='headline'>Relatórios de monitoramento da Estação de Tratamento de Esgotos de São Pedro
</h1>

<p>A Estação de Tratamento de Esgotos de São Pedro trata 25 litros por segundo. O processo beneficia a qualidade da água do Ribeirão Araquá e do Rio Piracicaba. </p>

<p>A Sabesp divulga os resultados dos monitoramentos realizados na instalação. Confira a seguir:</p>

<br><br><h2>1º SEMESTRE 2024</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_afluente.pdf">Resultado afluente abril 2024 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_afluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_afluente.pdf">Resultado afluente maio 2024 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_afluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_efluente.pdf">Resultado efluente abril 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_efluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_efluente.pdf">Resultado efluente maio 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_efluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_montante.pdf">Resultado montante abril 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_montante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_montante.pdf">Resultado montante maio 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_montante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_jusante.pdf">Resultado jusante abril 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_abr24_jusante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_jusante.pdf">Resultado jusante maio 2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="site/uploads/file/asabesp_doctos/aguas_sao_pedro_mai24_jusante.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>

<br><br><h2>2º SEMESTRE 2023</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_afluente.pdf">Resultado afluente novembro 2023 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_afluente.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_efluente.pdf">Resultado efluente novembro 2023 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_efluente.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_montante.pdf">Resultado montante novembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_montante.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_jusante.pdf">Resultado jusante novembro de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro_nov23_jusante.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>



<br><br><h2>1º SEMESTRE 2023</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente_jun_23.pdf">Resultado afluente junho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente_jun_23.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente_jun_23.pdf">Resultado efluente junho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente_jun_23.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_jun_23.pdf">Resultado montante junho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_jun_23.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_jun_23.pdf">Resultado jusante junho de 2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_jun_23.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>2º SEMESTRE 2022</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente_nov22.pdf">Resultado afluente novembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente_nov22.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente_nov22.pdf">Resultado efluente novembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente_nov22.pdf"><span>Acessar</span></a></td></tr>





<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_nov22.pdf">Resultado montante novembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_nov22.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_nov22.pdf">Resultado jusante  novembro de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_nov22.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>1º SEMESTRE 2022</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente_mai22.pdf">Resultado afluente maio de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente_mai22.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente_mai22.pdf">Resultado efluente maio de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente_mai22.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_mai22.pdf">Resultado montante maio de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_mai22.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_mai22.pdf">Resultado jusante maio de 2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_mai22.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>2º SEMESTRE 2021</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-001 08_08_21.pdf">Resultado afluente agosto de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-001 08_08_21.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-002 08_08_21.pdf">Resultado efluente agosto de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-002 08_08_21.pdf"><span>Acessar</span></a></td></tr>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-002 08_08b_21.pdf">Resultado efluente 2 agosto de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-002 08_08b_21.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-003 08_08_21.pdf">Resultado montante agosto de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-003 08_08_21.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-004 08_08_21.pdf">Resultado jusante agosto de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-004 08_08_21.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>1º SEMESTRE 2021</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_afluente_maio21.pdf">Resultado afluente maio de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_afluente_maio21.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_efluente_maio21.pdf">Resultado efluente maio de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_efluente_maio21.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_efluente_junho21.pdf">Resultado efluente junho de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_efluente_junho21.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_montante_maio21.pdf">Resultado montante maio de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_montante_maio21.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_montante_junho21.pdf">Resultado montante junho de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_montante_junho21.pdf"><span>Acessar</span></a></td></tr>



<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_jusante_maio21.pdf">Resultado jusante maio de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_jusante_maio21.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_jusante_junho21.pdf">Resultado jusante junho de 2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/resultado_jusante_junho21.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>



<br><br><h2>2° SEMESTRE 2020</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/afluente_setembro20.pdf">Resultado afluente setembro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/afluente_setembro20.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_setembro20.pdf">Resultado efluente setembro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_setembro20.pdf"><span>Acessar</span></a></td></tr>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante_setembro20.pdf">Resultado jusante setembro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante_setembro20.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante_setembro20.pdf">Resultado montante setembro 2020 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante_setembro20.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2> 1º SEMESTRE 2020</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/afluente_maio20.pdf"> Resultado afluente maio 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/afluente_maio20.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_termo_jan20.pdf">Resultado efluente janeiro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_termo_jan20.pdf"><span>Acessar</span></a></td></tr>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_maio20.pdf">Resultado efluente 2 maio 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_maio20.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante_janeiro20.pdf">Resultado jusante janeiro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante_janeiro20.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante_janeiro20.pdf">Resultado montante janeiro 2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante_janeiro20.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>2º SEMESTRE 2019</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente.pdf ">Resultado efluente agosto de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente.pdf "><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_novembro19.pdf">Resultado efluente 2 setembro de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_novembro19.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_duplicata_novembro19.pdf">Resultado efluente 3 setembro de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_duplicata_novembro19.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente.pdf">Resultado afluente agosto de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_afluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante.pdf">Resultado jusante agosto de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante.pdf">Resultado montante agosto de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>1º SEMESTRE 2019</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_102_1sem2019.pdf"> Resultado efluente fevereiro de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente_102_1sem2019.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante_1sem2019.pdf">Resultado jusante fevereiro de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante_1sem2019.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante_1sem2019.pdf">Resultado montante fevereiro de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante_1sem2019.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/od_efluente.pdf">Resultado efluente 2 março de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/od_efluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/od_jusante.pdf">Resultado jusante 2 março de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/od_jusante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/od_montante.pdf">Resultado montante 2 março de 2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/od_montante.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>2º SEMESTRE 2018</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/afluente 2 sem 2018.pdf">Resultado afluente julho de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/afluente 2 sem 2018.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente 2 sem 2018.pdf">Resultado efluente julho de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/efluente 2 sem 2018.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante 2 sem 2018.pdf">Resultado jusante julho de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante 2 sem 2018.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante 2 sem 2018_od.pdf">Resultado jusante 2 julho de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/jusante 2 sem 2018_od.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante 2 sem 2018.pdf">Resultado montante julho de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante 2 sem 2018.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante 2 sem 2018_od.pdf">Resultado montante 2 julho de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/montante 2 sem 2018_od.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>1° SEMESTRE 2018</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente 1 2018 v_2.pdf"> Resultado afluente abril de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_efluente 1 2018 v_2.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_parcial 1 2018.pdf">Resultado efluente abril de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_parcial 1 2018.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_parcial 1 2018.pdf">Resultado jusante abril de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_parcial 1 2018.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_parcial 1_2  2018.pdf">Resultado jusante 2 maio de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_jusante_parcial 1_2  2018.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_parcial%201%202018.pdf">Resultado montante abril de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_parcial%201%202018.pdf
  "><span>Acessar</span></a></td></tr>


<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_parcial%201_%202%202018.pdf">Resultado montante 2 maio de 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/aguas_sao_pedro_montante_parcial%201_%202%202018.pdf
  "><span>Acessar</span></a></td></tr>
</tbody></table></div>



<br><br><h2>2º SEMESTRE 2017</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_afluente.pdf">Resultado afluente agosto  de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_afluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_efluente.pdf">Resultado efluente agosto de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_efluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_jusante.pdf">Resultado jusante agosto de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_jusante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_jusante_amoniacal.pdf">Resultado jusante (nitrogênio amoniacal) setembro de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_jusante_amoniacal.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_montante.pdf">Resultado montante agosto de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_montante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_mon_amo.pdf">Resultado montante (nitrogênio amoniacal) setembro de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/asp_mon_amo.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>1° SEMESTRE 2017</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-001 23_03_17.pdf">Resultado afluente março de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-001 23_03_17.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-002 23_03_17 og.pdf">Resultado efluente março de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-002 23_03_17 og.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-004 23_03_17.pdf">Resultado jusante março de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-004 23_03_17.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-003%2023_03_17.pdf">Resultado montante março de 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-003%2023_03_17.pdf
  "><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>2° SEMESTRE 2016 </h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/sao_pedro_afluente_nov2016.pdf
  ">Resultado afluente agosto de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/sao_pedro_afluente_nov2016.pdf
  "><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-004 23_03_17.pdf">Resultado efluente agosto de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/102-efl-004 23_03_17.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/saopedro_jusante.pdf">Resultado jusante agosto de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/saopedro_jusante.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/saopedro_montante.pdf">Resultado montante agosto de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/saopedro_montante.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>1° SEMESTRE 2016</h2>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/saopedro_afluente.pdf">Resultado afluente maio de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/saopedro_afluente.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/saopedro_efluente_maio2016.pdf">Resultado efluente maio de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/saopedro_efluente_maio2016.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/sao_pedro_jusante_nov2016.pdf">Resultado jusante maio de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/sao_pedro_jusante_nov2016.pdf"><span>Acessar</span></a></td></tr>

<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/sao_pedro_montante_nov2016.pdf">Resultado montante maio de 2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/aguas_sao_pedro/sao_pedro_montante_nov2016.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>


        </div>
    </article>


</main>
