import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-subprodutos-esgotos',
  standalone: true,
  imports: [],
  templateUrl: './subprodutos-esgotos.component.html',
  styleUrls: ['/src/assets/css/internal.css', '/src/assets/css/components.css', './subprodutos-esgotos.component.css']
})
export class SubprodutosEsgotosComponent {
  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Subprodutos de Esgotos");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Conheça os subprodutos do tratamento de esgotos gerenciados pela Sabesp, incluindo a água de reúso para indústrias e urbanas, produção de biogás e lodo para geração de energia. Descubra como transformamos resíduos em recursos valiosos e sustentáveis. '
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, subprodutos de esgoto, água de reúso, biogás, lodo, tratamento de esgotos, sustentabilidade, economia circular, recursos hídricos, energia limpa. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Subprodutos de Esgotos'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Subprodutos de Esgotos'
    });
  }
}
