<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Busca">Busca</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <section class="content">
        <div class="container petals">
            <h1 class="headline">Resultados encontrados: {{results.totalItems}}</h1>

            <div class="listing">
                <div class="item" *ngFor="let r of results.items">
                    <h2 class="title"><a href="{{link(r.kind, r.id)}}">{{ r.title }}</a></h2>
                    <p class="published"><a href="{{link(r.kind, r.id)}}" tabindex="-1"><time attr.datetime="{{ r.date }}">{{ r.date | date:'dd/MM/yyyy':'UTC' }}</time></a></p>
                    <p class="description"><a href="{{link(r.kind, r.id)}}" tabindex="-1">{{ r.summary }}</a></p>
                </div>
            </div>

            <app-pagination
                [totalItems]="results.totalItems"
                [itemsPerPage]="results.itemsPerPage"
                [totalPages]="results.totalPages"
                (pageChange)="search($event)"></app-pagination>
        </div>
    </section>
</main>
