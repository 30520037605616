import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorios-atas-pesquisas',
  standalone: true,
  imports: [],
  templateUrl: './relatorios-atas-pesquisas.component.html',
  styleUrl: './relatorios-atas-pesquisas.component.css'
})
export class RelatoriosAtasPesquisasComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Relatórios, Atas e Pesquisas");
}
}
