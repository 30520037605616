import { Component, ViewEncapsulation } from '@angular/core';
import { News } from '../news';
import { NewsService } from '../news.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NoticiaComponent {
  news: News | undefined;
  mostViewed: News[] = [];
  currentURL: string = '';

  constructor(private route: ActivatedRoute, private newsService: NewsService) { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'instant' })
    this.getNews();
    this.getMostViewedNews();
    this.getCurrentURL();
  }

  getCurrentURL(): void {
    this.currentURL = window.location.href;
    console.log(this.currentURL);
  }

  getNews(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.newsService.getNewsById(id ? id : '')
      .subscribe(news => this.news = news);
  }

  getMostViewedNews(): void {
    this.newsService.getMostViewedNews()
      .subscribe(news => this.mostViewed = news);
  }
}
