<main class="main">
    <meta name="description" content="Consulta Pública Virtual: participe das discussões sobre os projetos de saneamento sustentável da Sabesp e contribua para a melhoria dos serviços.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a class="breadcrumb-link" href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a class="breadcrumb-link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo" title="Programa de Saneamento Sustentável Inclusivo">Programa de Saneamento Sustentável Inclusivo</a></li>
                <li><a class="breadcrumb-link" href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/consulta-publica-virtual" title="Consulta Pública Virtual">Consulta Pública Virtual</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            <h1 class='headline'>Consulta Pública Virtual</h1>
            <p>Consulta Pública é um meio utilizado pela administração pública para obter informações, opiniões e críticas da sociedade a respeito de algum tema. </p>

            <p>É também um mecanismo de participação social, neste momento não presencial, com período determinado para encerrar, para que todos os cidadãos possam se inteirar de questões de interesse coletivo, ampliar o debate sobre o assunto e nortear as decisões sobre formulação e definição de políticas públicas.</p>

            <br><br><h2>COMO PODEMOS CONTRIBUIR?</h2>
            <p>Toda e qualquer contribuição feita no link da Consulta Pública vai para um relatório de Avaliação Socioambiental e será analisada. Ou seja, participar é a chance de fazer com que as decisões tomadas considerem as aplicações práticas em nossas vidas. Logo precisamos estar sempre atentos e não esquecer que #JuntosSomosMaisFortes!</p>

            <br><br><h2>CONSULTA PÚBLICA </h2>
            <p>No momento não há consultas disponíveis    </p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/consulta_publica/ses embu 2 plano de reassentamento_final 22-05-24.pdf">Plano de Reassentamento e Aquisição do Município de Embu das Artes - Etapa 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/consulta_publica/ses embu 2 plano de reassentamento_final 22-05-24.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/consulta_publica/ras embu 2_final_completo.pdf">Relatório Socioambiental Obras de Esgoto de Embu das Artes - Etapa 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/consulta_publica/ras embu 2_final_completo.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

        </div>
    </article>


</main>
