import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-consulta-publica-virtual',
  standalone: true,
  imports: [],
  templateUrl: './consulta-publica-virtual.component.html',
  styleUrl: './consulta-publica-virtual.component.css'
})
export class ConsultaPublicaVirtualComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Consulta Pública Virtual");
}
}
