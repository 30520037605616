<main class="main">
    <meta name="description" content="Selo Ouro PBGHG: Sabesp é reconhecida por seu inventário de emissões de GEE, com dados públicos de alta qualidade.">
    <nav class="breadcrumbs">
        <div class="container">
        <ul class="menu">
            <li><a href="/" title="Início">Início</a></li>
            <li><a title="Sustentabilidade ">Sustentabilidade</a></li>
            <li><a title="Documentos e Relatórios">Documentos e Relatórios</a></li>
            <li><a title="Integra Tietê">Inventário de Emissões de Gases de Efeito Estufa</a></li>
        </ul>

        <ul class="buttons">
            <li><a class="button -home" href="/" title="Início">Início</a></li>
        </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Inventário de Emissões de Gases de Efeito Estufa  </h1>

            <p>A Sabesp recebeu o Selo Ouro do Programa Brasileiro GHG Protocol (PBGHG) pelo seu inventário de emissões de gases de efeito estufa (GEE). Essa distinção é concedida a inventários completos e avaliados por um organismo independente credenciado pelo Inmetro. O Selo Ouro do PBGHG, apoiado pelo WRI Brasil (World Resources Institute) e pelo Conselho Empresarial Mundial para o Desenvolvimento Sustentável (WBCSD), certifica que o inventário corporativo da Sabesp atinge o mais alto nível de qualificação e contribui com dados de emissões públicos e de qualidade. Desde 2008, o PBGHG adapta o método GHG Protocol ao contexto brasileiro, oferecendo instrumentos e padrões de qualidade internacional para contabilização das emissões e publicação dos inventários.
            </p>

            <p>
                <a href="https://registropublicodeemissoes.fgv.br/estatistica/estatistica-participantes/4596" target="_blank" rel="noopener noreferrer">Acesse o Inventário de Emissões de Gases de Efeito Estufa de 2022</a>
            </p>
        </div>
    </article>
</main>