import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { NoticiaComponent } from './noticia/noticia.component';
import { MinhaCidadeComponent } from './minha-cidade/minha-cidade.component';
import { CanaisAtendimentoComponent } from './canais-atendimento/canais-atendimento.component';
import { QualidadeAguaComponent } from './qualidade-agua/qualidade-agua.component';
import { ReleaseComponent } from './release/release.component';
import { ReleasesComponent } from './releases/releases.component';
import { TemplateCarrosselComponent } from './template-carrossel/template-carrossel.component';
import { LinhaTempoComponent } from './linha-tempo/linha-tempo.component';
import { ParaVoceComponent } from "./para-voce/para-voce.component";
import { ParaFornecedoresComponent } from "./para-fornecedores/para-fornecedores.component";
import { PerguntasFrequentesComponent } from './perguntas-frequentes/perguntas-frequentes.component';
import { ParaEmpresaComponent } from "./para-empresa/para-empresa.component";
import { FolhetosComponent } from './folhetos/folhetos.component';
import { CursoPesquisaVazamentosComponent } from './curso-pesquisa-vazamentos/curso-pesquisa-vazamentos.component';
import { DicasEconomiaAguaComponent } from './dicas-economia-agua/dicas-economia-agua.component';
import { ControlePerdasComponent } from './controle-perdas/controle-perdas.component';
import { VisiteSabespComponent } from './visite-sabesp/visite-sabesp.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { MananciaisComponent } from './mananciais/mananciais.component';
import { OuvidoriaComponent } from './ouvidoria/ouvidoria.component';
import { TemplateGeralInternoComponent } from "./template-geral-interno/template-geral-interno.component";
import { ContatoImprensaComponent } from './contato-imprensa/contato-imprensa.component';
import { CaptacaoAguaComponent } from './captacao-agua/captacao-agua.component';
import { QualidadeAgua2Component } from './qualidade-agua-2/qualidade-agua-2.component';
import { DistribuicaoAguaComponent } from './distribuicao-agua/distribuicao-agua.component';
import { CuidandoDoSocialComponent } from "./cuidando-do-social/cuidando-do-social.component";
import { CarreirasComponent } from "./carreiras/carreiras.component";
import { CuidandoDoMeioAmbienteComponent } from "./cuidando-do-meio-ambiente/cuidando-do-meio-ambiente.component";
import { EliminadoresArComponent } from "./eliminadores-ar/eliminadores-ar.component";
import { GestaoDemandaComponent } from "./gestao-demanda/gestao-demanda.component";
import { InformacoesCidadaoComponent } from "./informacoes-cidadao/informacoes-cidadao.component";
import { TratamentoAguaComponent } from "./tratamento-agua/tratamento-agua.component";
import { BiogasComponent } from "./biogas/biogas.component";
import { JornadaEsgComponent } from "./jornada-esg/jornada-esg.component";
import { SistemaOxigenacaoComponent } from "./sistema-oxigenacao/sistema-oxigenacao.component";
import { InovacaoTecnologiaComponent } from "./inovacao-tecnologia/inovacao-tecnologia.component";
import { FornecimentoAguaComponent } from "./fornecimento-agua/fornecimento-agua.component";
import { UsinasFotovoltaicasComponent } from "./usinas-fotovoltaicas/usinas-fotovoltaicas.component";
import { OndaLimpaComponent } from './onda-limpa/onda-limpa.component';
import { UsoRacionalAguaComponent } from './uso-racional-agua/uso-racional-agua.component';
import { FortalecendoAGovernancaComponent } from './fortalecendo-a-governanca/fortalecendo-a-governanca.component';
import { TiposDeTratamentoComponent } from './tipos-de-tratamento/tipos-de-tratamento.component';
import { ReciclagemOleoComponent } from './reciclagem-oleo/reciclagem-oleo.component';
import { BuscaGeralComponent } from './busca-geral/busca-geral.component';
import { CorregoLimpoComponent } from './corrego-limpo/corrego-limpo.component';
import { ComunicadoComponent } from './comunicado/comunicado.component';
import { ComunicadosComponent } from './comunicados/comunicados.component';
import { SubprodutosEsgotosComponent } from './subprodutos-esgotos/subprodutos-esgotos/subprodutos-esgotos.component';
import { IntegraTieteComponent } from './integra-tiete/integra-tiete/integra-tiete.component';
import { GovernancaCorporativaComponent } from './governanca-corporativa/governanca-corporativa/governanca-corporativa.component';
import { ListaEnsaiosComponent } from './lista-ensaios/lista-ensaios/lista-ensaios.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { AguaLegalComponent } from './agua-legal/agua-legal.component';
import { SeLigaNaRedeComponent } from './se-liga-na-rede/se-liga-na-rede.component';
import { SolucoesEsgotosComponent } from './solucoes-esgotos/solucoes-esgotos.component';
import { ColetaEsgotosComponent } from './coleta-esgotos/coleta-esgotos.component';
import { ObrasProgramasComponent } from './obras-programas/obras-programas.component'
import { BaciaRioItapanhauComponent } from './bacia-rio-itapanhau/bacia-rio-itapanhau.component';
import { ConsultaPublicaVirtualComponent } from './consulta-publica-virtual/consulta-publica-virtual.component';
import { FornecedoresServicosPublicitariosAnosAnterioresComponent } from './fornecedores-servicos-publicitarios-anos-anteriores/fornecedores-servicos-publicitarios-anos-anteriores.component';
import { FornecedoresServicosPublicitariosComponent } from './fornecedores-servicos-publicitarios/fornecedores-servicos-publicitarios.component';
import { InformacoesOperacionaisComponent } from './informacoes-operacionais/informacoes-operacionais.component';
import { RelatoriosMonitoramentoEstacaoTratamentoEsgotosToninhasComponent } from './relatorios-monitoramento-estacao-tratamento-esgotos-toninhas/relatorios-monitoramento-estacao-tratamento-esgotos-toninhas.component';
import { RelatoriosMonitoramentoEstacaoTratamentoEsgotosBaleiaComponent } from './relatorios-monitoramento-estacao-tratamento-esgotos-baleia/relatorios-monitoramento-estacao-tratamento-esgotos-baleia.component';
import { RelatoriosMonitoramentoEstacaoTratamentoEsgotosSaoPedroComponent } from './relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro/relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro.component';
import { ObrasEsgotosComponent } from './obras-esgotos/obras-esgotos.component';
import { ParticipacoesSocietariasComponent } from './participacoes-societarias/participacoes-societarias.component';
import { ProgramaSaneamentoSustentavelInclusivoComponent } from './programa-saneamento-sustentavel-inclusivo/programa-saneamento-sustentavel-inclusivo.component';
import { QualidadeAguaDistribuidaComponent } from './qualidade-agua-distribuida/qualidade-agua-distribuida.component';
import { RegistrosFaltaDaguaComponent } from './registros-falta-dagua/registros-falta-dagua.component';
import { RelatoriosAtasPesquisasAnosAnterioresComponent } from './relatorios-atas-pesquisas-anos-anteriores/relatorios-atas-pesquisas-anos-anteriores.component';
import { RelatoriosAtasPesquisasComponent } from './relatorios-atas-pesquisas/relatorios-atas-pesquisas.component';
import { SegurancaHidricaComponent } from './seguranca-hidrica/seguranca-hidrica.component';
import { SubstituicaoRedesSetorizacaoComponent } from './substituicao-redes-setorizacao/substituicao-redes-setorizacao.component';
import { TransparenciaComponent } from './transparencia/transparencia.component';
import { RelatoriosSustentabilidadeComponent } from './relatorios-sustentabilidade/relatorios-sustentabilidade.component';
import { TratamentoEsgotosComponent } from './tratamento-esgotos/tratamento-esgotos.component'
import { ProgramaAguaLegalComponent } from './programa-agua-legal/programa-agua-legal.component';
import { SabespEEsgComponent } from './sabesp-e-esg/sabesp-e-esg.component';
import { EstatutoSocialComponent } from './estatuto-social/estatuto-social.component';
import { CodigoCondutaIntegridadeComponent } from './codigo-conduta-integridade/codigo-conduta-integridade.component';
import { RelatorioMananciaisComponent } from './monitoramento-mananciais/relatorio-mananciais.component';
import { AvaliacaoMetasResultadosComponent } from './avaliacao-metas-resultados/avaliacao-metas-resultados.component';
import { AtasComiteAuditoriaComponent } from './atas-comite-auditoria/atas-comite-auditoria.component';
import { RelatorioIgualdadeSalarialComponent } from './relatorio-igualdade-salarial/relatorio-igualdade-salarial.component';
import { ManualTemplateComponent } from './manual-template/manual-template.component';
import { InventarioEmissoesGasesEfeitoEstufaComponent } from './inventario-emissoes-gases-efeito-estufa/inventario-emissoes-gases-efeito-estufa.component';
import { RelatorioAcoesVerdesB3Component } from './relatorio-acoes-verdes-b3/relatorio-acoes-verdes-b3.component';
import { FrameworkFinancasSustentaveisComponent } from './framework-financas-sustentaveis/framework-financas-sustentaveis.component';
import { RedirectMapComponent } from './redirect-map/redirect-map.component';
import { FormularioOuvidoriaComponent } from './formulario-ouvidoria/formulario-ouvidoria.component';
import { PremiosReconhecimentosComponent } from './premios-reconhecimentos/premios-reconhecimentos.component';

const routes: Routes = [
  { path: 'a-sabesp/central-noticias/utilidade-publica', component: ComunicadosComponent },
  { path: 'a-sabesp/central-noticias/utilidade-publica/:id', component: ComunicadoComponent },
  { path: 'a-sabesp/central-noticias/releases', component: ReleasesComponent },
  { path: 'a-sabesp/central-noticias/releases/:id', component: ReleaseComponent },
  { path: 'a-sabesp/central-noticias/contatos-assessoria-imprensa', component: ContatoImprensaComponent },
  { path: 'a-sabesp/central-noticias/noticias', component: NoticiasComponent },
  { path: 'a-sabesp/central-noticias/noticias/:id', component: NoticiaComponent },
  { path: 'a-sabesp/meu-municipio', component: MinhaCidadeComponent },
  { path: 'a-sabesp/meu-municipio/:city', component: MinhaCidadeComponent },
  { path: 'o-que-fazemos/fornecimento-agua/qualidade-agua/analises-anuais-qualidade-agua-distribuida', component: QualidadeAguaComponent },
  { path: 'o-que-fazemos/fornecimento-agua/qualidade-agua/analises-anuais-qualidade-agua-distribuida/:city', component: QualidadeAguaComponent },
  { path: 'fale-com-a-sabesp', component: CanaisAtendimentoComponent },
  { path: 'template-carrossel', component: TemplateCarrosselComponent },
  { path: 'a-sabesp/institucional/nossa-historia', component: LinhaTempoComponent },
  { path: 'servicos/para-voce', component: ParaVoceComponent },
  { path: 'servicos/para-fornecedores', component: ParaFornecedoresComponent },
  { path: 'fale-com-a-sabesp/perguntas-frequentes', component: PerguntasFrequentesComponent },
  { path: 'servicos/para-empresa', component: ParaEmpresaComponent },
  { path: 'servicos/folhetos', component: FolhetosComponent },
  { path: 'a-sabesp/educacao/curso-pesquisa-vazamento', component: CursoPesquisaVazamentosComponent },
  { path: 'a-sabesp/educacao/dicas-economia-agua', component: DicasEconomiaAguaComponent },
  { path: 'o-que-fazemos/obras-programas/reducao-perdas/controle-perdas', component: ControlePerdasComponent },
  { path: 'a-sabesp/educacao/visite-sabesp', component: VisiteSabespComponent },
  { path: 'a-sabesp/institucional/perfil', component: InstitucionalComponent },
  { path: 'o-que-fazemos/fornecimento-agua/captacao-agua/mananciais', component: MananciaisComponent },
  { path: 'fale-com-a-sabesp/ouvidoria', component: OuvidoriaComponent },
  { path: 'template-geral-interno', component: TemplateGeralInternoComponent },
  { path: 'o-que-fazemos/fornecimento-agua/captacao-agua', component: CaptacaoAguaComponent },
  { path: 'o-que-fazemos/fornecimento-agua/qualidade-agua/nosso-cuidado-com-a-agua', component: QualidadeAgua2Component },
  { path: 'o-que-fazemos/fornecimento-agua/distribuicao-agua/da-sabesp-para-sua-casa', component: DistribuicaoAguaComponent },
  { path: 'sustentabilidade/cuidando-do-social', component: CuidandoDoSocialComponent },
  { path: 'a-sabesp/carreiras', component: CarreirasComponent },
  { path: 'a-sabesp/inovacao-e-tecnologia/usinas-fotovoltaicas', component: UsinasFotovoltaicasComponent },
  { path: 'o-que-fazemos/fornecimento-agua/distribuicao-agua/eliminadores-ar', component: EliminadoresArComponent },
  { path: 'o-que-fazemos/fornecimento-agua/distribuicao-agua/gestao-demanda', component: GestaoDemandaComponent },
  { path: 'sustentabilidade/transparencia/informacoes-ao-cidadao', component: InformacoesCidadaoComponent },
  { path: 'o-que-fazemos/fornecimento-agua/tratamento-agua', component: TratamentoAguaComponent },
  { path: 'o-que-fazemos/fornecimento-agua/recursos-hidricos', component: FornecimentoAguaComponent },
  { path: 'sustentabilidade/meio-ambiente', component: CuidandoDoMeioAmbienteComponent },
  { path: 'a-sabesp/inovacao-e-tecnologia/biogas', component: BiogasComponent },
  { path: 'a-sabesp/inovacao-e-tecnologia/sistema-de-oxigenacao', component: SistemaOxigenacaoComponent },
  { path: 'a-sabesp/inovacao-e-tecnologia/pesquisa-desenvolvimento-inovacao', component: InovacaoTecnologiaComponent },
  { path: 'sustentabilidade/sabesp-sustentavel', component: JornadaEsgComponent },
  { path: 'o-que-fazemos/obras-programas/onda-limpa', component: OndaLimpaComponent },
  { path: 'o-que-fazemos/obras-programas/uso-racional-agua', component: UsoRacionalAguaComponent },
  { path: 'o-que-fazemos/obras-programas/onda-limpa', component: OndaLimpaComponent },
  { path: 'sustentabilidade/governanca', component: FortalecendoAGovernancaComponent },
  { path: 'sustentabilidade/premios-reconhecimentos', component: PremiosReconhecimentosComponent },
  { path: 'busca', component: BuscaGeralComponent },
  { path: 'o-que-fazemos/obras-programas/corrego-limpo', component: CorregoLimpoComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/tipos-tratamento', component: TiposDeTratamentoComponent },
  { path: 'o-que-fazemos/obras-programas/reciclagem-oleo', component: ReciclagemOleoComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos', component: SubprodutosEsgotosComponent },
  { path: 'o-que-fazemos/obras-programas/integra-tiete', component: IntegraTieteComponent },
  { path: 'sustentabilidade/governanca/documentos-governanca', component: GovernancaCorporativaComponent },
  { path: 'o-que-fazemos/obras-programas/agua-legal', component: AguaLegalComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/sistema-coleta-tratamento', component: SolucoesEsgotosComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/coleta-esgotos', component: ColetaEsgotosComponent },
  { path: 'o-que-fazemos/obras-programas/saneamento-que-transforma', component: ObrasProgramasComponent },
  { path: 'o-que-fazemos/obras-programas/se-liga-na-rede', component: SeLigaNaRedeComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos', component: SubprodutosEsgotosComponent },
  { path: 'o-que-fazemos/obras-programas/integra-tiete', component: IntegraTieteComponent },
  { path: 'o-que-fazemos/fornecimento-agua/qualidade-agua/lista-ensaios', component: ListaEnsaiosComponent },
  { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/sistema-coleta-tratamento', component: SolucoesEsgotosComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/coleta-esgotos', component: ColetaEsgotosComponent },
  { path: 'o-que-fazemos/obras-programas', component: ObrasProgramasComponent },
  { path: 'sustentabilidade/transparencia/bacia-rio-itapanhau', component: BaciaRioItapanhauComponent },
  { path: 'sustentabilidade/transparencia/fornecedores-servicos-publicitarios-anos-anteriores', component: FornecedoresServicosPublicitariosAnosAnterioresComponent },
  { path: 'sustentabilidade/transparencia/fornecedores-servicos-publicitarios', component: FornecedoresServicosPublicitariosComponent },
  { path: 'sustentabilidade/transparencia/relatorios-ouvidoria', component: InformacoesOperacionaisComponent },
  { path: 'o-que-fazemos/fornecimento-agua/qualidade-agua/relatorio-qualidade-agua-mananciais', component: RelatorioMananciaisComponent },
  { path: 'sustentabilidade/transparencia/participacoes-societarias', component: ParticipacoesSocietariasComponent },
  { path: 'o-que-fazemos/fornecimento-agua/qualidade-agua/analises-mensais-qualidade-agua-distribuida', component: QualidadeAguaDistribuidaComponent },
  { path: 'sustentabilidade/transparencia/registros-falta-dagua', component: RegistrosFaltaDaguaComponent },
  { path: 'sustentabilidade/transparencia/relatorios-atas-pesquisas-anos-anteriores', component: RelatoriosAtasPesquisasAnosAnterioresComponent },
  { path: 'sustentabilidade/transparencia/relatorios-atas-pesquisas', component: RelatoriosAtasPesquisasComponent },
  { path: 'sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-baleia', component: RelatoriosMonitoramentoEstacaoTratamentoEsgotosBaleiaComponent },
  { path: 'sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-toninhas', component: RelatoriosMonitoramentoEstacaoTratamentoEsgotosToninhasComponent },
  { path: 'sustentabilidade/transparencia/relatorios-monitoramento-estacao-tratamento-esgotos-sao-pedro', component: RelatoriosMonitoramentoEstacaoTratamentoEsgotosSaoPedroComponent },
  { path: 'sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo', component: ProgramaSaneamentoSustentavelInclusivoComponent },
  { path: 'sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/consulta-publica-virtual', component: ConsultaPublicaVirtualComponent },
  { path: 'sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/seguranca-hidrica', component: SegurancaHidricaComponent },
  { path: 'sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/obras-esgotos', component: ObrasEsgotosComponent },
  { path: 'sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/substituicao-redes-setorizacao', component: SubstituicaoRedesSetorizacaoComponent },
  { path: 'sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/agua-legal', component: AguaLegalComponent },
  { path: 'sustentabilidade/transparencia', component: TransparenciaComponent },
  { path: 'sustentabilidade/documentos-relatorios/relatorios_sustentabilidade', component: RelatoriosSustentabilidadeComponent },
  { path: 'o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/o-processo-tratamento', component: TratamentoEsgotosComponent },
  { path: 'sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/programa-agua-legal', component: ProgramaAguaLegalComponent },
  { path: 'sustentabilidade/transparencia/sabesp-e-esg', component: SabespEEsgComponent },
  { path: 'sustentabilidade/transparencia/sabesp-e-esg/estatuto-social', component: EstatutoSocialComponent },
  { path: 'sustentabilidade/transparencia/sabesp-e-esg/codigo-conduta-integridade', component: CodigoCondutaIntegridadeComponent },
  { path: 'sustentabilidade/transparencia/avaliacao-de-metas-e-resultados', component: AvaliacaoMetasResultadosComponent },
  { path: 'sustentabilidade/transparencia/atas-do-comite-de-auditoria', component: AtasComiteAuditoriaComponent },
  { path: 'sustentabilidade/transparencia/relatorio-igualdade-salarial', component: RelatorioIgualdadeSalarialComponent },
  { path: 'sustentabilidade/documentos-relatorios/inventario-emissoes-gases-efeito-estufa', component: InventarioEmissoesGasesEfeitoEstufaComponent },
  { path: 'sustentabilidade/documentos-relatorios/relatorio-acoes-verdes-B3', component: RelatorioAcoesVerdesB3Component },
  { path: 'sustentabilidade/documentos-relatorios/framework-financas-sustentaveis', component: FrameworkFinancasSustentaveisComponent },
  { path: 'fale-com-a-sabesp/ouvidoria/formulario', component: FormularioOuvidoriaComponent },


  { path: '', component: HomeComponent },

  { path: 'manual-template', component: ManualTemplateComponent },
  // Redirects for old URLs
  { path: '**', component: RedirectMapComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    enableTracing: false
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
