import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormularioOuvidoriaService } from '../form.service';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-formulario-ouvidoria',
  templateUrl: './formulario-ouvidoria.component.html',
  styleUrls: ['./formulario-ouvidoria.component.css']
})
export class FormularioOuvidoriaComponent {
  ouvidoriaForm: FormGroup;

  recaptchaSiteKey = environment.recaptchaSiteKey;
  anexos: FileList | [] = [];

  constructor(
    private title: Title,
    private formBuilder: FormBuilder,
    private ouvidoriaService: FormularioOuvidoriaService
  ) {
    this.ouvidoriaForm = this.formBuilder.group({
      nome: ['', Validators.required],
      protocolo: ['', Validators.required],
      pde: [''],
      cep: ['', Validators.required],
      endereco: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      cpf: [''],
      email: ['', Validators.required],
      telefoneFixo: ['', Validators.required],
      telefoneCelular: ['', Validators.required],
      assunto: ['', Validators.required],
      mensagem: [''],
      recaptchaResponse: [''],
    });
    this.title.setTitle('Sabesp | Formulário Ouvidoria')
  }

  onFileChanged(event: any) {
    this.anexos = event.target.files;
  }

  onSubmit() {
    if (this.ouvidoriaForm.invalid) return;

    const formData = new FormData();
    for (const key in this.ouvidoriaForm.value) {
      if (this.ouvidoriaForm.value.hasOwnProperty(key) && key != 'recaptchaResponse') {
        formData.append(key, this.ouvidoriaForm.value[key]);
      }
    }

    if (this.anexos) {
      for (let i = 0; i < this.anexos.length; i++) {
        formData.append('arquivos', this.anexos[i], this.anexos[i].name);
      }
    }

    formData.append('g-recaptcha-response', this.ouvidoriaForm.value.recaptchaResponse);

    this.ouvidoriaService.enviarFormulario(formData).subscribe(
      response => {
        console.log('Formulário enviado com sucesso', response);
      },
      error => {
        console.error('Erro ao enviar formulário', error);
      }
    );
  }

  fieldInvalid(fieldName: string): boolean {
    return !!this.ouvidoriaForm.get(fieldName)?.invalid;
  }
}
