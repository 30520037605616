import { Component, ViewEncapsulation, Renderer2, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'filter.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterComponent implements OnInit {

  @Input() categories: any[] = [];
  @Input() totalItems = 0;
  @Output() filterChange = new EventEmitter<object>();

  disabled = true;
  starDateValue = "";
  endDateValue = "";
  orderBy = "";
  searchTerm = "";
  filterTerm = "";
  filterCategory = "";
  pendencias: string[] = [];
  queryObject = { categoryId: <string> '', q: '', startDate: '', endDate: '', order: '' }


  ngOnInit() {
   
  }

  ngAfterViewInit() {
  }

  termImput(e: any) {
    this.filterTerm = e;
  }

  filterByCategory() {
    this.queryObject.categoryId = this.filterCategory;
    this.filterChange.emit(this.queryObject);
  }

  filterTypeSearch(data: string) {
    this.queryObject.q = data;
    this.filterChange.emit(this.queryObject);
  }

  filterByStartDate(data: any) {
    let date = new Date();
    let dateNow = date.toISOString().split('T')[0];
    if (data > dateNow) {
      let yesterday = new Date().setHours(-1);
      this.starDateValue = new Date(yesterday).toISOString().split('T')[0];
    } else {
      this.starDateValue = data;
    }

    this.disabled = false;
  }

  filterByEndDate(data: any) {
    let date = new Date();
    let dateNow = date.toISOString().split('T')[0];
    if (data > dateNow  || this.starDateValue > data) {
      this.endDateValue = dateNow;
    } else {
      this.endDateValue = data;
    }
  
    //let dateConverteStart = this.starDateValue.split("-").reverse().join("-");
    //let dateConverteEnd = this.endDateValue.split("-").reverse().join("-");

    this.queryObject.startDate = this.starDateValue;
    this.queryObject.endDate = this.endDateValue;
    this.filterChange.emit(this.queryObject);
  }

  orderByChange(data: any) {
    this.queryObject.order = data;
    this.filterChange.emit(this.queryObject);
  }


}
