import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-qualidade-agua-2',
  templateUrl: './qualidade-agua-2.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', '../../assets/css/water-quality.css'],
  encapsulation: ViewEncapsulation.None,
})
export class QualidadeAgua2Component implements OnInit {

  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle('Sabesp | Nosso Cuidado com a Água')
  }
}
