<main class="main">
    <meta name="description" content="Veja as análises anuais da qualidade da água distribuída pela Sabesp e entenda nosso compromisso com a saúde e segurança. Confira os dados!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
               <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a href="/o-que-fazemos/fornecimento-agua/qualidade-agua/" title="Qualidade da Água">Qualidade da Água</a></li>
                <li><a href="javascript:void(0);" title="Relatórios de Qualidade da Água Anuais">Relatórios de Qualidade da Água Anuais</a></li>
            </ul>


            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <section class="generic-filters">
        <div class="container">
            <form class="form" method="get" action="">
                <fieldset class="cities">
                    <div class="select">
                        <select name="cidade" id="cidade" [(ngModel)]="selectedCity" (change)="navigateToCity()">
                            <option [value]="''">Cidade</option>
                            <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
                        </select>
                        <span class="icon"></span>
                    </div>
                </fieldset>
            </form>
        </div>
    </section>

    <article class="content">
        <div class="container">
            <h1 class="headline">Relatórios Anuais de Qualidade da Água</h1>

            <p>A Sabesp controla a qualidade da água em todo o sistema de abastecimento, desde os mananciais até o cavalete de seu imóvel, por meio de coletas sistemáticas de amostras e realização de ensaios laboratoriais, em atendimento a Portaria de potabilidade da água do Ministério da Saúde. Para isso, possui 16 laboratórios de controle sanitários com várias análises acreditadas pela ISO 17.025.</p>

            <p *ngIf="selectedCity" class="lead"><strong>Exibindo resultados para: <span class="-txt-blue" id="selectedCity">{{ selectedName }}</span></strong></p>

            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                        <tr>
                            <th width="300">Cidade</th>
                            <th>Data</th>
                            <th>Nome</th>
                            <th class="txt-center" width="200">Ver/Baixar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doc of waterQuality">
                            <td>
                                <a href="{{ doc.url }}" target="_blank" rel="noopener noreferrer">
                                    {{ selectedName }}
                                </a>
                            </td>
                            <td>
                                <time attr.datetime="{{ doc.date }}">
                                    <a href="{{ doc.url }}" target="_blank" rel="noopener noreferrer">
                                        {{ doc.date | date:'dd/MM/yyyy':'UTC' }}
                                    </a>
                                </time>
                            </td>
                            <td>
                                <a href="{{ doc.url }}" target="_blank" rel="noopener noreferrer" class="-link">
                                    {{ doc.title}}
                                </a>
                            </td>
                            <td class="txt-center">
                                <a class="action-icon -download" href="{{ doc.url }}" download>
                                    <span>Baixar arquivo</span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>
</main>
