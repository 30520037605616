<main class="main">
    <meta name="description" content="Conheça o Programa Onda Limpa da Sabesp, que visa ampliar a coleta e tratamento de esgotos, melhorar a balneabilidade das praias e promover a saúde e o desenvolvimento sustentável do litoral paulista. Descubra as etapas e resultados desse importante programa de saneamento.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
                <li><a href="javascript:void(0);" title="Programa Onda Limpa">Programa Onda Limpa</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Programa Onda Limpa</h1>
            <p>Ampliar a coleta e tratamento dos esgotos, melhorar a balneabilidade das praias, contribuir para a saúde da população, além de contribuir com o desenvolvimento sustentável do litoral paulista. Estes são os objetivos de um dos principais programas de saneamento da América Latina: o Programa Onda Limpa.</p>

            <picture class="picture align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="/assets/images/o-que-fazemos/onda-limpa-praia.jpg" alt="Silhueta de família de mãos dadas na praia​" title="Programa Onda Limpa">
            </picture>
            <h2 class="subheadline">Primeira Etapa</h2>
            <p>Os trabalhos aconteceram de 2007 a 2018, com investimentos de <abbr title="Real (BRL)">R$</abbr> 2 bilhões financiados pelo <em>Japan International Cooperation Agency</em> – <abbr title="Japan International Cooperation Agency">JICA</abbr>, Banco Nacional de Desenvolvimento Econômico e Social – <abbr title="Banco Nacional de Desenvolvimento Econômico e Social">BNDES</abbr> e as seguintes obras:</p>

            <ul class="list">
                <li>1.127 quilômetros de redes coletoras;</li>
                <li>50,4 quilômetros de coletores – tronco;</li>
                <li>2 quilômetros de interceptores;</li>
                <li>89,7 quilômetros de linhas de recalque;</li>
                <li>116 estações elevatórias de esgotos;</li>
                <li>110 mil novas ligações domiciliares;</li>
                <li>7 estações de tratamento;</li>
                <li>2 estações de precondicionamento;</li>
                <li>1,5 quilômetro de emissários terrestres;</li>
                <li>4,5 quilômetros de emissários submarinos.</li>
            </ul>

            <p><strong>Resultados</strong></p>
            <ul class="list">
                <li>Aumento do índice de esgotos coletados, que era de 53% e passou para 80% em 2018;</li>
                <li>O índice de tratamento passou de 96% para 100%.</li>
            </ul>
        </div>
    </article>

    <article class="content -gray">
        <div class="container">
            <h2 class="subheadline">Segunda Etapa</h2>
            <p>A segunda etapa teve início em 2018, e deve investir mais <abbr title="Real (BRL)">R$</abbr> 2 bilhões até 2026, beneficiando todos os 9 municípios da Baixada Santista.</p>
            <p>Já foram executados os sistemas de disposição oceânica 1 e 2 de Praia Grande, com a instalação de duas estações de precondicionamento, reforma e ampliação das estações elevatórias iniciais, 6,3 km de emissários terrestres e 1,3 km de emissários submarinos e conclusão das obras de atendimento com coleta e afastamento de esgotos dos bairros Cidade da Criança e Jardim Princesa.</p>
            <p>Entregas previstas na segunda etapa:</p>
            <ul class="list">
                <li>450 quilômetros de rede de coleta de esgoto;</li>
                <li>48 mil novas ligações;</li>
                <li>3 novas estações de tratamento de esgoto;</li>
                <li>Melhorias e ampliações em 10 estações de tratamento de esgoto.</li>
            </ul>
        </div>
    </article>

    <article class="content">
        <div class="container">
            <h2 class="subheadline">Litoral Norte</h2>
            <p>Em 2019, o Programa Onda Limpa foi estendido para os quatro municípios do Litoral Norte: São Sebastião, Caraguatatuba, Ubatuba e Ilhabela.</p>
            <p>Atualmente, estão em execução as obras de ampliação da Estação de Tratamento de Esgotos Principal de Ubatuba e das estações elevatórias Cristo e Tamoios, sistema completo de esgotamento sanitário de Maresias e ampliação do sistema Barra do Una em São Sebastião e recuperação do coletor tronco Poiares em Caraguatatuba.</p>
            <p>Já foram entregues as obras do Jardim Adalgisa e Jardim Gaivotas em Caraguatatuba e Alameda Santana em São Sebastião. No Litoral Norte, o Programa Onda Limpa prevê investir cerca de <abbr title="Real (BRL)">R$</abbr> 1 bilhão.</p>
            <p>A Sabesp está em tratativas com a JICA, visando o financiamento das obras das etapas futuras do Programa, como a ampliação de 10 estações de tratamento da Baixada Santista, a ampliação da área de cobertura dos municípios de Bertioga, Itanhaém e Peruíbe. Trata-se de importante parceria para atingirmos os objetivos do Programa e universalizar os serviços na Baixada Santista.</p>
        </div>
    </article>
</main>
