<main class="main">
    <meta name="description" content="Consulta de fornecedores publicitários: acesse o histórico de contratações de anos anteriores para maior transparência nas ações da Sabesp.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/fornecedores-servicos-publicitarios" title="Fornecedores de serviços publicitários">Fornecedores de Serviços Publicitários</a></li>
                <li><a href="javascript:void(0);">Anos Anteriores</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">



            <h1 class='headline'>Serviços de Publicidade - Anos anteriores</h1>

            <br><br><h2>2020</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/dezembro_2020.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/dezembro_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/novembro_2020.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/novembro_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/outubro_2020.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/outubro_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_2020.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_2020.pdf"><span>Acessar</span></a></td></tr>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/agosto_2020.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/agosto_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/julho_2020.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/julho_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/junho_2020.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/junho_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/maio_2020.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/maio_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/abril_2020.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/abril_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/marco_2020.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/marco_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_2020.pdf">Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2020.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2020.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2019</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/dezembro_19.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/dezembro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/novembro_19.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/novembro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/outubro_19.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/outubro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_19.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/agosto_19.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/agosto_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/julho_19.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/julho_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/junho_19.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/junho_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/maio_2019.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/maio_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/abril_2019.pdf">Abril </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/abril_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/marco_2019.pdf">Março </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/marco_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_2019.pdf">Fevereiro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_2019_tre.pdf">Fevereiro - TRE </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_2019_tre.pdf"><span>Acessar</span></a></td></tr>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2019.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2019_tre.pdf">Janeiro - TRE  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2019_tre.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2019</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/dezembro_19.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/dezembro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/novembro_19.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/novembro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/outubro_19.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/outubro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_19.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/agosto_19.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/agosto_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/julho_19.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/julho_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/junho_19.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/junho_19.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/maio_2019.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/maio_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/abril_2019.pdf">Abril </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/abril_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/marco_2019.pdf">Março </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/marco_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_2019.pdf">Fevereiro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_2019_tre.pdf">Fevereiro - TRE </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_2019_tre.pdf"><span>Acessar</span></a></td></tr>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2019.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2019_tre.pdf">Janeiro - TRE </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2019_tre.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2018</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/dezembro_2018.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/dezembro_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/dezembro_2018_tre.pdf">Dezembro - TRE</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/dezembro_2018_tre.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/novembro_2018.pdf">Novembro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/novembro_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/novembro_2018_tre.pdf">Novembro - TRE</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/novembro_2018_tre.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/outubro_2018.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/outubro_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/outubro_2018_tre.pdf">Outubro - TRE</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/outubro_2018_tre.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_18.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_18.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_tre18.pdf">Setembro - TRE</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_tre18.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_agosto_2018.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_agosto_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_agosto_2018_tre.pdf">Agosto - TRE</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_agosto_2018_tre.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_julho_2018.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_julho_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_junho_2018.pdf">Junho </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_junho_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_maio_2018.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_maio_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_abril2018.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_abril2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_marco2018.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_marco2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidades_fev2018.pdf">Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidades_fev2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_jan2018.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_jan2018.pdf"><span>Acessar</span></a></td></tr>

        </tbody></table></div>


            <br><br><h2>2017</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_dezembro2017.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_dezembro2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_novembro2017.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_novembro2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_outubro2017.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_outubro2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_setembro2017.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_setembro2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_agosto2017.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_agosto2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_julho2017.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_julho2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_junho_2017.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_junho_2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_maio_2017.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_maio_2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_abril2017.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_abril2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_marco2017.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_marco2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_fevereiro2017.pdf">Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_fevereiro2017.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_janeiro2017.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_janeiro2017.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2016</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_dezembro2016.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_dezembro2016.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_novembro_2016.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_novembro_2016.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_outubro_2016.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_outubro_2016.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_setembro_2016.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_setembro_2016.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_agosto_2016.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_agosto_2016.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/publicidade_julho_2016.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/publicidade_julho_2016.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/noticias/fornecedores%20de%20servic%CC%A7os%20publicita%CC%81rios_junho%202016.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/noticias/fornecedores%20de%20servic%CC%A7os%20publicita%CC%81rios_junho%202016.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/arquivo_maio16.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/arquivo_maio16.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_abril16.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_abril16.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_marco16.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_marco16.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_fevereiro16.pdf">Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_fevereiro16.pdf"><span>Acessar</span></a></td></tr>

               <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_janeiro16.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_janeiro16.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2015</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_dezembro15.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_dezembro15.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_novembro15.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_novembro15.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_outubro_2015.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_outubro_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_setembro_2015.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_setembro_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_agosto_2015.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_agosto_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_julho_2015.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_julho_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_junho_2015.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_junho_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_maio_2015.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_maio_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_abril_2015.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_abril_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link"href="/assets/pdf/site_uploads_file_contrato_arquivo_marco_2015.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/assets/pdf/site_uploads_file_contrato_arquivo_marco_2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/assets/pdf/site_uploads_file_contrato_arquivo_fevereiro2015.pdf">Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/assets/pdf/site_uploads_file_contrato_arquivo_fevereiro2015.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/assets/pdf/site_uploads_file_contrato_arquivo_janeiro2015.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/assets/pdf/site_uploads_file_contrato_arquivo_janeiro2015.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2014</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_dezembro_2014.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_dezembro_2014.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_novembro_2014.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_novembro_2014.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_outubro_2014.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_outubro_2014.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_setembro_2014.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_setembro_2014.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/contrato_48303_12_agosto_2014.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/contrato_48303_12_agosto_2014.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/contrato_54603_13_julho_2014.pdf">Julho 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/contrato_54603_13_julho_2014.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/contrato_48303_12_julho_2014.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/contrato_48303_12_julho_2014.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2014_ct_580152007.pdf">Janeiro a Junho 3</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2014_ct_580152007.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2014_ct_546032013.pdf">Janeiro a Junho 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2014_ct_546032013.pdf"><span>Acessar</span></a></td></tr>

                <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2014 _ct_483032012.pdf">Janeiro a Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2014 _ct_483032012.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

            <br><br><h2>Veja também</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2010.pdf">2010</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2010.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2011.pdf">2011</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2011.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2012.pdf">2012</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2012.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2013.pdf">2013</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2013.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/assets/pdf/site_uploads_file_contrato_anexovi_manual_agencias_publicidade.pdf">Anexo VI da Concorrência CSS nº 48303/12 - Manual para Seleção de Agência de Publicidade Contratada</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download"  href="/assets/pdf/site_uploads_file_contrato_anexovi_manual_agencias_publicidade.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

        </div>
    </article>


</main>
