<main class="main">
    <meta name="description" content="Saiba como a Sabesp realiza a captação de água em rios, lagos, poços e represas. Entenda a importância dos mananciais e nossas iniciativas para monitorar e preservar essas fontes hídricas essenciais.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Captação de água">Captação de água</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Captação de água</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244"
                    src="./assets/images/captacao-agua.jpg" alt="Operários em estação " title="Captação de água">
            </picture>

            <p class="-space-md">A água que consumimos é retirada de rios, lagos, poços e represas criadas
                artificialmente. Esse processo de buscar água na natureza para abastecimento se chama captação.
                Conservar e proteger essas áreas é fundamental para garantir água em quantidade e qualidade adequadas.
            </p>
            <h2 class="subheadline">O que são Mananciais</h2>
            <span id="mananciais" class="anchor-offset"></span>

            <p class="-space-md">Mananciais são as fontes de água ou reservas hídricas para abastecimento público e
                podem ser superficiais, como represas, lagos e rios, ou subterrâneas, como poços.
                Na Região Metropolitana de São Paulo, a principal fonte utilizada para abastecimento público é a água
                armazenada em barragens ou represas. Já no Interior do Estado, é comum o uso da água de poços profundos,
                provenientes de mananciais subterrâneos e que também inspiram proteção e cuidados. No litoral, há
                mananciais de serra e rios imprescindíveis ao desenvolvimento das regiões.
            </p>

            <div class="g-columns supercards">
                <div class="item -gray">
                    <div class="info">
                    <span id="captacao-superficial" class="anchor-offset"></span>
                        <h3 class="subheadline">Captação Superficial</h3>
                        <p>
                            As represas têm a finalidade de acumular água no período de chuvas para ser utilizada
                            durante
                            o período seco ou de estiagem, permitindo o fornecimento de água ao longo do ano. Elas
                            também possuem um papel fundamental no controle das cheias, pois, no período de fortes
                            chuvas, retêm parte da vazão que chegaria aos rios, córregos e centros urbanos, armazenando
                            e liberando os volumes de água aos poucos, de forma controlada, evitando ou reduzindo o
                            impacto de inundações.<br><br>

                            Na Região Metropolitana de São Paulo, a água é captada predominantemente em mananciais
                            superficiais, sendo as principais represas: Águas Claras, Cabuçu, Tanque Grande, Taiaçupeba,
                            Rio Grande, Guarapiranga, Cachoeira da Graça e Cachoeira do França. Os mananciais nesta área
                            possuem capacidade de armazenar cerca de 1,9 bilhão de litros de água.<br><br>

                            <!--Para saber mais sobre os mananciais superficiais da Região Metropolitana de São Paulo, baixe
                            o app
                            <a href="https://play.google.com/store/apps/details?id=br.com.sabesp.MananciaisRMSP"
                                title="Sabesp Mananciais RMSP" target="_blank">Sabesp Mananciais RMSP</a>. -->

                            Para saber mais sobre os mananciais superficiais da Região Metropolitana de São Paulo, acesse o
                            <a class="acess-link" href="https://mananciais.sabesp.com.br/Situacao" title="Sabesp Mananciais RMSP" target="_blank">
                                Portal de Mananciais</a> que tem os índices das represas atualizados diariamente, às 9h.
                        </p>
                    </div>
                </div>

                <div class="item -gray">
                    <div class="info">
                        <span id="captacao-subterranea" class="anchor-offset"></span>
                        <h3 class="subheadline">Captação Subterrânea</h3>
                        <p>
                            Água subterrânea é aquela existente no subsolo. Através da construção de poços artesianos,
                            essa água pode ser captada e utilizada no abastecimento público.<br><br>

                            Em muitos dos municípios do interior de São Paulo atendidos pela Sabesp, o abastecimento é
                            feito por meio de poços - são mais de 1.000, atendendo cerca de 3 milhões de pessoas. Na
                            Região Metropolitana, cerca de 60 poços atendem as regiões não abastecidas pelo Sistema
                            Integrado, também conhecido como Sistema Adutor Metropolitano.<br><br>

                            A Sabesp possui o poço mais profundo em Presidente Prudente, com 1.794,97 metros de
                            profundidade e uma vazão de exploração de 110 litros por segundo. Além disso, há poços em
                            Jales, Fernandópolis e Tupã que requerem resfriamento devido às altas temperaturas de
                            captação, atingindo 58 ºC, 57 ºC e 48 ºC, respectivamente.<br><br>

                            A Sabesp desaconselha o uso de poços ilegais como fontes alternativas de abastecimento, pois
                            não possuem registro nem autorização do Governo, e frequentemente não atendem aos padrões de
                            qualidade exigidos pelo Ministério da Saúde, representando sérios riscos para a saúde
                            pública.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </article>

    <section class="content -gray">
        <span id="monitoramento" class="anchor-offset"></span>
        <div class="container">
            <h2 class="subheadline">Monitoramento dos Mananciais</h2>
            <p>
                A Sabesp monitora e preserva mais de 44 mil hectares, o equivalente a quase 200 parques do Ibirapuera.
                São 9 mil hectares de espelho d'água e 35 mil hectares de área no entorno dos mananciais que abastecem
                os sistemas produtores de água Rio Claro (Parque Estadual da Serra do Mar), Alto Cotia (Reserva do Morro
                Grande), Guarapiranga (Área de Proteção Ambiental Capivari-Monos), Alto Tietê (represa Taiaçupeba) e
                Cantareira (represas Atibainha, Cachoeira, Jacareí, Jaguari e Paiva Castro).<br><br>

                O monitoramento dos mananciais é realizado em pontos estratégicos de cada represa para acompanhar a
                qualidade da água que será captada para tratamento e posteriormente distribuída à população. Esse
                monitoramento é realizado através de sondas e coletas de amostras de água realizada por equipes da
                Sabesp com frequências semanais ou mensais. As amostras de água coletadas são analisadas em laboratório
                da própria companhia.<br><br>

                As reservas monitoradas pela Sabesp representam 1,4% do remanescente de Mata Atlântica do Estado de São
                Paulo e protegem a água consumida por mais de 14 milhões de moradores da capital e da Grande SP.<br><br>

                Você pode consultar
                <a class="acess-link" href="/o-que-fazemos/fornecimento-agua/qualidade-agua/relatorio-qualidade-agua-mananciais">aqui</a>
                os relatórios de Monitoramento da Qualidade da Água dos Mananciais da sua cidade e outros municípios
                atendidos pela Sabesp.
            </p>
        </div>
    </section>
</main>
