import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fornecedores-servicos-publicitarios-anos-anteriores',
  standalone: true,
  imports: [],
  templateUrl: './fornecedores-servicos-publicitarios-anos-anteriores.component.html',
  styleUrl: './fornecedores-servicos-publicitarios-anos-anteriores.component.css'
})
export class FornecedoresServicosPublicitariosAnosAnterioresComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Anos Anteriores");
}
}
