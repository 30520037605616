<main class="main">
    <meta name="description" content="Entenda como funciona a coleta de esgotos pela Sabesp, desde a rede interna dos imóveis até as estações de tratamento. Conheça os benefícios de estar conectado ao sistema de esgoto e as soluções para esgotos domésticos e não domésticos.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="o-que-fazemos/solucoes-para-esgotos" title="Soluções para Esgotos">Soluções para Esgotos</a></li>
                <li><a class="breadcrumb-link" href="o-que-fazemos/solucoes-para-esgotos/coleta-esgotos" title="Coleta de Esgotos">Coleta de Esgotos</a></li>
            </ul>
            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Coleta de Esgotos</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="/assets/images/o-que-fazemos/coleta-esgotos.jpg" alt="Vista aérea da Estação de Tratamento de Água da SABESP​" title="Coleta de Esgotos">
            </picture>

            <p>Depois de ser utilizada, a água passa pelos encanamentos internos do imóvel e cai em caixas de concreto e/ou de inspeção. De lá, segue até as ruas, onde encontra as redes coletoras. O esgoto chega, então, às tubulações de grande porte, conhecidas como coletores-tronco e interceptores, que o transportam até uma estação para passar por todo o processo de tratamento necessário para a retirada de poluentes.</p>
            <p>Após passar por todo o tratamento em uma das Estações de Tratamento de Esgotos da Sabesp, a água é devolvida aos cursos d'água.</p>
        </div>
    </article>

    <section class="content -gray">
      <span id="esgotos-domesticos" class="anchor-offset"></span>
        <div class="container">
            <h2 class="subheadline">Esgotos Domésticos</h2>
            <p>Assim que o seu imóvel é ligado à rede coletora de esgoto da Sabesp, você passa a contar com um importante serviço de saúde pública e de grande contribuição para a qualidade de vida da população, cujos reflexos são altamente positivos para o meio ambiente.</p>
            <p>Confira alguns benefícios de ter sua residência conectada ao sistema de coleta e tratamento de esgotos:</p>
            <ul class="list">
                <li>Prevenção de doenças contagiosas como cólera, hepatite e esquistossomose, entre outras;</li>
                <li>Eliminação de focos de mau cheiro;</li>
                <li>Contribuição para a despoluição de córregos e rios.</li>
            </ul>
        </div>
    </section>
    <section class="content">
        <div class="container">
            <div class="basiccards -space-md">
                <div class="item -center">
                    <picture class="imagemap -space-md">
                        <source media="(max-width: 767px)" width="372" height="256" srcset="/assets/images/o-que-fazemos/infografico-coleta-esgoto-mobile.png">
                        <img loading="lazy" fetchpriority="low" width="1146" height="712" alt="Mapa ilustrativo da rede de esgoto da SABESP​" title="Coleta de Esgoto" src="/assets/images/o-que-fazemos/infografico-coleta-esgoto.png">
                    </picture>

                    <p class="txt-center"><b><a class="-no-underline -download2" target="_blank" rel="noopener noreferrer" href="/assets/pdf/o-que-fazemos/solucoes-esgotos/coleta-esgotos/sabesp-coleta-esgoto.pdf" download="">Clique aqui para baixar o infográfico</a></b></p>
                </div>
            </div>

            <p>
                <b>Importante</b><br>
                Lembre-se que privada não é lixeira! O esgoto não é apropriado para o descarte de lixo. Quando você joga lixo no vaso sanitário, além de provocar entupimentos na sua própria residência e nas tubulações do sistema coletor, pode acabar causando danos nas estações de tratamento de esgotos com a quebra dos equipamentos.
            </p>
            <p>A primeira ligação de esgotos para imóveis residenciais é gratuita, desde que as instalações estejam dentro dos parâmetros estabelecidos pela Sabesp. <br>Para saber como fazer a ligação de esgotos do seu imóvel corretamente, <strong><a class="-no-underline -txt-blue" target="_blank" rel="noopener noreferrer" href="../../assets/pdf/servicos/para-voce/folhetos/sabesp-ligacao-esgoto.pdf">confira esse material</a></strong> que preparamos com todas as orientações.</p>
        </div>
    </section>
    <section class="content -gray">
      <span id="esgotos-nao-domesticos" class="anchor-offset"></span>
        <div class="container">
            <h2 class="subheadline">Esgotos Não Domésticos</h2>

            <p>Os esgotos não domésticos são aqueles provenientes de processos comerciais ou industriais e são diferentes do tipo gerado em residências, por isso precisam ter um destino adequado para evitar a contaminação de córregos, rios e represas. Se forem lançados no meio ambiente de forma inadequada, podem causar sérios danos ambientais e implicações legais.</p>
            <p>A Sabesp possui toda a infraestrutura necessária para receber os esgotos não domésticos no sistema público e encaminhá-los às Estações de Tratamento. Com isso, a indústria ou comércio passa para a Sabesp a responsabilidade pelo tratamento de seus esgotos, com redução de custos operacionais e controle da poluição ambiental.</p>
            <p>Se a região onde a empresa ou indústria está localizada possuir sistema público de esgotos e a ligação for possível, ela deverá lançar seus resíduos no sistema. Caso contrário, o gerador do esgoto deve armazená-lo em suas dependências e enviá-lo em caminhões a um Posto de Recebimento de Resíduos Líquidos da Sabesp nas cidades do interior e litoral paulista.</p>
            <p>No caso específico da Região Metropolitana de São Paulo, há um empreendimento que atua em conjunto com a Sabesp: a Attend Ambiental, que realiza o recebimento, pré-tratamento e posterior lançamento no sistema público de esgotos, atendendo todos os padrões legais.</p>
        </div>
    </section>
</main>