import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-inventario-emissoes-gases-efeito-estufa',
  templateUrl: './inventario-emissoes-gases-efeito-estufa.component.html',
  styleUrl: './inventario-emissoes-gases-efeito-estufa.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class InventarioEmissoesGasesEfeitoEstufaComponent {

  constructor(
    private title: Title
  ) {
    this.title.setTitle('Sabesp | Inventário de Emissões de Gases de Efeito Estufa');
  }

}
