<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/sabesp-e-esg" title="Sabesp e ESG">Sabesp e ESG</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            
            <h1 class='headline'>Sabesp e ESG</h1>


<p>A Sabesp é uma sociedade anônima de economia mista fundada em 1973 e atualmente é responsável pelo fornecimento de água, coleta e tratamento de esgotos de 375 municípios do Estado de São Paulo.</p>

<p>É considerada uma das maiores empresas de saneamento do mundo em população atendida. São 28,4 milhões de  abastecidas com água e 25,2 milhões de  com coleta de esgotos.</p>


<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

<tr><td><a rel="noopener noreferrer" class="-link" href="javascript:void(0);">Jornada ESG</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="javascript:void(0);"><span>Acessar</span></a></td></tr>
<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/sabesp-e-esg/estatuto-social">Estatuto Social</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/sabesp-e-esg/estatuto-social"><span>Acessar</span></a></td></tr>
<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/sabesp-e-esg/codigo-conduta-integridade">Código de Conduta e Integridade</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/sabesp-e-esg/codigo-conduta-integridade"><span>Acessar</span></a></td></tr>
</tbody></table></div>



<br><br><h2>Conformidade Sabesp</h2>

<p>Confira o vídeo - apresentação sobre as práticas e condutas da companhia</p>
<p style="text-align: center;"> </p>
<div style="position: relative; height: 0px; padding-bottom: 75%; text-align: center;"><iframe src="https://www.youtube.com/embed/Duqo9N7NGas?rel=0&showinfo=0&ecver=2" width="480" height="360" frameborder="0" allow="autoplay; encrypted-media" style="position:absolute;width:80%;height:80%;left:0" allowfullscreen=""></iframe></div>


<h2> CONSELHO DE ADMINISTRAÇÃO</h2>
<p> No site de Relação com Investidores estão disponíveis informações sobre Diretoria, Conselho de Administração, Conselho Fiscal e Comitê de Auditoria.</p>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://ri.sabesp.com.br/governanca-corporativa/conselhos-comites-do-conselho-e-diretoria/">Saiba mais</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://ri.sabesp.com.br/governanca-corporativa/conselhos-comites-do-conselho-e-diretoria/"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>GOVERNANÇA CORPORATIVA</h2>
<p>A Companhia está listada no Novo Mercado da Bolsa de Valores, Mercadorias e Futuros - BM&FBovespa, o segmento de mais alto nível de governança corporativa do Brasil, e na New York Stock Exchange – NYSE (ADR nível III), desde 2002, e portanto se sujeita às regras da Comissão de Valores Mobiliários - CVM e da BM&FBovespa, no Brasil, e às normas da Securities and Exchange Commission - SEC e da NYSE, nos Estados Unidos.</p>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://ri.sabesp.com.br/governanca-corporativa/governanca-corporativa-e-padroes-da-nyse/">Saiba mais</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://ri.sabesp.com.br/governanca-corporativa/governanca-corporativa-e-padroes-da-nyse/"><span>Acessar</span></a></td></tr>
</tbody></table></div>


<br><br><h2>POLÍTICAS </h2>

<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/meio-ambiente">Política de Meio ambiente</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/meio-ambiente"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/5d74575a-5177-7527-b5cc-23ceaccabadf?origin=1">Política de Atos e Fatos Relevantes e Negociação de Valores Mobiliários de Emissão da Sabesp</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/5d74575a-5177-7527-b5cc-23ceaccabadf?origin=1"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/3623e8c2-8f35-4bd2-a09c-af32f90f54c1?origin=1">Política de Transações com Partes Relacionadas</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/3623e8c2-8f35-4bd2-a09c-af32f90f54c1?origin=1"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/politica_qualidade.pdf">Política de Qualidade</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/politica_qualidade.pdf"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/aeddaab5-9d01-464a-ac93-31e4767f1a41?origin=1">Política de Distribuição de Dividendos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/aeddaab5-9d01-464a-ac93-31e4767f1a41?origin=1"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/02cc41ae-9ba6-4389-bc10-a168b73a8c9f?origin=1">Política de Gestão de Riscos Corporativos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/02cc41ae-9ba6-4389-bc10-a168b73a8c9f?origin=1"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/politica_institucional_conformidade.pdf">Política de Conformidade </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/politica_institucional_conformidade.pdf"><span>Acessar</span></a></td></tr>
</tbody></table></div>




<br><br><h2>RECURSOS HUMANOS</h2>



<p></p>
<div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="http://www.transparencia.sp.gov.br/home/servidor">Cargos e salários - Estas informações estão disponíveis no Portal da Transparência </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="http://www.transparencia.sp.gov.br/home/servidor"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="http://www.transparencia.sp.gov.br/home/servidor">Relação de Empregados - Confira no Portal da Transparência</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="http://www.transparencia.sp.gov.br/home/servidor"><span>Acessar</span></a></td></tr>
<tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="http://www.transparencia.sp.gov.br/home/servidor">Cargos e funções - Veja também no Portal da Transparência </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="http://www.transparencia.sp.gov.br/home/servidor"><span>Acessar</span></a></td></tr>
</tbody></table></div>


        </div>
    </article>


</main>