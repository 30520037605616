import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fortalecendo-a-governanca',
  standalone: true,
  imports: [],
  templateUrl: './fortalecendo-a-governanca.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
})
export class FortalecendoAGovernancaComponent {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle(" Sabesp | Governança");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Conheça as práticas de governança corporativa da Sabesp, pautadas pela transparência, responsabilidade e valores éticos. Saiba como adotamos políticas e procedimentos avançados para mitigar riscos, prevenir fraudes e garantir a conformidade regulatória.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, governança, governança corporativa, transparência, integridade, conformidade, prevenção de fraudes, responsabilidade, ética, CVM, SEC, desenvolvimento sustentável. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: ' Sabesp | Governança'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: ' Sabesp | Governança'
    });
  }

}
