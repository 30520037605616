import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { News } from './news';
import { Pagination } from './pagination';
import { environment } from '../environments/environment';
import { Category } from './category';
import { formatDate } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private newsUrl = environment.apiUrl + '/api/v1/news';
  private mostViewedNewsUrl = environment.apiUrl + '/api/v1/mostViewedNews';
  private newsCategoriesUrl = environment.apiUrl + '/api/v1/newsCategories';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getNews(categoryId: string, startDate: Date | null, endDate: Date | null, q: string, order: string, page: Number, perPage: Number): Observable<Pagination<News>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('perPage', perPage.toString());
    if (categoryId) {
      params = params.set('categoryId', categoryId);
    }
    if (startDate && endDate) {
      params = params.set('startDate', formatDate(startDate, 'dd-MM-yyyy', 'en-US'));
      params = params.set('endDate', formatDate(endDate, 'dd-MM-yyyy', 'en-US'));
    }
    if (q) {
      params = params.set('q', q);
    }
    if (order) {
      params = params.set('order', order);
    }
    return this.http.get<Pagination<News>>(this.newsUrl, { params })
      .pipe(
        catchError(ErrorService.handle<Pagination<News>>('getNews', { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] }))
      );
  }

  getNewsById(id: string): Observable<News> {
    const url = `${this.newsUrl}/${id}`;
    return this.http.get<News>(url).pipe(
      catchError(ErrorService.handle<News>(`getNewsById id=${id}`))
    );
  }

  getMostViewedNews(): Observable<News[]> {
    const url = `${this.mostViewedNewsUrl}`;
    return this.http.get<News[]>(url).pipe(
      catchError(ErrorService.handle<News[]>('getMostViewedNews', []))
    );
  }

  getNewsCategories(): Observable<Category[]> {
    const url = `${this.newsCategoriesUrl}`;
    return this.http.get<Category[]>(url).pipe(
      catchError(ErrorService.handle<Category[]>('getNewsCategories', []))
    );
  }
}