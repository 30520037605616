<main class="main">
    <meta name="description" content="Descubra como o sistema de oxigenação da Sabesp aprimora a qualidade da água e promove inovação no saneamento básico. Saiba mais!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/inovacao-e-tecnologia/pesquisa-desenvolvimento-inovacao" title="Inovação e Tecnologia">Inovação e Tecnologia</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/inovacao-e-tecnologia/sistema-de-oxigenacao" title="Sistema de Oxigenação">Sistema de Oxigenação</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Sistema de Oxigenação</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/a-sabesp/sistema-oxigenacao.jpg" alt="Sistema de Oxigenação" title="Sistema de Oxigenação">
            </picture>

            <p>A qualidade da água está intimamente ligada à sua oxigenação. Maiores concentrações de oxigênio dissolvido em rios e lagos são essenciais para a sobrevivência de diversas espécies de peixes, plantas aquáticas e outros organismos.</p>
            <p>A oxigenação é um processo que já ocorre naturalmente nos corpos hídricos, mas possui limitações devido à quantidade e concentração dos poluentes presentes nos córregos e rios mais degradados.</p>
            <p>Em uma iniciativa inovadora para auxiliar na aeração da água, a Sabesp trouxe para o Rio Pinheiros o Sistema de Oxigenação SDOx, tecnologia que utiliza uma solução supersaturada para transferir uma quantidade muito maior de oxigênio para a água. Ela utiliza um processo pressurizado para dissolver rápida e eficientemente o oxigênio na água, que consiste na injeção de oxigênio puro em um vaso de pressão, onde também é injetada a água a ser tratada, de forma pulverizada. A água atinge uma supersaturação com um tempo de produção da solução entre 15 a 20 segundos e depois é devolvida ao rio.</p>
            <p>Pioneira na utilização do sistema na América Latina, a Sabesp firmou um contrato por performance para garantir o cumprimento das metas de oxigenação estabelecidas e contribuir para a manutenção do equilíbrio ecológico dos ecossistemas aquáticos e a recuperação dos corpos hídricos.</p>
        </div>
    </article>
</main>
