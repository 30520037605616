<main class="main">
    <meta name="description" content="Relatório de Igualdade Salarial: veja as ações e dados da Sabesp para garantir equidade salarial entre homens e mulheres na empresa.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="javascript:void(0);"
                        title="Relatório de Igualdade Salarial">Relatório de Igualdade Salarial</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            <h1 class='headline'>Relatório de Igualdade Salarial</h1>

            <p>Conforme Decreto Federal nº 11.975/23, a Sabesp deve publicar o Relatório de Transparência Salarial e de Critérios Remuneratórios em seu site eletrônico, disponibilizado pelo Ministério do Trabalho e Emprego para cada localidade com mais de 100 empregados. Consulte o relatório no link, que apresenta as informações das 24 localidades da enquadradas nesta situação, a saber:</p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517000180_costa_carvalho.pdf"> CNPJ 43.776.517/0001-80 – Costa Carvalho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517000180_costa_carvalho.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517015454_santos.pdf"> CNPJ 43.776.517/0154-54 – Santos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517015454_santos.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517022400_pres_prudente.pdf"> CNPJ 43.776.517/0224-00 – Presidente Prudente</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517022400_pres_prudente.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031140_nicolau_gagliardi.pdf"> CNPJ 43.776.517/0311-40 – Nicolau Gagliardi</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031140_nicolau_gagliardi.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031905_ponte_pequena.pdf"> CNPJ 43.776.517/0319-05 – Ponte Pequena</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031905_ponte_pequena.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032464_sumidouro.pdf"> CNPJ 43.776.517/0324-64 – Sumidouro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032464_sumidouro.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032545_vl_romana.pdf"> CNPJ43.776.517/0325-45 – Vila Romana</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032545_vl_romana.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032626_smiguel_pta.pdf"> CNPJ 43.776.517/0326-26 – São Miguel Paulista</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032626_smiguel_pta.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036290_santo_amaro.pdf"> CNPJ 43.776.517/0362-90 – Santo Amaro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036290_santo_amaro.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036613_socorro.pdf"> CNPJ 43.776.517/0366-13 – Socorro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036613_socorro.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517039981_vl_leopoldina.pdf"> CNPJ 43.776.517/0399-81 – Vila Leopoldina</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517039981_vl_leopoldina.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517040645_santana.pdf"> CNPJ 43.776.517/0406-45 – Santana</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517040645_santana.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517044390_sao_jose_campos.pdf"> CNPJ 43.776.517/0443-90 – São José dos Campos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517044390_sao_jose_campos.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517060670_franca.pdf"> CNPJ 43.776.517/0606-70 – Franca</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517060670_franca.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517061995_penha.pdf"> CNPJ 43.776.517/0619-95 – Penha</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517061995_penha.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517062967_suzano.pdf"> CNPJ 43.776.517/0629-67 – Suzano</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517062967_suzano.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517064404_pq_said_murad.pdf"> CNPJ 43.776.517/0644-04 – Parque Said Murad</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517064404_pq_said_murad.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517065206_itaquera.pdf"> CNPJ 43.776.517/0652-06 – Itaquera</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517065206_itaquera.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066016_botucatu.pdf"> CNPJ 43.776.517/0660-16 – Botucatu</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066016_botucatu.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066105_carapicuiba.pdf"> CNPJ 43.776.517/0661-05 – Carapicuiba</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066105_carapicuiba.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071010_ch_sto_antonio.pdf"> CNPJ 43.776.517/0710-10 – Chácara Santo Antônio </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071010_ch_sto_antonio.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071958_sb_do_campo.pdf"> CNPJ 43.776.517/0719-58 – São Bernardo do Campo</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071958_sb_do_campo.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517079002_barueri.pdf"> CNPJ 43.776.517/0790-02 – Barueri</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517079002_barueri.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517082135_santo_amaro.pdf"> CNPJ 43.776.517/0821-35 – Santo Amaro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517082135_santo_amaro.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


        </div>
    </article>


</main>
