<main class="main">
    <meta name="description" content="Veja como a Sabesp protege e preserva a água, garantindo qualidade e sustentabilidade em todos os processos de abastecimento.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a href="javascript:void(0);" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a href="javascript:void(0);" title="Qualidade da Água">Qualidade da Água</a></li>
                <li><a href="javascript:void(0);" title="Nosso Cuidado com a Água">Nosso Cuidado com a Água</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Nosso Cuidado com a Água</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244"
                    src="/assets/images/qualidade-agua.jpg" alt="Qualidade da Água" title="Qualidade da Água">
            </picture>

            <p>O Ministério da Saúde estabelece que a água produzida e distribuída para o consumo humano deve ser
                controlada. A legislação define também a quantidade mínima, a frequência em que as amostras de água
                devem ser coletadas e os limites permitidos. <strong class="-txt-blue">A Sabesp, em atendimento às
                    exigências estabelecidas, controla a qualidade da água em todo o sistema de abastecimento, desde os
                    mananciais até o cavalete dos imóveis</strong>, por meio de coletas sistemáticas de amostras e
                realização de ensaios laboratoriais.</p>
        </div>
    </article>

    <section class="content -gray">
        <div class="container">
            <div class="g-columns -gap-right -v-center">
                <div>
                    <picture class="picture">
                        <img loading="eager" fetchpriority="high" width="650" height="350"
                            src="/assets/images/mananciais-cotia-preservacao.jpg" alt="Reservatório de água com mata ao fundo" title="Mananciais">
                    </picture>
                </div>
                <div>
                    <h2 class="subheadline">Mananciais</h2>
                    <p>O monitoramento dos mananciais é realizado em pontos estratégicos de cada represa para acompanhar
                        a qualidade da água que será captada para tratamento e posteriormente distribuída à população.
                        <b>Esse monitoramento é realizado através de sondas e coletas de amostras de água por equipes da
                            Sabesp com frequências semanais ou mensais e são analisadas em laboratório da própria
                            companhia.</b></p>
                    <p>Acesse os relatórios de <strong class="-txt-blue">Qualidade da Água dos Mananciais</strong> de
                        todos os municípios atendidos pela Sabesp.</p>
                    <a class="button acess-link" href="o-que-fazemos/fornecimento-agua/qualidade-agua/relatorio-qualidade-agua-mananciais">Acessar Relatórios</a>
                </div>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <h2 class="subheadline">Água Tratada e Distribuída</h2>

            <p><b>As represas têm a finalidade de acumular água no período de chuvas para ser utilizada durante o
                    período seco ou de estiagem, permitindo o fornecimento de água ao longo do ano.</b> Elas também
                possuem um papel fundamental no controle das cheias, pois, no período de fortes chuvas, retêm parte da
                vazão que chegaria aos rios, córregos e centros urbanos, armazenando e liberando os volumes de água aos
                poucos, de forma controlada, evitando ou reduzindo o impacto de inundações.</p>

            <div class="g-columns -gap-left -reverse">
                <div>
                    <picture class="picture">
                        <img loading="lazy" fetchpriority="low" width="650" height="287"
                            data-src="/assets/images/qualidade-agua-distribuida.jpg" alt="Qualidade da Água Distribuída"
                            title="Qualidade da Água Distribuída">
                    </picture>
                </div>
                <div>
                    <p>Acesse as análises mensais de <strong class="-txt-blue">Qualidade da Água Distribuída</strong>
                        realizadas em todos os municípios atendidos pela Sabesp.</p>
                    <a class="button acess-link" href="o-que-fazemos/fornecimento-agua/qualidade-agua/analises-mensais-qualidade-agua-distribuida">Análises Mensais</a>
                    <p>Acesse as análises anuais de <strong class="-txt-blue">Qualidade da Água Distribuída</strong>
                        realizadas em todos os municípios atendidos pela Sabesp.</p>
                    <a class="button acess-link" href="o-que-fazemos/fornecimento-agua/qualidade-agua/analises-anuais-qualidade-agua-distribuida">Análises Anuais</a>
                </div>
            </div>
        </div>
    </section>

    <section class="content -gray">
        <div class="container">
            <h2 class="subheadline">Degustação de Água</h2>

            <p>Grandes variações no cheiro e no sabor da água podem caracterizar condições anormais em mananciais,
                estações de tratamento ou sistemas de distribuição. Por esta razão, a Sabesp reforça as análises com
                mais uma técnica: a degustação da água.</p>
            <p>Depois das inspeções de rotina nos laboratórios, técnicos da Sabesp utilizam como ferramentas o olfato e
                o paladar para controlar o gosto e o odor da água. Esses especialistas são conhecidos como degustadores
                e garantem a qualidade do produto fornecido à população. Por enquanto, existem 15 analistas que atuam na
                Região Metropolitana de São Paulo. A Sabesp é a única empresa brasileira do setor que adota essa
                sofisticada tecnologia.</p>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <h2 class="subheadline">Entenda os Parâmetros Analisados</h2>

            <ul class="list -fw-medium">
                <li>
                    <strong class="-txt-blue">Cloro e cloroamoniação:</strong> o cloro é um agente bactricida,
                    adicionado durante o tratamento com o objetivo de eliminar bactérias e outros micro-organismos que
                    podem estar presentes na água. O produto entregue ao consumidor deve conter, de acordo com o
                    Ministério da Saúde, uma concentração mínima de 0,2 mg/l (miligramas por litro) de cloro
                    residual.<br>
                    Com o mesmo objetivo, algumas localidades utilizam o método de cloroamoniação no processo de
                    desinfecção da água. De acordo com a Resolução SS nº 50 de 26/04/1995 da Secretaria de Estado da
                    Saúde, a água destes sistemas deve conter um mínimo de 2,0 mg/l como cloro residual total.
                </li>
                <li><strong class="-txt-blue">Turbidez:</strong> é a medição da resistência da água à passagem de luz,
                    provocada pela presença de partículas flutuando na água. A turbidez é um parâmetro de aspecto
                    estético de aceitação ou rejeição do produto e o valor máximo permitido de turbidez na água
                    distribuída é de 5,0 NTU.</li>
                <li><strong class="-txt-blue">Cor:</strong> a cor é um dado que indica a presença de substâncias
                    dissolvidas na água. Assim como a turbidez, a cor é um parâmetro de aspecto estético de aceitação ou
                    rejeição do produto. De acordo com a Portaria, o valor máximo permissível de cor na água distribuída
                    é de 15,0 U.C.</li>
                <li><strong class="-txt-blue">pH:</strong> pH é uma medida que determina se a água é ácida ou alcalina.
                    É um parâmetro que deve ser acompanhado para melhorar os processos de tratamento e preservar as
                    tubulações contra corrosões ou entupimentos. Esse fator não traz riscos sanitários e a faixa
                    recomendada de pH na água distribuída é de 6,0 a 9,5.</li>
                <li><strong class="-txt-blue">Coliformes:</strong> grupo de bactérias que normalmente vivem no intestino
                    de animais de sangue quente. Alguns tipos podem ser encontrados também no meio ambiente. Nos
                    laboratórios da Sabesp, são realizadas análises para identificar uma possível contaminação.</li>
                <li><strong class="-txt-blue">Flúor:</strong> o flúor é um elemento químico adicionado à água de
                    abastecimento, pois auxilia na proteção dos dentes contra a cárie. O teor de flúor na água é definido
                    de acordo com o clima e a temperatura de cada região, uma vez que isso afeta o consumo médio diário
                    de água por pessoa. Para o Estado de São Paulo, o teor ideal de flúor é de 0,7 mg/l (miligramas por
                    litro), podendo variar entre 0,6 a 0,8 mg/l. A ausência temporária ou variações da substância não
                    tornam a água imprópria para consumo.</li>
            </ul>
        </div>
    </section>

    <section class="content -gray pb-0">
        <div class="highlight">
            <div class="container">
                <div class="text">
                    <div class="boxes -full">
                        <div class="item">
                            <div class="top">
                                <figure class="icon">
                                    <a href="javascript:void(0);" tabindex="-1">
                                        <img loading="lazy" fetchpriority="low" width="21" height="15"
                                            data-src="/assets/images/icon/list.svg" alt="Ícone Lista de Ensaios"
                                            title="Lista de Ensaios">
                                    </a>
                                </figure>
                                <h2 class="title"> <a href="javascript:void(0);" tabindex="-1">Lista de Ensaios</a></h2>
                            </div>
                            <p class="description">
                                <a href="javascript:void(0);" tabindex="-1">Confira a lista de ensaios acreditados para cada laboratório
                                    da Sabesp.</a>
                            </p>
                            <a class="button acess-link" href="o-que-fazemos/fornecimento-agua/qualidade-agua/lista-ensaios" title="Acessar Lista de Ensaios">Acessar Lista de Ensaios</a>
                        </div>
                    </div>
                </div>

                <picture class="figure">
                    <img loading="lazy" fetchpriority="low" width="322" height="380"
                        src="/assets/images/illustration/sani-pensando.png" alt="Mascote da Sabesp sentada e pensativa" title="Mascote da Sabesp sentada e pensativa">
                </picture>
            </div>
        </div>
    </section>
</main>
