import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ouvidoria',
  templateUrl: './ouvidoria.component.html',
  styleUrls: ['../../assets/css/internal.css', '../../assets/css/components.css', '../../assets/css/ombudsman.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OuvidoriaComponent implements OnInit {

  constructor(private title: Title, private renderer: Renderer2, private meta: Meta) {}

  ngOnInit() {

    this.title.setTitle("Sabesp | Ouvidoria ");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Acesse a Ouvidoria da Sabesp para registrar reclamações, sugestões ou elogios. Utilize nosso canal de atendimento pelo telefone 0800 055 6586 e receba suporte para dúvidas sobre abastecimento de água e outros serviços. Informe o número do protocolo registrado e veja os contatos para outros assuntos institucionais.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, ouvidoria, atendimento ao cliente, reclamações, sugestões, elogios, suporte, abastecimento de água, serviços Sabesp, 0800 055 6586, protocolo, contatos institucionais, comunicação, recursos humanos, fornecedores, LGPD, canal de denúncia.'
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Ouvidoria 0800 055 6586'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Ouvidoria 0800 055 6586'
    });

    const floatButtons = document.querySelectorAll('.chat-popup');
    floatButtons.forEach(button => {
      this.renderer.listen(button, 'click', (e) => {
        e.preventDefault();
        this.windowOpen('https://sabesp-chat.sabesp.com.br/chat-externo', 340, 752);
      });
    });
  }

  windowOpen(url: string, w: number, h: number) {
    let newW = w + 100,
        newH = h + 100,
        top = window.screen.height - h - 250,
        left = window.screen.width - w - 15;

    let newWindow = window.open(url, 'name', 'width=' + newW + ',height=' + newH + ',left=' + left + ',top=' + top);

    if (newWindow) {
      newWindow.resizeTo(newW, newH);
      newWindow.moveTo(left, top);
      newWindow.focus();
    } else {
      return
    }

    return false;
  }

  accordionToggle(e: any) {
    e.preventDefault();
    let wrapper = e.target.closest('.item');
    wrapper.classList.toggle('-open');

    let collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
  }
}
