import { Component } from '@angular/core';

@Component({
  selector: 'app-sabesp-e-esg',
  standalone: true,
  imports: [],
  templateUrl: './sabesp-e-esg.component.html',
  styleUrl: './sabesp-e-esg.component.css'
})
export class SabespEEsgComponent {

}
