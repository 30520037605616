<main class="main">
    <meta name="description" content="Participe do curso gratuito de pesquisa de vazamento oferecido pela Sabesp. Aprenda técnicas práticas para identificar vazamentos em instalações hidráulicas. Inscreva-se agora!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Educação">Educação</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Curso de Pesquisa de Vazamentos">Curso de Pesquisa de Vazamentos</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Aprenda a Identificar Vazamentos - Curso Gratuito da Sabesp</h1>

            <picture class="banner petals-2 -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="/assets/images/curso_vazamentos_1300x244_IMG_1086.jpg" alt="Instrutor explicando sistema hidráulico para grupo de pessoas" title="Aprenda a Identificar Vazamentos - Curso Gratuito da Sabesp">
            </picture>

            <p class="-space-sm"><strong class="-fw-medium">Você sabia que a Sabesp oferece gratuitamente um curso de pesquisa de vazamento?</strong></p>
            <p class="mt-0">Em salas montadas com equipamentos hidráulicos especialmente adaptados, funcionários da Sabesp ensinam aos participantes técnicas simples e objetivas para identificar vazamentos em instalações hidráulicas de imóveis (casas, apartamentos ou prédios).</p>
            <p>O curso é aberto ao público em geral e é ministrado presencialmente de segunda a sexta, nos períodos da manhã (das 8h às 12h) ou da tarde (das 13h às 17h). Também é possível fazer o curso online ao vivo, com a duração de duas horas em horário comercial.</p>

            <p class="-space-sm"><strong class="-fw-medium">Quer participar?</strong></p>
            <p class="mt-0">Envie uma mensagem com seu nome completo, telefone para contato e preferência por curso online ou presencial para <a class="-no-underline -txt-blue mailto" href="mailto:pura@sabesp.com.br"><strong>pura&#64;sabesp.com.br</strong></a>.</p>
        </div>
    </article>
</main>
