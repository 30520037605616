<head>
    <meta property="og:title" content="{{ news?.title }}"/>
    <meta property="og:url" content="{{ currentURL }}"/>
    <meta property="og:description" content="{{ news?.content }}"/>
</head>
<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a href="javascript:void(0);" title="Central de Notícias">Central de Notícias</a></li>
                <li><a href="/a-sabesp/central-noticias/noticias" title="Notícias">Notícias</a></li>
                <li><a href="javascript:void(0);" title="{{ news?.title }}">{{ news?.title }}</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -back" href="/a-sabesp/central-noticias/noticias" title="Voltar para Notícias">Voltar para Notícias</a></li>
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">{{ news?.title }}</h1>
            <p class="published">Publicado em <time attr.datetime="{{ news?.date }}">{{ news?.date | date:'dd/MM/yyyy':'UTC' }}</time></p>

            <div [innerHTML]="news?.content | contentPipe"></div>
        </div>
    </article>

    <section class="actions">
        <div class="container">
            <div class="text">
                <h2 class="headline">Você também pode:</h2>

                <ul class="circle-buttons">
                    <li>
                        <a class="item print circle-button" onclick="window.print()">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="48" height="47"
                                    src="./assets/images/icon/printer2.svg" alt="Opção de imprimir conteúdo da Sabesp"
                                    title="Opção de imprimir conteúdo da Sabesp">
                            </figure>
                            <p class="caption">Imprimir Matéria</p>
                        </a>
                    </li>
                    <li>
                        <a class="item" href="javascript:void(0);">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="56" height="55"
                                    src="./assets/images/icon/share.svg" alt="Botão de compartilhar conteúdo"
                                    title="Botão de compartilhar conteúdo">
                            </figure>
                            <p class="caption">Compartilhar</p>
                        </a>

                        <nav class="share">
                            <a href="https://wa.me/?text={{ currentURL }}" target="_blank" class="whatsapp" title="Compartilhar no WhatsApp">Compartilhar
                                no WhatsApp</a>
                            <a href="https://www.facebook.com/sharer/sharer.php?u={{ currentURL }}" target="_blank" class="facebook" title="Compartilhar no Facebook">Compartilhar
                                no Facebook</a>
                            <a href="http://twitter.com/share?text={{ news?.title }}&url={{ currentURL }}&hashtags=Sabesp,Comunicados" target="_blank" class="twitter" title="Compartilhar no Twitter">Compartilhar
                                no Twitter</a>
                            <a href="https://www.linkedin.com/sharing/share-offsite/?url={{ currentURL }}" target="_blank" class="linkedin" title="Compartilhar no LinkedIn">Compartilhar
                                no LinkedIn</a>
                        </nav>
                    </li>
                    <li>
                        <a class="item send-email" href="mailto:?subject=Título Release | Sabesp&amp;body=Lorem%20ipsum%20dolor%20sit%20amet%2C%20consectetur%20adipiscing%20elit.%20%0A%0A%20Sabesp%20inicia%20nesta%20quinta-feira%20(23%2F11)%20o%20feir%C3%A3o%20'Acertando%20Suas%20Contas%20com%20a%20Sabesp'%20%0A%0A%20https%3A%2F%2Fhotsites-foster.azurewebsites.net%2Fsabesp%2Finterna-release.html">
                            <figure class="icon">
                                <img loading="lazy" fetchpriority="low" width="54" height="37"
                                    src="./assets/images/icon/mail.svg"
                                    alt="Opção de receber conteúdo da Sabesp via e-mail"
                                    title="Opção de receber conteúdo da Sabesp via e-mail">
                            </figure>
                            <p class="caption">Enviar por E-mail</p>
                        </a>
                    </li>
                </ul>
            </div>

            <picture class="figure">
                <img loading="lazy" fetchpriority="low" width="592" height="405" src="./assets/images/illustration/sani-mostra-3.png" alt="Sani" title="Sani">
            </picture>
        </div>
    </section>

    <section class="more">
        <div class="container">
            <div class="columns">
                <nav class="nav">
                    <h2 class="headline">Notícias Relacionadas</h2>

                    <ul class="items">
                        <li class="item" *ngFor="let n of news?.related">
                            <a class="title description-link" href="/a-sabesp/central-noticias/noticias/{{n.id}}">{{ n.title }}</a>
                            <a class="date" href="/a-sabesp/central-noticias/noticias/{{n.id}}"><time attr.datetime="{{ news?.date }}">{{ news?.date | date:'dd/MM/yyyy':'UTC' }}</time></a>
                        </li>
                    </ul>
                </nav>

                <nav class="nav">
                    <h2 class="headline">Notícias mais Acessadas</h2>

                    <ul class="items">
                        <li class="item" *ngFor="let n of mostViewed">
                            <a class="title description-link" href="/a-sabesp/central-noticias/noticias/{{n.id}}">{{ n.title }}</a>
                            <a class="date" href="/a-sabesp/central-noticias/noticias/{{n.id}}"><time attr.datetime="{{ n.date }}">{{ n.date | date:'dd/MM/yyyy':'UTC' }}</time></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</main>
