import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-governanca-corporativa',
  standalone: true,
  imports: [],
  templateUrl: './governanca-corporativa.component.html',
  styleUrls: ['/src/assets/css/internal.css', '/src/assets/css/components.css']
})
export class GovernancaCorporativaComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Documentos de Governança");
}
}
