import { Component, ViewEncapsulation, Renderer2, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Timelines } from '../timelines';
import { TimelinesService } from '../timelines.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-linha-tempo',
  templateUrl: './linha-tempo.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'linha-tempo.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LinhaTempoComponent implements OnInit, OnDestroy {

  timelines: Timelines[] = [];
  unlistener: () => void;

  constructor(private timelinesService: TimelinesService, private renderer2: Renderer2, private title: Title) { this.unlistener = () => { }; }

  ngOnInit() {

    this.title.setTitle("Sabesp | Nossa História");

    this.getTimelines();
    this.unlistener = this.renderer2.listen("document", "scroll", event => {

      let documentTop = document.documentElement.scrollTop || document.body.scrollTop,
        target = document.querySelectorAll<HTMLElement>('.to-left, .to-right, .to-up, .to-down'),
        resetClass = 'to-end',
        offset = window.innerHeight * 0.3;
      target.forEach(e => {
        documentTop > e.offsetTop - offset ? e.classList.add(resetClass) : e.classList.remove(resetClass);
      });

    });

  }




  ngOnDestroy() {
    this.unlistener();
  }
  getTimelines(): void {
    this.timelinesService.getTimelines()
      .subscribe(timelines => this.timelines = timelines);
  }

}
