<main class="main">
    <meta name="description" content="Sabesp: compromisso com ESG e ODS, promovendo sustentabilidade, inclusão e excelência no saneamento.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sabesp Sustentável">Sabesp Sustentável</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Ser Sabesp é ser Sustentável</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/sustentabilidade/ser-sabesp-e-ser-sustentavel.jpg" alt="Trabalhadora da Sabesp sorrindo com EPI" title="Ser Sabesp é ser Sustentável">
            </picture>

            <p>Na Sabesp, cultivamos o propósito de transformar a vida das pessoas para contribuir com uma sociedade mais inclusiva e sustentável, atuando de maneira integrada com base nos princípios ESG (sigla em inglês para Ambiental, Social e Governança).</p>
            <p>O saneamento em si possui um forte vínculo com a sustentabilidade, mas o nosso trabalho na Sabesp vai ainda mais além. Aqui, o compromisso com o meio ambiente, o cuidado com as pessoas e as melhores práticas de governança permeiam cada área da companhia e estão inseridos no trabalho diário de nossos colaboradores.</p>
            <p>Além do ESG, nossa estratégia de atuação é alinhada a práticas de excelência do mercado e é pautada pelo atendimento aos Objetivos de Desenvolvimento Sustentável (ODS), Agenda 2030 e pelos grandes temas ambientais da atualidade.</p>
            <p>A integração da gestão ambiental na Sabesp reforça o nosso compromisso com o engajamento da força de trabalho, com a qualidade do meio ambiente e a sustentabilidade na prestação dos serviços de saneamento.</p>
            <p>Confira as ações que confirmam nosso posicionamento e que foram <a target="_blank" class="-external" href="site/uploads/file/relatorios_sustentabilidade/relatorio_sustentabilidade_2023.pdf#page=14">destaques em 2023.</a></p>
        </div>

        <section class="more">
            <div class="container">
                <nav class="nav">
                    <h2 class="headline">Veja também</h2>
    
                    <ul class="items">
                        <li class="item">
                            <a class="title" rel="noopener noreferrer" href="https://novo-site-hml.sabesp.com.br/sustentabilidade/premios-reconhecimentos" title="Prêmios e Reconhecimentos">Prêmios e Reconhecimentos</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>

        <section style="padding-top: 0px !important;" class="more">
            <div class="container">
                <nav class="nav">
                    <h2 class="headline">Conheça Nossos Programas</h2>
                    <ul class="items grid-programas">
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/agua-legal" title="Programa Água Legal">Programa Água Legal</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/uso-racional-agua" title="Programa de Uso Racional da Água">Programa de Uso Racional da Água</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/corrego-limpo" title="Programa Córrego Limpo">Programa Córrego Limpo</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/integra-tiete" title="Programa Integra Tietê">Programa Integra Tietê</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="/o-que-fazemos/obras-programas/reciclagem-oleo" title="Programa de Reciclagem de Óleo de Frituras">Programa de Reciclagem de Óleo</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/onda-limpa" title="Programa Onda Limpa">Programa Onda Limpa</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/reducao-perdas/controle-perdas" title="Programa de Redução de Perdas">Programa de Redução de Perdas</a>
                        </li>
                        <li class="item">
                            <a class="acess-link" href="o-que-fazemos/obras-programas/se-liga-na-rede" title="Programa Se Liga na Rede">Programa Se Liga na Rede</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    </article>
</main>
