<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/relatorios-atas-pesquisas"
                        title="Relatórios, atas e pesquisas">Relatórios, atas e pesquisas</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            <h1 class='headline'>Relatórios, atas e pesquisas</h1>

            <br><br><h2>CARTA ANUAL DE GOVERNANÇA CORPORATIVA</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://ri.sabesp.com.br/governanca-corporativa/carta-de-governanca/"> Carta Anual de Políticas Públicas e Governança Corporativa</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://ri.sabesp.com.br/governanca-corporativa/carta-de-governanca/"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>RELATÓRIOS DE SUSTENTABILIDADE</h2>

            <p> </p>
            <p>O Relatório de Sustentabilidade traduz  as informações e realizações da Sabesp nas áreas social, econômica e ambiental. Trata-se de uma ferramenta de prestação de contas e de diálogo da Companhia com seus públicos. </p>
            
            <p>Por meio dele, a Empresa se posiciona, comenta seus desafios e alinha expectativas. Tal posicionamento representa uma comunicação transparente e esclarecedora que se reflete em credibilidade. </p>
            
            <p>Na Sabesp, o relatório segue as diretrizes Global Reporting Iniciative (GRI), utilizando-se de um método participativo e abrangente que envolve seus públicos de relacionamento internos e externos em todos os níveis, do topo à base. </p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/documentos-relatorios/relatorios_sustentabilidade">  Veja os últimos relatórios divulgados</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/documentos-relatorios/relatorios_sustentabilidade"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            
            <br><br><h2>RELATÓRIO DA ADMINISTRAÇÃO</h2>
            
            <p>O Relatório da Administração apresenta os principais resultados alcançados pela Sabesp, bem como seus objetivos e políticas, em complemento às peças contábeis e notas explicativas contidas nas Demonstrações Financeiras.</p>
            
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://ri.sabesp.com.br/informacoes-financeiras/submetidas-a-cvm/">Consulte o site de RI para acessar as informações completas </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://ri.sabesp.com.br/informacoes-financeiras/submetidas-a-cvm/"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>20 F</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://ri.sabesp.com.br/informacoes-financeiras/submetidas-a-sec/">Consulte o site de RI para acessar as informações completas submetidas à Security anda Exchange Comission (SEC)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://ri.sabesp.com.br/informacoes-financeiras/submetidas-a-sec/"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>Relatório de Igualdade Salarial</h2>
            <p>Conforme Decreto Federal nº 11.975/23, a Sabesp deve publicar o Relatório de Transparência Salarial e de Critérios Remuneratórios em seu site eletrônico, disponibilizado pelo Ministério do Trabalho e Emprego para cada localidade com mais de 100 empregados. Consulte o relatório no link, que apresenta as informações das 24 localidades da enquadradas nesta situação, a saber:</p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517000180_costa_carvalho.pdf"> CNPJ 43.776.517/0001-80 – Costa Carvalho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517000180_costa_carvalho.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517015454_santos.pdf"> CNPJ 43.776.517/0154-54 – Santos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517015454_santos.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517022400_pres_prudente.pdf"> CNPJ 43.776.517/0224-00 – Presidente Prudente</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517022400_pres_prudente.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031140_nicolau_gagliardi.pdf"> CNPJ 43.776.517/0311-40 – Nicolau Gagliardi</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031140_nicolau_gagliardi.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031905_ponte_pequena.pdf"> CNPJ 43.776.517/0319-05 – Ponte Pequena</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517031905_ponte_pequena.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032464_sumidouro.pdf"> CNPJ 43.776.517/0324-64 – Sumidouro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032464_sumidouro.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032545_vl_romana.pdf"> CNPJ43.776.517/0325-45 – Vila Romana</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032545_vl_romana.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032626_smiguel_pta.pdf"> CNPJ 43.776.517/0326-26 – São Miguel Paulista</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517032626_smiguel_pta.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036290_santo_amaro.pdf"> CNPJ 43.776.517/0362-90 – Santo Amaro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036290_santo_amaro.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036613_socorro.pdf"> CNPJ 43.776.517/0366-13 – Socorro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517036613_socorro.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517039981_vl_leopoldina.pdf"> CNPJ 43.776.517/0399-81 – Vila Leopoldina</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517039981_vl_leopoldina.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517040645_santana.pdf"> CNPJ 43.776.517/0406-45 – Santana</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517040645_santana.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517044390_sao_jose_campos.pdf"> CNPJ 43.776.517/0443-90 – São José dos Campos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517044390_sao_jose_campos.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517060670_franca.pdf"> CNPJ 43.776.517/0606-70 – Franca</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517060670_franca.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517061995_penha.pdf"> CNPJ 43.776.517/0619-95 – Penha</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517061995_penha.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517062967_suzano.pdf"> CNPJ 43.776.517/0629-67 – Suzano</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517062967_suzano.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517064404_pq_said_murad.pdf"> CNPJ 43.776.517/0644-04 – Parque Said Murad</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517064404_pq_said_murad.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517065206_itaquera.pdf"> CNPJ 43.776.517/0652-06 – Itaquera</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517065206_itaquera.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066016_botucatu.pdf"> CNPJ 43.776.517/0660-16 – Botucatu</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066016_botucatu.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066105_carapicuiba.pdf"> CNPJ 43.776.517/0661-05 – Carapicuiba</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517066105_carapicuiba.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071010_ch_sto_antonio.pdf"> CNPJ 43.776.517/0710-10 – Chácara Santo Antônio </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071010_ch_sto_antonio.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071958_sb_do_campo.pdf"> CNPJ 43.776.517/0719-58 – São Bernardo do Campo</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517071958_sb_do_campo.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517079002_barueri.pdf"> CNPJ 43.776.517/0790-02 – Barueri</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517079002_barueri.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/relatorio_43776517082135_santo_amaro.pdf"> CNPJ 43.776.517/0821-35 – Santo Amaro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/relatorio_43776517082135_santo_amaro.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>PESQUISA DE SATISFAÇÃO</h2>
            <p>Para conhecer as necessidades específicas das áreas atendidas, a Sabesp realiza pesquisa de satisfação e divulga os resultados na página de cada município.</p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a rel="noopener noreferrer" class="-link" href="/a-sabesp/meu-municipio">Faça a pesquisa por cidade e acesse as informações</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/a-sabesp/meu-municipio"><span>Acessar</span></a></td></tr>
            </tbody></table></div>       
            
            <br><br><h2>ANÁLISE DAS METAS E RESULTADOS</h2>
            
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar24.pdf">2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar24.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar23.pdf">2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar23.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar22.pdf">2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar22.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar21.pdf">2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar21.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_marco20.pdf">2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_marco20.pdf"><span>Acessar</span></a></td></tr>
           
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar19.pdf">2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar19.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>ATAS DE ASSEMBLEIAS E REUNIÕES</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://ri.sabesp.com.br/governanca-corporativa/assembleia-e-atas/">Consulte todos os documentos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://ri.sabesp.com.br/governanca-corporativa/assembleia-e-atas/"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>ATAS DAS REUNIÕES DO COMITÊ DE AUDITORIA</h2>
            
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240508_extratoata_1024.pdf"> 460ª Reunião - 08/05/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240508_extratoata_1024.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240426_extratoata_0924.pdf"> 459ª Reunião - 26/04/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240426_extratoata_0924.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240424_extratoata_0824.pdf"> 458ª Reunião - 24/04/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240424_extratoata_0824.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240417_extratoata_0724.pdf"> 457ª Reunião - 17/04/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240417_extratoata_0724.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240320_extratoata_0624.pdf"> 456ª Reunião - 20/03/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240320_extratoata_0624.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240314_extratoata_0524.pdf"> 455ª Reunião - 14/03/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240314_extratoata_0524.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240228_extratoata_0424.pdf"> 454ª Reunião - 28/02/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240228_extratoata_0424.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240221_extratoata_0324.pdf"> 453ª Reunião - 21/02/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240221_extratoata_0324.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240131_extratoata_0224.pdf"> 452ª Reunião - 31/01/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240131_extratoata_0224.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20240117_extratoata_0124.pdf"> 451ª Reunião - 17/01/2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20240117_extratoata_0124.pdf"><span>Acessar</span></a></td></tr>
            
            
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20231221_extratoata_2523.pdf"> 450ª Reunião - 21/12/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20231221_extratoata_2523.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20231213_extratoata_2423.pdf"> 449ª Reunião - 13/12/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20231213_extratoata_2423.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20231128_extratoata_2323.pdf"> 448ª Reunião - 28/11/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20231128_extratoata_2323.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20231122_extratoata_2223.pdf"> 447ª Reunião - 22/11/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20231122_extratoata_2223.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20231108_extratoata_2123.pdf"> 446ª Reunião - 08/11/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20231108_extratoata_2123.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20231030_extratoata_2023.pdf"> 445ª Reunião - 30/10/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20231030_extratoata_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20231018_extratoata_1923.pdf"> 444ª Reunião - 18/10/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20231018_extratoata_1923.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230927_extratoata_1823.pdf"> 443ª Reunião - 27/09/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230927_extratoata_1823.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230923_extratoata_1723.pdf"> 442ª Reunião - 13/09/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230923_extratoata_1723.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230823_extratoata_1623.pdf"> 441ª Reunião - 23/08/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230823_extratoata_1623.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230809_extratoata_1523.pdf"> 440ª Reunião - 09/08/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230809_extratoata_1523.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230726_extratoata_1423.pdf"> 439ª Reunião - 26/07/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230726_extratoata_1423.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230712_extratoata_1323.pdf"> 438ª Reunião - 12/07/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230712_extratoata_1323.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230628_extratoata_1223.pdf"> 437ª Reunião - 28/06/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230628_extratoata_1223.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230614_extratoata_1123.pdf"> 436ª Reunião - 14/06/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230614_extratoata_1123.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230524_extratoata_1023.pdf"> 435ª Reunião - 24/05/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230524_extratoata_1023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230510_extratoata_0923.pdf"> 434ª Reunião - 10/05/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230510_extratoata_0923.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230425_extratoata_0823.pdf"> 433ª Reunião - 25/04/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230425_extratoata_0823.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230411_extratoata_0723.pdf"> 432ª Reunião - 11/04/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230411_extratoata_0723.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230321_extratoata_0623.pdf"> 431ª Reunião - 21/03/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230321_extratoata_0623.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230314_extratoata_0523.pdf"> 430ª Reunião - 14/03/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230314_extratoata_0523.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230228_extratoata_0423.pdf"> 429ª Reunião - 28/02/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230228_extratoata_0423.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230215_extratoata_0323.pdf"> 428ª Reunião - 15/02/2023 </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230215_extratoata_0323.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230131_extratoata_0223.pdf"> 427ª Reunião - 31/01/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230131_extratoata_0223.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/20230117_extratoata_0123.pdf"> 426ª Reunião - 17/01/2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/20230117_extratoata_0123.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            
            
            <br><br><h2>ANOS ANTERIORES</h2>
            
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/relatorios-atas-pesquisas-anos-anteriores">  Atas do ano de 2022 a 2018</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/relatorios-atas-pesquisas-anos-anteriores"><span>Acessar</span></a></td></tr>
            
            </tbody></table></div>
            
            


        </div>
    </article>


</main>