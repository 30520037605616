<main class="main">
    <meta name="description" content="Confira as análises mensais da qualidade da água distribuída pela Sabesp e veja como garantimos água segura e potável. Saiba mais!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
               <li><a href="javascript:void(0);" title="O que Fazemos">O que Fazemos</a></li>
                <li><a href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a href="/o-que-fazemos/fornecimento-agua/qualidade-agua/" title="Qualidade da Água">Qualidade da Água</a></li>
                <li><a href="javascript:void(0);" title="Relatórios de Qualidade da Água Mensais">Relatórios de Qualidade da Água Mensais</a></li>
            </ul>
            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <h1 class='headline'>Relatórios de Qualidade da Água Mensais.</h1>

            <p>A Sabesp controla a qualidade da água em todo o sistema de abastecimento, desde os mananciais até o cavalete dos imóveis por meio de coletas sistemáticas de amostras e realização de ensaios laboratoriais, em  à portaria do Ministério da Saúde.</p>
            
            <p>Para isso, possui 16 laboratórios de controle sanitários com vários ensaios acreditados pela ISO/IEC 17.025. Acesse aqui a lista de ensaios acreditados para cada laboratório. </p>
            
            <br><br><h2>Nesta área são apresentadas as análises mensais realizadas em todos os municípios atendidos pela Sabesp.</h2>
            
            
            
            <br><br><h2>Relatórios 2024 </h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_maio_24.pdf"> Qualidade da água distribuída por sistema de abastecimento</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_maio_24.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>Relatórios 2023</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/relatorio_qualidade%20da%20a%CC%81gua%20distribuida%20por%20sistema%20de%20abastecimento%20em%202023.pdf"> Qualidade da água distribuída por sistema de abastecimento</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/relatorio_qualidade%20da%20a%CC%81gua%20distribuida%20por%20sistema%20de%20abastecimento%20em%202023.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            
            <br><br><h2>Relatórios 2022</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_dez_22.pdf"> Qualidade da água distribuída por sistema de abastecimento</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_dez_22.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>Relatórios 2021</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/inf_qualidade_quant_dez2021.pdf"> Qualidade da água distribuída por sistema de abastecimento*</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/monitoramento/inf_qualidade_quant_dez2021.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            <p>* Portaria Consolidação nº 5 (vigência até 07/2021)</p>
            <p>* Portaria GM/MS nº 888 (vigência a partir de 08/2021)</p>
            
            
            
            <br><br><h2>Relatórios 2020</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez_2020.pdf"> Qualidade da água distribuída por sistema de abastecimento</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez_2020.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>Relatórios 2019</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez2019.pdf"> Qualidade da água distribuída por sistema de abastecimento </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez2019.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>Relatórios 2018</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez2018.pdf"> Qualidade da água distribuída por sistema de abastecimento</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez2018.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>Relatórios 2017</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez2017.pdf"> Qualidade da água distribuída por sistema de abastecimento*</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez2017.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            <p>  * Portaria 2914 (vigência até 09/2017) </p>
            <p>  * Portaria Consolidação nº 5 (vigência a partir de 10/2017)</p>
            
            
            
            <br><br><h2>Portaria 2914/2011 - Ministério da Saúde</h2>
            
            
            <br><br><h2>Relatórios 2016</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez_2016pdf.pdf"> Qualidade da água distribuída por sistema de abastecimento </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_jan_dez_2016pdf.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>Relatórios 2015</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/informacoes_qualidade_agua_jan_dez15.pdf"> Qualidade da água distribuída por sistema de abastecimento </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/informacoes_qualidade_agua_jan_dez15.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>Relatórios 2014</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_dez2014.pdf"> Qualidade da água distribuída por sistema de abastecimento </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_qualidade_agua/inf_qualidade_quant_dez2014.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>Relatórios 2013</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/monitoramento/inf_qualidade_quant_dez13.pdf"> Qualidade da água distribuída por sistema de abastecimento </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/uploads/file/monitoramento/inf_qualidade_quant_dez13.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>Relatórios 2012</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/rel_qual_agua2012.pdf">  Qualidade da água distribuída por sistema de abastecimento </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/uploads/file/asabesp_doctos/rel_qual_agua2012.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>Relatórios 2011</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_qualidade_agua/inf_qual_quant_dez2011_23022012.pdf"> Qualidade da água distribuída por sistema de abastecimento </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/uploads/file/relatorios_qualidade_agua/inf_qual_quant_dez2011_23022012.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            

        </div>
    </article>


</main>