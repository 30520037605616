<main class="main">
  <meta name="description" content="Entre em contato com a Sabesp: canais de atendimento ao cliente, ouvidoria e serviços online para resolver suas dúvidas e solicitações.">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li>
          <a class="breadcrumb-link" href="/" title="Início">Início</a>
        </li>
        <li>
          <a class="breadcrumb-link" href="/fale-com-a-sabesp" title="Fale com a Sabesp">Fale com a Sabesp</a>
        </li>
        <li>
          <a class="breadcrumb-link" href="/fale-com-a-sabesp/ouvidoria" title="Ouvidoria">Ouvidoria</a>
        </li>
        <li>
          <a class="breadcrumb-link" href="javascript:void(0);" title="Fomulário">Fomulário</a>
        </li>
      </ul>

      <ul class="buttons">
        <li>
          <a class="button -home breadcrumb-link" href="./" title="Início">Início</a>
        </li>
      </ul>
    </div>
  </nav>
  <section class="content">
    <form [formGroup]="ouvidoriaForm" (ngSubmit)="onSubmit()" class="ouvidoria-form container"
      enctype="multipart/form-data">
      <h1 class="headline">Bem vindo ao canal da Ouvidoria</h1>
      <p>Caso já tenha feito alguma solicitação de serviço à Sabesp e não tenha sido atendido ou considera a solução
        insatisfatória, preencha os dados do formulário.</p>

      <div class="form-group input-grande">
        <label for="protocolo">Número do Protocolo do serviço solicitado *</label>
        <input type="text" id="protocolo" formControlName="protocolo" class="form-control"
          [ngClass]="{ 'invalid': fieldInvalid('protocolo') }" />
      </div>

      <fieldset class="form-fieldset">
        <legend>Dados do Imóvel</legend>

        <div class="form-row">
          <div class="form-group input-grande">
            <label for="fornecimento">Fornecimento/PDE</label>
            <input type="text" id="pde" formControlName="pde" class="form-control" />
          </div>

          <div class="form-group input-pequeno">
            <label for="cep">CEP *</label>
            <input type="text" id="cep" formControlName="cep" class="form-control"
              [ngClass]="{ 'invalid': fieldInvalid('cep') }" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group input-grande">
            <label for="rua">Rua/Avenida *</label>
            <input type="text" id="endereco" formControlName="endereco" class="form-control"
              [ngClass]="{ 'invalid': fieldInvalid('endereco') }" />
          </div>

          <div class="form-group input-pequeno">
            <label for="numero">Número *</label>
            <input type="text" id="numero" formControlName="numero" class="form-control"
              [ngClass]="{ 'invalid': fieldInvalid('numero') }" />
          </div>

          <div class="form-group input-medio">
            <label for="complemento">Complemento</label>
            <input type="text" id="complemento" formControlName="complemento" class="form-control" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group input-grande">
            <label for="bairro">Bairro *</label>
            <input type="text" id="bairro" formControlName="bairro" class="form-control"
              [ngClass]="{ 'invalid': fieldInvalid('bairro') }" />
          </div>

          <div class="form-group input-medio">
            <label for="cidade">Cidade *</label>
            <input type="text" id="cidade" formControlName="cidade" class="form-control"
              [ngClass]="{ 'invalid': fieldInvalid('cidade') }" />
          </div>
        </div>
      </fieldset>

      <fieldset class="form-fieldset">
        <legend>Dados Pessoais</legend>

        <!-- <div class="form-row">
          <div class="form-group input-grande">
            <label for="cpf">CPF</label>
            <input type="text" id="cpf" formControlName="cpf" class="form-control" />
          </div>
        </div> -->

        <div class="form-row">
          <div class="form-group input-grande">
            <label for="nome">Nome *</label>
            <input type="text" id="nome" formControlName="nome" class="form-control"
              [ngClass]="{ 'invalid': fieldInvalid('nome') }" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group input-grande">
            <label for="email">E-mail *</label>
            <input type="email" id="email" formControlName="email" class="form-control"
              [ngClass]="{ 'invalid': fieldInvalid('email') }" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group input-medio">
            <label for="telefone-fixo">Telefone fixo *</label>
            <input type="tel" id="telefone-fixo" formControlName="telefoneFixo" class="form-control"
              mask="(00) 0000-0000" placeholder="(00) 0000-0000" [ngClass]="{ 'invalid': fieldInvalid('telefoneFixo') }" />
          </div>

          <div class="form-group input-medio">
            <label for="telefone-celular">Telefone celular *</label>
            <input type="tel" id="telefone-celular" formControlName="telefoneCelular" class="form-control"
              mask="(00) 0 0000-0000" placeholder="(00) 0 0000-0000"
              [ngClass]="{ 'invalid': fieldInvalid('telefoneCelular') }" />
          </div>
        </div>
      </fieldset>

      <fieldset class="form-fieldset">
        <legend>Manifestação</legend>

        <div class="form-group input-grande">
          <label for="assunto">Assunto/motivo do contato *</label>
          <select id="assunto" formControlName="assunto" class="form-control"
            [ngClass]="{ 'invalid': fieldInvalid('assunto') }">
            <option value="agua">Água</option>
            <option value="esgoto">Esgoto</option>
            <option value="comercial">Comercial</option>
            <option value="outros">Outros</option>
          </select>
        </div>

        <div class="form-group">
          <label for="mensagem">Escreva sua mensagem</label>
          <textarea id="mensagem" formControlName="mensagem" class="form-control"></textarea>
        </div>

        <div class="form-group">
          <label for="anexos">Enviar arquivos ou anexos</label>
          <input type="file" id="anexos" (change)="onFileChanged($event)" multiple class="form-control" />
          <div class="text-muted">Até 10mb, somente pdf, jpeg e png</div>
        </div>
      </fieldset>

      <div class="text-required">* Campo obrigatório</div>

      <re-captcha formControlName="recaptchaResponse" required siteKey="{{recaptchaSiteKey}}"></re-captcha>

      <button type="submit" class="button" [disabled]="ouvidoriaForm.invalid">Enviar</button>
    </form>
  </section>
</main>