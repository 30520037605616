import { Component, ViewEncapsulation } from '@angular/core';
import { Pagination } from '../pagination';
import { Result } from '../result';
import { SearchService } from '../search.service';
import { ActivatedRoute } from '@angular/router';
import { ContentPipe } from '../content-pipe/content.pipe';

@Component({
  selector: 'app-busca-geral',
  templateUrl: './busca-geral.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'busca-geral.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BuscaGeralComponent {
  results: Pagination<Result> = { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] };
  perPage = 6;
  q = "";

  constructor(private searchService: SearchService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.q = params['q'] || ""; // Use || para fornecer um valor padrão se 'q' não estiver presente
      this.search(1);
    });
  }

  search(page: Number): void {
    this.searchService.search(this.q, page, this.perPage)
      .subscribe(results => this.results = results);
  }

  link(kind: number, id: string): string {
    return ContentPipe.linkRel(kind, id);
  }
}