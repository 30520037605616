<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="javascript:void(0);" title="Informações ao Cidadão">Informações ao Cidadão</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Informações ao Cidadão</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/sustentabilidade/informacoes-cidadao-fala-sp.jpg" alt=" Profissional em frente ao computador​" title="Informações ao Cidadão">
            </picture>

            <p>A Plataforma Fala.SP é o canal para que você possa registrar seu pedido de acesso à informação e manifestação de ouvidoria para todos os órgãos e entidades do Poder Executivo estadual, se estabelecendo como um diálogo simples, direto, seguro e acessível com o Governo de São Paulo e tudo isso por meio de um único login.</p>
            <p>Para registrar seu Pedido de Informações você acessa o <strong><a class="-txt-blue" href="https://fala.sp.gov.br" target="_blank" rel="noopener noreferrer">Fala.SP</a></strong> e escolhe o quadro "Pedido de Acesso à Informação", faz seu login com sua conta do gov.br e é direcionado para o formulário de registro de seu pedido.</p>
            <p>Os pedidos são encaminhados para a área responsável da Empresa, analisados por um Grupo de Apoio que assegura que a informação contida na resposta esteja – em primeiro lugar – aderente a todas as formas de relacionamento que a Sabesp mantém com seus clientes e com toda a sociedade. Além disso, com esta análise também se assegura que nenhuma informação que possa vir a afetar as relações da Sabesp com o mercado seja divulgada – vale lembrar que, por ter ações negociadas no Novo Mercado Bovespa e na Bolsa de Nova Iorque, a Sabesp deve seguir regulamentações específicas aderentes a esses ambientes.</p>
            <p><strong>Área responsável:</strong> Superintendência Jurídica.</p>
        </div>
    </article>

    <section class="more">
        <div class="container">
            <div class="columns">
                <nav class="nav">
                    <h2 class="headline">Relatório de Acompanhamento</h2>

                    <ul class="items">
                        <li class="item">
                            <a class="title" target="_blank" rel="noopener noreferrer" href="assets/pdf/rel_inf_cidadao_junho_24.pdf">Junho de 2024</a>
                        </li>
                        <li class="item">
                            <a class="title" target="_blank" rel="noopener noreferrer" href="assets/pdf/relatorio_sic_dez_23.pdf">Relatório anual de 2023</a>
                        </li>
                        <li class="item">
                            <a class="title" target="_blank" rel="noopener noreferrer" href="assets/pdf/relatorio_sic_dez_22.pdf">Relatório anual de 2022</a>
                        </li>
                    </ul>
                </nav>

                <nav class="nav">
                    <h2 class="headline">Legislação</h2>

                    <ul class="items">
                        <li class="item">
                            <a class="title" target="_blank" rel="noopener noreferrer" href="https://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm">Lei Federal 12.527/11</a>
                        </li>
                        <li class="item">
                            <a class="title" target="_blank" rel="noopener noreferrer" href="https://www.al.sp.gov.br/repositorio/legislacao/decreto/2023/decreto-68155-09.12.2023.html">Decreto n° 68.155/23</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <h2 class="headline">Lei Geral de Proteção de Dados</h2>
            <p>Informações, esclarecimentos, reclamações ou demais providências relacionadas aos seus dados pessoais devem ser encaminhadas para a Encarregada da Proteção de Dados Pessoais da Sabesp, Regina Helena Piccolo Cardia, pelo e-mail <a class="-mail" href="mailto:lgpd@sabesp.com.br">lgpd&#64;sabesp.com.br</a></p>
        </div>
    </section>
</main>
