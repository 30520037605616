<main class="main">
    <meta name="description" content="Descubra como a Sabesp transforma vidas através do saneamento, promovendo saúde, qualidade de vida e sustentabilidade.">
    <nav class="breadcrumbs">
        <div class="container">
          <ul class="menu">
            <li><a href="/" title="Início">Início</a></li>
           <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
            <li><a href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
            <li><a href="/o-que-fazemos/obras-programas" title="Saneamento que Transforma">Saneamento que Transforma</a></li>
          </ul>

          <ul class="buttons">
            <li><a class="button -home" href="/" title="Início">Início</a></li>
          </ul>
        </div>
      </nav>

      <article class="content">
        <div class="container">
            <h1 class="headline">Saneamento que Transforma</h1>

            <picture class="picture align-right petals">
                <img loading="eager" fetchpriority="high" width="650" height="380" src="/assets/images/o-que-fazemos/obras-programas.jpg" alt="Integra Tietê" title="Integra Tietê">
            </picture>

            <p>Estar em harmonia com o meio ambiente à nossa volta é fundamental para nós, da Sabesp. Nossa grande matéria-prima é a água, e precisamos dela para realizar o nosso trabalho diário de abastecer a casa de milhões de pessoas.</p>
            <p>Temos também a missão fundamental de coletar o esgoto na casa das pessoas, nos comércios e nas indústrias e levá-lo às estações para ser tratado e transformado novamente em água que pode ser devolvida à natureza, sem riscos para as pessoas e o meio ambiente. Assim, completamos todo o ciclo do saneamento, indo da água bruta na natureza até o retorno dela aos mananciais depois de ser tratada.</p>
            <p>A Sabesp acredita que precisa fazer mais do que apenas não prejudicar o meio ambiente e obedecer às leis sobre o tema. Precisa também ajudar a recuperar, regenerar e preservar a natureza para todos. Esse cuidado é tão importante que criamos alguns programas como o Projeto Tietê – agora transformado em Integra Tietê – Onda Limpa e Redução de Perdas, entre outras ações.</p>
            <p>Convidamos você a conhecer um pouco mais sobre eles!</p>
        </div>
    </article>

    <section class="carousel">
        <div class="carousel-slider">
            <div class="item -green">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img class="tns-lazy-img" loading="lazy" fetchpriority="low" width="642" height="375" src="./assets/images/o-que-fazemos/programa-integra-tiete.jpg" data-src="./assets/images/o-que-fazemos/programa-integra-tiete.jpg" alt="Clique aqui e confira!" title="Clique aqui e confira!">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Integra Tietê </h2>
                        <div class="description">
                            <p>O Projeto Tietê, criado em 1992, é o maior programa do país e um dos maiores do mundo em saneamento ambiental. Foi desenvolvido para levar os serviços de coleta e tratamento de esgoto para grande parte da população da Região Metropolitana de São Paulo, que inclui 31 municípios. Para se ter uma ideia, de 1992 a 2022, o programa levou saneamento a cerca de 13 milhões de pessoas.</p>
                            <p>Agora, o programa ganhou um novo impulso, tornando-se Integra Tietê. Lançado em 2023, ele une os esforços do Projeto Tietê e ações de outros órgãos do Governo do Estado de São Paulo, acelerando os investimentos e os resultados, sob a coordenação da Secretaria de Meio Ambiente, Infraestrutura e Logística (Semil), e terá ações que envolvem desde a retirada do lixo, o controle de cheias, o monitoramento da qualidade da água, a manutenção das margens, e a recuperação de fauna e da flora, além de desassoreamento e estímulo à navegação e ao turismo.</p>
                        </div>
                        <a class="link -white" href="/o-que-fazemos/obras-programas/integra-tiete" title="Saiba mais sobre o Integra Tietê">Saiba mais sobre o Integra Tietê</a>
                    </div>
                </div>
            </div>
            <div class="item -blue">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="eager" fetchpriority="high" width="642" height="375" src="./assets/images/o-que-fazemos/programa-onda-limpa.jpg" data-src="./assets/images/o-que-fazemos/programa-onda-limpa.jpg" alt="Onda Limpa" title="Onda Limpa">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Onda Limpa</h2>
                        <div class="description">
                            <p>A Sabesp também cuida das águas do mar, fazendo a coleta do esgoto no litoral com o Onda Limpa, o maior programa de saneamento da costa brasileira.</p>
                            <p>Iniciado na Baixada Santista em 2007, o programa já realizou 118 mil ligações de esgoto e o índice de coleta passou de 53% para 84%, com 100% de tratamento. Desde 2018, estamos na segunda etapa, com obras em Praia Grande, Mongaguá, São Vicente, Itanhaém, Guarujá e Peruíbe. São 450 quilômetros de redes coletoras de esgoto, 48 mil novas ligações, três novas estações de tratamento, bem como a ampliação e a melhoria das estações que já temos. Em 2019, o Onda Limpa chegou ao Litoral Norte, com obras para atender a população de Caraguatatuba, São Sebastião, Ubatuba e Ilhabela.</p>
                        </div>
                        <a class="link -white" href="/o-que-fazemos/obras-programas/onda-limpa" title="Saiba mais sobre o Onda Limpa">Saiba mais sobre o Onda Limpa</a>
                    </div>
                </div>
            </div>

            <div class="item -tangelo" data-nav-color="black" style="color:#171717">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img class="tns-lazy-img loaded tns-complete" loading="lazy" fetchpriority="low" width="642" height="375" data-src="./assets/images/o-que-fazemos/obras-reducao-perdas.jpg" alt="Redução de Perdas" title="Redução de Perdas" src="../assets/images/o-que-fazemos/obras-reducao-perdas.jpg">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Redução de Perdas</h2>
                        <div class="description">
                            <p>As perdas de água nos sistemas de abastecimento correspondem à diferença entre o volume total de água produzido nas estações de tratamento e a soma dos volumes medidos nos hidrômetros instalados nos imóveis dos clientes.</p>
                            <p>O combate às perdas demanda um esforço permanente, pois as perdas de água têm uma tendência natural de aumento pois, com o passar do tempo, a infraestrutura envelhece, surgem novos vazamentos, os hidrômetros perdem precisão e as irregularidades aumentam.</p>
                        </div>
                        <a class="link -black" href="/o-que-fazemos/obras-programas/reducao-perdas/controle-perdas" title="Saiba mais">Saiba mais</a>
                    </div>
                </div>
            </div>

            <div class="item -dark-blue">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img class="tns-lazy-img loaded tns-complete" loading="lazy" fetchpriority="low" width="650" height="380" src="./assets/images/o-que-fazemos/programa-uso-racional-agua.jpg" alt="Programa de Uso Racional da Água" title="Programa de Uso Racional da Água">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Programa de Uso Racional da Água</h2>
                        <p class="description">A quantidade de água disponível na Bacia do Alto Tietê, onde fica a Região Metropolitana de São Paulo, corresponde apenas a 1/10 do valor indicado pela ONU como adequado. Por conta disso, é fundamental que as pessoas consumam água de maneira consciente. A Sabesp tem um programa criado especialmente para auxiliar a população a fazer sua parte: é o Programa de Uso Racional da Água, que tem informações, dicas e até cursos gratuitos.</p>
                        <a class="link -white" href="/o-que-fazemos/obras-programas/uso-racional-agua" title="Saiba mais">Saiba mais</a>
                    </div>
                </div>
            </div>

        </div>
    </section>


</main>
