import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-avaliacao-metas-resultados',
  standalone: true,
  imports: [],
  templateUrl: './avaliacao-metas-resultados.component.html',
  styleUrl: './avaliacao-metas-resultados.component.css'
})
export class AvaliacaoMetasResultadosComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Avaliação de Metas e Resultados");
}
}
