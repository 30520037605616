import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Pagination } from './pagination';
import { environment } from '../environments/environment';
import { Result } from './result';


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchUrl = environment.apiUrl + '/api/v1/search';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  search(q: string, page: Number, perPage: Number): Observable<Pagination<Result>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('perPage', perPage.toString());
    if (q) {
      params = params.set('q', q);
    }
    return this.http.get<Pagination<Result>>(this.searchUrl, { params })
      .pipe(
        catchError(ErrorService.handle<Pagination<Result>>('search', { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] }))
      );
  }
}