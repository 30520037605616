<main class="main">
  <nav class="breadcrumbs">
    <div class="container">
      <ul class="menu">
        <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
        <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
        <li><a href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
        <li><a href="javascript:void(0);" title="Qualidade da Água">Qualidade da Água</a></li>
        <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Lista de ensaios</a></li>
      </ul>

      <ul class="buttons">
        <li><a class="button -home breadcrumb-link" href="./" title="Início">Início</a></li>
      </ul>
    </div>
  </nav>

  <article class="content">
    <div class="container">
      <h1 class="headline">Lista de ensaios</h1>

      <p>O Ministério da Saúde estabelece que a água produzida e distribuída para o consumo humano deve ser controlada. A legislação define também a quantidade mínima, a frequência em que as amostras de água devem ser coletadas e os limites permitidos. </p>

        <p>Em atendimento às exigências estabelecidas, a Sabesp analisa a qualidade da água desde a origem até os pontos de consumo. Para assegurar a confiabilidade do seu produto, a empresa executa um forte trabalho nas suas centrais de controle sanitário, estrategicamente instaladas pela Região Metropolitana de São Paulo, Interior e Litoral.</p>

        <p>Para isso, possui 17 laboratórios de controle sanitários com vários ensaios acreditados pela ISO/IEC 17.025. A lista de ensaios acreditados para cada laboratório pode ser obtida através dos links abaixo.
        </p>

      <div class="table-responsive">
        <table class="table col-striped" style="min-width: 700px;">
          <thead>
            <tr>
              <th>Tipo de Documento</th>
              <th class="txt-center" width="200">Ver/Baixar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0085.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Pardo e Grande - Franca
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0085.pdf" target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0128.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Alto Paranapanema - Itapetininga
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0128.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0168.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Vale do Paraíba - São José dos Campos - Laboratório 1
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0168.pdf" target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0213.pdf " target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Vale do Paraíba - São José dos Campos - Laboratório 2
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0213.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0265.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Baixo Paranapanema - Presidente Prudente
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0265.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0276.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Médio Tietê - Botucatu
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0276.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0277.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Baixo Tietê e Grande - Fernandópolis
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0277.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0278.pdf " target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Baixo Tietê e Grande Lins
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0278.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0279.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Baixada Santista - Santos
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0279.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0282.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Vale do Ribeiro - Registro
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0282.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0284.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Litoral Norte - Caraguatatuba
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0284.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0217.pdf " target="_blank" rel="noopener noreferrer" class="-link">
                  Departamento Controle da Qualidade dos Produtos Água e Esgotos - São Paulo
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0217.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0305.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Sul - São Paulo
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0305.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0332.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Centro - São Paulo - Laboratório 1
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0332.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0333.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Centro - São Paulo - Laboratório 2
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0333.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0712.pdf " target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Norte - São Paulo
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0712.pdf " target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0764.pdf" target="_blank" rel="noopener noreferrer" class="-link">
                  Divisão de Controle Sanitário Capivari/Jundiaí - Campo Limpo Paulista
                </a>
              </td>
              <td class="txt-center">
                <a class="action-icon -link-external" href="http://www.inmetro.gov.br/laboratorios/rble/docs/CRL0764.pdf" target="_blank" rel="noopener noreferrer">
                  <span>Ver página</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</main>
