<main class="main">
	<meta name="description" content="Saiba como a Sabesp promove o uso racional da água e conheça nossos programas para garantir sustentabilidade e eficiência hídrica. Confira!">
		<nav class="breadcrumbs">
			<div class="container">
				<ul class="menu">
					<li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
					<li><a class="breadcrumb-link" href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="Programa de Uso Racional da Água">Programa de Uso Racional da Água</a></li>
				</ul>

				<ul class="buttons">
					<li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
				</ul>
			</div>
		</nav>

		<article class="content">
			<div class="container">
				<h1 class="headline">Programa de Uso Racional da Água</h1>

				<p>Você sabia que a quantidade de água disponível na Bacia do Alto Tietê, onde fica a Região Metropolitana de São Paulo, é de 200 mil litros por habitante ao ano, o que corresponde a 1/10 do valor recomendado pela Organização das Nações Unidas (<abbr title="Organização das Nações Unidas">ONU</abbr>) para atender as necessidades de consumo e higiene? Isso acontece por conta do grande número de pessoas vivendo na região, que é maior do que a capacidade de recursos hídricos disponíveis que a Bacia pode atender de forma ideal.</p>

				<div class="table-responsive">
					<table class="table col-striped" style="min-width: 700px;">
						<thead>
							<tr>
								<th>Classificação da ONU</th>
								<th>Disponibilidade Hídrica (m<sup>3</sup>/habitante/ano)</th>
								<th>Região</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Abundante</td>
								<td>Maior que 20.000</td>
								<td>Brasil (35.000)</td>
							</tr>
							<tr>
								<td>Correta</td>
								<td>Entre 2.500 e 20.000</td>
								<td>Paraná (12.600)</td>
							</tr>
							<tr>
								<td>Pobre</td>
								<td>Entre 1.500 e 2.500</td>
								<td>Estado de São Paulo (2.209)</td>
							</tr>
							<tr>
								<td rowspan="3"><span>Crítica</span></td>
								<td rowspan="3"><span>Menor que 1.500</span></td>
								<td>Estado de Pernambuco (1.270)</td>
							</tr>
							<tr>
								<td class="-no-first">Bacia do Piracicaba (408)</td>
							</tr>
							<tr>
								<td class="-no-first">Bacia do Alto Tietê (200)</td>
							</tr>
						</tbody>
					</table>
				</div>

				<p>Diante deste quadro, a Sabesp criou o <b>Programa de Uso Racional da Água</b>, em parceria com a Escola Politécnica da Universidade de São Paulo (<abbr title="Universidade de São Paulo">USP</abbr>) e o Instituto de Pesquisas Tecnológicas (<abbr title="Instituto de Pesquisas Tecnológicas">IPT</abbr>). O programa combina tecnologia e mudanças comportamentais para promover o consumo consciente e combater o desperdício.</p>

				<picture class="picture align-right">
					<img loading="eager" fetchpriority="high" width="650" height="380" src="./assets/images/o-que-fazemos/uso-racional-agua.jpg" alt="Banheiro moderno com pias e cabines​" title="Programa de Uso Racional da Água">
				</picture>

				<p>A proposta tem como objetivos:</p>
				<ul class="list">
					<li><b>Conscientizar</b> a população sobre o valor da água;</li>
					<li><b>Ampliar</b> a vida útil dos mananciais de modo a garantir o fornecimento de água;</li>
					<li><b>Reduzir</b> custos com tratamento de esgotos, diminuindo o volume dos mesmos;</li>
					<li><b>Diminuir</b> o consumo de energia elétrica, produtos químicos e outros insumos nos sistemas de abastecimento de água e esgoto.</li>
				</ul>
				<p>Com o Programa de Uso Racional da Água, é feito o levantamento do volume de água utilizado, adotando medidas para monitorar as instalações hidráulicas e identificar possíveis vazamentos.</p>
				<p>Após a verificação e conforme necessário, são indicados reparos nas instalações hidráulicas e na estrutura interna das edificações, bem como a substituição de equipamentos convencionais por economizadores. Além disso, são realizadas palestras e orientações para promover a modificação dos hábitos de consumo. O retorno do investimento é rápido e, em alguns casos, imediato.</p>

				<h2 class="subheadline">Como Surgiu</h2>
				<p>O Programa de Uso Racional da Água foi criado pela Sabesp em 1996, em parceria com a Escola Politécnica da Universidade de São Paulo (<abbr title="Universidade de São Paulo">USP</abbr>) e o Instituto de Pesquisas Tecnológicas (<abbr title="Instituto de Pesquisas Tecnológicas">IPT</abbr>).</p>
				<p>Inicialmente, foi estabelecida a estrutura necessária. Em seguida, foram implementados projetos pilotos que possibilitaram o desenvolvimento de metodologias para medir o consumo de água em hospitais, escolas, cozinhas industriais, prédios comerciais e condomínios. Os resultados obtidos foram surpreendentes, permitindo a identificação tanto o consumo por habitante, como o potencial para reduzir o volume de água utilizado.</p>
				<p>Confira na tabela abaixo o consumo por habitante em diversos locais:</p>
				<div class="table-responsive">
					<table class="table col-striped" style="min-width: 700px;">
						<thead>
							<tr>
								<th>Natureza</th>
								<th>Consumo por Pessoa</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Escolas Estaduais 1º e 2º Grau</td>
								<td>25 litros/aluno/dia</td>
							</tr>
							<tr>
								<td>Escolas Internatos</td>
								<td>150 litros/aluno/dia</td>
							</tr>
							<tr>
								<td>Escolas Semi - Internatos</td>
								<td>100 litros/aluno/dia</td>
							</tr>
							<tr>
								<td>Prédios Públicos e Comerciais</td>
								<td>50 litros/funcionário/dia</td>
							</tr>
							<tr>
								<td>Prédios Hospitalares sem lavanderia</td>
								<td>500 litros/leito/dia</td>
							</tr>
							<tr>
								<td>Prédios Hospitalares com lavanderia</td>
								<td>750 litros/leito/dia</td>
							</tr>
							<tr>
								<td>Prédios com alojamentos provisórios / cozinha / lavanderia</td>
								<td>120 litros/pessoa/dia</td>
							</tr>
							<tr>
								<td>Prédios Públicos - Quartéis/Militares</td>
								<td>150 litros/militar/dia</td>
							</tr>
							<tr>
								<td>Prédios Penitenciários</td>
								<td>200 litros/preso/dia</td>
							</tr>
							<tr>
								<td>Restaurantes - Prédios Públicos</td>
								<td>25 litros/refeição/dia</td>
							</tr>
							<tr>
								<td>Creches - Prédios Públicos</td>
								<td>50 litros/pessoa/dia</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p class="-txt-gray -space-lg"><small><em>Consumos específicos por natureza (fonte: Decreto Estadual 45 805, de <time datetime="2021-05-15">15/5/2021</time>)</em></small></p>

				<h2 class="subheadline">Como Funciona</h2>
				<p>Ao adotar o Programa de Uso Racional da Água, especialistas apontam onde estão os maiores “vilões” do desperdício para analisar e combater as causas. Normalmente, há divisão entre consumo interno - banheiros, cozinha, ambulatórios e laboratórios - e os externos - área de jardim, estacionamento ou outros locais fora do imóvel.</p>
				<p>Na etapa de vigilância do consumo, além de detalhar os pontos de uso, pode-se adotar a telemedição, que consiste na instalação de medidores para leitura e acompanhamento dos gastos à distância. Na Universidade de São Paulo, por exemplo, existem diversas faculdades e o trabalho ali desenvolvido consistiu em instalar equipamentos para medir à distância e online, identificando o consumo em diferentes horários. Caso fossem registradas alterações durante a madrugada, havia sinais de perdas por vazamentos já que as unidades permanecem fechadas. Sem esta técnica, um problema de vazamento poderia levar anos para ser identificado, gerando gastos excessivos e desnecessários.</p>
				<p>Essa tecnologia, integrada a programas e central de computadores, permite que os responsáveis pelo programa vejam qual é a demanda de água para cada ponto, com registro de dados e informações de forma rápida, diferente da medição mensal.</p>

				<h2 class="subheadline">Hábitos e Costumes</h2>
				<p>Um ponto muito importante do Programa de Uso Racional da Água é a análise dos hábitos de consumo, que requer uma identificação detalhada para determinar se os maiores gastos acontecem na cozinha, áreas externas ou banheiros. Essencial observar, registrar e falar com as pessoas para compreender a dinâmica.</p>
				<p>A disseminação das dicas e orientações são importantes para alcançar bons resultados. Palestras, reuniões e campanhas de conscientização são criadas para modificar padrões de consumo e alcançar a redução do volume utilizado.</p>
				<p>Por exemplo, em uma escola, após a implementação do Programa de Uso Racional da Água, a média diária de consumo diminuiu  de 14 litros para 6 litros por aluno. Já num prédio administrativo, o consumo, que anteriormente era de 32 litros por pessoa, reduziu para 25 litros.</p>

				<h2 class="subheadline">Equipamentos Economizadores</h2>
				<p>Torneiras com arejadores podem reduzir o consumo de água em até 75%. Veja a comparação entre equipamentos convencionais e economizadores:</p>

				<div class="table-responsive">
					<table class="table col-striped" style="min-width: 700px;">
						<thead>
							<tr>
								<th>Equipamento Convencional</th>
								<th>Consumo</th>
								<th>Equipamento Economizador</th>
								<th>Consumo</th>
								<th class="txt-center">Economia</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Bacia com caixa acoplada</td>
								<td>12 litros/descarga</td>
								<td>Bacia VDR</td>
								<td>6 litros/descarga</td>
								<td class="txt-center">50%</td>
							</tr>
							<tr>
								<td>Bacia com válvula bem regulada</td>
								<td>10 litros/descarga</td>
								<td>Bacia VDR</td>
								<td>6 litros/descarga</td>
								<td class="txt-center">40%</td>
							</tr>
							<tr>
								<td>Ducha (água quente/fria) - até 6 mca</td>
								<td>0,19 litros/seg
								</td><td>Restritor de vazão 8 litros/min</td>
								<td>0,13 litros/seg</td>
								<td class="txt-center">32%</td>
							</tr>
							<tr>
								<td>Ducha (água quente/fria) - 15 a 20 mca</td>
								<td>0,34 litros/seg</td>
								<td>Restritor de vazão 8 litros/min</td>
								<td>0,13 litros/seg</td>
								<td class="txt-center">62%</td>
							</tr>
							<tr>
								<td>Ducha (água quente/fria) - 15 a 20 mca</td>
								<td>0,34 litros/seg
								</td><td>Restritor de vazão 12 litros/min</td>
								<td>0,20 litros/seg</td>
								<td class="txt-center">41%</td>
							</tr>
							<tr>
								<td>Torneira de pia - até 6 mca</td>
								<td>0,23 litros/seg</td>
								<td>Arejador vazão cte (6 litros/min)</td>
								<td>0,10 litros/seg</td>
								<td class="txt-center">57%</td>
							</tr>
							<tr>
								<td>Torneira de pia - 15 a 20 mca</td>
								<td>0,42 litros/seg</td>
								<td>Arejador vazão cte (6 litros/min)</td>
								<td>0,10 litros/seg</td>
								<td class="txt-center">76% </td>
							</tr>
							<tr>
								<td>Torneira uso geral/tanque - até 6 mca</td>
								<td>0,26 litros/seg</td>
								<td>Regulador de vazão</td>
								<td>0,13 litros/seg</td>
								<td class="txt-center">50%</td>
							</tr>
							<tr>
								<td>Torneira uso geral/tanque - 15 a 20 mca</td>
								<td>0,42 litros/seg</td>
								<td>Regulador de vazão</td>
								<td>0,21 litros/seg</td>
								<td class="txt-center">50%</td>
							</tr>
							<tr>
								<td>Torneira uso geral/tanque - até 6 mca</td>
								<td>0,26 litros/seg</td>
								<td>Restritor de vazão</td>
								<td>0,10 litros/seg</td>
								<td class="txt-center">62%</td>
							</tr>
							<tr>
								<td>Torneira uso geral/tanque - 15 a 20 mca</td>
								<td>0,42 litros/seg</td>
								<td>Restritor de vazão</td>
								<td>0,10 litros/seg</td>
								<td class="txt-center">76%</td>
							</tr>
							<tr>
								<td>Torneira de jardim - 40 a 50 mca</td>
								<td>0,66 litros/seg</td>
								<td>Regulador de vazão</td>
								<td>0,33 litros/seg</td>
								<td class="txt-center">50%</td>
							</tr>
							<tr>
								<td>Mictório</td>
								<td>2 litros/uso</td>
								<td>Válvula automática</td>
								<td>1 litro/seg</td>
								<td class="txt-center">50%</td>
							</tr>
						</tbody>
					</table>
				</div>

				<p class="-txt-gray"><small><em>
					- Torneira de Pia - abertura 1 volta<br>
					- Ducha - abertura total<br>
					- O regulador de vazão permite o usuário regular de acordo com sua necessidade
				</em></small></p>
				<p class="-txt-gray -space-lg"><small><em>Fonte: Relatório Mensal 3 Projeto de Pesquisa Escola Politécnica / USPxSABESP - Junho/96 e informações técnicas da ASFAMAS.</em></small></p>

				<p>Antes de adquirir os equipamentos, é importante verificar o seguinte:</p>
				<ul class="list">
					<li>Se os fabricantes dos produtos participam do Programa Brasileiro da Qualidade e Produtividade do Habitat;</li>
					<li>Se os produtos ofertados obedecem às especificações técnicas;</li>
					<li>Se os produtos têm garantia mínima de 05 anos contra defeitos de fabricação de qualquer peça fora dos padrões exigidos nas especificações técnicas, bem como a garantia de sua reposição imediata.</li>
				</ul>
			</div>
		</article>

  <section class="carousel">
        <div class="carousel-slider">
            <div class="item -green">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img class="tns-lazy-img" loading="lazy" fetchpriority="low" width="642" height="375" src="./assets/images/o-que-fazemos/dicas-economia-agua.jpg" data-src="./assets/images/o-que-fazemos/dicas-economia-agua.jpg" alt="Mão abrindo uma torneira com água corrente​" title="Clique aqui e confira!">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Como usar Água de Maneira Consciente</h2>
                        <p class="description">A Sabesp preparou uma série de dicas simples e fáceis de seguir, para que você consuma água de forma consciente e contribua para que esse bem precioso não se esgote, além de fazer uma economia financeira significativa.</p>
                        <a class="link -white" href="/a-sabesp/educacao/dicas-economia-agua" title="Clique aqui e confira!">Clique aqui e confira!</a>
                    </div>
                </div>
            </div>

            <div class="item -blue">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="eager" fetchpriority="high" width="642" height="375" src="./assets/images/o-que-fazemos/curso-pesquisa-vazamentos.jpg" data-src="./assets/images/o-que-fazemos/curso-pesquisa-vazamentos.jpg" alt="Ajuste de torneira com chave inglesa​" title="Curso de Pesquisa de Vazamento">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Curso de Pesquisa de Vazamento</h2>
                        <p class="description">Você também pode participar gratuitamente do curso de pesquisa de vazamento oferecido pela Sabesp. Em salas montadas com equipamentos hidráulicos especialmente adaptados, funcionários da Companhia ensinam aos participantes técnicas simples e objetivas para identificar vazamentos em instalações hidráulicas de imóveis (casas, apartamentos ou prédios).</p>
                        <a class="link -white" href="/a-sabesp/educacao/curso-pesquisa-vazamento" title="Saiba mais e inscreva-se!">Saiba mais e inscreva-se!</a>
                    </div>
                </div>
            </div>

        </div>
    </section>

</main>
