export enum ContentKind {
  Default = 0,
  News = 1,
  Release = 2,
  Notice = 3,
  Question = 4,
}

export const KIND_PATHS: { [key: number]: string } = {
  [ContentKind.News]: "/a-sabesp/central-noticias/noticias",
  [ContentKind.Release]: "/a-sabesp/noticias/releases",
  [ContentKind.Notice]: "/a-sabesp/central-noticias/utilidade-publica",
  [ContentKind.Question]: "/fale-com-a-sabesp/perguntas-frequentes",
}
