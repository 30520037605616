import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TemplateCarrosselService } from './template-carrossel.service';
import { CarouselItem } from './template-carrossel';

@Component({
  selector: 'app-template-carrossel',
  templateUrl: './template-carrossel.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', './template-carrossel.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateCarrosselComponent implements OnInit {

  carouselItems!: CarouselItem[];

  constructor(
    private templateCarrosselService: TemplateCarrosselService
  ) {}

  ngOnInit(): void {
    this.templateCarrosselService.getCarouselTemplate().subscribe(
      carouselTemplate => { this.carouselItems = carouselTemplate.carousel }
    )
  }

}
