import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-controle-perdas',
  templateUrl: './controle-perdas.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ControlePerdasComponent implements OnInit{

  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Controle de Perdas");

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Controle de Perdas'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Controle de Perdas'
    });
  }
}
