<main class="main">
    <meta name="description" content="Fique por dentro das últimas notícias da Sabesp e acompanhe as novidades e projetos no setor de saneamento básico em São Paulo. Confira!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Central de Notícias">Central de Notícias</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Notícias">Notícias</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <app-filter
    [classList]="'filter'"
    [totalItems]="1"
    [categories]="categories"
    (filterChange)="filterTypeSearch($event)">
    </app-filter>

    <section class="wrapper">
        <div class="container petals">
            <div class="content">
                <h1 class="headline title-link">Notícias</h1>

                <div class="listing">
                    <div class="item" *ngFor="let n of news.items">
                        <figure *ngIf="n.listImage" class="figure">
                            <a class="acess-link" routerLink="/a-sabesp/central-noticias/noticias/{{n.id}}">
                                <img loading="lazy" fetchpriority="auto" width="380" height="380" src="{{ n.listImage }}" alt="" title="">
                            </a>
                        </figure>
                        <h2 class="title"><a class="acess-link" routerLink="/a-sabesp/central-noticias/noticias/{{n.id}}">{{ n.title }}</a></h2>
                        <p class="published"><a routerLink="/a-sabesp/central-noticias/noticias/{{n.id}}" tabindex="-1">Publicado em <time attr.datetime="{{ n.date }}">{{ n.date | date:'dd/MM/yyyy':'UTC' }}</time></a></p>
                        <p class="description"><a routerLink="/a-sabesp/central-noticias/noticias/{{n.id}}" tabindex="-1">{{ n.summary }}</a></p>
                    </div>
                </div>

                <app-pagination
                    [totalItems]="news.totalItems"
                    [itemsPerPage]="news.itemsPerPage"
                    [totalPages]="news.totalPages"
                    (pageChange)="getNews($event)"></app-pagination>
            </div>

            <aside class="aside">
                <div class="columns">
                    <nav class="nav">
                        <div class="top">
                            <h2 class="headline">Utilidade Pública </h2>
                            <a class="link -simple acess-link" href="a-sabesp/central-noticias/utilidade-publica" title="Ver todos">Ver todos</a>
                        </div>

                        <ul class="items">
                            <li class="item" *ngFor="let n of noticies">
                                <h3 class="title"><a class="description-link" href="/a-sabesp/central-noticias/utilidade-publica/{{n.id}}">{{ n.title }}</a></h3>
                                <a class="date" href="/a-sabesp/central-noticias/utilidade-publica/{{n.id}}"><time attr.datetime="{{ n.date }}">{{ n.date | date:'dd/MM/yyyy':'UTC' }}</time></a>
                            </li>
                        </ul>
                    </nav>

                    <nav class="nav">
                        <h2 class="headline">Notícias mais Acessadas</h2>

                        <ul class="items">
                            <li class="item" *ngFor="let n of mostViewed">
                                <h3 class="title"><a class="description-link" href="/a-sabesp/central-noticias/noticias/{{n.id}}">{{ n.title }}</a></h3>
                                <a class="date" href="/a-sabesp/central-noticias/noticias/{{n.id}}"><time attr.datetime="{{ n.date }}">{{ n.date | date:'dd/MM/yyyy':'UTC' }}</time></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    </section>
</main>
