import { Title } from '@angular/platform-browser';
import { Component, ViewEncapsulation } from '@angular/core';
import { NewsService } from '../news.service';
import { News } from '../news';
import { Category } from '../category';
import { Pagination } from '../pagination';
import { NoticeService } from '../notice.service';
import { Notice } from '../notice';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'noticias.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NoticiasComponent {
  news: Pagination<News> = { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] };
  perPage = 6;
  categoryId = "";
  startDate: Date | null = null;
  endDate: Date | null = null;
  q = "";
  order = "";
  mostViewed: News[] = [];
  noticies: Notice[] = [];
  categories: Category[] = [];
  page = Number(1);

  constructor(private newsService: NewsService, private noticeService: NoticeService, private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Notícias ");
    this.getNews(1);
    this.getMostViewedNews();
    this.getMostViewedNoticies();
    this.getCategories();
  }

  getNews(page: Number): void {
    this.page = Number(page);
    if (this.q == "" && this.order == "") {
      this.order = "DESC";
    }
    this.newsService.getNews(this.categoryId, this.startDate, this.endDate, this.q, this.order, page, this.perPage)
      .subscribe(news => this.news = news);
  }

  getMostViewedNews(): void {
    this.newsService.getMostViewedNews()
      .subscribe(news => this.mostViewed = news);
  }

  getMostViewedNoticies(): void {
    this.noticeService.getMostViewedNoticies()
      .subscribe(notices => this.noticies = notices);
  }

  getCategories(): void {
    this.newsService.getNewsCategories()
      .subscribe(categories => this.categories = categories);
  }

  filterTypeSearch(searchQuery: any) {
    if (searchQuery.q == "" && searchQuery.order == "") {
      searchQuery.order = "DESC";
    }
    this.newsService.getNews(searchQuery.categoryId, searchQuery.startDate, searchQuery.endDate, searchQuery.q, searchQuery.order, this.page, this.perPage)
    .subscribe(news => this.news = news);

  }


}
