import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cuidando-do-meio-ambiente',
  standalone: true,
  imports: [],
  templateUrl: './cuidando-do-meio-ambiente.component.html',
  styleUrl: './cuidando-do-meio-ambiente.component.css'
})
export class CuidandoDoMeioAmbienteComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Meio Ambiente ");
  }
}
