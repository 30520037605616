import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reciclagem-oleo',
  standalone: true,
  imports: [],
  templateUrl: './reciclagem-oleo.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css']
})
export class ReciclagemOleoComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Programa de Reciclagem de Óleo de Frituras ");
}
}
