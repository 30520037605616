import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-obras-esgotos',
  standalone: true,
  imports: [],
  templateUrl: './obras-esgotos.component.html',
  styleUrl: './obras-esgotos.component.css'
})
export class ObrasEsgotosComponent {

  constructor(
    private title: Title
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Obras de Esgotos")
  }
}
