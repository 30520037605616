<main class="main">
    <meta name="description" content="Descubra as iniciativas da Sabesp para o controle de perdas na distribuição de água e garanta eficiência no saneamento. Saiba mais!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua/distribuicao-agua" title="Distribuição de Água">Distribuição de Água</a></li>
                <li><a clbreadcrumb-link href="javascript:void(0);" title="Controle de Perdas">Controle de Perdas</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Controle de Perdas</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="./assets/images/controle-perdas.jpg" alt="Controle de Perdas" title="Controle de Perdas">
            </picture>

            <p class="-space-md">As perdas de água nos sistemas de abastecimento correspondem à diferença entre o volume total de água produzido nas estações de tratamento e a soma dos volumes medidos nos hidrômetros instalados nos imóveis dos clientes.</p>

            <h2 class="subheadline">As perdas totais de água são divididas em duas:</h2>

            <div class="g-columns supercards">
                <div class="item -gray">
                    <div class="info">
                        <h3 class="subheadline">Perdas Físicas ou Reais</h3>
                        <p>São referentes aos volumes de água que não são consumidos por serem perdidos em vazamentos em seu percurso, desde as estações de tratamento de água até os pontos de entrega nos imóveis dos clientes. <strong class="-txt-blue">Esses vazamentos ocorrem, principalmente, devido ao desgaste das tubulações com seu envelhecimento e às pressões elevadas</strong>. Existem dois tipos de vazamentos: os vazamentos visíveis, que surgem na superfície do pavimento e das calçadas, sendo informados à Sabesp pela população e rapidamente reparados, e os vazamentos não visíveis, que não aparecem na superfície e cuja localização depende da realização de ações de varredura nas redes e ramais para sua localização, com a utilização de equipamentos por métodos acústicos.</p>
                    </div>
                </div>

                <div class="item -gray">
                    <div class="info">
                        <h3 class="subheadline">Perdas não Físicas ou Aparentes</h3>
                        <p>São os volumes de água que são consumidos, mas não são contabilizados pela empresa, principalmente devido às irregularidades (como fraudes e ligações clandestinas - os chamados “gatos”) e à submedição dos hidrômetros. Assim, a parcela de perdas não físicas ou aparentes representam, basicamente, perda de faturamento da empresa, não equivalendo à perda física do recurso hídrico.</p>
                        <p>Portanto, o nível de perdas de água nos sistemas de abastecimento está diretamente ligado às condições da infraestrutura instalada e à eficiência operacional e comercial.</p>
                        <p><strong class="-txt-blue">Não existe sistema com perda “zero”</strong>, ou seja, todos os sistemas de abastecimento do mundo, por melhor que sejam a infraestrutura, sua operação e manutenção, possuem perdas de água.</p>
                    </div>
                </div>
            </div>
        </div>
    </article>

    <section class="content -gray">
        <div class="container">
            <h2 class="subheadline">Ações para o combate às perdas</h2>
            <p>O combate às perdas demanda um esforço permanente. Isso acontece porque, se nada for feito, com o passar do tempo elas apresentam uma tendência natural de aumento, uma vez que a infraestrutura envelhece, surgem novos vazamentos, os hidrômetros perdem precisão e as irregularidades aumentam.<br> Assim, é preciso realizar um nível de esforço e aplicação de recursos constante para evitar que as perdas aumentem, e ainda um nível adicional para reduzir aquelas que são inevitáveis.</p>

            <h2 class="subheadline">As principais ações para o combate às perdas físicas ou reais são:</h2>
            <ul class="list -fw-medium">
                <li>Gerenciamento de pressões a partir da execução de obras de setorização dos sistemas de distribuição de água, incluindo a instalação de Válvulas Redutoras de Pressão em áreas específicas com pressões elevadas, para reduzir e estabilizar as pressões, diminuindo a quantidade de novos vazamentos e a vazão dos existentes;</li>
                <li>Varreduras para localização de vazamentos não visíveis;</li>
                <li>Reparo dos vazamentos visíveis e não visíveis em redes e ramais;</li>
                <li>Renovação da infraestrutura, com a substituição de redes e ramais antigos e deteriorados;</li>
                <li>Execução de obras de adequação dos setores de abastecimento, com melhoria dos níveis de pressão nas redes e a redução do tamanho das áreas de controle, de modo a otimizar sua operação e manutenção.</li>
            </ul>

            <h2 class="subheadline">As principais ações para o combate às perdas não físicas ou aparentes são:</h2>
            <ul class="list -fw-medium">
                <li>Substituição de hidrômetros, de modo a se obter a medição precisa dos volumes entregues aos clientes;</li>
                <li>Combate a irregularidades (“caça-fraude”).</li>
            </ul>
        </div>
    </section>

    <section class="content">
        <div class="container">
            <h2 class="subheadline">Programa corporativo de redução de perdas</h2>

            <p><b>A Sabesp investe em ações de combate às perdas há muitos anos.</b></p>
            <p>Consciente de sua responsabilidade com relação à situação de escassez dos recursos hídricos e com foco na busca de maior eficiência operacional, em 2009 a Companhia decidiu intensificar estes esforços por meio da implantação de um Programa de longo prazo buscando a garantia de sua continuidade e redução dos custos financeiros por meio do seu financiamento.</p>
            <p><b>Nesse Programa já foram aplicados R$ 9,4 bilhões (valores correntes), no período entre 2009 e 2023.</b></p>
            <p>Na etapa inicial do programa, de 2009 a 2012, <strong class="-txt-blue">foram aplicados cerca de $ 1,2 bilhões em ações de combate as perdas</strong>, com parte dos recursos financiada pelo BNDES – Banco Nacional de Desenvolvimento Econômico e Social.</p>
            <p>A etapa atual, de 2013 a 2023, tem parte dos recursos financiada pela Agência de Cooperação do Governo Japonês: JICA – Japan International Cooperation Agency.</p>
            <p><b>A seguir é apresentada a evolução dos indicadores de perdas de água da Sabesp ao longo dos últimos anos:</b></p>

            <picture class="picture -border -radius -full -contain">
                <h2 class="subheadline">Histórico - Índices de Perdas Sabesp</h2>
                <img loading="lazy" fetchpriority="low" width="1192" height="426" src="./assets/images/historico-indices-perdas-sabesp.png" alt="Histórico - Índices de Perdas Sabesp" title="Histórico - Índices de Perdas Sabesp">
            </picture>

            <p>Como se observa no gráfico acima, de dezembro de 2008 a dezembro de 2023, o índice de perdas por ligação (litros por ligação por dia), <strong class="-txt-blue">reduziu de 430 para 260 litros por ligação por dia</strong>. Nesse mesmo período, o índice de perdas reais ou físicas caiu 2,7 pontos percentuais, passando de 22,2% para 19,5%.</p>
            <p>A meta do Programa é atingir, até dezembro de 2024, um indicador de perdas por ligação de 255 litros por ligação por dia, comparável a sistemas de abastecimento de países desenvolvidos.</p>
        </div>
    </section>
</main>
