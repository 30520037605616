<section class="carousel">
    <div class="carousel-slider">
        <div class="item -green">
            <div class="container">
                <picture class="figure">
                    <img loading="eager" fetchpriority="high" width="642" height="298"
                        src="/assets/images/template-carrossel-2.png"
                        alt="Conexão com a vida"
                        title="Conexão com a vida">
                </picture>

                <div class="text">
                    <h2 class="title">Título Carrossel</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi
                        vel, tristique blandit purus. Duis et sagittis urna.<br>
                        Pellentesque enim felis, aliquet vel erat at, vehicula fringilla ante. Aenean varius risus nec
                        finibus feugiat. Cras commodo dignissim felis, et sodales nulla tincidunt non. Fusce lobortis
                        justo in dictum ultrices.<br>
                        Aliquam sodales pharetra dictum. Integer eleifend egestas laoreet.
                    </p>
                    <a class="link -white" href="javascript:void(0);" title="CTA">CTA</a>
                </div>
            </div>
        </div>

        <div class="item -blue">
            <div class="container">
                <picture class="figure">
                    <img loading="lazy" fetchpriority="low" width="642" height="298"
                        src="assets/images/template-carrossel-2.png"
                        alt="Conexão com a vida"
                        title="Conexão com a vida">
                </picture>

                <div class="text">
                    <h2 class="title">Título Carrossel</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi
                        vel, tristique blandit purus. Duis et sagittis urna.<br>
                        Pellentesque enim felis, aliquet vel erat at, vehicula fringilla ante. Aenean varius risus nec
                        finibus feugiat. Cras commodo dignissim felis, et sodales nulla tincidunt non. Fusce lobortis
                        justo in dictum ultrices.<br>
                        Aliquam sodales pharetra dictum. Integer eleifend egestas laoreet.
                    </p>
                    <a class="link -white" href="javascript:void(0);" title="CTA">CTA</a>
                </div>
            </div>
        </div>

        <div class="item -tangelo" data-nav-color="black">
            <div class="container">
                <picture class="figure">
                    <img loading="lazy" fetchpriority="low" width="642" height="298"
                        src="/assets/images/template-carrossel-2.png"
                        alt="Conexão com a vida"
                        title="Conexão com a vida">
                </picture>

                <div class="text">
                    <h2 class="title">Título Carrossel</h2>
                    <p class="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eros tortor, euismod vel nisi
                        vel, tristique blandit purus. Duis et sagittis urna.<br>
                        Pellentesque enim felis, aliquet vel erat at, vehicula fringilla ante. Aenean varius risus nec
                        finibus feugiat. Cras commodo dignissim felis, et sodales nulla tincidunt non. Fusce lobortis
                        justo in dictum ultrices.<br>
                        Aliquam sodales pharetra dictum. Integer eleifend egestas laoreet.
                    </p>
                    <a class="link -black" href="javascript:void(0);" title="CTA">CTA</a>
                </div>
            </div>
        </div>
    </div>
</section>