<main class="main">
    <meta name="description" content="Acesse os releases da Sabesp e fique atualizado com as últimas informações e novidades divulgadas para a imprensa. Confira agora!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Central de Notícias">Central de Notícias</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Releases">Releases</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <app-filter
        [classList]="'filter'"
        [totalItems]="1"
        [categories]="categories"
        (filterChange)="filterTypeSearch($event)">
    </app-filter>

    <section class="content">
        <div class="container petals">
            <h1 class="headline title-link">Releases</h1>

            <div class="listing">
                <div class="item" *ngFor="let r of releases.items">
                    <h2 class="title"><a routerLink="/a-sabesp/central-noticias/releases/{{r.id}}">{{ r.title }}</a></h2>
                    <p class="published"><a routerLink="/a-sabesp/central-noticias/releases/{{r.id}}" tabindex="-1">Publicado em
                            <time attr.datetime="{{ r.date }}">{{ r.date | date:'dd/MM/yyyy':'UTC' }}</time></a></p>
                    <p class="description"><a routerLink="/a-sabesp/central-noticias/releases/{{r.id}}" tabindex="-1">{{
                            r.summary }}</a></p>
                    <!-- <a class="link" routerLink="/a-sabesp/central-noticias/releases/{{r.id}}" title="Leia mais">Leia mais</a> -->
                </div>
            </div>

            <app-pagination [totalItems]="releases.totalItems" [itemsPerPage]="releases.itemsPerPage"
                [totalPages]="releases.totalPages" (pageChange)="getReleases($event)"></app-pagination>
        </div>
    </section>
</main>
