<main class="main">
    <meta name="description" content="Saiba como a Sabesp utiliza biogás para promover sustentabilidade e inovação no saneamento básico. Descubra mais sobre nossas tecnologias!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/inovacao-e-tecnologia/pesquisa-desenvolvimento-inovacao" title="Inovação e Tecnologia">Inovação e Tecnologia</a></li>
                <li><a class="breadcrumb-link" href="/a-sabesp/inovacao-e-tecnologia/biogas" title="Biogás">Biogás</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Biogás</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/a-sabesp/biogas.jpg" alt="Biogás" title="Funcionário da Sabesp abastecendo veículo com biometano​
                ">
            </picture>

            <p>A transformação que queremos para o mundo, começa conosco. Aqui na Sabesp desde 2018, utilizamos o gás gerado pelo esgoto para movimentar a nossa frota de veículos na cidade de Franca.</p>
            <p>A Unidade de Beneficiamento de Biogás da Estação de Tratamento de Esgoto (ETE) Franca produz biometano, um combustível veicular, a partir dos gases gerados no tratamento do esgoto realizado na estação, que passam por um processo de remoção de impurezas, umidade e aumento da concentração de metano. O biometano substitui a gasolina, o etanol e o GNV (Gás Natural Veicular).</p>
            <p>A ETE Franca trata 550 litros de esgoto por segundo e produz cerca de 3 mil m³ de biogás por dia. Atualmente, são realizados, em média, 208 abastecimentos por mês em 40 veículos.</p>
            <p>Com esse projeto inovador, utilizamos uma fonte de energia limpa, reduzimos o consumo de combustíveis fósseis em nossa frota de veículos e também a emissão de gases de efeito estufa. Alinhados aos conceitos do ESG, contribuímos para o crescimento deste modelo de economia circular nos sistemas de tratamento de esgoto e para a redução das mudanças climáticas.</p>
        </div>
    </article>
</main>
