import { AfterViewChecked, Component, Input, ViewEncapsulation } from '@angular/core';
import { TinySliderInstance, tns } from 'tiny-slider';
import { CarouselItem } from '../template-carrossel/template-carrossel';

@Component({
  selector: 'app-carrossel',
  templateUrl: './carrossel.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', './carrossel.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CarrosselComponent {
  // @Input() carouselItems!: CarouselItem[];

  carousel!: TinySliderInstance;
  carouselStarted: boolean = false;
  bgColors = ['-green', '-green-teal', '-blue', '-dark-blue', '-tangelo'];

  ngAfterViewChecked() {
    if (!this.carouselStarted) {
      this.initCarousel();
      this.carouselStarted = true;
    }
  }

  initCarousel() {
    this.carousel = tns({
      container: '.carousel-slider',
      items: 1,
      autoplay: false,
      loop: true,
      controls: true,
      controlsText: ['Anterior', 'Próximo'],
      navPosition: 'bottom',
      autoplayText: ['', ''],
      autoplayButtonOutput: false,
      autoHeight: true,
      lazyload: true
    });

    setTimeout(() => {
      this.carouselInit();
      this.carousel?.events.on('indexChanged', (info: any) => {
        info.container.closest('.tns-outer').dataset.navColor = info.slideItems[info.index]?.dataset.navColor;
      });
    }, 0);
  }

  carouselInit(): void {
    const info: any = this.carousel.getInfo();
    const container: HTMLElement = info.container.closest('.tns-outer');
    if (container) {
      container.dataset['navColor'] = info.slideItems[info.index].dataset.navColor;

      let dots = document.querySelectorAll('.tns-nav [data-nav]');
      dots?.forEach((dot: any) => {
        dot.classList.add('classlist');
        const span = document.createElement('span');
        span.textContent = `<span>${parseInt(dot.dataset.nav) + 1}</span>`;
        dot.appendChild(span);
      });
  
      

      let nav = document.querySelector(`#${info.container.id}-ow .tns-nav`) as HTMLElement,
        controls = document.querySelector(`#${info.container.id}-ow .tns-controls`) as HTMLElement;
      controls?.style.setProperty('--nav-width', `${nav?.offsetWidth}px`);

      // Adiciona classes para os botões de navegação anterior e próximo
      const prev = controls.querySelector('[data-controls="prev"]');
      prev?.classList.add('slider-prev');

      const next = controls.querySelector('[data-controls="next"]');
      next?.classList.add('slider-next');
    }
  }
}
