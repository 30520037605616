import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Release } from './release';
import { Pagination } from './pagination';
import { environment } from '../environments/environment';
import { Category } from './category';
import { formatDate } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class ReleaseService {
  private releasesUrl = environment.apiUrl + '/api/v1/releases';
  private mostViewedReleasesUrl = environment.apiUrl + '/api/v1/mostViewedReleases';
  private releaseCategoriesUrl = environment.apiUrl + '/api/v1/releaseCategories';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getReleases(categoryId: string, startDate: Date | null, endDate: Date | null, q: string, order: string, page: Number, perPage: Number): Observable<Pagination<Release>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('perPage', perPage.toString());
    if (categoryId) {
      params = params.set('categoryId', categoryId);
    }
    if (startDate && endDate) {
      params = params.set('startDate', formatDate(startDate, 'dd-MM-yyyy', 'en-US'));
      params = params.set('endDate', formatDate(endDate, 'dd-MM-yyyy', 'en-US'));
    }
    if (q) {
      params = params.set('q', q);
    }
    if (order) {
      params = params.set('order', order);
    }
    return this.http.get<Pagination<Release>>(this.releasesUrl, { params })
      .pipe(
        catchError(ErrorService.handle<Pagination<Release>>('getReleases', { currentPage: 1, totalItems: 0, itemsPerPage: 0, totalPages: 1, items: [] }))
      );
  }

  getReleaseById(id: string): Observable<Release> {
    const url = `${this.releasesUrl}/${id}`;
    return this.http.get<Release>(url).pipe(
      catchError(ErrorService.handle<Release>(`getReleaseById id=${id}`))
    );
  }

  getMostViewedReleases(): Observable<Release[]> {
    const url = `${this.mostViewedReleasesUrl}`;
    return this.http.get<Release[]>(url).pipe(
      catchError(ErrorService.handle<Release[]>('getMostViewedReleases', []))
    );
  }

  getReleaseCategories(): Observable<Category[]> {
    const url = `${this.releaseCategoriesUrl}`;
    return this.http.get<Category[]>(url).pipe(
      catchError(ErrorService.handle<Category[]>('getReleaseCategories', []))
    );
  }
}