
<main class="main">
  <h1>Nossa essência é cuidar das pessoas e do meio ambiente</h1>

  <section class="hero">
    <div class="hero-slider">
      <div class="item" *ngFor="let banner of banners">
        <a target="_blank" rel="noopener noreferrer" href="{{ banner.href }}" tabindex="-1">
          <picture class="figure">
            <source media="(max-width: 767px)" width="430" height="350" srcset="{{ banner.mobileImg }}">
            <img loading="eager" fetchpriority="high" width="1440" height="480" src="{{ banner.img }}" data-src="{{ banner.img }}" alt="{{ banner.alt }}" title="{{ banner.alt }}">
          </picture>
        </a>
        <div class="overlay">
          <div class="container">
            <h2 class="title">{{ banner.title }}</h2>
            <p class="description">{{ banner.description }}</p>
            <a class="link -white" href="{{ banner.href }}" title="{{ banner.hrefText }}">{{ banner.hrefText | uppercase }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="virtual">
    <div class="container">
      <h2 class="headline">Agência Virtual</h2>

      <div class="buttons">
        <a class="item" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual.sabesp.com.br/fatura-completa">
          <figure class="icon">
            <img loading="eager" fetchpriority="high" width="45" height="48" src="./assets/images/icon/segunda-via-conta.svg" alt="Ícone da Sabesp para 2ª via de conta" title="Ícone da Sabesp para 2ª via de conta">
          </figure>
          <h3 class="caption">2ª via de conta</h3>
        </a>
        <a class="item" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual.sabesp.com.br/fatura-completa">
          <figure class="icon">
            <img loading="eager" fetchpriority="high" width="45" height="48" src="./assets/images/icon/pagamento.svg" alt="Imagem de mão com moeda para informar pagamento da Sabesp" title="Imagem de mão com moeda para informar pagamento da Sabesp">
          </figure>
          <h3 class="caption">Informar pagamento</h3>
        </a>
        <a class="item" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual.sabesp.com.br/parcelamento">
          <figure class="icon">
            <img loading="eager" fetchpriority="high" width="45" height="48" src="./assets/images/icon/parcelas.svg" alt="Imagem de calendário com moeda para parcelamento de contas da Sabesp" title="Imagem de calendário com moeda para parcelamento de contas da Sabesp">
          </figure>
          <h3 class="caption">Parcelar contas</h3>
        </a>
        <a class="item" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual.sabesp.com.br/troca-titularidade/identificacao">
          <figure class="icon">
            <img loading="eager" fetchpriority="high" width="45" height="48" src="./assets/images/icon/titular.svg" alt="Imagem de lápis para alterar titularidade da conta da Sabesp" title="Imagem de lápis para alterar titularidade da conta da Sabesp">
          </figure>
          <h3 class="caption">Alterar <br>titular</h3>
        </a>
      </div>

      <a class="link -white" href="https://agenciavirtual.sabesp.com.br" target="_blank">VER TODOS OS SERVIÇOS</a>
    </div>
  </section>

  <section class="acontece">
    <div class="container">
      <h2 class="headline">Acontece</h2>

      <div class="cards">
        <div class="item">
          <h3 class="title">
            <a class="title-link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-inicia-obras-que-ampliam-saneamento-em-praia-grande-20-06-2024">Onda Limpa</a>
          </h3>
          <figure class="figure">
            <a class="thumb-link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-inicia-obras-que-ampliam-saneamento-em-praia-grande-20-06-2024">
              <img loading="lazy" fetchpriority="low" width="356" height="200" src="./assets/images/praia-grande.png" alt="Onda Limpa" title="Onda Limpa">
            </a>
          </figure>
          <div class="info">
            <p class="description">
              <a class="description-link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-inicia-obras-que-ampliam-saneamento-em-praia-grande-20-06-2024" tabindex="-1">Mais saúde e qualidade de vida com as obras que ampliam saneamento em Praia Grande
                .</a>
            </p>
            <a class="link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-inicia-obras-que-ampliam-saneamento-em-praia-grande-20-06-2024" title="Leia mais">Leia mais</a>
          </div>
        </div>

        <div class="item">
          <h3 class="title">
            <a class="title-link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-trabalhara-junto-a-unfccc-para-aumentar-resiliencia-hidrica-no-mundo-20-06-2024">Resiliência Hídrica</a>
          </h3>
          <figure class="figure">
            <a class="thumb-link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-trabalhara-junto-a-unfccc-para-aumentar-resiliencia-hidrica-no-mundo-20-06-2024">
              <img loading="lazy" fetchpriority="low" width="356" height="200" src="./assets/images/Foto_356x200px_ETA ABV.jpg" alt="Resiliência Hídrica" title="Resiliência Hídrica">
            </a>
          </figure>
          <div class="info">
            <p class="description">
              <a class="description-link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-trabalhara-junto-a-unfccc-para-aumentar-resiliencia-hidrica-no-mundo-20-06-2024" tabindex="-1">Parceria com Convenção-Quadro das Nações Unidas sobre Mudança do Clima (UNFCCC) para desenvolver atividades e projetos modelo.</a>
            </p>
            <a class="link" target="_blank" href="/a-sabesp/central-noticias/noticias/sabesp-trabalhara-junto-a-unfccc-para-aumentar-resiliencia-hidrica-no-mundo-20-06-2024" title="Leia mais">Leia mais</a>
          </div>
        </div>

        <div class="item -highlight">
          <h3 class="title">Nossa essência é transformar vidas</h3>
          <div class="video">
            <div class="embed-responsive embed-responsive-16by9" style="background-image: url(https://i.ytimg.com/vi/-5TlSuZ_flM/sddefault.jpg)">
              <iframe class="embed-responsive-item" data-src="https://www.youtube.com/embed/jZHKXeat-Eo?cc_load_policy=1&controls=1&rel=0&showinfo=0&loop=1&modestbranding=0&autoplay=0&fs=0" loading="lazy" fetchpriority="low" width="100%" height="100%" title="YouTube vídeo player" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; autoplay;" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
          <div class="info">
            <p class="description">A Sabesp leva água tratada e saneamento muito além do básico para milhões de pessoas, promovendo saúde, dignidade e sustentabilidade. Hoje, ontem e sempre.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="sustentabilidade">
    <div class="container petals">
      <h2 class="headline">Inovação e Sustentabilidade</h2>

      <div class="cards">
        <div class="item">
          <h3 class="title">
            <a class="title-link" target="_blank" href="/a-sabesp/inovacao-e-tecnologia/usinas-fotovoltaicas">Energia Fotovoltaica</a>
          </h3>
          <figure class="figure">
            <a class="thumb-link" target="_blank" href="/a-sabesp/inovacao-e-tecnologia/usinas-fotovoltaicas">
              <img loading="lazy" fetchpriority="low" width="356" height="200" src="./assets/images/energia_fotovoltaica.jpg" alt="Inovação Sustentável" title="Inovação Sustentável">
            </a>
          </figure>
          <div class="info">
            <a class="link" target="_blank" href="/a-sabesp/inovacao-e-tecnologia/usinas-fotovoltaicas" title="Leia mais">Leia mais</a>
          </div>
        </div>

        <div class="item">
          <h3 class="title">
            <a class="title-link" target="_blank" href="/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos#aguareuso">Aquapolo Ambiental</a>
          </h3>
          <figure class="figure">
            <a class="thumb-link" target="_blank" href="/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos#aguareuso">
              <img loading="lazy" fetchpriority="low" width="356" height="200" src="./assets/images/aquapolo-ambiental.jpg" alt="Aquapolo Ambiental" title="Aquapolo Ambiental">
            </a>
          </figure>
          <div class="info">
            <a class="link" target="_blank" href="/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/subprodutos-esgotos#aguareuso
" title="Leia mais">Leia mais</a>
          </div>
        </div>

        <div class="item">
          <h3 class="title">
            <a class="title-link" target="_blank" href="https://www.youtube.com/watch?v=76W7DpNkR3s">Preservação da Mata Atlântica</a>
          </h3>
          <figure class="figure">
            <a class="thumb-link" target="_blank" href="https://www.youtube.com/watch?v=76W7DpNkR3s">
              <img loading="lazy" fetchpriority="low" width="356" height="200" src="./assets/images/floresta.jpg" alt="Preservação da Mata Atlântica" title="Preservação da Mata Atlântica">
            </a>
          </figure>
          <div class="info">
            <a class="link" target="_blank" href="https://www.youtube.com/watch?v=76W7DpNkR3s" title="Assista">Assista</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>