<div id="menuMobile" class="menu-mobile">
    <div class="close">
        <button (click)="closeMenu()">Fechar</button>
        <div class="separator separator-header"></div>
    </div>

    <ul class="nav">
        @for (item of menuItems; track $index; let last = $last) {
        <li [ngClass]="{'active' : item.open}" (click)="clickItem(item)">
            <a>
                <span>{{ item.displayName }}</span>
                <img src="/assets/images/icon/arrow-down.svg">
            </a>
        </li>

        <!-- LEVEL 2 -->
        <ul *ngIf="item.children && item.open" class="level-2">

            @for (child2 of item.children; track $index; let last = $last) {

            <li [ngStyle]="{'background-color': child2.open && !child2.route ? '#F2F4F8' : '' }" (click)="clickItem(child2)">
                <a>
                    <span>{{ child2.displayName }}</span>
                    <img *ngIf="child2.children" [ngStyle]="{ 'rotate': child2.open ? '180deg' : '' }"
                        src="/assets/images/icon/arrow-down.svg">
                </a>
            </li>

            <!-- LEVEL 3 -->
            <ul *ngIf="child2.children && child2.open" class="level-3">

                @for (child3 of child2.children; track $index) {

                <li (click)="clickItem(child3)" [ngStyle]="{ 'background-color': child3.open && !child3.route ? '#E9ECF1' : '' }">
                    <a>
                        <span>{{ child3.displayName }}</span>
                        <img *ngIf="child3.children" [ngStyle]="{ 'rotate': child3.open ? '180deg' : '' }"
                            src="/assets/images/icon/arrow-down.svg">
                    </a>
                </li>

                <!-- LEVEL 4 -->
                <ul *ngIf="child3.children && child3.open" class="level-4">

                    @for (child4 of child3.children; track $index) {

                    <li (click)="clickItem(child4)">
                        <a>
                            <span>{{ child4.displayName }}</span>
                            <img *ngIf="child4.children" [ngStyle]="{ 'rotate': child4.open ? '180deg' : '' }"
                                src="/assets/images/icon/arrow-down.svg">
                        </a>
                    </li>
                    }
                </ul>
                }
            </ul>
            }
        </ul>

        <div *ngIf="!last" class="separator"></div>
        }
    </ul>

    <div class="menu-mobile__footer">
        <a target="_blank" href="https://agenciavirtual.sabesp.com.br" class="agencia-virtual">
            Agência Virtual
        </a>
        <ul>
            <li>
                <a href="https://ri.sabesp.com.br" target="_blank" title="Investidores">
                    Investidores
                </a>
            </li>
            <li>
                <a href="/servicos/para-fornecedores" title="Fornecedores">Fornecedores</a>
            </li>
            <li>
                <a href="/fale-com-a-sabesp" title="Fale com a Sabesp">Fale com a Sabesp</a>
            </li>
            <li>
                <a href="https://ri.sabesp.com.br/en" target="_blank" title="English">
                    English
                </a>
            </li>
        </ul>
    </div>
</div>