<main class="main">
    <meta name="description" content="Acesse os relatórios da Sabesp para obter informações detalhadas sobre nossas atividades. Transparência e responsabilidade em nossas publicações para todos os públicos.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="javascript:void(0);" title="Documentos e Relatórios">Documentos e Relatórios</a></li>
                <li><a href="/sustentabilidade/documentos-relatorios/relatorios_sustentabilidade"
                        title="Relatórios de Sustentabilidade">Relatórios de Sustentabilidade</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <h1 class='headline'>Relatórios de Sustentabilidade</h1>

            <p>O relatório é uma ferramenta de prestação de contas e de diálogo da Companhia com seus públicos. Por meio dele, a Empresa se posiciona, comenta seus desafios e alinha expectativas. No ambiente de mercado de capitais, uma comunicação transparente e esclarecedora se reflete em credibilidade. </p>

            <p>Na Sabesp, o relatório segue as diretrizes Global Reporting Iniciative (GRI), utilizando-se de um método participativo e abrangente que envolve seus públicos de relacionamento internos e externos em todos os níveis, do topo à base. </p>

            <br><br><h2>2023</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/relatorio_sustentabilidade_2023.pdf"> Relatórios de Sustentabilidade  2023 - versão português (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/relatorio_sustentabilidade_2023.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sustainability_report_2023.pdf"> Relatórios de Sustentabilidade  2023 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sustainability_report_2023.pdf"><span>Acessar</span></a></td></tr>

            </tbody></table></div>

            <br><br><h2>2022</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_relatorio_sustentabilidade_2022.pdf"> Relatório de Sustentabilidade 2022 - versão português (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_relatorio_sustentabilidade_2022.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_sustainability_report_2022.pdf"> Relatório de Sustentabilidade 2022 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_sustainability_report_2022.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2021</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_relatorio_sustentablidade_2021.pdf"> Relatório de Sustentabilidade 2021 - versão português (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_relatorio_sustentablidade_2021.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sustainability_report2021.pdf"> Relatório de Sustentabilidade 2021 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sustainability_report2021.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2020</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_relatorio_sustentabilidade_2020.pdf"> Relatório de Sustentabilidade 2020 - versão português (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_relatorio_sustentabilidade_2020.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_rs_2020_eng.pdf"> Relatório de Sustentabilidade 2020 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_rs_2020_eng.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://www.youtube.com/watch?v=n_Rs0tLZVPI">  Assista ao vídeo sobre o Relatório de Sustentabilidade</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://www.youtube.com/watch?v=n_Rs0tLZVPI"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


            <br><br><h2>2019</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/rs2019_portugues.pdf"> Relatório de Sustentabilidade 2019- versão português (pdf)  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/rs2019_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/rs2019_ingles.pdf"> Relatório de Sustentabilidade 2019- versão inglês (pdf) </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/rs2019_ingles.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>



            <br><br><h2>2018</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_rs_2018_portugues(1).pdf">  Relatório de Sustentabilidade 2018- versão português (pdf)  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_rs_2018_portugues(1).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_rs_2018_english.pdf"> Relatório de Sustentabilidade 2018- versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_rs_2018_english.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="http://spark.adobe.com/page/BYcqYLm8Wxyw0/"> Relatório de Sustentabilidade 2018- versão português </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="http://spark.adobe.com/page/BYcqYLm8Wxyw0/"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://spark.adobe.com/page/tbDmdSkimOIo8/"> Relatório de Sustentabilidade 2018- versão inglês</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://spark.adobe.com/page/tbDmdSkimOIo8/"><span>Acessar</span></a></td></tr>

            </tbody></table></div>


            <br><br><h2>ANOS ANTERIORES</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2017_rs_portugues.pdf"> Relatório de Sustentabilidade 2017- versão português (pdf)  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2017_rs_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2017_sustainability_report_english.pdf"> Relatório de Sustentabilidade 2017- versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2017_sustainability_report_english.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2016_rs_portugues.pdf"> Relatório de Sustentabilidade 2016 - versão português (pdf)  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2016_rs_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/sociedade_meioamb/sustainability_report_2016.pdf"> Relatório de Sustentabilidade 2016 - versão inglês (pdf) </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/sociedade_meioamb/sustainability_report_2016.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2015_rs_portugues.pdf"> Relatório de Sustentabilidade 2015 - versão português (pdf)  </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2015_rs_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2015_sustainability_report_english.pdf"> Relatório de Sustentabilidade 2015 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2015_sustainability_report_english.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2014_rs_portugues.pdf"> Relatório de Sustentabilidade 2014 - versão português (pdf) </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2014_rs_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2014_sustainability_report_english.pdf"> Relatório de Sustentabilidade 2014 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2014_sustainability_report_english.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2013_rs_portugues.pdf">  Relatório de Sustentabilidade 2013 - versão português (pdf) </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2013_rs_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2013_sustainability_report_english.pdf"> Relatório de Sustentabilidade 2013 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2013_sustainability_report_english.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/uploads/file/asabesp_doctos/sabesp_rs_2012_portugues.pdf"> Relatório de Sustentabilidade 2012 - versão português (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/uploads/file/asabesp_doctos/sabesp_rs_2012_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2012_sustainability_report_english.pdf"> Relatório de Sustentabilidade 2012 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2012_sustainability_report_english.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2012_is_espanol.pdf"> Relatório de Sustentabilidade 2012 - versão espanhol (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2012_is_espanol.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2011_rs_portugues.pdf"> Relatório de Sustentabilidade 2011 - versão português (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2011_rs_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2010_rs_portugues.pdf"> Relatório de Sustentabilidade 2010 - versão português (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2010_rs_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2010_sustainability_report_english.pdf"> Relatório de Sustentabilidade 2010 - versão inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2010_sustainability_report_english.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2010_is_espanol.pdf"> Relatório de Sustentabilidade 2010 - versão espanhol (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/relatorios_sustentabilidade/sabesp_2010_is_espanol.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/uploads/file/sociedade_meioamb/rs_2009_portugues.pdf"> Relatório de Sustentabilidade 2009 (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/uploads/file/sociedade_meioamb/rs_2009_portugues.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/uploads/file/sociedade_meioamb/rs_2009_ingles.pdf"> Relatório de Sustentabilidade 2009- inglês (pdf)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/uploads/file/sociedade_meioamb/rs_2009_ingles.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


        </div>
    </article>

</main>
