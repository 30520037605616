import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fornecimento-agua',
  standalone: true,
  imports: [],
  templateUrl: './fornecimento-agua.component.html',
  styleUrl: './fornecimento-agua.component.css'
})
export class FornecimentoAguaComponent {
  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Recursos Hídricos ");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Descubra como a Sabesp garante o fornecimento de água para milhões de pessoas, desde a captação em rios e represas até o tratamento e distribuição. Saiba mais sobre nossas iniciativas de preservação ambiental.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, fornecimento de água, captação de água, tratamento de água, rios, poços, represas, sustentabilidade, preservação ambiental, abastecimento de água. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Fornecimento de Água '
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Fornecimento de Água '
    });
  }
}
