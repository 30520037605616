import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-captacao-agua',
  templateUrl: './captacao-agua.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
})
export class CaptacaoAguaComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Captação de Água ");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Descubra como a Sabesp garante o fornecimento de água para milhões de pessoas, desde a captação em rios e represas até o tratamento e distribuição. Saiba mais sobre nossas iniciativas de preservação ambiental.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, fornecimento de água, captação de água, tratamento de água, rios, poços, represas, sustentabilidade, preservação ambiental, abastecimento de água. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Captação de Água '
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Captação de Água '
    });
  }
}
