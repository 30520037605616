<main class="main">
    <meta name="description" content="Aprenda como economizar água com as dicas da Sabesp. Reduza seu consumo diário e contribua para a preservação dos recursos naturais e a economia financeira. Confira nossas sugestões para o banheiro, cozinha, área de serviço e jardim.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Educação">Educação</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Dicas de Economia de Água">Dicas de Economia de Água</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Dicas de Economia de Água</h1>

            <div class="subtitle -space-md">Uso consciente: economia nos gastos e proteção do meio ambiente!</div>

            <div class="g-columns -gap-left -space-md">
                <div>
                    <p>A água é um recurso natural essencial para nossa vida. Segundo a Organização das Nações Unidas, <strong class="-txt-blue">cada pessoa necessita de 3,3 mil litros de água por mês</strong> (cerca de 110 litros de água por dia) para atender suas necessidades de consumo e higiene. No entanto, aqui no Brasil o consumo por pessoa pode chegar a mais de 200 litros/dia. Pouco mais da metade da água é gasta no banheiro com banhos, descargas ou outras utilizações.</p>

                    <p>Consumir água de forma consciente, além de contribuir para que esse bem precioso não se esgote, também resulta em uma economia financeira significativa.</p>
                </div>
                <div>
                    <picture class="picture">
                        <img loading="eager" fetchpriority="high" width="650" height="260" src="./assets/images/economia-agua-650x260.jpg" alt="Mão abrindo uma torneira com água corrente" title="Dicas de Economia de Água">
                    </picture>
                </div>
            </div>

            <div class="subtitle -space-md">A Sabesp preparou uma série de dicas simples e fáceis de seguir para ajudar você a fazer a sua parte. <strong class="-txt-blue">Confira!</strong></div>

            <div class="accordion">
                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#no-banheiro" class="accordion-link">No Banheiro</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body nobanheiro">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <div class="columns -auto -v-center">
                                <div>
                                    <picture class="picture align-left mb-0">
                                        <img loading="lazy" fetchpriority="low" width="650" height="260" src="./assets/images/chuveiro-167x342.jpg" alt="Mulher relaxando embaixo de um chuveiro" title="Dicas de Economia de Água">
                                    </picture>
                                </div>
                                <ul class="list" style="padding-left: 0px;">
                                    <li>Tome banhos mais curtos e feche a torneira ao se ensaboar; 5 minutos são suficientes para uma boa higiene;</li>
                                    <li>Ao escovar os dentes, lavar o rosto ou fazer a barba, mantenha a torneira fechada. Abra somente quando for mesmo necessário;</li>
                                    <li><strong class="-txt-blue">O vaso sanitário não deve ser usado como lixeira ou acionado à toa</strong>, pois consome muita água. Também evite jogar papel higiênico para não causar entupimentos.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#na-cozinha" class="accordion-link">Na Cozinha</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <div class="columns -auto -v-center">
                                <ul class="list">
                                    <li>Antes de lavar a louça, <strong class="-txt-blue">retire os restos de comida dos pratos</strong>, talheres e
                                        panelas;</li>
                                    <li>Deixe a torneira fechada enquanto ensaboa a louça e <strong class="-txt-blue">abra apenas no momento de enxaguar</strong>;</li>
                                    <li>Caso tenha máquina de lavar louça, ligue apenas quando estiver cheia.</li>

                                </ul>
                                <div>
                                    <picture class="picture align-left mb-0">
                                        <img loading="lazy" fetchpriority="low" width="650" height="311" data-src="./assets/images/cozinha-650x311.jpg" alt="Lavagem de utensílio de cozinha na pia com água e sabão" title="Lavagem de utensílio de cozinha na pia com água e sabão">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#area-servicos" class="accordion-link">Na Área de Serviço</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>
                            <div>
                                <picture class="picture align-left mb-0">
                                    <img loading="lazy" fetchpriority="low" width="650" height="311" data-src="./assets/images/maquina-de-lavar-205x269.jpg" alt="Roupas coloridas dentro de uma máquina de lavar" title="Roupas coloridas dentro de uma máquina de lavar">
                                </picture>
                            </div>
                            <ul class="list">
                                <li><strong class="-txt-blue">Junte bastante roupa suja</strong> antes de ligar a máquina ou usar o tanque. Não lave uma peça por vez;</li>
                                <li>A água que sai da máquina de lavar pode ser útil em outras atividades, como lavar a calçada, a varanda ou outra parte da casa. </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a (click)="accordionToggle($event)" href="#jardim-area-externa" class="accordion-link">No Jardim e Área Externa</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>

                            <div class="columns -auto -v-center">
                                <ul class="list">
                                    <li><strong class="-txt-blue">Use vassoura e não mangueira</strong> para limpar a calçada, o quintal ou outras áreas da sua casa;</li>
                                    <li>Ao cuidar das plantas, <strong class="-txt-blue">use um regador</strong>. Durante o verão, molhe de manhãzinha ou à noite, pois isso reduz a perda por evaporação. No inverno, a rega pode ser feita dia sim, dia não, pela manhã</li>
                                    <li>Se você tem piscina, <strong class="-txt-blue">faça a limpeza e a manutenção com produtos adequados</strong>. Assim você evita fazer trocas frequentes de água.</li>
                                </ul>
                                <div>
                                    <picture class="picture align-left mb-0">
                                        <img loading="lazy" fetchpriority="low" width="650" height="311" data-src="./assets/images/jardim.jpg" alt="Regador, vassoura e ferramentas de jardim ao ar livre" title="Regador, vassoura e ferramentas de jardim ao ar livre">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <h2 class="head">
                        <a href="#outras-dicas" (click)="accordionToggle($event)" class="accordion-link">Outras dicas</a>
                    </h2>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button type="button" class="close" (click)="accordionToggle($event)">Fechar</button>
                            <div>
                                <picture class="picture align-left mb-5" style="align-items: center;">
                                    <img  loading="lazy" fetchpriority="low" width="650" height="311" data-src="./assets/images/lavagem-carro.jpg" alt="Mulher lavando a roda de um carro com escova" title="Mulher lavando a roda de um carro com escova">
                                </picture>
                            </div>
                            <ul class="list">
                                <li>Se precisar lavar o carro, <strong class="-txt-blue">use balde ao invés de mangueira</strong>;</li>
                                <li>Sempre verifique se seu chuveiro, vaso sanitário, tanque ou torneiras apresentam vazamentos. <br>
                                    <a style="text-decoration: none; font-weight: 500;" class="goto-link" target="_blank" rel="noopener noreferrer" href="a-sabesp/educacao/curso-pesquisa-vazamento">A Sabesp tem um curso gratuito de pesquisa de vazamento que você pode fazer!</a>
                                </li>
                                <li>A instalação de arejadores e redutores de pressão também colabora com a economia, fazendo com que torneiras e chuveiros <strong class="-txt-blue">gastem menos água</strong>.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
</main>
