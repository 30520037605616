<main class="main">
    <meta name="description" content="Acesse os registros de falta d'água e acompanhe as informações atualizadas sobre o abastecimento em sua região.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/registros-falta-dagua"
                        title="Registros de Falta d' Água">Registros de Falta d' Água</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <h1 class='headline'>Registros de Falta d'Água</h1>

            <p>Histórico de clientes afetados por falta de água nos municípios operados, apresentados por ano e mês:</p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/fa_mar_24.pdf">2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/fa_mar_24.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/fa_dez_23.pdf">2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/fa_dez_23.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/fa_dez_2022.pdf">2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/fa_dez_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/fa_dezembro_2021.pdf">2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/fa_dezembro_2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2020.pdf">2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2020.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2019.pdf">2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2019.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2018.pdf">2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2018.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2017.pdf">2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2017.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2016.pdf">2016</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2016.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2015.pdf">2015</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2015.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2014.pdf">2014</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2014.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2013.pdf">2013</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2013.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2012.pdf">2012</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2012.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2011.pdf">2011</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2011.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/falta_agua_2010.pdf">2010</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/falta_agua_2010.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            

        </div>
    </article>


</main>