<main class="main">
    <meta name="description" content="Programa Água Legal: conheça as ações da Sabesp para regularizar o abastecimento de água em áreas vulneráveis, promovendo acesso seguro e legal.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo" title="Programa de Saneamento Sustentável Inclusivo">Programa de Saneamento Sustentável Inclusivo</a></li>
                <li><a href="/sustentabilidade/transparencia/programa-saneamento-sustentavel-inclusivo/programa-agua-legal" title="Programa Água Legal">Programa Água Legal</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            <h1 class='headline'>Programa Água Legal</h1>

            <p>O Programa Água Legal é uma iniciativa da Sabesp para a regularização do abastecimento de água em regiões de alta vulnerabilidade social, onde normalmente o abastecimento é precário, efetuado de forma irregular e por tubulações improvisadas e sujeitas à contaminação. O propósito é levar saúde e qualidade de vida para a população, preservar os recursos hídricos e promover a sustentabilidade urbana.</p>

            <p>Para consulta, estão disponíveis os seguintes documentos de avaliação ambiental elaborados para cada uma das áreas de intervenção do Programa Água Legal:</p>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>


            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_billings.pdf">Água Legal Billings</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_billings.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_ legal_freguesia_ 2019.pdf">Água Legal Freguesia do Ó</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_ legal_freguesia_ 2019.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_interlagos.pdf">Água Legal Interlagos 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_interlagos.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_interlagos_2018.pdf">Água Legal Interlagos 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_interlagos_2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_pirituba.pdf">Água Legal Pirituba</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_pirituba.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_ guarapiranga_ 2017.pdf">Água Legal Guarapiranga 2017</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_ guarapiranga_ 2017.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_guarapiranga_ 2018.pdf">Água Legal Guarapiranga 2018</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_guarapiranga_ 2018.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_sao_mateus.pdf">Água Legal São Mateus</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_sao_mateus.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_sao_miguel.pdf">Água Legal São Miguel</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_sao_miguel.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/agua_legal_pantanal_varzea.pdf">Água Legal Jd. Pantanal Várzea</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/agua_legal_pantanal_varzea.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/ras_ butanta_ agua legal_ site_lgpd.pdf">Água Legal Butantã</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/ras_ butanta_ agua legal_ site_lgpd.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/relatorio_salvaguarda_alto_tiete.pdf">Água Legal Alto Tietê</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/relatorio_salvaguarda_alto_tiete.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            <br><br><h2>Planos de Gestão Ambiental e Social de Obras</h2>

            <p>Para consulta, estão disponíveis os Planos de Gestão Ambiental e Social de Obras elaborados para as áreas de intervenção:</p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_tr17_sa%CC%83omateus_publicac%CC%A7a%CC%83o%20(1).pdf">São Mateus</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_tr17_sa%CC%83omateus_publicac%CC%A7a%CC%83o%20(1).pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa%20tr%2021%20extremo%20norte%20-%20publicac%CC%A7a%CC%83o_jan2024.pdf">Extremo Norte e Pirituba</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa%20tr%2021%20extremo%20norte%20-%20publicac%CC%A7a%CC%83o_jan2024.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_tr15_freguesia_santana.pdf">TR 15 - Freguesia do Ó e Santana II</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_tr15_freguesia_santana.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_tr20_butanta_pirajussara.pdf">Butantã II</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_tr20_butanta_pirajussara.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_guarulhos_iv.pdf">Guarulhos IV</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_guarulhos_iv.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_guarulhos_iii.pdf">Guarulhos III</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_guarulhos_iii.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_butanta_i.pdf">Butantã I</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_butanta_i.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_pirituba_iv.pdf">Pirituba IV</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_pirituba_iv.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_piritubaii_finalizado.pdf">Pirituba II</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_piritubaii_finalizado.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_guarulhosii_finalizado.pdf">Guarulhos II</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_guarulhosii_finalizado.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_pantanal_finalizado.pdf">Pantanal Várzea do Tietê</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_pantanal_finalizado.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_saomiguel_finalizado.pdf">São Miguel</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_saomiguel_finalizado.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_extremo_norte.pdf">Água Legal Extremo Norte</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_extremo_norte.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_freguesia_santana.pdf">Água Legal Freguesia do Ó/Santana</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_freguesia_santana.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_cotia_taboa%CC%83o_serra_osasco_barueri.pdf">Água Legal Cotia, Taboão da Serra, Itapevi, Osasco e Barueri</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_cotia_taboa%CC%83o_serra_osasco_barueri.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_stoandre.pdf">Santo André</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_stoandre.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/psga_centro.pdf">Ipiranga, Jardins, Mooca e São Mateus</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/psga_centro.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_itaquera_site_lgpd.pdf">Itaquera</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_itaquera_site_lgpd.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_agua_legal_guarulhos.pdf">Guarulhos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_agua_legal_guarulhos.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/saneamento_sustentavel/pgsa_interlagos.pdf">Interlagos</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/saneamento_sustentavel/pgsa_interlagos.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>


        </div>
    </article>


</main>
