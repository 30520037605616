import { Component } from '@angular/core';

@Component({
  selector: 'app-estatuto-social',
  standalone: true,
  imports: [],
  templateUrl: './estatuto-social.component.html',
  styleUrl: './estatuto-social.component.css'
})
export class EstatutoSocialComponent {

}
