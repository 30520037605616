import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-participacoes-societarias',
  standalone: true,
  imports: [],
  templateUrl: './participacoes-societarias.component.html',
  styleUrl: './participacoes-societarias.component.css'
})
export class ParticipacoesSocietariasComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Participações Societárias");
}
}
