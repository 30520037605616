import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorios-monitoramento-estacao-tratamento-esgotos-baleia',
  standalone: true,
  imports: [],
  templateUrl: './relatorios-monitoramento-estacao-tratamento-esgotos-baleia.component.html',
  styleUrl: './relatorios-monitoramento-estacao-tratamento-esgotos-baleia.component.css'
})
export class RelatoriosMonitoramentoEstacaoTratamentoEsgotosBaleiaComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | ETE Baleia");
}
}
