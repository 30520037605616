<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="javascript:void(0);"
                        title="Avaliação de Metas e Resultados">Avaliação de Metas e Resultados</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <h1 class='headline'>Avaliação de Metas e Resultados</h1>

            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar24.pdf">2024</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar24.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar23.pdf">2023</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocio_estrategia_mar23.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar22.pdf">2022</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar22.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar21.pdf">2021</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar21.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_marco20.pdf">2020</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_marco20.pdf"><span>Acessar</span></a></td></tr>

            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar19.pdf">2019</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/asabesp_doctos/plano_negocios_estrategia_mar19.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>

        </div>
    </article>


</main>
