import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-canais-atendimento',
  templateUrl: './canais-atendimento.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', './canais-atendimento.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CanaisAtendimentoComponent implements OnInit {
  constructor(private title: Title, private renderer: Renderer2) {}

  ngOnInit() {
    this.title.setTitle("Sabesp | Fale com a Sabesp");

    const floatButtons = document.querySelectorAll('.chat-popup');
    floatButtons.forEach(button => {
      this.renderer.listen(button, 'click', (e) => {
        e.preventDefault();
        this.windowOpen('https://sabesp-chat.sabesp.com.br/chat-externo', 340, 752);
      });
    });
  }

  windowOpen(url: string, w: number, h: number) {
    let newW = w + 100,
        newH = h + 100,
        top = window.screen.height - h - 250,
        left = window.screen.width - w - 15;

    let newWindow = window.open(url, 'name', 'width=' + newW + ',height=' + newH + ',left=' + left + ',top=' + top);

    if (newWindow) {
      newWindow.resizeTo(newW, newH);
      newWindow.moveTo(left, top);
      newWindow.focus();
    } else {
      return
    }

    return false;
  }
}
