import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-programa-saneamento-sustentavel-inclusivo',
  standalone: true,
  imports: [],
  templateUrl: './programa-saneamento-sustentavel-inclusivo.component.html',
  styleUrl: './programa-saneamento-sustentavel-inclusivo.component.css'
})
export class ProgramaSaneamentoSustentavelInclusivoComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Programa de Saneamento Sustentável e Inclusivo");
}
}
