<main class="main">
    <meta name="description" content="Conheça os serviços da Sabesp para sua empresa e descubra soluções em saneamento e gestão de água. Veja como podemos ajudar!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0)" title="Serviços">Serviços</a></li>
                <li><a href="javascript:void(0);" title="Para sua Empresa">Para sua Empresa</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <section id="agencia-virtual" class="content">
        <div id="guia-servicos" class="container">
            <h1 class="headline">Para sua Empresa</h1>

            <div class="boxes">
                <div class="item -gray">
                    <div class="top">
                        <figure class="icon">
                            <a href="https://agenciavirtual.sabesp.com.br" target="_blank" rel="noopener noreferrer" tabindex="-1">
                                <img loading="eager" fetchpriority="low" width="34" height="30" src="./assets/images/icon/monitor.svg" alt="Ícone de monitor com seta de clique​" title="Agência Virtual">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="https://agenciavirtual.sabesp.com.br" target="_blank" rel="noopener noreferrer" tabindex="-1">
                                Agência Virtual
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        Acesse a <a href="https://agenciavirtual.sabesp.com.br" target="_blank" rel="noopener noreferrer" tabindex="-1"><strong>Agência Virtual</strong></a> da Sabesp para solicitar um serviço para sua empresa.
                    </p>
                    <a class="button" target="_blank" rel="noopener noreferrer" href="https://agenciavirtual.sabesp.com.br" title="Acesse">Acesse</a>
                </div>

                <div class="item -gray">
                    <div class="top">
                        <figure class="icon">
                            <a href="https://agenciavirtual.sabesp.com.br/" target="_blank" tabindex="-1">
                                <img loading="eager" fetchpriority="low" width="28" height="28" src="./assets/images/icon/services-grid.svg" alt="Ícone de grade azul" title="Guia de Serviços">
                            </a>
                        </figure>
                        <h2 class="title">
                            <a href="https://agenciavirtual.sabesp.com.br/" target="_blank" tabindex="-1">
                                Guia de Serviços
                            </a>
                        </h2>
                    </div>
                    <p class="description">
                        <a href="https://agenciavirtual.sabesp.com.br/" target="_blank" tabindex="-1">
                            Aqui você encontra todos os serviços da Sabesp disponíveis para sua empresa.
                        </a>
                    </p>
                    <a class="button" href="https://agenciavirtual.sabesp.com.br/" target="_blank" title="Confira">Confira</a>
                </div>
            </div>
        </div>
    </section>

    <section class="content -gray">
        <div class="container">
            <h2 class="headline">Empreendimentos</h2>
            <p class="-space-md">Nesta área estão as informações e orientações voltadas a empreendedores.</p>

            <div class="accordion">
                <div #manual class="item">
                    <h3 class="head">
                        <a class="accordion-link" (click)="accordionToggle(manual)">Manual do Empreendedor</a>
                    </h3>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
								<button (click)="accordionToggle(manual)" type="button" class="close">Fechar</button>

								<p class="-balance">O manual foi concebido para orientar os empreendedores, projetistas e construtores do Estado de São Paulo quanto aos critérios adotados pela Sabesp para a correta interligação de empreendimentos imobiliários aos sistemas públicos de água e esgotos.</p>

								<div class="columns -auto">
									<div>
										<ul class="list">
											<li>
												Manual do Empreendedor<br>
												<b><a class="-no-underline -external" href="./assets/pdf/servicos/para-empresa/sabesp-manual-empreendedor.pdf" target="_blank" rel="noopener noreferrer">Confira na íntegra</a></b>
											</li>
										</ul>
									</div>

								</div>
							</div>
                    </div>
                </div>

                <div #manuais class="item">
                    <h3 class="head">
                        <a class="accordion-link" (click)="accordionToggle(manuais)">Manuais e Normas</a>
                    </h3>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button (click)="accordionToggle(manuais)" type="button" class="close">Fechar</button>

                            <div class="columns">
                                <div class="column">
                                    <p class="-txt-blue -space-sm"><strong>Manuais</strong></p>
                                    <p class="mt-0">Veja as normas, manuais e orientadores disponíveis:</p>

                                    <ul class="list -gap-lg">
                                        <li>
                                            Manual da Norma Regulamentadora<br>
                                            <b><a class="-no-underline -download2" href="./assets/pdf/servicos/para-empresa/sabesp-manual-orientador-NR10.pdf" target="_blank" rel="noopener noreferrer">NR10 - Segurança em Instalações e Serviços em Eletricidade</a></b>
                                        </li>
                                        <li>
                                            Manual Orientador<br>
                                            <b><a class="-no-underline -download2" href="./assets/pdf/servicos/para-empresa/controle_alteracao_manual_empreendedor.pdf" target="_blank" rel="noopener noreferrer">Padronização de Quadros Elétricos e Entrada de Energia Elétrica</a></b>
                                        </li>
                                        <li>
                                            Manual Orientador<br>
                                            <b><a class="-no-underline -download2" href="./assets/pdf/servicos/para-empresa/sabesp-manual-orientador-SPDA.pdf" target="_blank" rel="noopener noreferrer">SPDA Proteção Contra Descargas Elétricas Proteção Contra Surtos</a></b>
                                        </li>
                                    </ul>
                                </div>
                                <div class="column">
                                    <p class="-txt-blue"><strong>Cadernos Técnicos</strong></p>
                                    <ul class="list mt-0 -gap-lg">
                                        <li>
                                            Os cadernos técnicos foram substituídos pela NTS0338<br>
                                            <b><a class="-no-underline -external" href="https://normastecnicas.sabesp.com.br" target="_blank" rel="noopener noreferrer">Normas Técnicas</a></b>
                                        </li>
                                    </ul>

                                    <p class="-txt-blue"><strong>Normas Técnicas</strong></p>
                                    <ul class="list mt-0 -gap-lg">
                                        <li>
                                            Consulte as normas e orientações técnicas da Sabesp<br>
                                            <b><a class="-no-underline -external" href="https://normastecnicas.sabesp.com.br" target="_blank" rel="noopener noreferrer">Veja mais</a></b>
                                        </li>
                                    </ul>

                                    <p class="-txt-blue"><strong>Avaliação de Estudos, Emissão de Certidão e Diretrizes</strong></p>
                                    <ul class="list mt-0 -gap-lg">
                                        <li>
                                            Sistema de gestão de empreendimentos imobiliários<br>
                                            <b><a class="-no-underline -external" href="https://empimob.sabesp.com.br/login" target="_blank" rel="noopener noreferrer">Acesse o sistema</a></b>
                                        </li>
                                    </ul>
									</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div #certidao class="item">
                    <h3 class="head">
                        <a class="accordion-link" (click)="accordionToggle(certidao)">Certidão de Esgotamento Sanitário</a>
                    </h3>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button  (click)="accordionToggle(certidao)"  type="button" class="close">Fechar</button>

                            <p>A Certidão de Esgotamento Sanitário é um documento emitido pela Sabesp que <b>comprova o lançamento do esgoto gerado à rede coletora</b> e concede licença de operação junto à Cetesb.</p>
                            <p><b>Para solicitar a emissão da certidão é preciso baixar, preencher e encaminhar à Sabesp os documentos abaixo:</b></p>
                            <ul class="list -auto-row -space-md">
                                <li><b><a class="-no-underline -download2" href="./assets/pdf/servicos/para-empresa/sabesp-modelo-solicitacao-certidao-esgotamento-sanitario.pdf" target="_blank" rel="noopener noreferrer">Modelo de Solicitação da Certidão</a></b></li>
                                <li><b><a class="-no-underline -download2" href="../../assets/pdf/servicos/para-empresa/sabesp-formulario-prend-sabesp-certidao.docx" target="_blank" rel="noopener noreferrer">Formulário para Preenchimento com Informações da Empresa</a></b></li>
                            </ul>

                            <p><b>Junto à solicitação da certidão, é necessário entregar também os seguintes documentos:</b></p>
                            <ul class="list">
                                <li>Planta baixa da empresa;</li>
                                <li>Cópia de conta de água recente, caso seja cliente Sabesp;</li>
                                <li>Cópia dos últimos laudos de monitoramento se houver;</li>
                                <li>Croqui de localização;</li>
                                <li>Procuração no caso de não ser proprietário;</li>
                                <li>Formulário Prend preenchido conforme o modelo Sabesp.</li>
                            </ul>

                            <p class="-space-md">Informamos ainda que o prazo de atendimento à solicitação é de <b>60 dias</b> e o custo do serviço é de <b><abbr title="Real (BRL)">R$</abbr> 582,51</b>.</p>

                            <p><b>Para solicitar a emissão da certidão é preciso baixar, preencher e encaminhar à Sabesp os documentos abaixo:</b></p>
                            <ul class="list -auto-row">
                                <li><b><a class="-no-underline -external" href="https://empimob.sabesp.com.br" target="_blank" rel="noopener noreferrer">Sistema de Gestão de Empreendimentos Imobiliários</a></b></li>
                                <li><b><a class="-no-underline -external" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento-ggc" target="_blank" rel="noopener noreferrer">Atendimento a Grandes Consumidores</a></b></li>
                                <li><b><a class="-no-underline -external" href="https://agenciavirtual.sabesp.com.br/agencias-de-atendimento" target="_blank" rel="noopener noreferrer">Demais Clientes</a></b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div #hidrometro class="item">
                    <h3 class="head">
                        <a class="accordion-link" (click)="accordionToggle(hidrometro)">Hidrômetro Individualizado</a>
                    </h3>
                    <div class="collapse" aria-hidden="true">
                        <div class="body">
                            <button  (click)="accordionToggle(hidrometro)"  type="button" class="close">Fechar</button>

                            <p>A instalação de hidrômetros individuais em condomínios horizontais e verticais <b>propicia a medição do consumo de água de cada unidade de forma particularizada</b>. Dessa forma, cada uma passa a ter a conta individual da Sabesp, mais a conta da área comum.</p>
                            <p>As instalações hidráulicas e de medição devem estar no padrão aceito pela Sabesp e, para seguir tais especificações, foi criado o <strong class="-txt-black">Proacqua</strong> – <b>Programa de Qualidade e Produtividade dos Sistemas de Medição Individualizada de Água</b>, que capacita e certifica profissionais para a elaboração de projetos e a homologação de tecnologias, equipamentos e hidrômetros individuais. Tudo isso para garantir a qualidade da água, a regularidade do abastecimento e a confiabilidade da medição do consumo.</p>

                            <p class="-space-sm"><b>Benefícios</b></p>
                            <p class="mt-0"><b>O padrão do programa da Sabesp tem inúmeras vantagens em relação aos modelos não homologados existentes no mercado, tais como:</b></p>

                            <ul class="list">
                                <li>Emissão da conta pela Sabesp sem a necessidade da entrada no condomínio, o que significa mais segurança e maior confiabilidade para a unidade;</li>
                                <li>Hidrômetros fora do apartamento/casa, trazendo maior segurança contra riscos de vazamentos de água ou manipulação;</li>
                                <li>Projeto hidráulico feito por profissional treinado e certificado com o correto dimensionamento, qualidade da água e a medição correta do consumo;</li>
                                <li>Obrigatoriedade de recolhimento de ART (Anotação de Responsabilidade Técnica) para o projeto e para a obra;</li>
                                <li>Tecnologia, equipamentos e hidrômetros testados e homologados de acordo com os padrões estabelecidos;</li>
                                <li>Cada unidade passa a ser um cliente individual da Sabesp, contando com toda a rede de relacionamento disponível.</li>
                            </ul>

                            <p class="-space-sm"><b>Onde solicitar</b></p>
                            <p class="mt-0 -space-sm">Os condomínios interessados na medição individualizada podem solicitá-la pelo telefone:</p>
                            <p class="mt-0"><strong><a class="drop-link -no-underline -txt-blue" href="tel:0800 771 2482">0800 771 2482</a></strong></p>
                            <p class="mt-0"><small>(24 horas, incluindo emergências)</small></p>

                            <p>Após o atendimento, as empresas com tecnologias e equipamentos homologados entrarão em contato com o condomínio solicitante para a realização dos orçamentos.</p>
                            <ul class="list">
                                <li>
                                    Veja também<br>
                                    <b><a class="-no-underline -external" href="http://www.proacqua.org.br" target="_blank" rel="noopener noreferrer">www.proacqua.org.br</a></b>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mb-0">
        </div>
    </section>

    <section class="highlight">
        <div class="container">
            <div class="text">
                <div class="boxes -full">
                    <div class="item">
                        <div class="top">
                            <figure class="icon">
                                <a href="../servicos/folhetos" tabindex="-1">
                                    <img loading="lazy" fetchpriority="low" width="27" height="27" data-src="./assets/images/icon/jornal.svg" alt="Ícone de relatório azul​" title="Folhetos">
                                </a>
                            </figure>
                            <h2 class="title">
                                <a href="../servicos/folhetos" tabindex="-1">
                                    Folhetos
                                </a>
                            </h2>
                        </div>
                        <p class="description">
                            <a target="_blank" rel="noopener noreferrer" href="javascript:void(0);" tabindex="-1">
                                Aqui estão disponíveis os folhetos com dicas,<br>
                                orientações e instruções da Sabesp para você!
                            </a>
                        </p>
                        <a class="button" href="../servicos/folhetos" title="Confira">Confira</a>
                    </div>
                </div>
            </div>

            <picture class="figure">
                <img loading="lazy" fetchpriority="low" width="322" height="380" data-src="./assets/images/illustration/sani-pensando.png" alt="Mascote da Sabesp sentada e pensativa" title="Mascote da Sabesp sentada e pensativa">
            </picture>
        </div>
    </section>
</main>
