import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorio-acoes-verdes-b3',
  templateUrl: './relatorio-acoes-verdes-b3.component.html',
  styleUrl: './relatorio-acoes-verdes-b3.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class RelatorioAcoesVerdesB3Component {

  constructor(
    private title: Title
  ) {
    this.title.setTitle('Sabesp | Relatório Ações Verdes B3');
  }

}
