import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-informacoes-operacionais',
  standalone: true,
  imports: [],
  templateUrl: './informacoes-operacionais.component.html',
  styleUrl: './informacoes-operacionais.component.css'
})
export class InformacoesOperacionaisComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Relatórios de Ouvidoria");
}
}
