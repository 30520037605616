<main class="main">
	<nav class="breadcrumbs">
		<div class="container">
			<ul class="menu">
				<li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
				<li><a class="breadcrumb-link" href="javascript:void(0);" title="Política de Privacidade">Política de Privacidade</a></li>
			</ul>

			<ul class="buttons">
				<li><a class="button -home" href="/" title="Início">Início</a></li>
			</ul>
		</div>
	</nav>

	<article class="content">
		<div class="container">
			<h1 class="headline">Política de Privacidade</h1>

			<h2 class="subheadline">Introdução</h2>
			<p>A Política de Privacidade e Proteção de Dados Pessoais da Sabesp descreve como realizamos o tratamento de dados pessoais fornecidos pelo seu titular, para prestação de serviços da empresa, coletados de fontes disponíveis ou compartilhados entre os prestadores de serviço e parceiros, nos termos da legislação.</p>
			<p>Esta Política é aplicável aos titulares de dados, isto é, nossos clientes, administradores, conselheiros, diretores, empregados, ex-empregados, aposentados, assistentes executivos, assessores, prestadores de serviço, profissionais terceirizados, estagiários, menores aprendizes, consultores externos e quaisquer outras pessoas naturais que se relacionem com a Sabesp.</p>

			<h2 class="subheadline">Referências</h2>
			<p>Esta Política foi elaborada em conformidade com a Lei n.º 13.709/2018 (Lei Geral de Proteção de Dados – <abbr title="Lei Geral de Proteção de Dados">LGPD</abbr>), com a Lei n.º 12.965/2014 (Marco Civil da Internet) e demais legislações e normas aplicáveis ao tratamento de dados pessoais, para determinar diretrizes quanto ao tratamento dos dados pessoais que são fornecidos à Companhia ou coletados por ela por meio de site, serviços on-line (aplicativo mobile, sites, totens etc.) e demais situações envolvendo os titulares dos dados.</p>
			<p>Esta Política está em consonância com os procedimentos que tratam de tecnologia e segurança da informação, cibersegurança, controle de acesso lógico e físico, sigilo e confidencialidade, arquivamento e eliminação de documentos, utilização de equipamentos e tecnologias, dentre outros, sendo recomendável conhecimento integral de seus termos.</p>

			<h2 class="subheadline">Conceitos</h2>
			<p><b>Governança Corporativa:</b> sistema por meio qual as empresas e demais organizações são dirigidas, monitoradas e incentivadas, abrangendo os relacionamentos entre sócios, conselho de administração, diretoria, órgãos de fiscalização e controle e demais partes interessadas.</p>
			<p><b>Anonimização:</b> utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.</p>
			<p><b>Autoridade Nacional de Proteção de Dados – ANPD:</b> é um órgão da administração pública direta federal do Brasil que faz parte da Presidência da República e possui atribuições relacionadas a proteção de dados pessoais e da privacidade e, sobretudo, deve realizar a fiscalização do cumprimento da Lei nº 13.709/2018, conhecida como Lei Geral de Proteção de Dados Pessoais (<abbr title="Lei Geral de Proteção de Dados">LGPD</abbr>).</p>
			<p><b>Banco de dados:</b> conjunto estruturado de dados pessoais, estabelecido em um ou em vários locais, em suporte eletrônico ou físico.</p>
			<p><b>Bloqueios:</b> suspensão temporária de qualquer operação de tratamento, mediante guarda do dado pessoal ou do banco de dados.</p>
			<p><b>Consentimento:</b> manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada. </p>
			<p><b>Controlador:</b> pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais. </p>
			<p><b>Cookies</b> são arquivos armazenados no dispositivo do usuário enquanto navega na internet, eles são coletados pelo seu navegador e ajudam a registrar suas preferências e a personalizar o acesso aos serviços dos nossos portais, sites, aplicativos e outras ferramentas.</p>
			<p><b>Dado Anonimizado:</b> dado relativo a titular que não possa ser identificado, considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.</p>
			<p><b>Dado Pessoal:</b> informação relacionada a pessoa natural identificada ou identificável.</p>
			<p><b>Dado Pessoal de Criança e de Adolescente:</b> O Estatuto da Criança e do Adolescente (ECA) considera criança a pessoa até 12 anos de idade incompletos e adolescente aquela entre 12 e 18 anos de idade. Em especial, a <abbr title="Lei Geral de Proteção de Dados">LGPD</abbr> determina que as informações sobre o tratamento de dados pessoais de crianças e de adolescentes deverão ser fornecidas de maneira simples, clara e acessível de forma a proporcionar a informação necessária aos pais ou ao responsável legal e adequada ao entendimento da criança.</p>
			<p><b>Dado Pessoal Sensível:</b> dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural</p>
			<p><b>Eliminação:</b> exclusão de dado ou de conjunto de dados armazenados em banco de dados, independentemente do procedimento empregado.</p>
			<p><b>Encarregado:</b> pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).</p>
			<p><b>Livre acesso:</b> garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do tratamento, bem como sobre a integralidade de seus dados pessoais.</p>
			<p><b>Operador:</b> pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.</p>
			<p><b>Titular dos Dados ou Titular:</b> pessoa natural a quem se referem os dados pessoais que são objeto de tratamento, como por exemplo: colaboradores, acionistas, fornecedores e clientes.</p>
			<p><b>Tratamento:</b>  toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração;</p>
			<p><b>Transferência internacional de dados:</b> transferência de dados pessoais para país estrangeiro ou organismo internacional do qual o país seja membro.</p>
			<p><b>Uso compartilhado de dados:</b> comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.</p>
			<p><b>Violação de dados pessoais:</b> quando a sua empresa/organização sofre um incidente de segurança relativo aos dados pelos quais é responsável que resulta numa violação da confidencialidade, da disponibilidade ou da integridade dos dados.</p>

			<h2 class="subheadline">Objetivos</h2>
			<p>Esta Política de Privacidade e Proteção de Dados Pessoais tem como objetivo esclarecer aos titulares e interessados sobre a finalidade pela qual a Sabesp realiza o tratamento de Dados Pessoais fornecidos pelo titular, coletados de fontes disponíveis ou compartilhados entre os prestadores de serviço e parceiros. </p>
			<p>Esta Política diz respeito aos dados pessoais disponíveis à Sabesp, independentemente de seu formato (eletrônico, papel, audiovisual etc.), aplicável aos titulares dos dados pessoais e a qualquer ente que esteja em contato ou tenha acesso aos dados pessoais da Sabesp, na qualidade de Controladora.</p>

			<h2 class="subheadline">Diretrizes</h2>
			<p><b>AGENTES DE TRATAMENTO</b></p>
			<p><b>Controladora</b></p>
			<p>Companhia de Saneamento Básico do Estado de São Paulo - SABESP 
				Endereço: Rua Costa Carvalho, 300 - São Paulo, SP, Brasil, CEP 05429-900 
				CNPJ 43.776.517/0001-80
			</p>
			<p>Compete a SABESP:</p>
			<ol type="a" class="alpha">
				<li>manter registro das operações de tratamento de dados pessoais que realizar;</li>
				<li>supervisionar o cumprimento desta Política;</li>
				<li>adotar medidas técnicas e administrativas para proteção de dados;</li>
				<li>comunicar a Autoridade Nacional de Proteção de Dados – ANPD, em caso de grave incidente de segurança, nos termos da legislação e normas;</li>
				<li>seguir as orientações e/ou determinações da ANPD;</li>
				<li>nomear e divulgar a identidade da Encarregada no site da SABESP;</li>
				<li>dar suporte para promoção das atribuições da Encarregada;</li>
				<li>apoiar a produção e revisão do Inventário de Dados e do Relatório de Impacto sobre Proteção de Dados – RIPD;</li>
				<li>apoiar as atividades de avaliação de riscos e ações de mitigação;</li>
				<li>manter cláusulas de proteção de dados nos contratos que celebrar;</li>
				<li>patrocinar a implementação de boas práticas para governança e proteção de dados;</li>
				<li>não permitir qualquer prática com fins discriminatórios ilícitos ou abusivos.</li>
			</ol>

			<p><b>Operadores</b></p>
			<p>Todos os fornecedores e parceiros contratados pela SABESP, que realizam o tratamento de dados pessoais em nome da controladora. </p>

			<p>Competem aos Operadores da SABESP:</p>
			<ol type="a" class="alpha">
				<li>manter registro das operações de tratamento de dados pessoais que realizar;</li>
				<li>realizar o tratamento que lhe for conferido, segundo as instruções fornecidas pela SABESP;</li>
				<li>informar imediatamente qualquer incidente de segurança à Sabesp;</li>
				<li>informar imediatamente qualquer comunicação de titular à Sabesp;</li>
				<li>solicitar autorização prévia da Sabesp para repasse de qualquer atividade de tratamento de dados à terceiros;</li>
				<li>seguir as orientações e/ou determinações da ANPD.</li>
			</ol>

			<p><b>ENCARREGADA DA PROTEÇÃO DE DADOS PESSOAIS:</b></p>
			<p>Regina Helena Piccolo Cardia</p>
			<p>Canal de atendimento: lgpd&#64;sabesp.com.br</p>

			<p>Competem à Encarregada da Proteção de Dados Pessoais da SABESP:</p>
			<ol type="a" class="alpha">
				<li>aceitar reclamações e comunicações dos titulares, prestar esclarecimentos e adotar providências;</li>
				<li>receber comunicações da autoridade nacional e adotar providências;</li>
				<li>orientar os funcionários e os contratados da entidade a respeito das práticas a serem tomadas em relação à proteção de dados pessoais;</li>
				<li>executar as demais atribuições determinadas pela controladora ou estabelecidas em normas complementares;</li>
				<li>propor alteração desta Política Institucional, sempre que necessário;</li>
				<li>opinar sobre a criação/revisão de instrumentos normativos correlatos à proteção de dados pessoais;</li>
				<li>realizar treinamento periódico para público interno, fornecedores e terceiros;</li>
				<li>auxiliar para o estabelecimento de normas de segurança e padrões técnicos; obrigações específicas para os diversos envolvidos no tratamento; mecanismos internos de supervisão e de mitigação de riscos;</li>
				<li>emitir parecer sobre cláusulas contratuais, editais, termos de confidencialidade e instrumentos congêneres;</li>
				<li>participar do desenvolvimento de projetos, produtos, serviços, e demais planejamentos, em obediência aos princípios da proteção desde a concepção (privacy by design) e por padrão (privacy by default);</li>
				<li>gerenciar indicadores de desempenho da função.</li>
			</ol>

			<h2 class="subheadline">Princípios da proteção de dados pessoais</h2>
			<p>Na qualidade de Controladora dos dados pessoais, a Sabesp se compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados e a realizar o tratamento de dados em conformidade com os seguintes princípios:
			<ol type="a" class="alpha">
				<li>o tratamento de dados pessoais será realizado para a finalidade determinada nessa política, que preveem propósitos legítimos, específicos, explícitos e informados ao titular, limitado ao mínimo necessário para o atendimento do objetivo do tratamento. </li>
				<li>o tratamento de dados pessoais será realizado de forma transparente, sendo garantido ao titular dos dados pessoal: livre acesso, exatidão dos dados e consulta facilitada.</li>
				<li>o tratamento de dados pessoais será realizado de forma segura, por meio da adoção de medidas técnicas aptas a proteger, prevenir e mitigar danos decorrentes de eventual acesso não autorizado e de situação acidental ou ilícita de violação de dados, por meio de destruição, perda, alteração, comunicação ou difusão.</li>
				<li>a SABESP realizará a gestão de dados pessoais durante o ciclo de vida dos dados pessoais, adotando medidas técnicas e administrativas capazes de comprovar a observância das normas protetivas de dados pessoais e sua eficácia.</li>
				<li>em hipótese alguma haverá tratamento de dados para fins discriminatórios ilícitos ou abusivos.</li>
				<li>em cada desenvolvimento de projeto, produto ou serviço, em que houver o tratamento de dados pessoais, deverão estar previstas medidas de segurança, técnicas e administrativas, para proteção de dados pessoais, a fim de que a privacidade e proteção de dados estejam incorporados desde a concepção (privacy by design) e por padrão (privacy by default).</li>
			</ol>

			<h2 class="subheadline">Direitos do titular dos dados pessoais</h2>
			<p>O titular dos dados possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados e demais legislações aplicadas à espécie:</p>
			<ol type="a" class="alpha">
				<li>solicitar a confirmação da existência de tratamento e o aos dados pessoais;</li>
				<li>solicitar a correção de informações desatualizadas, incorretas ou incompletas;</li>
				<li>solicitar o bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a lei;</li>
				<li>solicitar anonimização de dados pessoais, que será realizado por meios técnicos razoáveis e disponíveis no momento do tratamento, quando verificado que os dados estão sendo tratados em desconformidade com a lei;</li>
				<li>apresentar oposição ao tratamento de seus dados pessoais, em caso de descumprimento da lei.</li>
				<li>solicitar a revogação do consentimento, nos casos de tratamento de dados amparado nessa hipótese legal.</li>
				<li>solicitar a portabilidade dos dados a outro fornecedor de serviço ou produto, quando for o caso, nos termos da lei.</li>
				<li>solicitar a eliminação dos seus dados pessoais tratados com o consentimento, sendo autorizada a conservação pela Sabesp, nas hipóteses legais, ou seja, para:
					<ol type="i" class="roman">
						<li>cumprimento de obrigação legal ou regulatória pela controladora;</li>
						<li>estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
						<li>transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na <abbr title="Lei Geral de Proteção de Dados">LGPD</abbr>; ou</li>
						<li>uso exclusivo da controladora, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
					</ol>
				</li>
			</ol>
			<p>A Sabesp empreenderá todos os esforços para atender às requisições feitas pelo titular dos dados no menor espaço de tempo possível. No entanto, de informações que demandem consulta a outras áreas ou pela complexidade da ação requisitada, o prazo de atendimento será de 15 (quinze) dias.</p>
			<p>O titular dos dados deve estar ciente que a sua requisição poderá ser legalmente rejeitada, seja por motivos formais (a exemplo de não comprovação da identidade pelo titular) ou legais (a exemplo do pedido de exclusão de dados cuja manutenção encontra amparo nas hipóteses legais), mediante justificativa para negativa.</p>

			<h2 class="subheadline">Dados coletados</h2>
			<p>Considerando a natureza de sua atuação, a Sabesp realizará o tratamento dos seguintes dados pessoais: nome, documento de identificação, endereço, telefone, número de fornecimento, ponto de entrega, código do sujeito, fotografia, biometria, endereço de IP, localização, dentre outros dados que possam identificar, direta ou indiretamente, a pessoa natural (titular dos dados).</p>
			<p>Eventualmente, poderão ser coletados dados pessoais sensíveis, bem como dados pessoais de criança e adolescentes, obedecidas as disposições da <abbr title="Lei Geral de Proteção de Dados">LGPD</abbr> e normas especiais.</p>

			<h2 class="subheadline">Finalidade do tratamento</h2>
			<p>O tratamento dos dados pessoais será realizado para atender às seguintes finalidades:</p>
			<ol type="a" class="alpha">
				<li>execução do contrato de prestação de serviços de saneamento em sua área de concessão;</li>
				<li>realização do processo de faturamento e cobrança decorrente de contrato de prestação de serviços ao titular dos dados;</li>
				<li>acessar as plataformas, visando garantir o funcionamento das funcionalidades disponibilizadas pelos canais de atendimento;</li>
				<li>responder as solicitações e dúvidas do titular dos dados;</li>
				<li>comunicar sobre interrupções programadas, alterações nos canais de atendimento e informações consideradas importantes ao titular dos dados;</li>
				<li>viabilizar e comunicar sobre a implantação de novos produtos, serviços, ofertas e oportunidades, visando apoiar e promover atividades e negócios da SABESP, bem como fornecer outras informações que tenham o potencial de beneficiar o titular dos dados.</li>
				<li>cumprir obrigação legal, judicial ou regulatória;</li>
				<li>atendimento a políticas públicas previstas em leis, regulamentos, contratos, convênios e instrumentos congêneres;</li>
				<li>realização de estudos por órgãos de pesquisa contratados para atender aos legítimos interesses da Sabesp, garantida a anonimização dos dados, nos termos da lei;</li>
				<li>proteção da vida ou incolumidade física do titular ou terceiros nas instalações da Sabesp ou parceiras;</li>
				<li>tutela da saúde, exclusivamente por tratamento realizado por profissionais da saúde, serviços de saúde ou autoridade sanitária, requeridos pela atividade realizada pela Sabesp ou a seu serviço;</li>
				<li>exercício regular de direitos em processo judicial, administrativo ou arbitral e demais hipóteses de tratamento previstas pela lei;</li>
				<li>formalização e manutenção dos contratos de trabalho;</li>
				<li>os dados pessoais coletados também poderão ser tratados para outras finalidades, que não sejam conflitantes ou excessivas em relação às finalidades listadas acima, sempre em conformidade com a legislação vigente, com base em interesses legítimos da Sabesp, ou de terceiros, exceto nos casos em que prevaleçam os direitos e garantias legais do titular dos dados.</li>
			</ol>
			<p><b>DADOS PESSOAIS SENSÍVEIS</b></p>
			<p>O tratamento de dados pessoais sensíveis será realizado mediante prévio e expresso consentimento do titular dos dados. Poderá ser realizado sem o consentimento, quando for indispensável para o cumprimento de obrigação legal, judicial ou regulatória, para o tratamento compartilhado de dados previstos em políticas públicas, realização de estudos,  exercício regular de direitos em processo judicial, administrativo ou arbitral, proteção da vida, tutela da saúde, prevenção de fraude e segurança do titular, na forma prevista em lei.</p>
			<p><b>DADOS PESSOAIS DE CRIANÇAS E ADOLESCENTES</b></p>
			<p>O tratamento de dados pessoais de menores somente será realizado, em seu melhor interesse, com o consentimento prévio, específico e destacado, por pelo menos um dos pais ou responsável legal, nos termos da lei.</p>
			<p>As informações sobre o tratamento de dados de crianças e adolescentes deverão ser fornecidas de maneira simples, clara e acessível, consideradas as características físico-motoras, perceptivas, sensoriais, intelectuais e mentais do usuário, de forma a proporcionar a informação necessária e adequada ao entendimento do menor e dos pais e/ou responsável legal.</p>

			<h2 class="subheadline">Bases legais para o tratamento de dados pessoais</h2>
			<p>Respeitadas as hipóteses em que for necessário o consentimento, o tratamento de dados pessoais realizado pela Sabesp está amparado nas seguintes hipóteses previstas em lei:</p>
			<ol type="a" class="alpha">
				<li>para o cumprimento de obrigação legal ou regulatória pela Controladora;</li>
				<li>pela administração pública, para o tratamento e uso compartilhado de dados necessários à execução de políticas públicas previstas em leis e regulamentos ou respaldadas em contratos, convênios ou instrumentos congêneres;</li>
				<li>para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
				<li>quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados;</li>
				<li>para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</li>
				<li>para a proteção de vida ou da incolumidade física do titular, ou de terceiro;</li>
				<li>para a tutela da saúde, exclusivamente, em procedimento realizado por profissionais de saúde, serviços de saúde ou autoridade sanitária;</li>
				<li>quando necessário para atender aos interesses legítimos da Controladora ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais; e</li>
				<li>para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</li>
			</ol>

			<p><b>COMPARTILHAMENTO E TRANSFERÊNCIA DOS DADOS</b></p>
			<p>Os dados pessoais do titular poderão ser compartilhados com autoridades governamentais, entidades autorreguladoras, Bolsa, Balcão, prestadores de serviços contratados, instituições financeiras, provedores de meio de pagamento de contas, instituições de análise de crédito, empresas de auditoria externa, entre outras, desde que respeitadas as disposições legais;</p>
			<p>É possível que algumas das transferências de dados ocorram fora do território brasileiro, ocasião em que a Sabesp se compromete a fazê-lo somente para países que proporcionem um grau de proteção dos dados pessoais dos titulares, considerando como adequado ao previsto na legislação aplicável ou mediante a adoção de garantias e salvaguardas como cláusulas específicas, cláusulas padrão, normas corporativas globais, entre outras; bem como mediante a prévia coleta do seu consentimento específico ou a observância das demais hipóteses autorizadas por lei; e</p>
			<p>A Sabesp não compartilhará, comercializará ou fornecerá os dados dos titulares para terceiros, salvo nas hipóteses legais.</p>

			<h2 class="subheadline">Eliminação de dados pessoais</h2>
			<p>Os dados pessoais que dependam de consentimento serão conservados até o término do tratamento, salvo se o titular solicitar a exclusão antes do final desse termo.</p>
			<p>A eliminação de dados será realizada em conformidade com a legislação e procedimento interno.</p>
			<p>Os dados pessoais poderão ser conservados, mesmo após o término de seu tratamento pela controladora nas seguintes hipóteses:</p>
			<ol type="a" class="alpha">
				<li>cumprimento de obrigação legal ou regulatória pela controladora;</li>
				<li>estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
				<li>transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na Lei; ou</li>
				<li>uso exclusivo da controladora, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
			</ol>
			<p>O prazo de permanência e conservação dos dados pessoais depende do atendimento aos parâmetros legais, contratuais e regulatórios exigíveis, observada a finalidade, adequação e necessidade previstas nesta Política de Privacidade e Proteção de Dados Pessoais.</p>

			<h2 class="subheadline">Segurança da informação</h2>
			<p>A Sabesp faz uso de várias tecnologias e emprega medidas técnicas e administrativas para evitar e detectar acessos não autorizados e situações acidentais ou ilícitas, inclusive podendo contratar operadores para executar diferentes níveis protetivos dos dados.</p>
			<p>O tratamento dos dados pessoais pelas equipes internas da empresa ou por parceiros, contratados para operações no âmbito da proteção de dados, tem como objetivo avaliar e propiciar ao titular a melhor performance de serviços e de resultados obtidos.</p>
			<p>Os dados e informações coletados dos titulares são incorporados ao banco de dados da Sabesp, sob nossa responsabilidade e domínio.</p>
			<p>Os dados e informações coletados são armazenados em ambiente seguro, observado o estado da técnica disponível, sendo acessados por pessoas qualificadas e autorizadas pela Sabesp.</p>
			<p>A Sabesp afirma que não compartilha, vende ou apresenta os dados pessoais dos titulares para terceiros, salvo com base em contratos ou termos congêneres.</p>
			<p>Nossos parceiros e operadores assinam contratos ou termos que dispõem do fiel cumprimento da <abbr title="Lei Geral de Proteção de Dados">LGPD</abbr> e cláusulas de responsabilidade, em caso de não cumprimento.</p>
			<p>Considerando que nenhum sistema de segurança é absolutamente seguro, a Sabesp se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados, salvo nos casos de dolo ou culpa da Companhia.</p>
			<p>Constatada a ocorrência de violação de segurança que redunde na violação de dados pessoais que possa causar algum risco para seus direitos e liberdades pessoais, seja ela de modo acidental ou ilícito, a Sabesp, dentro do prazo adequado, se compromete a comunicar aos titulares e à Autoridade Nacional de Proteção de Dados – ANPD.</p>
			<p>O titular possui o direito de solicitar acréscimos, modificações ou exclusões de dados que estiverem ligadas ao seu perfil registrado na Sabesp. Nos termos dessa Política, o titular declara estar ciente e de acordo com o tratamento dos dados, como coleta, armazenamento, tratamento, processamento, transferência, compartilhamento e uso.</p>

			<h2 class="subheadline">Utilização de cookies</h2>
			<p>Os titulares dos dados poderão gerenciar cookies de acordo com suas preferências, mas ficam cientes de que há cookies necessários que não podem ser desabilitados, pois interferem na funcionalidade do site, conforme Termo de Uso disponível.</p>
			<p>A SABESP utiliza cookies necessários para operação do serviço oferecido online e cookies de preferência, para lembrar as preferências do titular e de suas configurações, como uso de componentes e/ou plug-ins suportados por seu navegador.</p>

			<h2 class="subheadline">Resolução de conflitos</h2>
			<p>A Encarregada da Proteção de Dados deverá dirimir dúvidas e resolver, preliminarmente, conflitos que venham a surgir sobre a aplicação da lei e o tratamento, podendo consultar a Autoridade Nacional da Proteção de Dados e demais órgãos que julgar necessários, assim como orientar a adoção de medidas técnicas, administrativas e judiciais.</p>

			<p><b>Data de atualização desta política:</b> <time datetime="2020-10-13">18/06/2024</time></p>
		</div>
	</article>
</main>