<main class="main">
    <meta name="description" content="Conheça a gestão de demanda da Sabesp, uma abordagem eficiente para otimizar a distribuição de água e garantir sustentabilidade. Saiba mais!">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua/distribuicao-agua" title="Distribuição de Água">Distribuição de Água</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Gestão de Demanda">Gestão de Demanda</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -homebreadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Gestão de Demanda</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/o-que-fazemos/gestao-demanda.jpg" alt="Gestão de Demanda" title="Gestão de Demanda">
            </picture>

            <p>A Sabesp faz a gestão da demanda da água e controla a pressão nas redes de distribuição durante as 24 horas do dia, por meio de válvulas redutoras e sistema de monitoramento. Isso mantém o abastecimento para os consumidores e preserva as tubulações.</p>
            <p>No período noturno, quando há menor consumo, esse controle é intensificado, como forma de evitar perdas de água por vazamentos e rompimentos de tubulações – o que representa menos desperdício, menos manutenções e menos interferências em calçadas e vias. É algo semelhante ao que ocorre à noite, por exemplo, com o transporte público: com menos demanda, reduz-se a circulação de ônibus.</p>
            <p>A gestão de demanda é uma tecnologia mundial, recomendada pela Comissão Europeia e praticada rotineiramente pelas companhias de saneamento. A Sabesp já a adota na Grande São Paulo desde a década de 90.</p>
            <p>Se a sua casa possui caixa-d’água adequada para a quantidade de moradores e todas as instalações internas estão ligadas ao reservatório, você não sentirá os efeitos da gestão de demanda ou de intermitências causadas por reparos emergenciais nas redes de distribuição.</p>
            <p>Conforme determinam a norma <abbr title="Associação Brasileira de Normas Técnicas">ABNT</abbr> 5626 e o decreto estadual 12.342/78, é necessário que cada imóvel tenha um reservatório capaz de garantir o abastecimento por no mínimo 24 horas.</p>
        </div>
    </article>

    <section class="carousel">
        <div class="carousel-slider">
            <div class="item -green">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="eager" fetchpriority="high" width="640" height="300" src="./assets/images/o-que-fazemos/gestao-demanda-noturna.png" data-src="./assets/images/o-que-fazemos/gestao-demanda-noturna.png" alt="Como é feita a gestão de demanda noturna?" title="Como é feita a gestão de demanda noturna?">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Como é feita a gestão de demanda noturna?</h2>
                        <div class="description">
                            <p>No período de maior consumo durante o dia, as tubulações estão carregadas com maior pressão para que, mesmo com todos utilizando água ao mesmo tempo, os imóveis permaneçam abastecidos.</p>
                            <p>Na gestão da demanda noturna, a rede de distribuição mantém o fornecimento, mas com menor pressão, evitando rompimentos, vazamentos e perdas de água.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item" style="background-color: #01AAFF;">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img class="tns-lazy-img" loading="lazy" fetchpriority="low" width="640" height="300" src="data:image/gif;base64,R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="./assets/images/o-que-fazemos/como-deve-ser-caixa-d-agua.png" alt="Como deve ser a sua caixa d’água" title="Como deve ser a sua caixa d’água">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Como deve ser a sua caixa d’água</h2>
                        <p class="description">A caixa d’água, obrigatória em todo imóvel, deve estar dimensionada de acordo com o total de ocupantes, assegurando o abastecimento por no mínimo 24 horas. É importante também que todos os equipamentos hidráulicos (torneiras, chuveiros, máquina de lavar etc.) estejam conectados ao reservatório.</p>
                        <a class="link -white" href="/o-que-fazemos/fornecimento-agua/distribuicao-agua" title="Saiba mais como calcular o tamanho da sua caixa d’água">Saiba mais como calcular o tamanho da sua caixa d’água</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="more -white">
        <div class="container">
            <nav class="nav">
                <h2 class="headline">Veja também</h2>

                <ul class="items">
                    <li class="item">
                        <a class="title" target="_blank" rel="noopener noreferrer" href="https://www.sabesp.com.br/reducaopressao">Consulte se a Sabesp realiza a gestão de demanda noturna na sua região</a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</main>
