import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tratamento-agua',
  standalone: true,
  imports: [],
  templateUrl: './tratamento-agua.component.html',
  styleUrl: './tratamento-agua.component.css'
})
export class TratamentoAguaComponent {
  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Tratamento de Água");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Conheça o processo de tratamento de água realizado pela Sabesp. Nossas estações de tratamento produzem água potável com rigorosos controles de qualidade, garantindo água segura e limpa para milhões de pessoas. '
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, tratamento de água, estações de tratamento de água, ETAs, água potável, processo de tratamento, controle de qualidade, cloração, filtração, desinfecção, fluoretação.'
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Tratamento de Água'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Tratamento de Água'
    });
  }
}
