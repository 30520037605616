<main class="main">
    <meta name="description" content="Conheça o sistema da Sabesp para coleta e tratamento de esgoto, promovendo saúde e preservação ambiental.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
                <li><a href="/o-que-fazemos/solucoes-para-esgotos" title="Soluções para Esgotos">Soluções para Esgotos</a></li>
                <li><a href="/o-que-fazemos/solucoes-para-esgotos" title="Sistema de Coleta e Tratamento">Sistema de Coleta e Tratamento</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Sistema de Coleta e Tratamento</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="/assets/images/o-que-fazemos/tratamento-esgoto.jpg" alt="Vista aérea da Estação de Tratamento de Água da SABESP​" title="Tratamento de Esgoto">
            </picture>

            <p>Ao lavar as mãos, tomar banho, lavar as roupas, louças e usar a descarga do vaso sanitário, forma-se o esgoto. O contato com esta água residual pode causar doenças pela quantidade de micro-organismos ou resíduos tóxicos que provocam o crescimento de bactérias, vírus ou fungos, daí a importância dos sistemas de coleta e tratamento de esgotos, pois eles evitam a contaminação e a transmissão de doenças.</p>
            <p>Além das questões de saúde, o sistema de coleta e tratamento de esgotos também evita a poluição dos córregos, rios e mares, preservando o meio ambiente e os recursos hídricos que são fontes de abastecimento de água.</p>
            <p>A rede de coleta e transporte dos esgotos operada pela Sabesp tem cerca de 63,6 mil km e leva os resíduos para suas 596 Estações de Tratamento de Esgotos - <abbr title="Estações de Tratamento de Esgotos">ETEs</abbr>. Isso assegurou, em 2023, um índice de 93% de cobertura em coleta de esgotos, com aproximadamente 25,2 milhões de pessoas atendidas.</p>
            <p>Para continuar avançando, a Sabesp mantém ações e programas que visam a expansão da infraestrutura de coleta e tratamento, assim como a conexão de moradias ao sistema.</p>
        </div>
    </article>

    <section class="more">
        <div class="container">
            <nav class="nav">
                <h2 class="headline">Veja também</h2>

                <ul class="items">
                    <li class="item">
                        <a class="title" target="_blank" rel="noopener noreferrer" href="/o-que-fazemos/solucoes-para-esgotos/coleta-esgotos">Coleta de Esgotos</a>
                    </li>
                    <li class="item">
                        <a class="title" rel="noopener noreferrer" href="/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/o-processo-tratamento">O Processo de Tratamento</a>
                    </li>
                    <li class="item">
                        <a class="title" target="_blank" rel="noopener noreferrer" href="/servicos/para-empresa#empreendimentos">Como uma Empresa pode emitir a Certidão de Esgotamento Sanitário</a>
                    </li>
                </ul>
            </nav>
        </div>
    </section>

</main>
