import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-informacoes-cidadao',
  standalone: true,
  imports: [],
  templateUrl: './informacoes-cidadao.component.html',
  styleUrl: './informacoes-cidadao.component.css'
})
export class InformacoesCidadaoComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Informações ao Cidadão");
}
}
