import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bacia-rio-itapanhau',
  standalone: true,
  imports: [],
  templateUrl: './bacia-rio-itapanhau.component.html',
  styleUrl: './bacia-rio-itapanhau.component.css'
})
export class BaciaRioItapanhauComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Bacia do Rio Itapanhaú");
}
}
