<main class="main">
    <meta name="description" content="Transparência Sabesp: consulte os fornecedores de serviços publicitários e acompanhe as contratações para garantir a clareza nas ações da empresa.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a class="breadcrumb-link" href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a class="breadcrumb-link" href="/sustentabilidade/transparencia/fornecedores-servicos-publicitarios" title="Fornecedores de serviços publicitários">Fornecedores de serviços publicitários</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">


            <h1 class='headline'>Fornecedores de serviços publicitários</h1>

            <p>Para acessar as tabelas com fornecedores e meios de divulgação dos serviços publicitários, utilizar os links abaixo:</p>
            
            
            
            <br><br><h2>2024</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/maio_2024.pdf"> Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/maio_2024.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/abril_2024.pdf"> Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/abril_2024.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/marco_2024.pdf"> Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/marco_2024.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_2024.pdf"> Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_2024.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2024.pdf"> Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2024.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>2023</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/dezembro_2023.pdf">Dezembro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/dezembro_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/novembro_2023.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/novembro_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/outubro_2023.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/outubro_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_2023.pdf">Setembro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/agosto_2023.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/agosto_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/julho_2023.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/julho_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/junho_2023.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/junho_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/maio_2023.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/maio_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/abril_2023.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/abril_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo_marco_2023.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo_marco_2023.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_23.pdf">Fevereiro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_23.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2023.pdf">Janeiro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2023.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            <br><br><h2>2022</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/dezembro_2022.pdf">Dezembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/dezembro_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/novembro_2022.pdf">Novembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/novembro_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/outubro_2022.pdf">Outubro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/outubro_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_22.pdf">Setembro </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_22.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/agosto_2022.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/agosto_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/julho_2022.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/julho_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/junho_2022.pdf">Junho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/junho_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/maio_2022.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/maio_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/abril_2022.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/abril_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/marco_22.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/marco_22.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_22.pdf">Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_22.pdf"><span>Acessar</span></a></td></tr>
            
            
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo1_janeiro_2022.pdf">Janeiro - Arquivo 1</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo1_janeiro_2022.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2_janeiro_2022.pdf">Janeiro - Arquivo 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2_janeiro_2022.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            
            
            <br><br><h2>2021</h2>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            
            
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo1_dezembro_2021.pdf">Dezembro - Arquivo 1</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo1_dezembro_2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2_dezembro_2021.pdf">Dezembro - Arquivo 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2_dezembro_2021.pdf"><span>Acessar</span></a></td></tr>
            
            
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo1_novembro2021.pdf">Novembro - Arquivo 1</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo1_novembro2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2_novembro2021.pdf">Novembro - Arquivo 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2_novembro2021.pdf"><span>Acessar</span></a></td></tr>
            
            
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo1_outubro2021.pdf">Outubro - Arquivo 1</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo1_outubro2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/arquivo2_outubro2021.pdf">Outubro - Arquivo 2</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/arquivo2_outubro2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/setembro_21.pdf">Setembro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/setembro_21.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/agosto_21.pdf">Agosto</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/agosto_21.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/julho_2021.pdf">Julho</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/julho_2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/junho_21.pdf">Junho </a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/junho_21.pdf"><span>Acessar</span></a></td></tr>
            
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/maio_2021.pdf">Maio</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/maio_2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/abril_2021.pdf">Abril</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/abril_2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/marco_2021.pdf">Março</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/marco_2021.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/fevereiro_21.pdf">Fevereiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/fevereiro_21.pdf"><span>Acessar</span></a></td></tr>
            
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="/site/uploads/file/contrato/janeiro_2021.pdf">Janeiro</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -download" href="/site/uploads/file/contrato/janeiro_2021.pdf"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
     
            
            <br><br><h2>Anos anteriores </h2>
            <div class="table-responsive"><table class="table col-striped" style="min-width: 700px;"><thead><tr><th></th><th class="txt-center" width="200">Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a rel="noopener noreferrer" class="-link" href="/sustentabilidade/transparencia/fornecedores-servicos-publicitarios-anos-anteriores">Confira os arquivos</a></td><td class="txt-center"><!-- link -link-external download --><a class="action-icon -link" href="/sustentabilidade/transparencia/fornecedores-servicos-publicitarios-anos-anteriores"><span>Acessar</span></a></td></tr>
            </tbody></table></div>



        </div>
    </article>


</main>