<main class="main">
    <meta name="description" content="Sabesp recebe o selo B3 Ações Verdes 2024, destacando-se na proteção ambiental e combate às mudanças climáticas.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a title="Sustentabilidade ">Sustentabilidade</a></li>
                <li><a title="Documentos e Relatórios">Documentos e Relatórios</a></li>
                <li><a title="Integra Tietê">Relatório Ações Verdes - B3</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Relatório Ações Verdes B3</h1>

            <p>
                A Sabesp é a primeira companhia brasileira a ser reconhecida como empresa ‘verde’ no mundo. Em maio de
                2024, a B3, bolsa de valores do Brasil, instituiu o selo B3 Ações Verdes para reconhecer companhias que
                contribuem para a proteção do meio ambiente e no combate às mudanças climáticas. A Sabesp é também a
                primeira empresa fora da Europa a obter esta certificação, inspirada nos Green Equity Principles,
                lançados em 2023 pela World Federation of Exchanges (WFE).
            </p>

            <br><br>
            <h2>2024</h2>
            <div class='table-responsive'>
                <table class='table col-striped' style='min-width: 700px;'>
                    <thead>
                        <tr>
                            <th></th>
                            <th class='txt-center' width='200'>Ver/Baixar</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                                <a target="_blank" rel="noopener noreferrer" class="-link"
                                    href="/site/uploads/file/relatorios/relatorio_acoes_verdes_B3/S%26P%20%20%20-%20Relat%C3%B3rio%20de%20Avalia%C3%A7%C3%A3o%20-%20A%C3%A7%C3%B5es%20Verdes%20B3.pdf">
                                    S&P - Relatório de Avaliação Ações Verdes B3 - versão português (pdf)
                                </a>
                            </td>
                            <td class="txt-center">
                                <a target="_blank" class="action-icon -download"
                                    href="/site/uploads/file/relatorios/relatorio_acoes_verdes_B3/S%26P%20%20%20-%20Relat%C3%B3rio%20de%20Avalia%C3%A7%C3%A3o%20-%20A%C3%A7%C3%B5es%20Verdes%20B3.pdf"><span>Acessar</span></a>
                            </td>
                        </tr>

                        <tr>
                            <td><a target="_blank" rel="noopener noreferrer" class="-link"
                                    href="/site/uploads/file/relatorios/relatorio_acoes_verdes_B3/S%26P%20%20-Assessment%20Report%20-%20B3%20Green%20Equity%20.pdf">
                                    S&P - Relatório de Avaliação Ações Verdes B3 - versão inglês (pdf)</a></td>
                            <td class="txt-center"><a target="_blank" class="action-icon -download"
                                    href="/site/uploads/file/relatorios/relatorio_acoes_verdes_B3/S%26P%20%20-Assessment%20Report%20-%20B3%20Green%20Equity%20.pdf"><span>Acessar</span></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>
</main>