import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contato-imprensa',
  templateUrl: './contato-imprensa.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
})
export class ContatoImprensaComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Contatos da Assessoria de Imprensa");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Acesse comunicados de imprensa e informações sobre eventos da Sabesp. Encontre recursos e contatos para jornalistas e profissionais de mídia.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, imprensa, notícias, comunicados de imprensa, eventos, mídia, jornalistas, recursos de imprensa, contatos para imprensa'
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Contatos da Assessoria de Imprensa'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Contatos da Assessoria de Imprensa'
    });
  }
  }

