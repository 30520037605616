import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-tratamento-esgotos',
  standalone: true,
  imports: [],
  templateUrl: './tratamento-esgotos.component.html',
  styleUrl: './tratamento-esgotos.component.css'
})
export class TratamentoEsgotosComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private title: Title
  ) { }

  ngOnInit() {
    this.title.setTitle('Sabesp | O Processo de Tratamento');

    this.route.fragment.subscribe(f => {
      this.showAccordion(f);
    })
  }

  accordionToggle(e: any) {
    e.preventDefault();
    let wrapper = e.target.closest('.item');
    wrapper.classList.toggle('-open');

    let collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
    console.log('it works')
  }

  showAccordion(id: any) {
    let item = document.getElementById(id);

    item?.click();
  }

}
