import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Notice } from '../notice';
import { ActivatedRoute } from '@angular/router';
import { NoticeService } from '../notice.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-notice',
  templateUrl: './comunicado.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ComunicadoComponent {
  notice: Notice | undefined;
  mostViewed: Notice[] = [];
  currentURL: string = '';

  constructor(
    private route: ActivatedRoute,
    private noticeService: NoticeService,
    private title: Title
  ) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Utilidade Pública");
    window.scrollTo({ top: 0, behavior: 'instant' })
    this.getNotice();
    this.getMostViewedNotices();
    this.getCurrentURL();
  }

  getCurrentURL(): void {
    this.currentURL = window.location.href;
    console.log(this.currentURL);
  }

  getNotice(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.noticeService.getNoticeById(id ? id : '')
      .subscribe(notice => this.notice = notice);
  }

  getMostViewedNotices(): void {
    this.noticeService.getMostViewedNoticies()
      .subscribe(notices => this.mostViewed = notices);
  }
}
