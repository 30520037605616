import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-relatorio-igualdade-salarial',
  standalone: true,
  imports: [],
  templateUrl: './relatorio-igualdade-salarial.component.html',
  styleUrl: './relatorio-igualdade-salarial.component.css'
})
export class RelatorioIgualdadeSalarialComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Relatório de Igualdade Salarial");
}
}
