import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.css',
  encapsulation: ViewEncapsulation.None
})
export class PaginationComponent {
  @Input() totalItems = 0;
  @Input() itemsPerPage = 10;
  @Input() currentPage = 1;
  @Input() totalPages = 0;
  @Output() pageChange = new EventEmitter<number>();
  isMobile: boolean = false;
  limitPages = 10;

  constructor() {
    this.checkDeviceType();
  }

  ngOnInit(): void {
    this.checkDeviceType();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDeviceType();
  }

  private checkDeviceType() {
    const width = window.innerWidth;
    // Considera dispositivos com largura menor que 1200px como móveis
    this.isMobile = width < 1200;
    if (this.isMobile) {
      this.limitPages = 3;
    } else {
      this.limitPages = 10;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pageChange.emit(this.currentPage);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.pageChange.emit(this.currentPage);
    }
  }

  setPage(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }

  pages(): number[] {
    return Array(this.totalPages).fill(0).map((x, i) => i + 1);
  }

  getLimitedPages() {
    let pages = this.pages();
    if (pages.length > this.limitPages) {
      let start = Math.max(this.currentPage - (this.limitPages/2), 0);
      let end = Math.min(start + this.limitPages, pages.length);
      pages = pages.slice(start, end);
    }
    return pages;
  }
}
