import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-visite-sabesp',
  templateUrl: './visite-sabesp.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', './visite-sabesp.component.css'],
})
export class VisiteSabespComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Visite a Sabesp");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Venha conhecer a Sabesp! Oferecemos visitas guiadas para estudantes e professores, mostrando o processo de tratamento de água e esgoto na Região Metropolitana de São Paulo e na Baixada Santista. Agende sua visita e descubra como cuidamos da água.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, visita guiada, tratamento de água, tratamento de esgoto, Sistema Cantareira, Estação de Tratamento do Guaraú, Estação de Tratamento de Água Cubatão, educação ambiental, estudantes, professores'
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Visite a Sabesp'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Visite a Sabesp'
    });
  }
  accordionToggle(e: any) {
    e.preventDefault();
    let wrapper = e.target.closest('.item');
    wrapper.classList.toggle('-open');

    let collapse = wrapper.querySelector('.collapse');
    collapse.setAttribute('aria-hidden', collapse.getAttribute('aria-hidden') == 'true' ? 'false' : 'true');
  }
}
