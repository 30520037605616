import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {TinySliderInstance, tns} from "tiny-slider";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-inovacao-tecnologia',
  standalone: true,
  imports: [],
  templateUrl: './inovacao-tecnologia.component.html',
  styleUrl: './inovacao-tecnologia.component.css'
})
export class InovacaoTecnologiaComponent implements AfterViewChecked, OnInit{

  carousel!: TinySliderInstance;
  carouselStarted: boolean = false;

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Pesquisa, Desenvolvimento e Inovação");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Descubra as iniciativas tecnológicas e de inovação da Sabesp, incluindo usinas fotovoltaicas e sistemas avançados de oxigenação para melhorar a qualidade da água.'
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, tecnologia, inovação, usinas fotovoltaicas, oxigenação, saneamento '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Pesquisa, Desenvolvimento e Inovação'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Pesquisa, Desenvolvimento e Inovação'
    });
  }

  ngAfterViewChecked(): void {
    if (!this.carouselStarted) {
      this.initCarousel();
      this.carouselStarted = true;
    }
  }

  private initCarousel(): void {
    this.carousel = tns({
      container: '.carousel-slider',
      items: 1,
      autoplay: false,
      loop: true,
      controls: true,
      controlsText: ['Anterior', 'Próximo'],
      navPosition: 'bottom',
      autoplayText: ['', ''],
      autoplayButtonOutput: false,
      autoHeight: true,
      lazyload: true,
      speed: 200
    });

    this.carouselInit();

    this.carousel?.events.on('indexChanged', (info: any) => {
      info.container.closest('.tns-outer').dataset.navColor = info.slideItems[info.index]?.dataset.navColor;
    });

    this.carousel?.events.on('transitionEnd', () => {
      this.carousel.updateSliderHeight();
    });
  }

  carouselInit(): void {
    const info: any = this.carousel.getInfo();
    const outer = document.querySelector('.tns-outer');
    outer?.setAttribute('data-navColor', info.slideItems[info.index]?.dataset.navColor);
  
    const dots = document.querySelectorAll('.tns-nav [data-nav]');
    dots.forEach((dot, index) => {
      const span = document.createElement('span');
      span.textContent = (index + 1).toString();
      span.classList.add('classlist');
      dot.innerHTML = '';
      dot.appendChild(span);
    });
  
    const nav = document.querySelector(`#${info.container.id}-ow .tns-nav`) as HTMLElement;
  
    const controls = document.querySelector(`#${info.container.id}-ow .tns-controls`) as HTMLElement;
    controls?.style.setProperty('--nav-width', `${nav?.offsetWidth}px`);
  
    // Adiciona classes para os botões de navegação anterior e próximo
    const prev = controls.querySelector('[data-controls="prev"]');
    prev?.classList.add('slider-prev');
  
    const next = controls.querySelector('[data-controls="next"]');
    next?.classList.add('slider-next');
  }
}
