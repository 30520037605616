<main class="main">
	<meta name="description" content="Saiba como a Sabesp promove a reciclagem de óleo e contribui para a preservação ambiental com seus programas de sustentabilidade. Confira!">
		<nav class="breadcrumbs">
			<div class="container">
				<ul class="menu">
					<li><a href="/" title="Início">Início</a></li>
					<li><a href="javascript:void(0);" title="O Que Fazemos">O Que Fazemos</a></li>
					<li><a href="/o-que-fazemos/obras-programas" title="Obras e Programas">Obras e Programas</a></li>
					<li><a href="javascript:void(0);" title="Programa de Reciclagem de Óleo de Frituras">Programa de Reciclagem de Óleo de Frituras</a></li>
				</ul>

				<ul class="buttons">
					<li><a class="button -home" href="/" title="Início">Início</a></li>
				</ul>
			</div>
		</nav>

		<article class="content">
			<div class="container">
				<h1 class="headline">Programa de Reciclagem de Óleo de Frituras</h1>

				<picture class="picture align-right petals">
					<img loading="eager" fetchpriority="high" width="650" height="380" src="/assets/images/sustentabilidade/programa-reciclagem-oleo-fritura.jpg" alt="Programa de Reciclagem de Óleo de Frituras" title="Programa de Reciclagem de Óleo de Frituras">
				</picture>

				<p>Você sabia que 1 litro de óleo pode contaminar até 25 mil litros de água? Isso acontece porque o óleo não se dissolve na água, e seu descarte incorreto na rede de esgoto, por pias e ralos, causa sérios danos ambientais.</p>
				<p>Além de poluir as águas de rios e córregos, os resíduos de óleo se acumulam nas paredes dos canos de esgoto, causando entupimentos e gerando a necessidade de manutenções frequentes.</p>
				<p>Por isso, a palavra-chave é reciclar e, com o Programa de Reciclagem de Óleo de Frituras (<abbr title="Programa de Reciclagem de Óleo de Frituras">Prol</abbr>), a Sabesp incentiva a destinação correta desse resíduo, promovendo a sustentabilidade ambiental e social.</p>
				<p>O óleo usado coletado pelo Prol evita danos ao meio ambiente e promove a geração de renda por meio de parcerias com instituições que recolhem e reaproveitam o material para fabricação de outros produtos, como sabão e biodiesel.</p>
			</div>
		</article>
	</main>
