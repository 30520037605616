import { Pipe } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ContentKind, KIND_PATHS } from "../content-kind";

@Pipe({
    name: "contentPipe",
    standalone: true,
})
export class ContentPipe {
    constructor(private sanitizer: DomSanitizer) { }

    transform(content: string | null | undefined): SafeHtml {
        if (!content) {
            return '';
        }
        const dec = ContentPipe.base64ToUtf8(content);
        return this.sanitizer.bypassSecurityTrustHtml(dec);
    }

    static base64ToUtf8(b64Encoded: string): string {
        const byteCharacters = atob(b64Encoded);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const decodedString = new TextDecoder('utf-8').decode(byteArray);
        return decodedString;
    }

    static linkRel(kind: number, id: string): string {
        switch (kind) {
            case ContentKind.Question:
                return KIND_PATHS[kind] + "#" + id;
            default:
                return KIND_PATHS[kind] + "/" + id;
        }
    }
}