<main class="main">
    <meta name="description" content="Explore como a Sabesp impulsiona a pesquisa e inovação para avançar em tecnologias de saneamento e melhorar a qualidade de vida.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="A Sabesp">A Sabesp</a></li>
                <li><a href="javascript:void(0);" title="Inovação e Tecnologia">Inovação e Tecnologia</a></li>
                <li><a href="javascript:void(0);" title="Pesquisa, Desenvolvimento e Inovação">Pesquisa, Desenvolvimento e Inovação</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Pesquisa, Desenvolvimento e Inovação</h1>

            <picture class="picture petals align-right">
                <img loading="eager" fetchpriority="low" width="650" height="380" src="./assets/images/a-sabesp/biometano.jpg" alt="Posto de abastecimento de biometano da Sabesp​
                " title="Posto de abastecimento de biometano da Sabesp​
                ">
            </picture>

            <p>As ações de Pesquisa, Desenvolvimento e Inovação da Sabesp mantêm sinergia com a Economia Circular, usando o Ciclo do Saneamento como um mapa para identificar oportunidades tecnológicas em cada etapa. Em colaboração com parceiros locais e globais, como institutos de pesquisa, universidades, startups e agências reguladoras, buscamos sempre soluções para atender às necessidades e superar os desafios que encontramos em nosso dia a dia.</p>
            <p>Essas parcerias envolvem testes, prototipagem e desenvolvimento de fornecedores, com ênfase na gestão dos resultados e da propriedade intelectual. Além disso, promovemos a disseminação de conhecimento técnico-científico através da <em>Revista DAE</em>, um importante veículo na área, mantendo um diálogo constante com a comunidade científica. O circuito <abbr title="Global Open Innovation and Technological Advances in Water and Sanitation">GOTAS</abbr> (<em lang="en-US">Global Open Innovation and Technological Advances in Water and Sanitation</em>), outro importante meio de comunicação, reúne instituições de vários países para a apresentação de tecnologias, aproximando realidades diferentes e proporcionando a troca de conhecimentos e experiências e, consequentemente, a criatividade e a inovação.</p>
            <p>Essas iniciativas já renderam à Sabesp patentes e registros de softwares, além de projetos como o sistema de biometano para veículos, biofiltros para controle de odores e microlaboratórios para detecção de fósforo em tempo real. São soluções inovadoras que refletem nosso compromisso com o avanço tecnológico e a sustentabilidade, muitas delas premiadas dentro e fora do país.</p>
            <p>Na Sabesp, adotamos o conceito de inovação aberta, identificando necessidades tecnológicas e incentivando o desenvolvimento de soluções criativas por meio do compartilhamento de conhecimento, tanto internamente quanto com o mercado. Recentemente, criamos a plataforma Água-Viva, inicialmente voltada ao público interno para fomentar a troca de ideias e inspirar a criatividade, mas que, em breve, estará aberta também ao público externo.</p>
            <p>Nossas iniciativas inovadoras estão presentes em todas as etapas do processo de saneamento, desde a produção de água até o tratamento de esgoto, refletindo-se na melhoria de nosso atendimento e na prestação de nossos serviços de qualidade de forma ágil e confiável.</p>
        </div>
    </article>

    <section class="carousel">
        <div class="carousel-slider">
            <div class="item -green">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img loading="eager" fetchpriority="high" width="642" height="375" src="../assets/images/a-sabesp/biogas.jpg" data-src="../assets/images/a-sabesp/biogas.jpg" alt="Funcionário da Sabesp abastecendo veículo com biometano​
                            " title="Funcionário da Sabesp abastecendo veículo com biometano​
                            ">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Biogás</h2>
                        <p class="description">Biogás é um tipo de biocombustível, produzido a partir da decomposição de materiais orgânicos, e que pode ser aproveitado para geração de energia térmica, elétrica e como combustível veicular. A Sabesp tem um projeto inovador na Unidade de Beneficiamento de Biogás da Estação de Tratamento de Esgoto (ETE) Franca, que utiliza o gás gerado pelo esgoto para abastecer sua frota de veículos.</p>
                        <a class="link -white" href="/a-sabesp/inovacao-e-tecnologia/biogas" title="Saiba mais">Saiba mais</a>
                    </div>
                </div>
            </div>

            <div class="item -blue">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img class="tns-lazy-img" loading="lazy" fetchpriority="low" width="642" height="375" src="./assets/images/a-sabesp/usinas-fotovoltaicas.jpg" data-src="./assets/images/a-sabesp/usinas-fotovoltaicas.jpg" alt="Painéis fotovoltaicos da Sabesp para geração de energia​
                            " title="Painéis fotovoltaicos da Sabesp para geração de energia​
                            ">
                        </picture>
                    </div>

                    <div class="text">
                        <h2 class="title">Usinas Fotovoltaicas</h2>
                        <p class="description">A energia fotovoltaica é um tipo de energia renovável e limpa, gerada a partir da luz do sol. De olho na inovação e na sustentabilidade, a Sabesp criou o Programa de Geração de Energia Fotovoltaica, em que utilizamos nossas estações de tratamento de água e esgoto para instalar usinas que geram energia elétrica a partir da luz solar. </p>
                        <a class="link -white" href="/a-sabesp/inovacao-e-tecnologia/usinas-fotovoltaicas" title="Saiba mais">Saiba mais</a>
                    </div>
                </div>
            </div>

            <div class="item -tangelo" data-nav-color="black">
                <div class="container">
                    <div>
                        <picture class="picture">
                            <img class="tns-lazy-img" loading="lazy" fetchpriority="low" width="642" height="375" data-src="./assets/images/a-sabesp/sistema-oxigenacao.jpg" alt="Estação da Sabesp no projeto Ação Respira Rio Pinheiros​
                            " title="Estação da Sabesp no projeto Ação Respira Rio Pinheiros​
                            ">
                        </picture>
                    </div>

                    <div class="text" style="color: black;">
                        <h2 class="title">Sistema de Oxigenação</h2>
                        <p class="description">A energia fotovoltaica é um tipo de energia renovável e limpa, gerada a partir da luz do sol. De olho na inovação e na sustentabilidade, a Sabesp criou o Programa de Geração de Energia Fotovoltaica, em que utilizamos nossas estações de tratamento de água e esgoto para instalar usinas que geram energia elétrica a partir da luz solar. </p>
                        <a class="link -black" href="/a-sabesp/inovacao-e-tecnologia/sistema-de-oxigenacao" title="Saiba mais">Saiba mais</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
