import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-manual-template',
  templateUrl: './manual-template.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', 'manual-template.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualTemplateComponent {

}
