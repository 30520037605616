import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-mananciais',
  templateUrl: './mananciais.component.html',
  styleUrls: ['../../assets/css/components.css', '../../assets/css/internal.css', './mananciais.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MananciaisComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle("Sabesp | Mananciais");

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Mananciais'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Mananciais'
    });
  }
}
