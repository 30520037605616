import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lista-ensaios',
  standalone: true,
  imports: [],
  templateUrl: './lista-ensaios.component.html',
  styleUrls: ['/src/assets/css/internal.css', '/src/assets/css/components.css']
})
export class ListaEnsaiosComponent {
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sabesp | Lista de Ensaios ");
}
}
