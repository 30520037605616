import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-jornada-esg',
  standalone: true,
  imports: [],
  templateUrl: './jornada-esg.component.html',
  styleUrl: './jornada-esg.component.css'
})
export class JornadaEsgComponent {
  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {

    this.title.setTitle("Sabesp | Sabesp Sustentável");

    this.meta.updateTag({
      property: 'twitter:description',
      content: 'Descubra como a Sabesp promove a sustentabilidade através dos princípios ESG e alinhada aos Objetivos de Desenvolvimento Sustentável (ODS). Conheça nosso compromisso com o meio ambiente, a sociedade e as melhores práticas de governança. '
    });

    this.meta.updateTag({
      name: 'twitter:keywords',
      content: 'Sabesp, sustentabilidade, ESG, meio ambiente, sociedade, governança, Objetivos de Desenvolvimento Sustentável, ODS, Agenda 2030, gestão ambiental, práticas sustentáveis, saneamento. '
    });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Sabesp | Jornada ESG'
    });

    this.meta.updateTag({
      property: 'twitter:title',
      content: 'Sabesp | Jornada ESG'
    });
  }

}
