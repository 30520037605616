<main class="main">
	<meta name="description" content="Conheça os diferentes tipos de tratamento de esgotos utilizados pela Sabesp, incluindo lodo ativado, reatores anaeróbios, lagoas facultativas, lagoas anaeróbias, lagoas aeradas, baias de infiltração e flotação. Cada método é adaptado às características dos resíduos e contribui para a purificação e reutilização da água.">
		<nav class="breadcrumbs">
			<div class="container">
				<ul class="menu">
					<li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="O que Fazemos">O Que Fazemos</a></li>
					<li><a class="breadcrumb-link" href="/o-que-fazemos/solucoes-para-esgotos" title="Soluções para Esgotos">Soluções para Esgotos</a></li>
					<li><a class="breadcrumb-link" href="/o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos" title="Tratamento de Esgotos">Tratamento de Esgotos</a></li>
					<li><a class="breadcrumb-link" href="javascript:void(0);" title="Tipos de Tratamento">Tipos de Tratamento de Esgotos</a></li>
				</ul>

				<ul class="buttons">
					<li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
				</ul>
			</div>
		</nav>

		<article class="content">
			<div class="container">
				<h1 class="headline">Tipos de Tratamento de Esgotos</h1>

				<p>A Sabesp adota diferentes métodos para o tratamento dos esgotos. O processo utilizado depende das características físicas, químicas e biológicas dos resíduos.</p>
				<p>Conheça os tipos de tratamento de esgotos:</p>

				<div class="accordion">
					<div class="item" id="lodo-ativado">
						<h2 class="head"><a style="text-decoration: none;" (click)="accordionToggle($event)" href="#lodo-ativado">Lodo Ativado</a></h2>
						<div class="collapse" aria-hidden="true">
							<div class="body">
								<button type="button" (click)="accordionToggle($event)" class="close">Fechar</button>

								<p>É um método biológico utilizado nas estações de maior capacidade de tratamento. O processo remove a matéria orgânica com uma eficiência de aproximadamente 90% e separa o material em duas fases (partes): a líquida e a sólida.</p>
								<p>Após o processo de depuração da matéria orgânica nos tanques de aeração, e do processo de sedimentação das partículas sólidas que ocorre nos decantadores secundários, o efluente clarificado é devolvido ao meio ambiente.</p>
								<p>A fase sólida diz respeito ao tratamento e disposição da massa biológica, chamada de lodo, gerada durante o tratamento. Nesta etapa, são adicionados produtos químicos ao lodo para condicionamento e desaguamento em centrífugas ou filtros-prensa para aumentar o teor de sólidos para transporte e disposição final em aterros sanitários aprovados pelo órgão ambiental.</p>
							</div>
						</div>
					</div>

					<div class="item" id="reator-anaerobio-fluxo-ascendente">
						<h2 class="head"><a style="text-decoration: none;" (click)="accordionToggle($event)" href="#reator-anaerobio-fluxo-ascendente">Reator Anaeróbio de Fluxo Ascendente (RAFA)</a></h2>
						<div class="collapse" aria-hidden="true">
							<div class="body">
								<button type="button" (click)="accordionToggle($event)" class="close">Fechar</button>

								<p>É um reator fechado, em que o tratamento biológico ocorre por processo anaeróbio, isto é, sem oxigênio. A decomposição da matéria orgânica é feita por micro-organismos presentes num manto de lodo.</p>
								<p>O esgoto entra pela parte de baixo do reator e passa pela camada de lodo que atua como um filtro. A eficiência atinge entre 65% e 75% e, por isso, é necessário um tratamento complementar, que pode ser feito através da <a href="o-que-fazemos/solucoes-para-esgotos/tratamento-esgotos/tipos-tratamento#lagoa-facultativa">Lagoa Facultativa</a>.</p>
							</div>
						</div>
					</div>

					<div class="item" #lagoaFacultativa>
						<h2 class="head"><a style="text-decoration: none;" (click)="accordionToggle($event)" href="#lagoa-facultativa">Lagoa Facultativa</a></h2>
						<div class="collapse" aria-hidden="true">
							<div class="body">
								<button type="button" (click)="accordionToggle($event)" class="close">Fechar</button>

								<p>São lagoas constituídas de forma simples, com profundidade de 1,5 a 3 metros, em que os esgotos entram em uma extremidade e saem na oposta. O termo "facultativo" se refere à mistura de condições aeróbias (com oxigênio) e anaeróbias (sem oxigênio). Em lagoas facultativas, as condições aeróbias são mantidas nas camadas superiores das águas, enquanto as condições anaeróbias predominam em camadas próximas ao fundo da lagoa.</p>
								<p>Embora parte do oxigênio necessário para manter as camadas superiores aeróbias seja fornecido pelo ambiente externo, a maior parte vem da fotossíntese das algas, que crescem naturalmente em águas com grandes quantidades de nutrientes e energia da luz solar. As bactérias que vivem nas lagoas utilizam o oxigênio produzido pelas algas para oxidar a matéria orgânica. Um dos produtos finais desse processo é o gás carbônico, que é utilizado pelas algas na sua fotossíntese.</p>
								<p>Este tipo de tratamento é ideal para comunidades pequenas, normalmente situadas no interior do Estado.</p>
							</div>
						</div>
					</div>

					<div class="item" id="lagoa-aerada">
						<h2 class="head"><a style="text-decoration: none;" (click)="accordionToggle($event)" href="#lagoa-aerada">Lagoa Aerada</a></h2>
						<div class="collapse" aria-hidden="true">
							<div class="body">
								<button type="button" (click)="accordionToggle($event)" class="close">Fechar</button>

								<p>Nesse processo, é necessário oxigênio e a profundidade das lagoas varia de 2,5 a 4 metros. Aeradores são utilizados para garantir oxigênio no meio e manter os sólidos bem separados do líquido (em suspensão). A qualidade do esgoto que vem da lagoa aerada não é adequada para lançamento direto pelo fato de conter uma grande quantidade de sólidos. Por isso, são geralmente seguidas por lagoas de decantação para remoção dos sólidos.</p>
							</div>
						</div>
					</div>

					<div class="item" id="baias-valas-infiltracao">
						<h2 class="head"><a style="text-decoration: none;" (click)="accordionToggle($event)" href="#baias-valas-infiltracao">Baias e Valas de Infiltração</a></h2>
						<div class="collapse" aria-hidden="true">
							<div class="body">
								<button type="button" (click)="accordionToggle($event)" class="close">Fechar</button>

								<p>Trata-se de um tratamento complementar, que consiste na passagem do esgoto por um filtro instalado no solo, formado por pedregulho e areia.</p>
							</div>
						</div>
					</div>

					<div class="item" id="flotacao">
						<h2 class="head"><a style="text-decoration: none;" (click)="accordionToggle($event)" href="#flotacao">Flotação</a></h2>
						<div class="collapse" aria-hidden="true">
							<div class="body">
								<button type="button" (click)="accordionToggle($event)" class="close">Fechar</button>

								<p>É um processo físico-químico, no qual uma substância coagulante ajuda na formação de flocos. Com isso, as partículas ficam mais concentradas e fáceis de serem removidas. Para ajudar no tratamento, a água é pressurizada, formando bolhas que atraem as partículas e fazem com que elas flutuem na superfície. O lodo formado é removido e enviado para disposição final.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</article>
	</main>
