<main class="main">
    <meta name="description" content="Conheça o perfil institucional da Sabesp, líder em saneamento, comprometida com a qualidade e inovação em serviços de água e esgoto.">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a class="breadcrumb-link" href="/" title="Início">Início</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Item">A Sabesp</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Item">Institucional</a></li>
                <li><a class="breadcrumb-link" href="javascript:void(0);" title="Item">Perfil</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home breadcrumb-link" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Perfil</h1>

            <p>A Sabesp é hoje a maior empresa de saneamento do Brasil e uma das maiores do mundo. Fornecemos água, coleta e tratamento de esgotos para 375 municípios do Estado de São Paulo. Abastecemos, diariamente, 28,4 milhões de pessoas com água e 25,2 milhões de pessoas com coleta de esgotos.</p>
            <p>Nossa história começou em 1973, com a fusão de empresas públicas ou sociedades controladas pelo Estado de São Paulo, ligadas ao saneamento básico. Inicialmente, foram incorporadas à Sabesp as empresas que prestavam serviços na Região Metropolitana de São Paulo, Baixada Santista e Vale do Ribeira.</p>

            <picture class="picture align-right">
                <img loading="eager" fetchpriority="low" width="650" height="390" src="./assets/images/sabesp-institucional.jpg" alt="Equipe da Sabesp reunida em formato de logo​" title="Equipe da Sabesp reunida em formato de logo​"
                >
            </picture>

            <p>São mais de 50 anos de trabalho e dedicação, que nos trouxeram até aqui e nos tornaram responsáveis por cerca de 30% do investimento em saneamento básico feito no Brasil. Para o período 2024-2028, devem ser investidos aproximadamente R$ 47,4 bilhões, com foco na ampliação da disponibilidade e segurança hídrica. </p>
            <p>Em 2023, alcançamos mais um marco importante: a Sabesp venceu a licitação para gestão do saneamento de Olímpia. Foi a primeira concessão que a Companhia ganhou na sua história em um processo competitivo. </p>
            <p>Compreendemos que nosso trabalho tem um papel essencial na sociedade e uma enorme importância na regeneração do meio ambiente. Também não perdemos de vista que precisamos ser exemplo de solidez, transparência e integridade na nossa governança, tanto para o mercado quanto para a sociedade em geral. </p>

            <p>Seguimos evoluindo e enfrentando os desafios para, assim, garantirmos um mundo melhor para as próximas gerações. </p>


            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                        <tr>
                            <th colspan="2">Água</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ligações Cadastradas de Água</td>
                            <td width="339" class="-txt-blue -fw-medium">10,3 Milhões</td>
                        </tr>

                        <tr>
                            <td>Estações de Tratamento de Água</td>
                            <td class="-txt-blue -fw-medium">235</td>
                        </tr>
                        <tr>
                            <td>Reservatórios</td>
                            <td class="-txt-blue -fw-medium">2.750</td>
                        </tr>
                        <tr>
                            <td>Capacidade do Armazenamento de Água (Reservatórios)</td>
                            <td class="-txt-blue -fw-medium">3,8 Bilhões de Litros</td>
                        </tr>
                        <tr>
                            <td>Poços</td>
                            <td class="-txt-blue -fw-medium">1.218</td>
                        </tr>
                        <tr>
                            <td>Adutoras</td>
                            <td class="-txt-blue -fw-medium">6,2 Mil Quilômetros</td>
                        </tr>
                        <tr>
                            <td>Redes de Distribuição de Água</td>
                            <td class="-txt-blue -fw-medium">86,9 Mil Quilômetros</td>
                        </tr>
                        <tr>
                            <td>Centrais de Controle Sanitário</td>
                            <td class="-txt-blue -fw-medium">16</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive">
                <table class="table col-striped" style="min-width: 700px;">
                    <thead>
                        <tr>
                            <th colspan="2">Esgoto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ligações Cadastradas de Esgotos</td>
                            <td class="-txt-blue -fw-medium" width="339">8,8 Milhões</td>
                        </tr>
                        <tr>
                            <td>Estações de Tratamento de Esgotos</td>
                            <td class="-txt-blue -fw-medium">596</td>
                        </tr>
                        <tr>
                            <td>Redes Coletoras de Esgotos</td>
                            <td class="-txt-blue -fw-medium">60,7 Mil Quilômetros</td>
                        </tr>
                        <tr>
                            <td>Coletores, Emissários e Interceptores</td>
                            <td class="-txt-blue -fw-medium">2,9 Mil Quilômetros</td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </article>

</main>
