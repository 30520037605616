<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="O Que Fazemos">O Que Fazemos</a></li>
                <li><a href="/o-que-fazemos/fornecimento-agua" title="Fornecimento de Água">Fornecimento de Água</a></li>
                <li><a class="breadcrumb-link" href="/o-que-fazemos/fornecimento-agua/captacao-agua" title="Captação de água">Captação de água</a></li>
                <li><a href="javascript:void(0);" title="Mananciais">Mananciais</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="/" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">
            <h1 class="headline">Mananciais</h1>

            <picture class="banner -no-shadow">
                <img loading="eager" fetchpriority="high" width="1300" height="244" src="./assets/images/mananciais.jpg" alt="Mananciais" title="Mananciais">
            </picture>

            <p><b class="-txt-blue">A água que consumimos é retirada de rios, lagos, poços e represas criadas artificialmente.</b> Esse processo de buscar água na natureza para abastecimento se chama captação. Conservar e proteger essas áreas é fundamental para garantir água em quantidade e qualidade adequadas.</p>
        </div>
    </article>

    <section class="content -gray">
      <span id="mananciais" class="anchor-offset"></span>
        <div class="container">
            <h2 class="subheadline">O que são Mananciais</h2>

            <p><b>Mananciais são as fontes de água ou reservas hídricas para abastecimento público e podem ser superficiais, como represas, lagos e rios, ou subterrâneas, como poços.</b></p>
            <p>Na Região Metropolitana de São Paulo, a principal fonte utilizada para abastecimento público é a água armazenada em barragens ou represas. Já no Interior do Estado, é comum o uso da água de poços profundos, provenientes de mananciais subterrâneos e que também inspiram proteção e cuidados. No litoral, há mananciais de serra e rios imprescindíveis ao desenvolvimento das regiões.</p>
        </div>
    </section>

    <section class="content">
      <span id="captacao-superficial" class="anchor-offset"></span>
        <div class="container">
            <div class="g-columns -gap-right -v-center">
                <div>
                    <picture class="picture">
                        <img loading="lazy" fetchpriority="low" width="650" height="294" data-src="./assets/images/captacao-superficial.jpg" alt="Captação Superficial" title="Captação Superficial">
                    </picture>
                </div>
                <div>
                    <h2 class="subheadline">Captação Superficial</h2>
                    <p><b>As represas têm a finalidade de acumular água no período de chuvas para ser utilizada durante o período seco ou de estiagem, permitindo o fornecimento de água ao longo do ano.</b> Elas também possuem um papel fundamental no controle das cheias, pois, no período de fortes chuvas, retêm parte da vazão que chegaria aos rios, córregos e centros urbanos, armazenando e liberando os volumes de água aos poucos, de forma controlada, evitando ou reduzindo o impacto de inundações.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="content -gray">
      <span id="captacao-subterranea" class="anchor-offset"></span>
        <div class="container">
            <h2 class="subheadline">Captação Subterrânea</h2>

            <p>Água subterrânea é aquela existente no subsolo. Através da construção de poços artesianos, essa água pode ser captada e utilizada no abastecimento público.</p>
            <p>Em muitos dos municípios do interior de São Paulo atendidos pela Sabesp, o abastecimento é feito por meio de poços - <strong class="-txt-blue">são mais de 1.000, atendendo cerca de 3 milhões de pessoas</strong>. Na Região Metropolitana, cerca de 60 poços atendem as regiões não abastecidas pelo Sistema Integrado, também conhecido como Sistema Adutor Metropolitano.</p>
            <p>O poço mais profundo que a Sabesp possui fica em Presidente Prudente. Ele tem 1.794,97 metros de profundidade e vazão de exploração de 110 litros por segundo. Há também poços profundos que precisam ser resfriados, pois, na captação, apresentam temperaturas elevadas. É o caso de Jales, com 58 ºC, Fernandópolis, com 57 ºC e Tupã com 48 ºC.</p>
            <p>É importante ressaltar que a Sabesp não recomenda a utilização de poços ilegais como fontes alternativas de abastecimento. Além de não possuírem registro e autorização do Governo, <b>em muitos casos não há o controle de qualidade exigido pelo Ministério da Saúde, trazendo graves riscos à saúde pública</b>.</p>
        </div>
    </section>

    <section class="content">
      <span id="monitoramento" class="anchor-offset"></span>
        <div class="container">
            <h2 class="subheadline">Monitoramento dos Mananciais</h2>

            <p><strong class="-txt-blue">A Sabesp monitora e preserva mais de 44 mil hectares</strong>, o equivalente a quase 200 parques do Ibirapuera. São 9 mil hectares de espelho d'água e 35 mil hectares de área no entorno dos mananciais que abastecem os sistemas produtores de água Rio Claro (Parque Estadual da Serra do Mar), Alto Cotia (Reserva do Morro Grande), Guarapiranga (Área de Proteção Ambiental Capivari-Monos), Alto Tietê (represa Taiaçupeba) e Cantareira (represas Atibainha, Cachoeira, Jacareí, Jaguari e Paiva Castro).</p>
            <p>O monitoramento dos mananciais é realizado em pontos estratégicos de cada represa para acompanhar a qualidade da água que será captada para tratamento e posteriormente distribuída à população. Esse monitoramento é realizado através de sondas e coletas de amostras de água realizada por equipes da Sabesp com frequências semanais ou mensais. As amostras de água coletadas são analisadas em laboratório da própria companhia.</p>
            <p><b>As reservas monitoradas pela Sabesp representam 1,4% do remanescente de Mata Atlântica do Estado de São Paulo e protegem a água consumida por mais de 14 milhões de moradores da capital e da Grande SP.</b></p>
            <p>Você pode consultar <a style="text-decoration: none;" href="/o-que-fazemos/fornecimento-agua/qualidade-agua/relatorio-qualidade-agua-mananciais" title="Relatórios de Qualidade da Água dos Mananciais"><strong>aqui</strong></a> os relatórios de Monitoramento da Qualidade da Água dos Mananciais da sua cidade e outros municípios atendidos pela Sabesp.</p>
        </div>
    </section>
</main>