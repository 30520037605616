<main class="main">
    <nav class="breadcrumbs">
        <div class="container">
            <ul class="menu">
                <li><a href="/" title="Início">Início</a></li>
                <li><a href="javascript:void(0);" title="Sustentabilidade">Sustentabilidade</a></li>
                <li><a href="/sustentabilidade/transparencia" title="Transparência">Transparência</a></li>
                <li><a href="/sustentabilidade/transparencia/sabesp-e-esg" title="Sabesp e ESG">Sabesp e ESG</a></li>
                <li><a href="/sustentabilidade/transparencia/sabesp-e-esg/codigo-conduta-integridade" title="Código de Conduta e Integridade">Código de Conduta e Integridade</a></li>
            </ul>

            <ul class="buttons">
                <li><a class="button -home" href="./" title="Início">Início</a></li>
            </ul>
        </div>
    </nav>

    <article class="content">
        <div class="container">

            <h1 class='headline'>Código de Conduta e Integridade</h1>
            <p>O Código de Conduta e Integridade da Sabesp foi construído de forma colaborativa e resume o conjunto de princípios e normas que direcionam a forma como a empresa irá se relacionar com seus diversos públicos de interesse: dirigentes, empregados, clientes, fornecedores, acionistas, comunidade e sociedade em geral.</p>
            <p>A adoção do Código de Conduta e Integridade  é essencial para que a Sabesp consolide e amplie sua reputação de empresa socialmente responsável.</p>
            
            <br><br><h2>Documento completo</h2>    
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/668bd038-88fc-4155-8ad1-45153290f57e?origin=2">Veja o arquivo na íntegra (PDF)</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://api.mziq.com/mzfilemanager/v2/d/9e47ee51-f833-4a23-af98-2bac9e54e0b3/668bd038-88fc-4155-8ad1-45153290f57e?origin=2"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            
            <br><br><h2>Canal de Denúncia</h2>
            <p>A Superintendência de Auditoria é responsável pelo recebimento e apuração de denúncias sobre ocorrências de fraude, corrupção, atos ilícitos, transgressões ao Código de Conduta e Integridade, ou sobre outras questões que possam acarretar prejuízos aos princípios e interesses da Sabesp, suas subsidiárias e acionistas.</p>
            <div class='table-responsive'><table class='table col-striped' style='min-width: 700px;'><thead><tr><th></th><th class='txt-center' width='200'>Ver/Baixar</th></tr></thead><tbody>
            <tr><td><a target="_blank" rel="noopener noreferrer" class="-link" href="https://www.contatoseguro.com.br/sabesp">Acesse: https://www.contatoseguro.com.br/sabesp</a></td><td class="txt-center"><!-- link -link-external download --><a target="_blank" class="action-icon -link-external" href="https://www.contatoseguro.com.br/sabesp"><span>Acessar</span></a></td></tr>
            </tbody></table></div>
            

        </div>
    </article>


</main>